(function (root, factory) {
    const pluginName = 'PearsonPagination';

    if (typeof define === 'function' && define.amd) {
        define([], factory(pluginName));
    } else if (typeof exports === 'object') {
        module.exports = factory(pluginName);
    } else {
        root[pluginName] = factory(pluginName);
    }
// eslint-disable-next-line no-unused-vars
}(this, (pluginName) => {
    /**
     * Default options
     */
    const defaults = {
        // Initial settings
        current: 1,
        numberPerPage: 10,
        activePageClass: 'isActive',
        mobileScreenSize: 768,
        // Items to paginate
        itemsWrapperClass: 'products',
        itemsClass: 'box',
        // Pagination elements
        paginationComponentClass: 'c-paging',
        pagesClass: 'c-paging__page',
        paginationInfoClass: 'c-paging__info',
        productShowCountClass: 'product-show-count',
        paginationItemStart: 'pagination-item-start',
        paginationItemEnd: 'pagination-item-end',
        paginationItemTotals: 'pagination-item-totals',
        previousBtn: 'prev',
        nextBtn: 'next',
    };

    let state = {};

    /**
     * Merge defaults with user options
     @param {Object} defaults Default settings
     @param {Object} options User options
     */
    // eslint-disable-next-line no-shadow
    const extend = function (defaults, options) {
        let prop; const
            extended = {};
            // eslint-disable-next-line no-restricted-syntax
        for (prop in defaults) {
            if (Object.prototype.hasOwnProperty.call(defaults, prop)) {
                extended[prop] = defaults[prop];
            }
        }
        // eslint-disable-next-line no-restricted-syntax
        for (prop in options) {
            if (Object.prototype.hasOwnProperty.call(options, prop)) {
                extended[prop] = options[prop];
            }
        }
        return extended;
    };

    /**
     * Set button element in enabled state
     @param {HTMLElement} button Button element
     */
    const enableButton = function (button) {
        if (!(button instanceof HTMLElement)) throw new Error('Please provide valid HTML element!');

        button.removeAttribute('disabled');
        button.removeAttribute('aria-disabled');
    };

    /**
     * Set Button in disabled state
     @param {HTMLElement} button Button element
     */
    const disableButton = function (button) {
        if (!(button instanceof HTMLElement)) throw new Error('Please provide valid HTML element!');

        button.setAttribute('disabled', 'disabled');
        button.setAttribute('aria-disabled', 'true');
    };

    /**
     * Update Buttons state for current page
     @param {Number} currentPage Current page number
     */
    const updateButtons = function (currentPage) {
        const prevBtnSelector = `.${state.paginationComponentClass}>.${state.previousBtn}`;
        const nextBtnSelector = `.${state.paginationComponentClass}>.${state.nextBtn}`;
        const prevButton = document.querySelector(prevBtnSelector);
        const nextButton = document.querySelector(nextBtnSelector);

        switch (true) {
        case (state.pageCount === 1):
            // First page is active
            disableButton(prevButton);
            disableButton(nextButton);
            break;
        case (currentPage === 1):
            // First page is active
            disableButton(prevButton);
            enableButton(nextButton);
            break;
        case (currentPage === state.pageCount):
            // Last page is active
            disableButton(nextButton);
            enableButton(prevButton);
            break;
        default:
            // Previous and next buttons are enabled
            enableButton(prevButton);
            enableButton(nextButton);
        }
    };

    /**
     * Set visible items for current page
     @param {Number} currentPage Current page number
     */
    const updateItems = function (currentPage) {
        if (!currentPage) return;

        // Set first and last item per page
        const startItem = (currentPage - 1) * state.numberPerPage + 1;
        const lastItem = startItem + state.numberPerPage - 1;

        // Fetch items
        const itemsSelector = `.${state.itemsWrapperClass} > .${state.itemsClass}[data-item-visibility="true"]`;
        const items = document.querySelectorAll(itemsSelector);
        if (!items.length) throw new Error('Items elements missing!');

        // Toggle items visibility
        let itemIndex;
        items.forEach((item, index) => {
            // eslint-disable-next-line no-plusplus
            itemIndex = ++index;
            if (itemIndex >= startItem && itemIndex <= lastItem) {
                item.dataset.itemPagination = true;
            } else {
                item.dataset.itemPagination = false;
            }
        });

        // Update pagination status text
        const paginationItemCountStart = document.querySelectorAll(`.${state.paginationItemStart}`);
        const paginationItemCountEnd = document.querySelectorAll(`.${state.paginationItemEnd}`);
        const paginationItemCountTotals = document.querySelectorAll(`.${state.paginationItemTotals}`);

        paginationItemCountStart.forEach((el) => {
            el.textContent = startItem;
        });

        paginationItemCountEnd.forEach((el) => {
            if (lastItem <= items.length) {
                el.textContent = lastItem;
            } else if (lastItem > items.length) {
                el.textContent = items.length;
            } else {
                el.textContent = lastItem - 1;
            }
        });

        paginationItemCountTotals.forEach((el) => {
            el.textContent = items.length;
        });
    };

    /**
     * Update Pagination Info text content for current page
     @param {Number} currentPage Current page number
     */
    const updatePaginationInfo = function (currentPage) {
        const paginationInfo = document.querySelector(`.${state.paginationInfoClass}`);
        if (!paginationInfo) throw new Error('Pagination info element missing!');

        paginationInfo.textContent = `${currentPage} of ${state.pageCount}`;
    };

    /**
     * Set page in active state
     @param {Number} currentPage Current page number
     */
    const updateActivePage = function (currentPage) {
        const pagesSelector = `.${state.paginationComponentClass} > .${state.pagesClass}`;
        const pages = document.querySelectorAll(pagesSelector);

        if (!pages.length) throw new Error('Page elements missing!');

        pages.forEach((el) => {
            if (Number(el.textContent) === currentPage) {
                el.classList.add(state.activePageClass);
                el.setAttribute('aria-current', 'true');
            } else {
                el.classList.remove(state.activePageClass);
                el.removeAttribute('aria-current');
            }
        });
    };

    /**
     * Decrement current page
     */
    const previousPage = function () {
        let currentPage = state.current;
        // eslint-disable-next-line no-plusplus,no-use-before-define
        goTo(--currentPage);
    };

    /**
     * Increment current page
     */
    const nextPage = function () {
        let currentPage = state.current;
        // eslint-disable-next-line no-use-before-define,no-plusplus
        goTo(++currentPage);
    };

    /**
     * Create dots element - Used in truncated version of pagination
     */
    const createDots = function () {
        const dots = document.createElement('span');
        dots.textContent = '...';
        dots.classList.add(state.pagesClass);
        dots.classList.add(state.truncClass);
        return dots;
    };

    /**
     * Create page element with specific number
     * @param {Number} pageNumber Number of page
     */
    const createPage = function (pageNumber) {
        const page = document.createElement('a');
        page.textContent = pageNumber;
        page.classList.add(state.pagesClass);
        page.href = `#${state.itemWrapperId}`;
        page.ariaLabel = `Go to Page ${pageNumber}`;

        page.addEventListener('click', () => {
            // eslint-disable-next-line no-use-before-define
            goTo(pageNumber);
        });

        return page;
    };

    /**
     * Set pagination and items visibility based on page number
     @param {Number} currentPage Current page number
     @param {Number} pageCount Total number of pages
     @param {Number} step Number of pages around active page
     */
    const createPageNumbers = function (currentPage, pageCount, step) {
        const paginationWrapper = document.querySelector(`.${state.paginationComponentClass}`);

        const pages = paginationWrapper.querySelectorAll(`.${state.pagesClass}`);

        // Remove all pages
        if (pages.length > 0) {
            pages.forEach((el) => {
                el.remove();
            });
        }

        const pagesHolder = document.createDocumentFragment();

        switch (true) {
        case pageCount < (step * 2 + 4):
            //  No need for truncation
            /*  Example
                     *  < 1 2 '3' 4 5 6 >
                     */

            for (let i = 1; i <= pageCount; i += 1) {
                pagesHolder.appendChild(createPage(i));
            }
            break;
        case currentPage < (step * 2 + 2):
            //  Somewhere at the beginning
            /*  Example
                     *  < 1 2 '3' 4 5 6 ... 20 >
                     */
            for (let i = 1; i <= (step * 2 + 1); i += 1) {
                pagesHolder.appendChild(createPage(i));
            }

            pagesHolder.appendChild(createDots());
            pagesHolder.appendChild(createPage(state.pageCount));
            break;
        case currentPage > (pageCount - step * 2 - 1):
            /* Somewhere at the  end
                     *  Example
                     *  < 1 ...  16 17 '18' 19 20 >
                     */
            pagesHolder.appendChild(createPage(1));
            pagesHolder.appendChild(createDots());

            for (let i = (pageCount - step * 2); i <= pageCount; i += 1) {
                pagesHolder.appendChild(createPage(i));
            }
            break;
        default:
            /*  Somewhere in the middle
                     *  Example
                     *  < 1 ... 5 6 '7' 8 9 ... 20 >
                     */
            pagesHolder.appendChild(createPage(1));
            pagesHolder.appendChild(createDots());

            for (let i = (currentPage - step); i <= (currentPage + step); i += 1) {
                pagesHolder.appendChild(createPage(i));
            }

            pagesHolder.appendChild(createDots());
            pagesHolder.appendChild(createPage(state.pageCount));
            break;
        }

        paginationWrapper.insertBefore(
            pagesHolder,
            paginationWrapper.lastElementChild,
        );
    };

    /**
     * Set pagination and items visibility based on page number
     @param {Number} pageNumber Number of page
     */
    const goTo = function (pageNumber) {
        if (pageNumber <= 0) { pageNumber = 1; }

        if (pageNumber > state.pageCount) { pageNumber = state.pageCount; }

        // Update current page
        state.current = pageNumber;

        // Set pagination
        updateButtons(state.current);
        updatePaginationInfo(state.current);

        // Set items visibility
        updateItems(state.current);

        // Skip creating page elements if the client is mobile
        if (state.isMobile) return;

        createPageNumbers(state.current, state.pageCount, 1);
        updateActivePage(state.current);
    };

    /**
     * Set initial state
     @param {Object} options Merged default and user settings
     @param {Number} itemsLength Items length
     @param {Number} innerWidth Available screen width
     */
    const setState = function (options, itemsLength, innerWidth) {
        state = options;
        state.isMobile = options.mobileScreenSize >= innerWidth;
        state.itemsLength = itemsLength;
        state.pageCount = Math.ceil(itemsLength / options.numberPerPage);
    };

    /**
     * Sort items by name from A to Z
     @param {Object} a First item
     @param {Object} b Second item
     */
    function sortByNameAsc(a, b) {
        const firstEl = a.querySelector('.product-title').textContent;
        const secondEl = b.querySelector('.product-title').textContent;

        return firstEl > secondEl ? 1 : -1;
    }

    /**
     * Sort items by name from Z to A
     @param {Object} a First item
     @param {Object} b Second item
     */
    function sortByNameDesc(a, b) {
        const firstEl = a.querySelector('.product-title').textContent;
        const secondEl = b.querySelector('.product-title').textContent;

        return firstEl < secondEl ? 1 : -1;
    }

    /**
     * Sort items by price from Low to High
     @param {Object} a First item
     @param {Object} b Second item
     */
    function sortByPriceAsc(a, b) {
        // Get number from price field (remove currency sign)
        const firstEl = a.querySelector('.product-price').textContent.replace(/\D/g, '');
        const secondEl = b.querySelector('.product-price').textContent.replace(/\D/g, '');

        return Number(firstEl) > Number(secondEl) ? 1 : -1;
    }

    /**
     * Sort items by price from High to Low
     @param {Object} a First item
     @param {Object} b Second item
     */
    function sortByPriceDesc(a, b) {
        const firstEl = a.querySelector('.product-price').textContent.replace(/\D/g, '');
        const secondEl = b.querySelector('.product-price').textContent.replace(/\D/g, '');

        return Number(firstEl) < Number(secondEl) ? 1 : -1;
    }

    /**
     * Sort items as they are recommended
     @param {Object} a First item
     @param {Object} b Second item
     */
    function sortByRecommended(a, b) {
        const firstEl = a.dataset.sort;
        const secondEl = b.dataset.sort;

        return Number(firstEl) > Number(secondEl) ? 1 : -1;
    }

    /**
     * Sort items based on sort criteria
     @param {String} sortBy sort criteria
     */
    function sortElementsBy(sortBy) {
        if (!sortBy) {
            sortBy = 'relevance';
        }
        const itemsListSelector = `.${state.itemsWrapperClass}`;
        const itemsList = document.querySelector(itemsListSelector);

        const itemselector = `.${state.itemsWrapperClass} > .${state.itemsClass}[data-item-visibility="true"]`;
        const items = itemsList.querySelectorAll(itemselector);
        if (!items.length) throw new Error('Items elements missing!');

        const itemsArr = Array.from(items);

        // eslint-disable-next-line default-case
        switch (sortBy) {
        case 'relevance':
            itemsArr.sort(sortByRecommended);
            break;
        case 'productName-asc':
            itemsArr.sort(sortByNameAsc);
            break;
        case 'productName-desc':
            itemsArr.sort(sortByNameDesc);
            break;
        case 'price-desc':
            itemsArr.sort(sortByPriceDesc);
            break;
        case 'price-asc':
            itemsArr.sort(sortByPriceAsc);
            break;
        }

        // Clear the list
        items.forEach((el) => {
            el.remove();
        });

        // Append elements before footer
        const ppFooter = itemsList.querySelector('.program-page__footer');

        // eslint-disable-next-line array-callback-return
        itemsArr.map((el) => {
            itemsList.insertBefore(el, ppFooter);
        });

        goTo(1);
    }

    /**
     * Handle sort by select on change
     @param {Object} e Event
     */
    const sortByOnChangeHandler = function (e) {
        const sortBy = e ? e.target.value : 'relevance';
        sortElementsBy(sortBy);
    };

    /**
     * Initialize pagination
     @param {Object} options Merged default and user settings
     */
    const initialize = function (options) {
        // Fetch pagination wrapper element
        const paginationWrapper = document.querySelector(`.${options.paginationComponentClass}`);
        if (!(paginationWrapper instanceof HTMLElement)) return;

        // Fetch items to paginate
        const itemsSelector = `.${options.itemsWrapperClass}>.${options.itemsClass}`;
        const items = document.querySelectorAll(itemsSelector);
        if (items.length <= 0) throw new Error('Please provide correct class for items!');

        setState(options, items.length, window.innerWidth);

        // Set previous button
        const prevBtn = paginationWrapper.querySelector(`.${options.previousBtn}`);
        prevBtn.href = `#${options.itemWrapperId}`;
        if (!prevBtn) throw new Error('Previous button missing!');
        prevBtn.addEventListener('click', previousPage);

        // Set next button
        const nextBtn = paginationWrapper.querySelector(`.${options.nextBtn}`);
        nextBtn.href = `#${options.itemWrapperId}`;
        if (!nextBtn) throw new Error('Next button missing!');
        nextBtn.addEventListener('click', nextPage);

        goTo(options.current);

        // Set up pagination header
        if (!options.sortByElementId) return;

        const sortBySelect = document.querySelector(`#${options.sortByElementId}`);
        if (!(sortBySelect instanceof HTMLElement)) return;

        sortBySelect.addEventListener('change', sortByOnChangeHandler);
    };

    /**
     * Plugin Object
     @param {Object} options User options
     @constructor
     */
    function Plugin(options) {
        this.options = extend(defaults, options);

        this.init(this.options);
    }

    /**
     * Plugin prototype
     @public
     */
    Plugin.prototype.init = function (options) {
        initialize(options);
    };

    /**
     * Fetch all filtered items, set current page to 1 and reset pageCount
     */
    Plugin.prototype.update = function () {
        // Fetch items
        const itemsSelector = `.${state.itemsWrapperClass} > .${state.itemsClass}[data-item-visibility="true"]`;
        const items = document.querySelectorAll(itemsSelector);
        if (!items.length) throw new Error('Items elements missing!');

        state.current = 1;
        state.itemsLength = items.length;
        state.pageCount = Math.ceil(items.length / state.numberPerPage);

        // Set up pagination header
        if (!state.sortByElementId) return;

        const sortBySelect = document.querySelector(`#${state.sortByElementId}`);
        if (!(sortBySelect instanceof HTMLElement)) return;

        goTo(1);
    };

    /**
     * Function to sort items based on selected sort option
     */
    Plugin.prototype.updateSort = function () {
        const sortBySelect = document.querySelector(`#${state.sortByElementId}`);
        if (!(sortBySelect instanceof HTMLElement)) return;
        sortElementsBy(sortBySelect.value);
    };

    /**
     * Function to update the pagination and info when changing the count view per page
     */

    Plugin.prototype.updateNumPerPage = function (number) {
        state.numberPerPage = Number(number);

        state.current = 1;
        state.pageCount = Math.ceil(state.itemsLength / state.numberPerPage);

        goTo(1);
    };

    return Plugin;
}));
