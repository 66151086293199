ACC.cmsswitchercomponent = {

    _autoload: [
        'adjustMarkup',
        'bindCMSSwitcherComponent',
        'bindCMSSwitcherSelection',
    ],

    tabStoragePath: $('h1.c-sectionHeader.hasBorderBottom').text(),

    bindCMSSwitcherComponent() {
        $('.js-cms-switcher-component').each((index, component) => {
            const firstEntry = $(component).find('.cms-switcher-component__entry:first').addClass('active');
            const target = $(firstEntry).data('target-uid');

            $(`[data-cms-switcher-target="${target}"]`).show();
        });
    },

    bindCMSSwitcherSelection() {
        const activeClass = 'active';
        $('.js-cms-switcher-component-entry').on('click', function () {
            if (!$(this).hasClass(activeClass)) {
                $('.js-cms-switcher-component .active').removeClass(activeClass);
                $(this).addClass(activeClass);
            } else {
                $(this).addClass(activeClass);
            }

            // eslint-disable-next-line max-len
            $(this).closest('.js-cms-switcher-component').find('.cms-switcher-component__entry').each((index, entry) => {
                const target = $(entry).data('target-uid');
                $(`[data-cms-switcher-target="${target}"]`).hide();
            });

            const currentSelectionTargets = $(this).data('target-uid');
            $(`[data-cms-switcher-target="${currentSelectionTargets}"]`).show();

            // Update mobile drop down
            $('#ctx-selector').val($(this).data('target-uid')).trigger('change');
        });

        // Drop down select for mobile
        $('.page-updateProfileAndPassword #ctx-selector').change(function () {
            $('.account-section-form.white-card').hide();

            const currentSelectionTargets = $('option:selected', this).data('target-uid');
            $(`[data-cms-switcher-target="${currentSelectionTargets}"]`).show();

            ACC.accounts.context.contextUid = $('option:selected', this).data('target-uid');

            // Update desktop context selector
            $('.page-updateProfileAndPassword .context-menu>ul>li').each(function () {
                const eachList = $(this);
                const currentUID = eachList.data('target-uid');
                if (currentUID === ACC.accounts.context.contextUid) {
                    $('.accountPageSideContent .active').removeClass(activeClass);
                    $(`.js-cms-switcher-component-entry[data-target-uid="${currentUID}"]`).addClass(activeClass);
                }
            });
        });
    },

    adjustMarkup() {
        // Removing border from section header
        $('.page-updateProfileAndPassword .c-sectionHeader').removeClass('hasBorderBottom');

        // Remove div and p tags inside buttons
        $('.js-cms-switcher-component-entry button').find('div.content, p').contents().unwrap();

        // Copy button labels to mobile select menu
        const buttonsText = [];
        $('.js-cms-switcher-component-entry button').each(function () {
            buttonsText.push($(this).text());
        });

        $('.page-updateProfileAndPassword #ctx-selector option').each(function (index) {
            $(this).text(buttonsText[index]);
        });
    },
};
