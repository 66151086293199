ACC.imagegallery = {

    _autoload: [
        'bindImageGallery',
    ],

    bindImageGallery() {
        $('.js-gallery').each(function () {
            const $image = $(this).find('.js-gallery-image');
            const $carousel = $(this).find('.js-gallery-carousel');

            // main 'stage'
            $image.each(function () {
                if ($(this).closest('.modal-content--container-altMethod').length === 0) {
                    $image.owlCarousel({
                        singleItem: true,
                        autoHeight: true,
                        pagination: false,
                        navigation: false,
                        mouseDrag: false,
                        touchDrag: false,
                        addClassActive: true,
                        afterMove: true,
                        lazyLoad: true,
                        afterLazyLoad() {
                            const b = $image.data('owlCarousel') || {};
                            if (!b.currentItem) {
                                b.currentItem = 0;
                            }
                        },

                        afterInit() {
                            /*  Magic Box customizations

                        Note: only for carousels rendered
                        inside .magic-box__media (program page)
                        ======================================= */
                            const mb_gallery = $('.magic-box__media').find('.js-gallery');
                            // eslint-disable-next-line max-len
                            const mb_gallery_wrapper = $('#magic-box').find('.image-gallery__image').find('.owl-wrapper-outer');

                            // make visible once DOM changes are complete
                            mb_gallery.addClass('is-visible');

                            // insert expander control
                            if (!$('.magic-box__img-expander').length) {
                                mb_gallery_wrapper
                                    // eslint-disable-next-line max-len
                                    .prepend('<a href="javascript:void(0)" class="magic-box__img-expander" target="_blank" rel="noopener noreferer"><span class="screenreader">Expand image in modal/new tab/new window"</span><i aria-hidden="true"></i></a>');
                            }
                        },

                        afterAction() {
                            ACC.imagegallery.syncPosition($image, $carousel, this.currentItem);

                            /*  Magic Box customizations

                        Note: only for carousels rendered
                        inside .magic-box__media (program page)
                        ======================================= */

                            if ($('.magic-box__media').length > 0) {
                                const mb_gallery_stage = $('.magic-box__media').find('.js-gallery-image');
                                const mb_active_stage_item = mb_gallery_stage.find('.owl-item.active');
                                // eslint-disable-next-line max-len
                                const mb_active_src = mb_active_stage_item.find('img.lazyOwl').attr('data-superzoom-src');
                                const mb_expander = mb_gallery_stage.find('.magic-box__img-expander');

                                mb_expander.attr('href', mb_active_src);
                            }
                        },

                    });
                }
            });

            // thumbnail img nav
            $carousel.owlCarousel({
                navigation: false,
                pagination: false,
                mouseDrag: false,
                touchDrag: false,
                items: 4,
                itemsDesktop: false,
                itemsDesktopSmall: false,
                itemsTablet: false,
                itemsTabletSmall: false,
                itemsMobile: false,
                lazyLoad: true,
            });

            $carousel.on('click', 'a.item', function (e) {
                e.preventDefault();
                $image.trigger('owl.goTo', $(this).parent('.owl-item').data('owlItem'));
            });
        });
    },

    syncPosition() {
        // ensure active item in thumbnav remains in sync with stage
        if ($('.magic-box__media').length > 0) {
            const mb_media = $('.magic-box__media');
            const mb_expander = $('.magic-box__img-expander');
            const modal_img = $('.modal-media--img');
            const mb_gallery = mb_media.find('.js-gallery-image');
            const active_media = mb_gallery.find('.owl-item.active');
            const active_media_img = active_media.find('img.lazyOwl');
            const active_media_src = active_media_img.attr('data-superzoom-src');
            const active_media_alt = active_media_img.attr('alt');

            mb_expander.attr('href', active_media_src);

            modal_img
                .attr({
                    src: active_media_src,
                    alt: active_media_alt,
                });
        }
    },

};
