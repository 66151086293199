ACC.countryProductAvailability = {
    _autoload: [
        'init',
    ],

    init() {
        /**
         * DMCP is a global geolocation object
         * If DMCP doent exist, return from countryProductAvailability property
         */
        try {
            // eslint-disable-next-line no-unused-expressions
            DMCP;
        } catch (e) {
            if (e.name === 'ReferenceError') {
                return;
            }
        }
        DMCP.Geolocation.trackUserLocale();
        const config = this.initConfig();
        this.checkLocale(config);
    },

    initConfig() {
        return {
            // eslint-disable-next-line no-underscore-dangle
            userLocale: DMCP.Geolocation._config.userLocale,
        };
    },

    checkLocale(config) {
        if (DMCP.Geolocation.Cookie.readCookie('isGeolocationModalSuppressed')) {
            return;
        }

        if (config.userLocale === 'NA') {
            // eslint-disable-next-line consistent-return
            return DMCP.Geolocation.akamaiResponse.always(() => {
                // eslint-disable-next-line no-underscore-dangle
                config.userLocale = DMCP.Geolocation._config.userLocale;
                ACC.countryProductAvailability.createModalPopup(config.userLocale);
            });
        }

        ACC.countryProductAvailability.createModalPopup(config.userLocale);
    },

    createModalPopup(userLocale) {
        if (ACC.countryProductAvailability.isCustomerOnSpecificLocaleSite(userLocale)) {
            return;
        }

        ACC.countryProductAvailability.temporaryLocaleForUS(userLocale);
        DMCP.Geolocation.createModal('weThinkYouWillEnjoy', ACC.countryProductAvailability.getCurrentSite(), false);
    },

    temporaryLocaleForUS(userLocale) {
        const url = window.location.href;
        const localeList_US = ['MX', 'CO', 'CL', 'AR', 'PE'];

        if (localeList_US.includes(userLocale) === true && (url.indexOf('pearsonclinical') > 0)) {
            // eslint-disable-next-line no-underscore-dangle
            DMCP.Geolocation._config.userLocale = 'US';
        }
    },

    getCurrentSite() {
        if (ACC.countryProductAvailability.isUsAssessments()
            || ACC.countryProductAvailability.isCaAssessments()
            || ACC.countryProductAvailability.isUkAssessments()
            || ACC.countryProductAvailability.isAuAssessments()
            || ACC.countryProductAvailability.isNzAssessments()
            || ACC.countryProductAvailability.isAsiaAssessments()
            || ACC.countryProductAvailability.isSgAssessments()) {
            return 'Assessments';
        }
        return 'Talentlens';
    },

    isUsAssessments() {
        return ACC.config.encodedContextPath.indexOf('usassessments') > 0;
    },

    isCaAssessments() {
        return ACC.config.encodedContextPath.indexOf('caassessments') > 0;
    },

    isUkAssessments() {
        return ACC.config.encodedContextPath.indexOf('ukassessments') > 0;
    },

    isAuAssessments() {
        return ACC.config.encodedContextPath.indexOf('auassessments') > 0;
    },

    isNzAssessments() {
        return ACC.config.encodedContextPath.indexOf('nzassessments') > 0;
    },

    isAsiaAssessments() {
        return ACC.config.encodedContextPath.indexOf('asiaassessments') > 0;
    },

    isSgAssessments() {
        return ACC.config.encodedContextPath.indexOf('sgassessments') > 0;
    },

    isUsTalentlens() {
        return ACC.config.encodedContextPath.indexOf('ustalentlens') > 0;
    },

    isCustomerOnSpecificLocaleSite(userLocale) {
        return (ACC.countryProductAvailability.isUsAssessments() && userLocale === 'US')
            || (ACC.countryProductAvailability.isCaAssessments() && userLocale === 'CA')
            || (ACC.countryProductAvailability.isUkAssessments() && userLocale === 'GB')
            || (ACC.countryProductAvailability.isUsTalentlens() && userLocale === 'US')
            || (ACC.countryProductAvailability.isAuAssessments() && userLocale === 'AU')
            || (ACC.countryProductAvailability.isNzAssessments() && userLocale === 'NZ')
            // eslint-disable-next-line max-len
            || (ACC.countryProductAvailability.isAsiaAssessments() && ACC.countryProductAvailability.countryBelongsToAsiaStore(userLocale))
            || (ACC.countryProductAvailability.isSgAssessments() && userLocale === 'SG');
    },

    countryBelongsToAsiaStore(userLocale) {
        const listOfCountriesOnAsiaStore = [
            'JP', 'KH', 'HK', 'ID', 'LA', 'MO', 'MM', 'KR', 'PH', 'TW', 'TH', 'VN',
        ];
        return listOfCountriesOnAsiaStore.indexOf(userLocale) >= 0;
    },
};
