ACC.sessionManagement = {

    _autoload: [
        ['initialize', ACC.config.currentCustomer.length !== 0],
        ['showSessionTimedOutModal', Boolean(ACC.config.sessionTimedOut)],
    ],
    _lastUserActivityTime: 0,
    _sessionTimeoutInterval: null,
    _sessionShowModalTimeout: null,
    _activityUpdated: false,
    _sessionTimeoutModalIsShown: false,
    _shouldRenewHybrisSession: false,

    initialize() {
        function addActivityListener(activity) {
            window.addEventListener(activity, ACC.sessionManagement.updateUserActivity, false);
        }

        window.addEventListener('storage', ACC.sessionManagement.storageEventListener, false);
        ACC.sessionManagement.updateUserActivity();
        ACC.sessionManagement.updateUserActivityInLocalStorage();
        ACC.sessionManagement.showSessionTimingOutWarningModal(ACC.sessionManagement.getExpectedTimeout());

        addActivityListener('keydown');
        addActivityListener('mousedown');
        addActivityListener('paste');
        addActivityListener('cut');
        addActivityListener('wheel');

        setInterval(ACC.sessionManagement.refreshActivity, ACC.config.sessionRefreshActivitySeconds * 1000, true);
        setInterval(ACC.sessionManagement.renewHybrisSession, ACC.config.sessionTimeoutSeconds * 1000);
    },

    storageEventListener(event) {
        if (event.key === 'lastUserActivityTime') {
            // eslint-disable-next-line no-underscore-dangle
            ACC.sessionManagement._lastUserActivityTime = parseInt(event.newValue, 10);
            // eslint-disable-next-line no-underscore-dangle
            ACC.sessionManagement._activityUpdated = true;
            ACC.sessionManagement.refreshActivity(false);
        } else if (event.key === 'triggerLogout') {
            ACC.sessionManagement.triggerLogout(false);
        }
    },

    updateUserActivity() {
        ACC.sessionManagement._activityUpdated = true;
        ACC.sessionManagement._lastUserActivityTime = Date.now();
    },

    updateUserActivityInLocalStorage() {
        localStorage.setItem('lastUserActivityTime', ACC.sessionManagement._lastUserActivityTime);
    },

    refreshActivity(shouldUpdateLocalStorage) {
        if (ACC.sessionManagement._activityUpdated) {
            ACC.sessionManagement._shouldRenewHybrisSession = true;
            ACC.sessionManagement._activityUpdated = false;
            ACC.sessionManagement.hideSessionTimingOutWarningModal();
            ACC.sessionManagement.showSessionTimingOutWarningModal(ACC.sessionManagement.getExpectedTimeout());

            if (shouldUpdateLocalStorage) {
                ACC.sessionManagement.updateUserActivityInLocalStorage();
            }
        }
    },

    getExpectedTimeout() {
        return ACC.sessionManagement._lastUserActivityTime + ACC.config.sessionTimeoutSeconds * 1000;
    },

    showSessionTimedOutModal() {
        if (!ACC.config.isSingleSignOn === 'true') {
            $('#showSessionTimedOut').click();
        }
    },

    showSessionTimingOutWarningModal(timeOutAtMilliseconds) {
        function updateTimeRemaining() {
            const delta = Math.max(0, timeOutAtMilliseconds - new Date().getTime());

            if (delta <= 0) {
                ACC.sessionManagement.hideSessionTimingOutWarningModal();
                ACC.sessionManagement.triggerLogout(true);
                return;
            }

            let timeRemainingDisplay = new Date(delta).toISOString().substr(11, 8);

            if (timeRemainingDisplay.startsWith('00:')) {
                timeRemainingDisplay = timeRemainingDisplay.substring(3); // Skip 00: hours
            }

            $('.js-session-time-remaining').text(timeRemainingDisplay);
            // eslint-disable-next-line consistent-return
            return delta;
        }

        const remainingMilliseconds = updateTimeRemaining();
        const millisecondsUntilWarning = remainingMilliseconds - ACC.config.sessionTimeoutWarningSeconds * 1000 - 500;
        ACC.sessionManagement._sessionTimeoutInterval = setInterval(() => {
            updateTimeRemaining();
        }, 1000);

        ACC.sessionManagement._sessionShowModalTimeout = setTimeout(() => {
            ACC.sessionManagement._sessionShowModalTimeout = null;
            updateTimeRemaining();
            $('#showTimeoutWarning').click();
            ACC.sessionManagement._timeoutModalIsShown = true;
        }, millisecondsUntilWarning);
    },

    hideSessionTimingOutWarningModal() {
        if (ACC.sessionManagement._sessionShowModalTimeout) {
            clearTimeout(ACC.sessionManagement._sessionShowModalTimeout);
            ACC.sessionManagement._sessionShowModalTimeout = null;
        }

        if (ACC.sessionManagement._timeoutModalIsShown) {
            $modal.close();
            ACC.sessionManagement._timeoutModalIsShown = false;
        }

        if (ACC.sessionManagement._sessionTimeoutInterval) {
            clearInterval(ACC.sessionManagement._sessionTimeoutInterval);
            ACC.sessionManagement._sessionTimeoutInterval = null;
        }
    },

    triggerLogout(isTimeout) {
        if (ACC.config.forgerockSsoEnabled) {
            if (window.forgerockLogout) {
                window.forgerockLogout()
                    .then(() => { ACC.sessionManagement.triggerHybrisLogout(isTimeout); });
            }
        } else {
            ACC.sessionManagement.triggerHybrisLogout(isTimeout);
        }
    },

    triggerHybrisLogout(isTimeout) {
        if (isTimeout) {
            window.location = `${ACC.config.logoutUrl}?sessionTimedOut=true`;
        } else {
            window.location = ACC.config.logoutUrl;
        }
    },

    renewHybrisSession() {
        if (ACC.sessionManagement._shouldRenewHybrisSession) {
            ACC.sessionManagement._shouldRenewHybrisSession = false;
            $.post({
                url: `${ACC.config.contextPath}/_s/renew-user-session`,
                success: function success() {
                    // eslint-disable-next-line no-console
                    console.log('Hybris session renewed');
                },
            });
        }
    },

    staySignedInOnClick() {
        ACC.sessionManagement.hideSessionTimingOutWarningModal();
        ACC.sessionManagement.updateUserActivity();
        ACC.sessionManagement.updateUserActivityInLocalStorage();
    },

    logoutOnClick() {
        localStorage.setItem('triggerLogout', ACC.sessionManagement._lastUserActivityTime);
        ACC.sessionManagement.triggerLogout(false);
    },
};
