ACC.digitalAccountsAimsWeb = {
    _autoload: ['bindAimswebStepRefresh', 'enterPressedAimsWebInputHandler'],

    bindAimswebStepRefresh() {
        const components = $('#aimswebExisting-verificationType, #aimswebNew-verificationType');
        components.click(function () {
            ACC.digitalAccountsViewHelper.showRadioChildContent($(this));
            const clickedRadioBtnId = $(this).attr('id');
            $('#addAimswebAccountID .selected').removeClass('selected');
            $(this).parent().addClass('selected');

            ACC.digitalAccountsViewHelper.resetCommonDivInputs('#addAimswebAccountID');
            $(`#${clickedRadioBtnId}`).prop('checked', true);
            ACC.digitalAccountsViewHelper.enableDisableRadios('#aimswebStepContent', false);
            ACC.digitalAccountsValidation.validateFieldInputs();
        });
    },

    hasAimswebProducts() {
        const component = $('.contextSelectorRadioBtn:checked');
        if (component.length > 0) {
            return component.data('hasaimswebproducts');
        }
        return $('.js-digital-accounts-step').data('hasaimswebproducts');
    },

    enterPressedAimsWebInputHandler() {
        $('#digitalAccountAimswebAccountNumber').keyup(function (event) {
            if (event.keyCode === 13) {
                $(this).valid();
            }
        });
    },

    isUserRadioButtonSelected() {
        const qualifiedUserRadioButtonSelector = '.qualifiedUsersSelectorRadioBtn';
        if ($(qualifiedUserRadioButtonSelector).length !== 0
            && $(`${qualifiedUserRadioButtonSelector}:checked`).length !== 0
            && $(`${qualifiedUserRadioButtonSelector}:checked`).val() !== '+') {
            return true;
        }
        return false;
    },

    isAimswebQualifiedUserSelected(selectorIdentifier) {
        const selectedOption = $(`${selectorIdentifier} option:selected`);
        const isSelectorOptionSelected = selectedOption.val() !== undefined
            && selectedOption.val() !== '' && selectedOption.val() !== '+';
        if (isSelectorOptionSelected || ACC.digitalAccountsAimsWeb.isUserRadioButtonSelected()) {
            return true;
        }
        return false;
    },

    validateAimswebExisting() {
        if ($('#aimswebExisting-verificationType').is(':checked')) {
            if (!ACC.digitalAccountsAimsWeb.isAimswebAccountNumberEmpty()
                && $('#existingAimswebTermsConditions').is(':checked')) {
                return true;
            }
        }
        return false;
    },

    isAimswebAccountNumberEmpty() {
        const userText = $('#digitalAccountAimswebAccountNumber').val();
        if (userText !== null) {
            return userText.replace(/^\s+|\s+$/g, '').length === 0;
        }
        return true;
    },

    validateAimswebNew() {
        return ACC.digitalAccountsAimsWeb.isAimswebNewFormUsed()
                && $('#newAimswebTermsConditions').is(':checked');
    },

    isAimswebNewFormUsed() {
        return $('#aimswebNew-verificationType').is(':checked');
    },

    isValidForAimsWebOnlyCart() {
        const isExistingAimsWebQualifiedUserValid = ACC.digitalAccountsAimsWeb.validateAimswebExisting()
            // eslint-disable-next-line max-len
            && ACC.digitalAccountsAimsWeb.isAimswebQualifiedUserSelected('#existingAimswebSelectedQualifiedUser-qualifiedUserID');

        const isNewAimsWebQualifiedUserValid = ACC.digitalAccountsAimsWeb.validateAimswebNew()
            // eslint-disable-next-line max-len
            && ACC.digitalAccountsAimsWeb.isAimswebQualifiedUserSelected('#newAimswebSelectedQualifiedUser-qualifiedUserID');

        return isExistingAimsWebQualifiedUserValid || isNewAimsWebQualifiedUserValid;
    },

    isAimswebStep() {
        return $('#aimswebPlatformForm').length > 0;
    },

    getAimswebFormId() {
        return 'aimswebPlatformForm';
    },
};
