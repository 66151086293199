ACC.globalmessages = {
    createGlobalMessage(globalMessageRequest) {
        $.ajax({
            url: `${ACC.config.encodedContextPath}/global-messages`,
            type: 'POST',
            async: false,
            headers: {
                'Content-Type': 'application/json',
                CSRFToken: ACC.config.CSRFToken,
            },
            data: JSON.stringify(globalMessageRequest),
            success(data) {
                const wrapper = $('<div class="global-alerts_wrap"></div>').append(data);
                $('.main__inner-wrapper').prepend(wrapper);
                $('html, body').animate({ scrollTop: 0 }, 'fast');
                $('.closeAccAlert').on('click', function () {
                    $(this).parents('.global-alerts_wrap').remove();
                });
            },
        });
    },

    removeGlobalMessages() {
        $('.closeAccAlert').click();
    },

    showErrorMessage(messageKey) {
        const globalMessageRequest = {
            globalMessages: [{
                messageHolder: 'accErrorMsgs',
                messages: [messageKey],
            }],
        };
        ACC.globalmessages.removeGlobalMessages();
        ACC.globalmessages.createGlobalMessage(globalMessageRequest);
    },

    showErrorMessageWithAttributes(messageKey, attributes) {
        const globalMessageRequest = {
            globalMessages: [{
                messageHolder: 'accErrorMsgs',
                messages: [messageKey],
                attributes,
            }],
        };
        ACC.globalmessages.removeGlobalMessages();
        ACC.globalmessages.createGlobalMessage(globalMessageRequest);
    },

    getGlobalMessageWithParam(inputField, messageKey, parameter) {
        $.ajax({
            type: 'POST',
            url: `${ACC.config.encodedContextPath}/get-global-message`,
            data: { messageKey, parameter },
            async: true,
            success(data) {
                inputField.attr('placeholder', data.globalMessage);
            },
            error(xhr, status, error) {
                console.error(error.message);
                // eslint-disable-next-line no-undef
                if (reloadOnError) {
                    window.location.reload();
                }
            },
        });
    },

};
