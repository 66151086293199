ACC.accordion = {

    _autoload: [
        ['init', $('.c-accordion-group').length !== 0],
    ],

    init() {
        $('.c-accordion-group .c-accordion__trigger').on('click', function (e) {
            e.preventDefault();

            const $btn = $(this);
            $btn.toggleClass('isActive');
            ACC.accordion.toggleAccordion($btn);
        });
    },

    initAccordion() {
        const btns = $('.c-accordion__trigger');

        btns.each((btn) => {
            const $btn = $(btn);
            ACC.accordion.toggleAccordion($btn);
        });
    },

    toggleAccordion(btn) {
        if (btn.hasClass('isActive')) {
            btn.attr('aria-expanded', 'true');
            btn.next('.c-accordion-panel').slideToggle('500');
            return;
        }
        btn.attr('aria-expanded', 'false');
        btn.next('.c-accordion-panel').slideUp('500');
    },
};
