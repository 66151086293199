ACC.mergecarts = {

    openMergeCartsModalTrigger(btn) {
        $modal.open(btn);
    },

    openMergeCartsModal() {
        const showMergeCartsTrigger = $('#showMergeCartsTrigger');
        if (showMergeCartsTrigger.length) {
            showMergeCartsTrigger.click();
        }
    },

    closeMergeCartsModal() {
        const url = $('#mergeCartsForm').data('removestalecarts-url');
        $.post(url, () => {
            console.log('Removed stale carts');
        });
        ACC.global.reloadOrCloseModal();
    },

};
