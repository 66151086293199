ACC.termsandconditions = {

    handleRegisterChkTermsConditionsChange() {
        $('#registerChkTermsConditions').change(function (e) {
            e.preventDefault();
            const form = $(this).parents('form:first');
            const btnSubmit = form.find(':submit');

            if ($(this).is(':checked')) {
                btnSubmit.prop('disabled', false);
            } else {
                btnSubmit.prop('disabled', true);
            }
        });
    },
};

$(() => {
    ACC.termsandconditions.handleRegisterChkTermsConditionsChange();
    $('#registerChkTermsConditions').removeAttr('disabled');
    $('[name="consentForm.consentGiven"]').removeAttr('disabled');
});
