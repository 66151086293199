ACC.productorderform = {

    _autoload: [
        'headerActions',
        'coreTableActions',
        'addToCartOrderGridForm',
    ],

    $addToCartOrderForm: $('#AddToCartOrderForm'),
    $addToCartBtn: $('#addToCartBtn'),
    $omsErrorMessageContainer: $('#globalMessages'),
    $emptySkuQuantityInputs: $('.sku-quantity[value]'),
    $nonEmptySkuQuantityInputs: $('.sku-quantity[value]'),
    $totalGridValues: $('[data-grid-total-id]'),

    // Templates
    $futureTooltipTemplate: $('#future-stock-template'),
    $futureTooltipErrorTemplate: $('#future-tooltip-error-template'),
    $omsErrorMessageTemplate: $('#oms-error-message-template'),
    $variantSummaryTemplate: $('#variant-summary'),
    selectedVariantData: 'selected-variant',
    selectedVariants: [],
    quantityTotal: 0,
    scrollTopPos: 0,

    headerActions() {
        ACC.productorderform.bindProductDetailToggle($('.product-details-toggle'));
    },

    coreTableActions() {
        ACC.productorderform.coreTableScrollActions(ACC.productorderform.$addToCartOrderForm);
        ACC.productorderform.bindUpdateFutureStockButton('.update_future_stock_button');
        ACC.productorderform.bindHideFutureStockInfo('.hide_future_stock_info');
        ACC.productorderform.bindVariantSelect($('.variant-select-btn'), 'AddToCartOrderForm');
        ACC.productorderform.cancelVariantModal('.closeVariantModal');
        ACC.productorderform.checkLimitExceed('.sku-quantity');
        const skuQuantityClass = '.sku-quantity';
        const skuVariantQuantityClass = '#cboxContent .sku-quantity';

        let quantityBefore = 0;
        let quantityAfter = 0;

        ACC.productorderform.$addToCartOrderForm.on('click', skuQuantityClass, function () {
            $(this).select();
        });

        ACC.productorderform.$addToCartOrderForm.on('focusin', skuQuantityClass, function () {
            quantityBefore = jQuery.trim(this.value);

            // reset
            $(this).parents('tr').next('.variant-summary').remove();
            if ($(this).parents('table').data(ACC.productorderform.selectedVariantData)) {
                // eslint-disable-next-line max-len
                ACC.productorderform.selectedVariants = $(this).parents('table').data(ACC.productorderform.selectedVariantData);
            } else {
                ACC.productorderform.selectedVariants = [];
            }

            if (quantityBefore === '') {
                quantityBefore = 0;
                this.value = 0;
            }
        });

        $(skuQuantityClass).on('blur keypress', function (event) {
            const code = event.keyCode || event.which || event.charCode;

            if (code !== 13 && code !== undefined) {
                return;
            }

            const indexPattern = '[0-9]+';
            const currentIndex = parseInt($(this).attr('id').match(indexPattern), 10);
            let totalPrice = 0;
            const _this = this;
            const currentPrice = $(`input[id='productPrice[${currentIndex}]']`).val();
            this.value = ACC.productorderform.filterSkuEntry(this.value);
            const $currentTotalItems = $('.js-total-items-count');
            let currentTotalItemsValue = $currentTotalItems.html();
            let currentTotalPrice = $('.js-total-price-value').val();
            const $gridGroup = $(this).parents('.orderForm_grid_group');

            // eslint-disable-next-line no-restricted-globals
            if (isNaN(jQuery.trim(this.value))) {
                this.value = 0;
            }

            quantityAfter = jQuery.trim(this.value);
            if (quantityAfter === '') {
                quantityAfter = 0;
                this.value = 0;
            }

            // If order forms advanced search enabled
            if (ACC.orderform) {
                if (sessionStorage.totalItems !== undefined && sessionStorage.totalPriceVal !== undefined) {
                    currentTotalItemsValue = sessionStorage.totalItems;
                    currentTotalPrice = sessionStorage.totalPriceVal;
                }

                if (quantityBefore === 0) {
                    $currentTotalItems.html(parseInt(currentTotalItemsValue, 10) + parseInt(quantityAfter, 10));
                    totalPrice = parseFloat(currentTotalPrice) + parseFloat(currentPrice) * parseInt(quantityAfter, 10);
                } else {
                    // eslint-disable-next-line max-len
                    $currentTotalItems.html(parseInt(currentTotalItemsValue, 10) + (parseInt(quantityAfter, 10) - parseInt(quantityBefore, 10)));
                    // eslint-disable-next-line max-len
                    totalPrice = parseFloat(currentTotalPrice) + parseFloat(currentPrice) * (parseInt(quantityAfter, 10) - parseInt(quantityBefore, 10));
                }

                sessionStorage.totalPrice = ACC.productorderform.formatTotalsCurrency(totalPrice);
                sessionStorage.totalItems = $currentTotalItems.html();
                sessionStorage.totalPriceVal = totalPrice;

                ACC.orderform.addToSkuQtyInput(_this);
            } else if ($gridGroup && $gridGroup.length > 0) {
                const $closestQuantityValue = $gridGroup.find('#quantityValue');
                const $closestAvgPriceValue = $gridGroup.find('#avgPriceValue');
                const $closestSubtotalValue = $gridGroup.find('#subtotalValue');
                const currentQuantityValue = $closestQuantityValue.val();
                const currentSubtotalValue = $closestSubtotalValue.val();

                if (quantityBefore === 0) {
                    $closestQuantityValue.val(parseInt(currentQuantityValue, 10) + parseInt(quantityAfter, 10));
                    // eslint-disable-next-line max-len
                    $closestSubtotalValue.val(parseFloat(currentSubtotalValue) + parseFloat(currentPrice) * parseInt(quantityAfter, 10));

                    $currentTotalItems.html(parseInt(currentTotalItemsValue, 10) + parseInt(quantityAfter, 10));
                    totalPrice = parseFloat(currentTotalPrice) + parseFloat(currentPrice) * parseInt(quantityAfter, 10);
                } else {
                    // eslint-disable-next-line max-len
                    $closestQuantityValue.val(parseInt(currentQuantityValue, 10) + (parseInt(quantityAfter, 10) - parseInt(quantityBefore, 10)));
                    // eslint-disable-next-line max-len
                    $closestSubtotalValue.val(parseFloat(currentSubtotalValue) + parseFloat(currentPrice) * (parseInt(quantityAfter, 10) - parseInt(quantityBefore, 10)));

                    // eslint-disable-next-line max-len
                    $currentTotalItems.html(parseInt(currentTotalItemsValue, 10) + (parseInt(quantityAfter, 10) - parseInt(quantityBefore, 10)));
                    // eslint-disable-next-line max-len
                    totalPrice = parseFloat(currentTotalPrice) + parseFloat(currentPrice) * (parseInt(quantityAfter, 10) - parseInt(quantityBefore, 10));
                }

                ACC.productorderform.enableBeforeUnloadEvent(quantityAfter, $currentTotalItems.text());

                // if there are no items to add, disable addToCartBtn, otherwise, enable it
                if ($currentTotalItems.length !== 0 && $currentTotalItems.text() === 0) {
                    ACC.productorderform.$addToCartBtn.attr('disabled', 'disabled');
                    $(window).off('beforeunload', ACC.productorderform.beforeUnloadHandler);
                } else {
                    ACC.productorderform.$addToCartBtn.removeAttr('disabled');
                }

                if (parseInt($closestQuantityValue.val(), 10) > 0) {
                    // eslint-disable-next-line max-len
                    $closestAvgPriceValue.val(parseFloat($closestSubtotalValue.val()) / parseInt($closestQuantityValue.val(), 10));
                } else {
                    $closestAvgPriceValue.val(0);
                }
            }

            if ($gridGroup && $gridGroup.length > 0) {
                let gridLevelTotalPrice = '';
                const $gridTotalValue = $gridGroup.find(`[data-grid-total-id=total_value_${currentIndex}]`);

                if (quantityAfter > 0) {
                    // eslint-disable-next-line max-len
                    gridLevelTotalPrice = ACC.productorderform.formatTotalsCurrency(parseFloat(currentPrice) * parseInt(quantityAfter, 10));
                }
                $gridTotalValue.html(gridLevelTotalPrice);
                ACC.productorderform.updateSelectedVariantGridTotal(this, quantityBefore, false, false);
            }

            $('.js-total-price').html(ACC.productorderform.formatTotalsCurrency(totalPrice));
            $('.js-total-price-value').val(totalPrice);
        });

        // MOBILE
        $('body').on('focusin', skuVariantQuantityClass, function () {
            quantityBefore = jQuery.trim(this.value);

            const currentVariantId = $(this).data('variant-id');
            // eslint-disable-next-line max-len
            const currentBaseInput = $('#AddToCartOrderForm, #cartOrderGridForm').find(`[data-variant-id='${currentVariantId}']`);
            currentBaseInput.trigger('focusin');

            currentBaseInput.parents('table').find('.variant-summary').remove();
            if (currentBaseInput.parents('table').data(ACC.productorderform.selectedVariantData)) {
                // eslint-disable-next-line max-len
                ACC.productorderform.selectedVariants = currentBaseInput.parents('table').data(ACC.productorderform.selectedVariantData);
            } else {
                ACC.productorderform.selectedVariants = [];
            }

            if (quantityBefore === '') {
                quantityBefore = 0;
                this.value = 0;
            }
        });

        $('body').on('blur', skuVariantQuantityClass, function () {
            const priceSibling = $(this).siblings('.price');
            const totalSibling = $(this).siblings('.data-grid-total');
            const currentVariantId = $(this).data('variant-id');
            // eslint-disable-next-line max-len
            const currentBaseInput = $('#AddToCartOrderForm, #cartOrderGridForm').find(`[data-variant-id='${currentVariantId}']`);
            this.value = ACC.productorderform.filterSkuEntry(this.value);

            // no text allowed || no negative number allowed || no empty string
            // eslint-disable-next-line no-restricted-globals
            if (isNaN(jQuery.trim(this.value)) || this.value < 0 || this.value === '') {
                this.value = 0;
            }

            // set current value also to hidden input field (baseTable), because its the base of all further interaction
            currentBaseInput.val(this.value);
            currentBaseInput.trigger('blur');
            ACC.productorderform.updateVariantTotal(priceSibling, this.value, totalSibling);

            // if there are no items to add, disable addToCartBtn, otherwise, enable it
            if (this.value > 0 && this.value !== quantityBefore) {
                currentBaseInput.parents('table').addClass('selected');
                currentBaseInput.trigger('change');
            } else if (ACC.productorderform.selectedVariants.length === 0) {
                currentBaseInput.parents('table').removeClass('selected');
            }
        });
    },

    // MOBILE
    updateSelectedVariantGridTotal(_this, quantityBefore, isFillQty, resetSummary) {
        const priceSibling = $(_this).siblings('.price');
        const propSibling = $(_this).siblings('.variant-prop');
        const currentSkuId = $(_this).next('.td_stock').data('sku-id');
        const currentBaseTotal = $(_this).siblings('.data-grid-total');

        if (isFillQty) {
            ACC.productorderform.selectedVariants = [];
        }

        if (_this.value !== quantityBefore) {
            let newVariant = true;
            ACC.productorderform.selectedVariants.forEach((item, index) => {
                if (item.id === currentSkuId) {
                    newVariant = false;
                    if (_this.value === '0' || _this.value === 0) {
                        ACC.productorderform.selectedVariants.splice(index, 1);
                    } else {
                        ACC.productorderform.selectedVariants[index].quantity = _this.value;
                        // eslint-disable-next-line max-len
                        ACC.productorderform.selectedVariants[index].total = ACC.productorderform.updateVariantTotal(priceSibling, _this.value, currentBaseTotal);
                    }
                }
            });

            if (newVariant && _this.value > 0) {
                // update variantData
                ACC.productorderform.selectedVariants.push({
                    id: currentSkuId,
                    size: propSibling.data('variant-prop'),
                    quantity: _this.value,
                    total: ACC.productorderform.updateVariantTotal(priceSibling, _this.value, currentBaseTotal),
                });
            }
        }

        if (resetSummary) {
            $(_this).parents('table').find('.variant-summary').remove();
        }

        ACC.productorderform.showSelectedVariant($(_this).parents('table'));

        if (_this.value > 0 && _this.value !== quantityBefore) {
            $(_this).parents('table').addClass('selected');
        } else if (ACC.productorderform.selectedVariants.length === 0) {
            $(_this).parents('table').removeClass('selected').find('.variant-summary')
                .remove();
        }
    },

    updateVariantTotal(priceSibling, quantity, totalElement) {
        const variantTotal = parseFloat(priceSibling.data('variant-price')) * parseInt(quantity, 10);
        // set total in modal and baseVariant
        totalElement.html(ACC.productorderform.formatTotalsCurrency(variantTotal));

        return ACC.productorderform.formatTotalsCurrency(variantTotal);
    },

    bindUpdateFutureStockButton(updateFutureStockButton) {
        $('body').on('click', updateFutureStockButton, function (event) {
            event.preventDefault();

            const $gridContainer = $(this).parents('.orderForm_grid_group').find('.product-grid-container');
            const $skus = jQuery.map($gridContainer.find("input[type='hidden'].sku"), (o) => o.value);
            const skusId = $(this).data('skusId');
            const futureStockUrl = $(this).data('skusFutureStockUrl');
            const postData = { skus: $skus, productCode: skusId };
            const hideFutureStockInfo = $(this).parent().find('.hide_future_stock_info');
            const showFutureStockLink = $(this);

            $.ajax({
                url: futureStockUrl,
                type: 'POST',
                data: postData,
                traditional: true,
                dataType: 'json',
                success(data) {
                    // eslint-disable-next-line max-len
                    ACC.productorderform.updateFuture($gridContainer, $skus, data, skusId, showFutureStockLink, hideFutureStockInfo);
                },
                error(xht, textStatus, ex) {
                    alert(`Failed to get delivery modes. Error details [${xht}, ${textStatus}, ${ex}]`);
                },
            });
        });
    },

    bindHideFutureStockInfo(hideFutureStockInfoLink) {
        $('body').on('click', hideFutureStockInfoLink, function (event) {
            event.preventDefault();
            const gridContainer = $(this).parent().parent().find('.product-grid-container');
            const updateFutureStockInfo = $(this).parent().find('.update_future_stock_button');
            updateFutureStockInfo.show();
            $(this).hide();
            const cell = gridContainer.find('[data-sku-id]');
            cell.children('.future_stock, .out-of-stock').remove();
        });
    },

    updateFuture(gridContainer, skus, freshData, callerId, showFutureStockInfoLink, hideFutureStockInfo) {
        // clear prior error messages
        ACC.productorderform.$omsErrorMessageContainer.find('div').remove();

        function isEmpty(obj) {
            return (Object.keys(obj).length <= 0);
        }

        if (freshData !== null && typeof freshData['basket.page.viewFuture.unavailable'] !== 'undefined') {
            // future stock service is not available
            $.tmpl(ACC.productorderform.$omsErrorMessageTemplate, {
                errorMessage: freshData['basket.page.viewFuture.unavailable'],
            }).appendTo(ACC.productorderform.$omsErrorMessageContainer);
        } else if (!isEmpty(freshData)) {
            showFutureStockInfoLink.hide();
            hideFutureStockInfo.css('display', 'block');
            $.each(skus, (index, skuId) => {
                const stocks = freshData[skuId];

                const cell = gridContainer.find(`[data-sku-id='${skuId}']`);
                const isCurrentlyInStock = cell[0].attributes.class.nodeValue.indexOf('in-stock') !== -1;
                // eslint-disable-next-line max-len
                const futureStockPresent = typeof stocks !== 'undefined' && stocks !== null && stocks[0] !== null && typeof stocks[0] !== 'undefined';

                cell.children('.future_stock, .out-of-stock').remove(); // remove previous tool tips

                if (futureStockPresent) {
                    // we have stock for this product
                    if (!isCurrentlyInStock) {
                        cell.addClass('future-stock');
                    }

                    // render template and append to cell
                    $.tmpl(ACC.productorderform.$futureTooltipTemplate, {
                        formattedDate: stocks[0].formattedDate,
                        availabilities: stocks,
                    }).appendTo(cell);
                } else if (!isCurrentlyInStock) { // no future stock for this product
                    cell[0].attributes.class.nodeValue = 'td_stock out-of-stock';
                }
            });
        }
    },

    toJSON(gridForm, skipZeroQuantity) {
        const skus = gridForm.find('input.sku').map((index, element) => element.value);
        const skuQuantities = gridForm.find('input.sku-quantity').map((index, element) => parseInt(element.value, 10));
        const skusAsJSON = [];

        for (let i = 0; i < skus.length; i += 1) {
            if (!(skipZeroQuantity && skuQuantities[i] === 0)) {
                skusAsJSON.push({ product: { code: skus[i] }, quantity: skuQuantities[i] });
            }
        }

        return JSON.stringify({ cartEntries: skusAsJSON });
    },

    formatTotalsCurrency(amount) {
        // eslint-disable-next-line no-undef, max-len
        let formattedCurrency = Currency.formatMoney(Number(amount).toFixed(2), Currency.money_format[ACC.common.currentCurrency]);
        if (ACC.config.storeSpecificCurrencySymbol) {
            // eslint-disable-next-line no-undef, max-len
            formattedCurrency = formattedCurrency.replace('$', ACC.productorderform.getStoreSpecificFormattedCurrencySymbol(ACC.config.storeSpecificCurrencySymbol));
        }
        return formattedCurrency;
    },

    getStoreSpecificFormattedCurrencySymbol(storeSpecificCurrencySymbol) {
        // eslint-disable-next-line no-undef, max-len
        return storeSpecificCurrencySymbol === '$' ? storeSpecificCurrencySymbol : storeSpecificCurrencySymbol.concat(' ');
    },

    cleanValues() {
        if ($('.orderForm_grid_group').length !== 0) {
            const formattedTotal = ACC.productorderform.formatTotalsCurrency('0.00');

            $('.js-total-price').html(formattedTotal);
            $('#quantity, .js-total-items-count').html(0);
            $('#quantityValue, #avgPriceValue, #subtotalValue, .js-total-price-value').val(0);
            ACC.productorderform.$emptySkuQuantityInputs.val(0);
            ACC.productorderform.$totalGridValues.html('');
        }
    },

    calculateGrid() {
        ACC.productorderform.$nonEmptySkuQuantityInputs.trigger('focusout');
    },

    bindProductDetailToggle(productDetailToggle) {
        productDetailToggle.on('click', function (event) {
            event.preventDefault();

            $(this).parents('.product-details').toggleClass('open');
        });
    },

    showSelectedVariant(currentVariant) {
        // render template and append to table
        $.tmpl(ACC.productorderform.$variantSummaryTemplate, {
            variants: ACC.productorderform.selectedVariants,
        }).appendTo(currentVariant);
        // save selectedVariantData
        $('.variant-summary .variant-property').html($('.variant-detail').data('variant-property'));
        currentVariant.data(ACC.productorderform.selectedVariantData, ACC.productorderform.selectedVariants);
        currentVariant.removeClass('currentVariant');
    },

    bindVariantSelect(variantSelectBtn, parentId) {
        variantSelectBtn.on('click', function (event) {
            event.preventDefault();
            const currentVariant = $(this).parents('table');

            // reset
            if (currentVariant.data(ACC.productorderform.selectedVariantData)) {
                ACC.productorderform.selectedVariants = currentVariant.data(ACC.productorderform.selectedVariantData);
            } else {
                ACC.productorderform.selectedVariants = [];
            }

            const titleHeader = variantSelectBtn.html();
            const tableWrap = $(`#${parentId}`).clone().empty().attr('id', `${parentId}Variant`);

            currentVariant.addClass('currentVariant');
            const popupContent = $(this).parents('.orderForm_grid_group').clone();
            currentVariant.removeClass('currentVariant');
            $(popupContent).find('.currentVariant').siblings().remove();
            tableWrap.html(popupContent);
            tableWrap.find('.hidden-xs').removeClass('hidden-xs');
            tableWrap.find('.hide').removeClass('hide');
            // eslint-disable-next-line no-undef
            scrollTopPos = $('body').scrollTop();
            $('body').scrollTop(0);

            ACC.colorbox.open(titleHeader, {
                html: tableWrap,
                reposition: false,
                className: 'variantSelectMobile',
                onCleanup() {
                    if (ACC.productorderform.$addToCartOrderForm.find('.selected').length > 0) {
                        ACC.productorderform.$addToCartBtn.removeAttr('disabled');
                    } else {
                        ACC.productorderform.$addToCartBtn.attr('disabled', 'disabled');
                    }
                    // eslint-disable-next-line no-undef
                    $('body').scrollTop(scrollTopPos);
                },
            });
        });
    },

    cancelVariantModal(closeVariantModal) {
        $('body').on('click', closeVariantModal, (event) => {
            event.preventDefault();
            ACC.colorbox.close();
        });
    },
    checkLimitExceed(closeVariantModal1) {
        $('body').on('keyup blur', closeVariantModal1, function () {
            const input = Number($(this).val());
            const stock_amt = Number($(this).attr('data-instock'));
            if (input > stock_amt) { $(this).val(stock_amt); }
        });
    },

    resetSelectedVariant() {
        // Reset all the selectedVariant data
        ACC.productorderform.selectedVariants = [];
        $('.product-grid-container table').removeData(ACC.productorderform.selectedVariantData)
            .removeClass('selected')
            .removeClass('currentVariant');
    },

    addToCartOrderGridForm: function addToCartOrderGridForm() {
        // Prevent accidentally submitting the form by hitting the Enter key.
        $('#AddToCartOrderForm').keypress(
            (event) => {
                if (event.which === '13') {
                    event.preventDefault();
                }
            },
        );

        ACC.productorderform.$addToCartBtn.click(() => {
            ACC.productorderform.$addToCartBtn.attr('disabled', 'disabled');

            $.ajax({
                url: ACC.productorderform.$addToCartOrderForm.attr('action'),
                type: 'POST',
                dataType: 'json',
                contentType: 'application/json',
                data: ACC.productorderform.toJSON(ACC.productorderform.$addToCartOrderForm, true),
                async: false,
                success(response) {
                    $(window).off('beforeunload', ACC.productorderform.beforeUnloadHandler);
                    ACC.product.displayAddToCartPopup(response);
                    ACC.productorderform.cleanValues();
                    ACC.productorderform.resetSelectedVariant();
                },
                error(jqXHR, textStatus, errorThrown) {
                    // log the error to the console
                    console.log(`The following error occured: ${textStatus}`, errorThrown);
                },
            });
        });
    },

    beforeUnloadHandler() {
        return ACC.productorderform.$addToCartOrderForm.attr('data-grid-confirm-message');
    },

    enableBeforeUnloadEvent(quantityAfter, currentTotalItems) {
        if (!ACC.orderform) {
            if (quantityAfter > 0 && currentTotalItems > 0) {
                $(window).off('beforeunload', ACC.productorderform.beforeUnloadHandler)
                    .on('beforeunload', ACC.productorderform.beforeUnloadHandler);
            }
        }
    },

    filterSkuEntry(quantityInput) {
        let filteredQty = 0;
        if (/\D/g.test(quantityInput)) {
            // Filter non-digits from input value.
            filteredQty = quantityInput.replace(/\D/g, '');
        } else {
            filteredQty = quantityInput;
        }
        return filteredQty;
    },

    // Order form scroll
    coreTableScrollActions($scrollContent) {
        if ($scrollContent.hasClass('visible')) {
            ACC.productorderform.orderGridScroll($scrollContent);
            const scrollRight = $scrollContent.parent().find('.order-form-scroll.right');
            const scrollLeft = $scrollContent.parent().find('.order-form-scroll.left');
            const scrollUp = $scrollContent.parent().find('.order-form-scroll.up');
            const scrollDown = $scrollContent.parent().find('.order-form-scroll.down');
            const widthReference = $scrollContent.find('.widthReference').outerWidth();
            const heightReference = $scrollContent.find('.product-grid-container table').eq(0).height() / 2;
            let maxWidth = 0;
            let maxHeight = 0;
            let widthDiff = 0;
            let heightDiff = 0;

            $scrollContent.find('.product-grid-container table').each(function () {
                if ($(this).outerWidth() > maxWidth) {
                    maxWidth = $(this).outerWidth();
                }
            });
            $scrollContent.find('.orderForm_grid_group').each(function () {
                maxHeight += $(this).height();
            });

            widthDiff = maxWidth - $scrollContent.outerWidth();// scroll-offset
            heightDiff = maxHeight - $scrollContent.height() + 14;// scroll-offset

            $scrollContent.scroll(function () {
                if ($(this).scrollLeft() > 0) {
                    scrollLeft.show();
                } else {
                    scrollLeft.hide();
                }

                if ($(this).scrollLeft() >= widthDiff) {
                    scrollRight.hide();
                } else {
                    scrollRight.show();
                }

                if ($(this).scrollTop() > 0) {
                    scrollUp.show();
                } else {
                    scrollUp.hide();
                }

                if ($(this).scrollTop() >= heightDiff) {
                    scrollDown.hide();
                } else {
                    scrollDown.show();
                }

                $scrollContent.find('.update-future-stock').css('margin-right', -$(this).scrollLeft());
            });

            $scrollContent.parent().find('.order-form-scroll').click(function () {
                const pos = {
                    left: $scrollContent.scrollLeft(),
                    top: $scrollContent.scrollTop(),
                };

                if ($(this).hasClass('right')) {
                    $scrollContent.scrollLeft(pos.left + widthReference);
                } else if ($(this).hasClass('left')) {
                    $scrollContent.scrollLeft(pos.left - widthReference);
                } else if ($(this).hasClass('up')) {
                    $scrollContent.scrollTop(pos.top - heightReference);
                } else {
                    $scrollContent.scrollTop(pos.top + heightReference);
                }
            });
        }
    },

    orderGridScroll(scrollContent) {
        let showRight = false;
        let calcHeight = 0;
        const maxWidth = $(scrollContent).find('.orderForm_grid_group').innerWidth();
        const maxHeight = $(scrollContent).innerHeight() - 18;

        $(scrollContent).find('.product-grid-container table').each(function () {
            if ($(this).width() > maxWidth) {
                showRight = true;
            }
            calcHeight += $(this).height();
        });

        if (showRight) {
            $(scrollContent).parent().find('.order-form-scroll.right').show();
        }

        if (calcHeight > maxHeight) {
            $(scrollContent).parent().find('.order-form-scroll.down').show();
        }
    },

    calculateVariantTotal(_this, quantityToAdd) {
        const $gridGroup = _this.parents('.orderForm_grid_group');
        const indexPattern = '[0-9]+';
        const currentIndex = parseInt(_this.attr('id').match(indexPattern), 10);
        const currentPrice = $(`input[id='productPrice[${currentIndex}]']`).val();
        const $gridTotalValue = $gridGroup.find(`[data-grid-total-id=total_value_${currentIndex}]`);
        // eslint-disable-next-line max-len
        if (quantityToAdd > 0) { $gridTotalValue.html(ACC.productorderform.formatTotalsCurrency(parseFloat(currentPrice) * parseInt(quantityToAdd, 10))); }
    },
};
