ACC.hopdebug = {

    bindAll() {
        this.bindShowDebugMode();
    },

    bindShowDebugMode() {
        const debugModeEnabled = $('#hopDebugMode').data('hopDebugMode');

        if (!debugModeEnabled && !$('#showDebugPage').val()) {
            $('#hostedOrderPagePostForm').submit();
        }
    },
};

$(document).ready(() => {
    ACC.hopdebug.bindAll();
});
