ACC.systemMsg = {

    _autoload: [
        'systemMsgBehavior',
    ],

    /*  acc.system-msg.js

    Purpose: Display global system message above
    utility nav inside header.
    ================================================== */

    // Removal
    systemMsgRemove(el) {
        el.remove();
    },

    systemMsgBehavior() {
        if ($('.system-msg__container').length > 0) {
            const systemMsgContainer = $('.system-msg__container');

            if ($('.system-msg__dismiss').find('a').length > 0) {
                const systemMsgClose = $('.system-msg__dismiss').find('a');

                // Set attributes for 'acceptContinue' link
                systemMsgClose
                    .attr({
                        id: 'system-msg__dismiss',
                        href: `javascript${String.fromCharCode(58)}void(0);`,
                    });
            }

            // Shift location of system message above utility nav
            if (!$('body').hasClass('cq-wcm-edit')) { // Not if in author mode (AEM)
                let pageHeader;

                if ($('body > header').length > 0) {
                    pageHeader = $('body > header');// AEM
                } else {
                    pageHeader = $('.js-mainHeader');// Hybris
                }

                // If skipNav control is present allow it to come first in markup
                if (pageHeader.find('.skip-nav').length > 0) {
                    const skipNav = pageHeader.find('.skip-nav');
                    skipNav.after(systemMsgContainer);
                } else {
                    pageHeader.prepend(systemMsgContainer);
                }
            }

            // Check to see if cookie exists
            const cnCookie = ACC.cookie.getCookie('system-msg');

            // eslint-disable-next-line no-mixed-operators
            if (!(cnCookie) || cnCookie !== '' && cnCookie !== null && cnCookie !== 'DISMISSED') {
                systemMsgContainer.slideDown();
            } else {
                systemMsgContainer.slideUp(ACC.systemMsg.systemMsgRemove($(this)));
            }

            // Set click event behavior for user options
            $('#system-msg__dismiss').on('click', function () {
                // Set cookie
                ACC.cookie.setCookie('system-msg', 'DISMISSED');
                // Hide system msg and remove from DOM
                systemMsgContainer.slideUp(ACC.systemMsg.systemMsgRemove($(this)));
            });
        }
    },
};
