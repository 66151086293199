ACC.paymentDetails = {
    _autoload: [
        'showRemovePaymentDetailsConfirmation',
    ],

    showRemovePaymentDetailsConfirmation() {
        $(document).on('click', '.removePaymentDetailsButton', function () {
            const paymentId = $(this).data('paymentId');
            const popupTitle = $(this).data('popupTitle');

            ACC.colorbox.open(popupTitle, {
                inline: true,
                href: `#popup_confirm_payment_removal_${paymentId}`,
                onComplete() {
                    $(this).colorbox.resize();
                },
            });
        });
    },
};
