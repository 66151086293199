ACC.changepwd = {

    _autoload: [
        'initialize',
        'trackFormChanges',
        'showCancelPwdChangeConfirmation',
    ],

    initialize() {
        if ($('#updatePwdToken').length > 0 && piSession) {
            piSession.getToken((status, token) => {
                $('#updatePwdToken').val(token);
                $('#updatePwdUserId').val(piSession.userId());
            });
        }
    },

    trackFormChanges() {
        $('#updatePearsonPasswordForm').on('change', () => {
            $('#updatePearsonPasswordForm').data('changed', true);
        });
    },

    showCancelPwdChangeConfirmation() {
        $('.js-cancel-change-pwd-popup-button').on('click', function () {
            if ($('#updatePearsonPasswordForm').data('changed')) {
                $modal.open(this);
            } else {
                window.location = $(this).data('cancelUrl');
            }
        });
    },
};
