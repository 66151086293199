ACC.redeemDigitalProduct = {

    _autoload: [
        'bindRedeemForm',
        'copyToClipboard',
    ],

    bindRedeemForm() {
        const howToRedeemForm = $('.js-howToRedeemForm');
        howToRedeemForm.ajaxForm({
            dataType: null,
            success: ACC.redeemDigitalProduct.updatePopupOnSuccess,
        });
    },

    updatePopupOnSuccess(result, statusText, xhr, formElement) {
        ACC.redeemDigitalProduct.displayHowToRedeemPopup(result, formElement);
    },

    displayHowToRedeemPopup(result) {
        $modal.open(null, {
            html: result,
            title: '',
        });
    },

    copyToClipboard() {
        const codeList = document.querySelectorAll('.js-access-code-copy');

        if (window.NodeList && !NodeList.prototype.forEach) {
            NodeList.prototype.forEach = Array.prototype.forEach;
        }

        codeList.forEach((currentItem) => {
            currentItem.addEventListener('click', function (event) {
                event.preventDefault();

                const _this = this;
                const code = _this.querySelector('.js-access-code-text');
                const range = document.createRange();

                range.selectNode(code);
                window.getSelection().removeAllRanges();
                window.getSelection().addRange(range);
                document.execCommand('copy');
                window.getSelection().removeAllRanges();

                _this.classList.add('isActive');

                setTimeout(() => { _this.classList.remove('isActive'); }, 5000);
            });
        });
    },

};
