$(document).ready(() => {
    $('input[data-copy-from][data-copy-to]').each(function () {
        const that = $(this);
        const attrFrom = $(this).attr('data-copy-from');
        const attrTo = $(this).attr('data-copy-to');

        $(attrTo).attr('readonly', true);

        function eventCheck() {
            if (that.is(':checked')) {
                $(attrTo).val($(attrFrom).val());
                $(attrTo).attr('readonly', true);
            } else {
                $(attrTo).val(null);
                $(attrTo).attr('readonly', false);
            }
            // Triggering validation after field value got changed
            $(this).keyup();
        }

        function eventFocusOut() {
            if (that.is(':checked')) {
                $(attrTo).val($(attrFrom).val());
                $(attrTo).valid();
            }
        }

        $(attrFrom).focusout(eventFocusOut);
        that.on('change', eventCheck);
    });
});
