ACC.ratingstars = {

    _autoload: [
        ['bindRatingStars', $('.js-ratingCalc').length > 0],
        ['bindRatingStarsSet', $('.js-ratingCalcSet').length > 0],
    ],

    bindRatingStars() {
        $('.js-ratingCalc').each(function () {
            const rating = $(this).data('rating');
            $(this).find('.js-greenStars').width($(this).width() * (parseFloat(rating.rating, 10) / rating.total));
        });
    },
    bindRatingStarsSet() {
        $('.js-writeReviewStars').on({
            mouseleave: function mouseleave() {
                // eslint-disable-next-line no-use-before-define
                clearReviewState();
                const sV = (parseFloat($('.js-ratingSetInput').val(), 10)) * 2;
                // eslint-disable-next-line no-restricted-globals,no-use-before-define,no-unused-expressions
                (typeof sV === 'number' && !isNaN(sV)) ? setReviewState(sV) : clearReviewState();
            },
        });
        const ratingIcons = $('.js-writeReviewStars .js-ratingIcon');
        const setReviewState = function (index) {
            ratingIcons.slice(0, parseFloat(index, 10)).addClass('active');
        };
        const clearReviewState = function () {
            ratingIcons.removeClass('active');
        };
        ratingIcons.on({
            mouseenter: function mouseenter() {
                clearReviewState();
                setReviewState($(this).index() + 1);
            },
            mouseleave: function mouseleave() {
                $(this).removeClass('active');
            },
            click: function click() {
                $('.js-ratingSetInput').val(($(this).index() + 1) / 2);
            },
        });
    },

};
