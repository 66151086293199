ACC.digitalAccountsService = {

    checkOrgParty(tepAccountNumber, qglobalAccountNumber, doneHandler) {
        $.get({
            url: `${ACC.config.encodedContextPath}/digital-accounts/checkOrgParty`,
            data: { tepAccountNumber, qglobalAccountNumber },
            dataType: 'html',
            async: false,
        }).done(doneHandler);
    },

    validateOrg(orgTepAccountNumber, successHandler, errorHandler) {
        $.get({
            url: `${ACC.config.encodedContextPath}/digital-accounts/validateOrg`,
            data: { orgTepAccountNumber },
            async: false,
            success: successHandler,
            error: errorHandler,
        });
    },

    associateWithOrg(organization, successHandler, errorHandler) {
        $.post({
            url: $('.js-digital-accounts-associate-org').data('target-url'),
            data: organization,
            contentType: 'application/json',
            dataType: 'json',
            success: successHandler,
            error: errorHandler,
        });
    },

    findOrg(qglobalAccountNumber, successHandler, errorHandler) {
        $.get({
            url: `${ACC.config.encodedContextPath}/digital-accounts/findOrg`,
            data: { qglobalAccountNumber },
            contentType: 'application/json',
            dataType: 'json',
            success: successHandler,
            error: errorHandler,
        });
    },

    getCurrentUserContexts(orgNumber, doneHandler) {
        $.post({
            url: `${ACC.config.encodedContextPath}/digital-accounts/currentUserContexts`,
        }).done((data) => {
            doneHandler(orgNumber, data);
        });
    },
};
