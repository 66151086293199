ACC.forgerock = {

    _autoload: [
        'displayLoginWidget',
        'bindLogoutAction',
    ],

    loginWidgetWrapperId: 'login-widget-wrapper',

    displayLoginWidget() {
        if (!ACC.config.forgerockSsoEnabled) {
            return;
        }
        const loginWidgetWrapper = $(`#${ACC.forgerock.loginWidgetWrapperId}`);
        if (loginWidgetWrapper.length && window.createForgerockLoginWidget) {
            const content = {
                username: loginWidgetWrapper.data('username'),
                password: loginWidgetWrapper.data('password'),
                usernameHint: loginWidgetWrapper.data('usernameHint'),
                signIn: loginWidgetWrapper.data('signIn'),
                forgottenPassword: loginWidgetWrapper.data('forgottenPassword'),
            };
            const setup = ACC.forgerock.getForgerockSetup();
            // eslint-disable-next-line max-len
            window.createForgerockLoginWidget(ACC.forgerock.loginWidgetWrapperId, ACC.config.encodedContextPath, loginWidgetWrapper.data('loginReferer'), content, setup);
        }
    },

    bindLogoutAction() {
        if (!ACC.config.forgerockSsoEnabled) {
            return;
        }
        $('body').on('click', 'a[href$="/logout"]', (e) => {
            e.preventDefault();
            if ($('#showLeaveRegistrationPopup').val() !== 'true') {
                if (window.forgerockLogout) {
                    window.forgerockLogout()
                        .then(() => { window.location.href = ACC.config.logoutUrl; });
                }
            }
        });
    },

    autologin() {
        if (window.forgerockAutoLogin) {
            window.forgerockAutoLogin(`${ACC.config.encodedContextPath}`, `${window.location.href}`);
        }
    },

    autologout() {
        if (window.forgerockAutoLogin) {
            window.forgerockAutoLogout(`${ACC.config.encodedContextPath}`);
        }
    },

    login(username, password, rememberMe, originalReferer) {
        ACC.forgerock.setForgerockRoutingRegion(username)
            .then((response) => {
                if (response) {
                    // eslint-disable-next-line max-len
                    ACC.forgerock.forgerockAuthenticate(username, password, response, rememberMe, originalReferer, true);
                }
            })
            .catch(() => {
                ACC.forgerock.showLoginError();
            });
    },

    forgerockAuthenticate(username, password, routingRegion, rememberMe, originalReferer, removeCookieIfAuthError) {
        ACC.forgerock.hideLoginError();
        const bodyIam = {
            template: '',
            stage: 'DataStore1',
            header: 'Sign in',
            callbacks: [
                {
                    type: 'NameCallback',
                    output: [
                        {
                            name: 'prompt',
                            value: 'User Name:',
                        },
                    ],
                    input: [
                        {
                            name: 'IDToken1',
                            value: username,
                        },
                    ],
                },
                {
                    type: 'PasswordCallback',
                    output: [
                        {
                            name: 'prompt',
                            value: 'Password:',
                        },
                    ],
                    input: [
                        {
                            name: 'IDToken2',
                            value: password,
                        },
                    ],
                },
            ],
        };
        fetch(ACC.config.iamAuthenticateUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept-Language': ACC.cookie.getCookie('site-locale'),
            },
            body: JSON.stringify(bodyIam),
        }).then((response) => {
            if (response.status !== 200) {
                ACC.forgerock.showLoginError();
            }
            return response.json();
        })
            .then((response) => {
                if (response && response.tokenId) {
                    window.getTokens().then((tokens) => {
                        if (tokens) {
                            ACC.forgerock.hybrisLogin(routingRegion, tokens.accessToken, originalReferer, rememberMe);
                        }
                    });
                } else if (response && !response.tokenId && removeCookieIfAuthError) {
                    ACC.forgerock.removeForgerockSSOCookie()
                        .then((res) => {
                            if (res && res.status === 200) {
                                // eslint-disable-next-line max-len
                                ACC.forgerock.forgerockAuthenticate(username, password, routingRegion, rememberMe, originalReferer, false);
                            } else {
                                ACC.forgerock.showLoginError();
                            }
                        });
                } else {
                    ACC.forgerock.showLoginError();
                }
            })
            .catch((error) => {
                ACC.forgerock.showLoginError();
                console.log(error);
            });
    },

    setForgerockRoutingRegion(username) {
        return fetch(`${ACC.config.forgerockRoutingRegionUrl}/${username}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        }).then((response) => {
            if (response.status !== 200) {
                throw new Error('fetching user region failed');
            }
            return response.json();
        }).then((response) => {
            if (response && response.routingRegion) {
                let cookieValue;
                if (Array.isArray(response.routingRegion)) {
                    [cookieValue] = response.routingRegion;
                } else {
                    cookieValue = response.routingRegion;
                }
                document.cookie = `routingRegion=${cookieValue};path=/`;
                return cookieValue;
            }
            return null;
        });
    },

    removeForgerockSSOCookie() {
        return fetch(`${ACC.config.encodedContextPath}/forgerock/authenticate/remove-cookie`, {
            method: 'POST',
            headers: {
                CSRFToken: ACC.config.CSRFToken,
            },
        }).catch((err) => {
            console.log(err);
        });
    },

    hybrisLogin(routingRegion, accessToken, loginReferer, rememberMe) {
        // eslint-disable-next-line max-len
        fetch(`${ACC.config.encodedContextPath}/forgerock/authenticate/login?loginReferer=${encodeURIComponent(loginReferer)}&rememberMe=${rememberMe}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                AuthToken: accessToken,
                RoutingRegion: routingRegion,
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    response.json().then((resultResponse) => {
                        if (resultResponse && resultResponse.errorMessage) {
                            ACC.forgerock.showLoginError(resultResponse.errorMessage);
                            window.forgerockLogout();
                        } else if (resultResponse && resultResponse.redirectUrl) {
                            window.location.href = resultResponse.redirectUrl;
                        }
                    }).catch((e) => {
                        console.log(e);
                    });
                } else {
                    ACC.forgerock.showLoginError();
                }
            })
            .catch((error) => {
                console.log(error);
                window.forgerockLogout();
            });
    },

    hybrisLogout() {
        return fetch(`${ACC.config.encodedContextPath}/forgerock/authenticate/logout`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
    },

    showLoginError(errorMessage) {
        $('.js-login-error-wrapper').show();
        const defaultErrorMessage = $('.js-login-error-text').data('login-error-message');
        $('.js-login-error-text').html(errorMessage || defaultErrorMessage);
    },

    hideLoginError() {
        $('.js-login-error-wrapper').hide();
    },

    isAuthGenerated() {
        return !!window.localStorage.getItem('forgerock-sdk-IDLW-Client');
    },

    isLoginPage() {
        return window.location.href.includes('/login');
    },

    isRegisterPage() {
        return window.location.href.includes('/register');
    },

    configureForgerock() {
        window.configureForgerock(ACC.forgerock.getForgerockSetup());
    },

    getForgerockSetup() {
        return {
            clientId: ACC.config.forgerockClientId,
            serverBaseUrl: `${ACC.config.forgerockBaseUrl}/auth`,
            realmPath: ACC.config.forgerockRealmPath,
        };
    },
};
