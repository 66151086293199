ACC.registerPersona = {

    _autoload: [
        'initialize',
        'bindCountryStates',
        'bindPostalCodeValidation',
        'checkForDuplicates',
        ['bindSubmitMarketingConsent', $('#registerPersonaMarketingConsent').length === 1],
    ],

    jobTitlesToSubcategoryMapCategoryIndex: 0,
    jobTitlesToSubcategoryMapSubcategoryIndex: 1,

    spinner: $(`<img src='${ACC.config.commonResourcePath}/images/spinner.gif' />`),

    initialize() {
        if ($('#registerPersonaForm').data('isiamredirecton') === true) {
            if ($('#registerToken').length > 0 && piSession) {
                piSession.getToken((status, token) => {
                    $('#registerToken').val(token);
                    $('#registerUserId').val(piSession.userId());
                });
            }
        } else {
            const pearsonUsername = $('#registerPersonaForm').data('personausername');
            if (pearsonUsername) {
                $('#registerUserId').val(pearsonUsername);
            }
        }
        if (ACC.registerPersona.isRegisterPersonaPersonalContext()) {
            ACC.registerPersona.linkJobTitleAndIndustryCategories();
            ACC.registerPersona.hideIndustryCategories();
        } else {
            ACC.registerPersona.bindRegisterPersona();
        }
    },

    hideIndustryCategories() {
        const hiddenIndustryCodesList = ACC.config.hiddenIndustryCodes;
        if (hiddenIndustryCodesList !== undefined && hiddenIndustryCodesList !== '') {
            hiddenIndustryCodesList.split(',').forEach((code) => {
                $(`#registerCategory option[value=${code}]`).attr('hidden', 'hidden');
            });
        }
    },

    isRegisterPersonaPersonalContext() {
        return $('.registerPersona__section').length > 0;
    },

    linkJobTitleAndIndustryCategories() {
        const url = `${ACC.config.encodedContextPath}/register/personal-info/linkedJobTitlesSelections`;
        $.getJSON(url, (data) => {
            $('#registerJobTitle').change(function () {
                ACC.registerPersona.registerJobTitleOnChangeHandler(data, this.value, false);
            });
            ACC.registerPersona.bindOnChangeIndustryCategoriesAndOnPageRefresh(data);
        });
    },

    bindOnChangeIndustryCategoriesAndOnPageRefresh(jobTitlesToSubcategoryMap) {
        const registerSubcategory = $('#registerSubCategoryCode').val();
        const url = `${ACC.config.encodedContextPath}/register/personal-info/linkedCategorySelections`;
        $.getJSON(url, (data) => {
            $('#registerCategory').change(function () {
                ACC.registerPersona.registerCategoryOnChangeHandler(data, this.value, false, false);
            });
            const shouldBeHidden = ACC.registerPersona.hasCategoryMappedSubcategory(data, registerSubcategory);
            ACC.registerPersona.onPageRefreshLinkComboBoxes(jobTitlesToSubcategoryMap, data, shouldBeHidden);
        });
    },

    registerJobTitleOnChangeHandler(jobTitlesToSubcategoryMap, jobTitle, validateForm) {
        const registerCategoryCombo = $('#registerCategory');
        const registerSubcategoryCombo = $('#registerSubCategory');
        if (ACC.registerPersona.hasJobTitlesMappedSubcategories(jobTitlesToSubcategoryMap, jobTitle)) {
            const mappedSubcategories = jobTitlesToSubcategoryMap[jobTitle];
            const category = mappedSubcategories[ACC.registerPersona.jobTitlesToSubcategoryMapCategoryIndex];
            const subcategory = mappedSubcategories[ACC.registerPersona.jobTitlesToSubcategoryMapSubcategoryIndex];
            ACC.registerPersona.selectValueAndHideComboBox(registerCategoryCombo, category, true);
            ACC.registerPersona.constructSubcategoriesComboBox(subcategory, validateForm, true);
        } else {
            ACC.registerPersona.resetComboBox(registerCategoryCombo);
            ACC.registerPersona.resetComboBox(registerSubcategoryCombo);
            ACC.registerPersona.showComboBox(registerSubcategoryCombo, false);
            ACC.registerPersona.showComboBox(registerCategoryCombo, true);
        }
    },

    resetComboBox(combo) {
        combo.find('option:first').selected(true);
    },

    showComboBox(combo, show) {
        if (show) {
            combo.closest('.form-group').show();
            combo.closest('.selectBoxWrapper').removeClass('hidden');
        } else {
            combo.closest('.form-group').hide();
            combo.closest('.selectBoxWrapper').addClass('hidden');
        }
    },

    hasJobTitlesMappedSubcategories(jobTitlesToSubcategoryMap, jobTitle) {
        const mappedSubcategories = jobTitlesToSubcategoryMap[jobTitle];
        return mappedSubcategories != null
            && mappedSubcategories.length === 2
            && mappedSubcategories[ACC.registerPersona.jobTitlesToSubcategoryMapCategoryIndex] != null
            && mappedSubcategories[ACC.registerPersona.jobTitlesToSubcategoryMapSubcategoryIndex] != null;
    },

    hasCategoryMappedSubcategory(categoryToSubcategoryMap, category) {
        const mappedSubcategory = categoryToSubcategoryMap[category];
        return mappedSubcategory != null;
    },

    registerCategoryOnChangeHandler(categoryToSubcategoryMap, category, validateForm, shouldBeHidden) {
        // eslint-disable-next-line no-undef
        const registerSubcategory = DOMPurify.sanitize($('#registerSubCategoryCode').val());
        if (ACC.registerPersona.hasCategoryMappedSubcategory(categoryToSubcategoryMap, category)) {
            ACC.registerPersona.constructSubcategoriesComboBox(categoryToSubcategoryMap[category], validateForm, true);
        } else {
            ACC.registerPersona.constructSubcategoriesComboBox(registerSubcategory, validateForm, shouldBeHidden);
        }
    },

    selectValueAndHideComboBox(combo, value, shouldBeHidden) {
        if (value) {
            let isValueSelected = false;
            combo.find('option').each(function () {
                if ($(this).val() === value) {
                    $(this).selected(true);
                    isValueSelected = true;
                }
            });
            if (isValueSelected && shouldBeHidden) {
                ACC.registerPersona.showComboBox(combo, false);
            }
        }
    },

    constructSubcategoriesComboBox(subcategoryToSelect, validateForm, shouldBeHidden) {
        const registerSubCategoryCombo = $('#registerSubCategory');
        const registerCategoryCombo = $('#registerCategory');
        const registerCategoryComboVal = registerCategoryCombo.val();
        // eslint-disable-next-line no-undef
        const emptyLabel = DOMPurify.sanitize($('#registerSubCategory option:first').prop('label'));
        // eslint-disable-next-line max-len
        ACC.registerPersona.populateSubcategoriesCombo(registerCategoryComboVal, emptyLabel, subcategoryToSelect, () => {
            return ACC.registerPersona.selectValueAndHideComboBox(
                registerSubCategoryCombo,
                subcategoryToSelect,
                shouldBeHidden,
            );
        }, validateForm);
    },

    getSubCategoriesUrl() {
        let url = window.location.origin + window.location.pathname;
        if (url.endsWith('/newPersona')) {
            url = url.substring(0, url.lastIndexOf('/newPersona'));
        } else if (url.endsWith('/address/suggestions')) {
            url = url.substring(0, url.lastIndexOf('/address/suggestions'));
        }
        return `${url}/subCategories`;
    },

    populateSubcategoriesCombo(categoryCode, emptyLabel, preselectedValue, onSuccessFunction, validateForm) {
        const comboId = '#registerSubCategory';
        const combo = $(comboId);
        if (categoryCode != null) {
            $.get(ACC.registerPersona.getSubCategoriesUrl(), {
                categoryCode,
            }, (data) => {
                if (data.length > 0) {
                    ACC.registerPersona.showComboBox(combo, true);
                    combo.children('option').remove();
                    // eslint-disable-next-line max-len
                    combo.append($(`<option value="" disabled="disabled" label="${emptyLabel}" />`));
                    $.each(data, (index) => {
                        combo.append($('<option/>', {
                            value: data[index].code,
                            text: data[index].name,
                        }));
                    });
                    // eslint-disable-next-line max-len
                    const subCategoryCode = preselectedValue
                        && $(`${comboId} option[value=${preselectedValue}]`).length !== 0 ? preselectedValue : '';
                    $(`${comboId} option[value='${subCategoryCode}']`).attr('selected', 'selected');
                    if (onSuccessFunction instanceof Function) {
                        onSuccessFunction.call();
                    }
                } else {
                    ACC.registerPersona.showComboBox(combo, false);
                }
            }).done(() => {
                if (validateForm) {
                    ACC.validation.triggerManualValidation('#registerPersonaForm');
                }
            });
        }
    },

    populateCategoriesAndSubcategories() {
        const registerCategoryCombo = $('#registerCategory');
        const registerSubCategoryCombo = $('#registerSubCategory');
        const emptyLabel = $('#registerSubCategory option:first').text();

        const registerCategoryComboVal = registerCategoryCombo.val();
        const registerSubCategoryComboVal = registerSubCategoryCombo.val();

        if (registerCategoryComboVal && !registerSubCategoryComboVal) {
            // eslint-disable-next-line max-len
            ACC.registerPersona.populateSubcategoriesCombo(registerCategoryComboVal, emptyLabel, null, null, false);
        }

        registerCategoryCombo.change(() => {
            // eslint-disable-next-line max-len
            ACC.registerPersona.populateSubcategoriesCombo(registerCategoryCombo.val(), emptyLabel, null, null, false);
        });
    },

    bindRegisterPersona() {
        if ($('#registerToken').length > 0) {
            ACC.registerPersona.populateCategoriesAndSubcategories();
        }
    },

    onPageRefreshLinkComboBoxes(jobTitlesToSubcategoryMap, categoryToSubcategoryMap, shouldBeHidden) {
        if ($('#registerToken').length > 0) {
            const registerCategoryComboVal = $('#registerCategory').val();
            const registerJobTitleComboVal = $('#registerJobTitle').val();

            // eslint-disable-next-line max-len
            if (ACC.registerPersona.hasJobTitlesMappedSubcategories(jobTitlesToSubcategoryMap, registerJobTitleComboVal)) {
                // eslint-disable-next-line max-len
                ACC.registerPersona.registerJobTitleOnChangeHandler(jobTitlesToSubcategoryMap, registerJobTitleComboVal, true);
            } else {
                // eslint-disable-next-line max-len
                ACC.registerPersona.registerCategoryOnChangeHandler(categoryToSubcategoryMap, registerCategoryComboVal, true, shouldBeHidden);
            }
        }
    },

    bindCountryStates() {
        $('#defaultAddressCountrySelector').on('change', (e) => {
            const selectedCountry = $('#defaultAddressCountrySelector :selected').val();
            e.target.classList.add('selected');
            ACC.registerPersona.displayStatesForChosenCountry(
                { countryIsoCode: selectedCountry },
                $('#countryAddressData'),
                ACC.registerPersona.spinner,
            );
        });
    },

    bindPostalCodeValidation() {
        $('#address\\.postcode').on('input', () => {
            const selectedCountry = $('#defaultAddressCountrySelector :selected').val();
            if (ACC.config.countriesWithoutPostalCodeValidation.includes(selectedCountry)) {
                $('#registerPersonaForm').validate().settings.rules['pearsonProfileAddressForm.postcode'] = {
                    required: false,
                };
            } else if (ACC.config.countriesWithPostCodeValidation.includes(selectedCountry)) {
                $('#registerPersonaForm').validate().settings.rules['pearsonProfileAddressForm.postcode'] = {
                    required: true,
                    minlength: 1,
                    maxlength: 30,
                    postCodeInvalid: true,
                };
            }
        });
        ACC.registerPersona.showPostalCodeInvalidFormatError();
    },

    showPostalCodeInvalidFormatError() {
        const selectedCountry = $('#defaultAddressCountrySelector :selected').val();
        const postCodeFormGroup = $($('#address\\.postcode').closest('.form-group'));

        if (postCodeFormGroup.hasClass('has-error') && selectedCountry === 'GB') {
            $('#practitionerPostalCodeInvalidFormatError').removeClass('hidden');
        }
    },

    bindSubmitMarketingConsent() {
        $('#registerPersonaForm').submit(function () {
            const form = $(this);
            const marketingConsentData = {
                marketingEmailConsent: $('#marketingEmailConsent').is(':checked'),
                marketingMailConsent: $('#marketingMailConsent').is(':checked'),
            };

            $.ajax({
                url: `${ACC.config.encodedContextPath}/customer-consent/set`,
                type: 'POST',
                async: false,
                data: marketingConsentData,
                dataType: 'json',
                success() {
                    form.off('submit');
                    form.submit();
                },
                error(e) {
                    // eslint-disable-next-line no-console
                    console.log(e);
                },
            });
            return false;
        });
    },

    displayStatesForChosenCountry(options, countryAddressData, spinner) {
        $.ajax({
            url: `${ACC.config.encodedContextPath}/register/displayStates`,
            async: true,
            data: options,
            dataType: 'html',
            beforeSend() {
                countryAddressData.html(spinner);
            },
        }).done((data) => {
            countryAddressData.empty();
            countryAddressData.append($(data).html());
            ACC.address.hidePostalZipCode(options.countryIsoCode);
        });
    },

    checkForDuplicates() {
        const form = $('#registerExistingForm');
        if (form.length) {
            const spinnerText = $('#registerExistingSpinnerText').text();
            ACC.spinner.start(spinnerText);
            const userData = {
                firstName: $('#registerPersonaForm').data('existinguserfirstname'),
                lastName: $('#registerPersonaForm').data('existinguserlastname'),
                email: $('#registerPersonaForm').data('existinguseremail'),
            };

            if (!userData.firstName && !userData.lastName && !userData.email) {
                ACC.spinner.stop();
                return;
            }

            $.ajax({
                url: `${ACC.config.encodedContextPath}/register-existing/checkForDuplicates`,
                type: 'POST',
                data: userData,
                success(existingUserId) {
                    $('#existingCustomerId').val(existingUserId);
                    form.submit();
                },
                error() {
                    ACC.spinner.stop();
                },
            });
        }
    },
};
