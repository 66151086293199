ACC.cartitem = {

    _autoload: [
        'bindCartItem',
    ],

    submitTriggered: false,

    bindCartItem() {
        $('.js-execute-entry-action-button a').on('click', function () {
            // eslint-disable-next-line no-unused-vars
            const entryAction = $(this).data('entryAction');
            const entryActionUrl = $(this).data('entryActionUrl');
            // eslint-disable-next-line no-unused-vars
            const entryProductCode = $(this).data('entryProductCode');
            // eslint-disable-next-line no-unused-vars
            const entryInitialQuantity = $(this).data('entryInitialQuantity');
            const actionEntryNumbers = $(this).data('actionEntryNumbers');
            const cartEntryActionForm = $('#cartEntryActionForm');
            const entryNumbers = actionEntryNumbers.toString().split(';');
            entryNumbers.forEach((entryNumber) => {
                const entryNumbersInput = $('<input>').attr('type', 'hidden')
                    .attr('name', 'entryNumbers').val(entryNumber);
                cartEntryActionForm.append($(entryNumbersInput));
            });
            cartEntryActionForm.attr('action', entryActionUrl).submit();
        });

        $('.js-update-entry-quantity-input').on('change', function (e) {
            e.preventDefault();
            if (navigator.userAgent.indexOf('Firefox') !== -1) {
                this.focus();
            }
            setTimeout(() => { ACC.cartitem.handleUpdateQuantity(this); }, 1000);
        }).on('keydown', function (e) {
            if (e.which === 13) {
                e.preventDefault();
                ACC.cartitem.handleUpdateQuantity(this);
            }
        });
    },

    handleUpdateQuantity(elementRef) {
        $('.js-create-quote-button').prop('disabled', true);
        $('.js-continue-checkout-button').prop('disabled', true);
        $('.js-save-cart-link').prop('disabled', true);

        const form = $(elementRef).closest('form');

        // eslint-disable-next-line no-unused-vars
        const initialCartQuantity = form.find('input[name=initialQuantity]').val();
        const newCartQuantity = form.find('input[name=quantity]').val();

        if (initialCartQuantity !== newCartQuantity && !ACC.cartitem.submitTriggered) {
            ACC.cartitem.submitTriggered = true;
            form.submit();
        }
    },
};

$(document).ready(() => {
    $('.js-cartItemDetailBtn').click(function (event) {
        event.stopPropagation();
        const thisDetailGroup = $(this).parent('.js-cartItemDetailGroup');
        $(thisDetailGroup).toggleClass('open'); // only in its parent
        if ($(thisDetailGroup).hasClass('open')) {
            // close all if not this parent
            $('.js-cartItemDetailGroup').not(thisDetailGroup).removeClass('open');
            // change aria
            $('.js-cartItemDetailBtn').attr('aria-expanded', 'true');
        } else {
            $('.js-cartItemDetailBtn').attr('aria-expanded', 'false');
        }
        $(document).click(() => {
            $(thisDetailGroup).removeClass('open');
        }); // closes when clicking outside this div
    });

    // enable comment for this item only
    $('.js-entry-comment-button').click(function (event) {
        event.preventDefault();
        const linkID = $(this).attr('href');
        $(linkID).toggleClass('in');
        // eslint-disable-next-line no-undef
        $(thisDetailGroup).removeClass('open');
    });
});
