ACC.checkoutTooltip = {
    _autoload: [
        ['init', $('#newCreditCardFormId').length !== 0 && document.querySelector('checkout-informal__cvv')],
    ],

    init() {
        ACC.checkoutTooltip.setTooltip('cvv', '#newCreditCardFormId');
    },

    appendScreenreaderText(tooltipText, field) {
        const screenreaderComponent = document.querySelector(`.checkout-informal__${field}`)
            .querySelector('.checkout-informal__icon').querySelector('.screenreader');
        if (screenreaderComponent.length !== 0) {
            screenreaderComponent.innerText = tooltipText;
        }
    },

    setTooltip(field, selector) {
        const parentElement = document.querySelector(selector);
        const tooltipContent = parentElement
            .querySelector(`.checkout-informal__${field}`)
            .querySelector('.checkout-informal__tooltip');
        let tooltipText;
        if (selector === '#newCreditCardFormId' || selector === '#addNewPaymentSOP') {
            tooltipText = ACC.config.tooltipMessageCVVCommon;
        } else {
            tooltipText = parentElement.closest('.c-saved-card').querySelector('.card-info__payment')
                .textContent.trim() === 'Amex' ? ACC.config.tooltipMessageCvvAmex : ACC.config.tooltipMessageCvvOther;
        }
        if (tooltipText.length !== 0) {
            tooltipContent.innerHTML = tooltipText;
            ACC.checkoutTooltip.appendScreenreaderText(tooltipText, field);
        }

        ACC.checkoutTooltip.bindEvents(field, selector);
    },

    bindEvents(field, selector) {
        const parentElement = document.querySelector(selector);
        const iconTooltip = parentElement
            .querySelector(`.checkout-informal__${field}`)
            .querySelector('.checkout-informal__icon');
        const tooltipContent = parentElement
            .querySelector(`.checkout-informal__${field}`)
            .querySelector('.checkout-informal__tooltip');

        // eslint-disable-next-line max-len
        iconTooltip.addEventListener('mouseover', () => ACC.checkoutTooltip.showTooltip(tooltipContent, iconTooltip, field));
        // eslint-disable-next-line max-len
        iconTooltip.addEventListener('focus', () => ACC.checkoutTooltip.showTooltip(tooltipContent, iconTooltip, field));

        if (ACC.utils.isHedTheme()) {
            // eslint-disable-next-line max-len
            iconTooltip.addEventListener('mouseleave', () => ACC.checkoutTooltip.hideTooltipWithTimeout(tooltipContent));
            iconTooltip.addEventListener('blur', () => ACC.checkoutTooltip.hideTooltipWithTimeout(tooltipContent));
            // eslint-disable-next-line max-len
            tooltipContent.addEventListener('mouseleave', () => ACC.checkoutTooltip.hideTooltipWithTimeout(tooltipContent));
        } else {
            iconTooltip.addEventListener('mouseleave', () => ACC.checkoutTooltip.hideTooltip(tooltipContent));
            iconTooltip.addEventListener('blur', () => ACC.checkoutTooltip.hideTooltip(tooltipContent));
        }
    },

    setTooltipsForSavedCards() {
        const savedCards = document.querySelectorAll('.c-saved-card');
        if (savedCards.length) {
            savedCards.forEach((card) => {
                const idOfSavedCard = card.querySelector('.saved-card-cvv').id;
                ACC.checkoutTooltip.setTooltip('cvv', `#${idOfSavedCard}`);
            });
        }
    },

    showTooltip(tooltipContent, iconTooltip, field) {
        tooltipContent.classList.add('isShow');
        tooltipContent.classList.remove('isHide');
        tooltipContent.setAttribute('aria-hidden', 'false');
        if (ACC.utils.isHedTheme()) {
            document.querySelector('.checkout-informal__icon')
                .setAttribute('aria-expanded', 'true');
        }
        if (field === 'cvv') {
            // eslint-disable-next-line no-undef,no-new
            new Popper(iconTooltip, tooltipContent, {
                placement: 'bottom-end',
                modifiers: {
                    flip: {
                        behavior: ['left', 'right'],
                    },
                    preventOverflow: {
                        enabled: true,
                        priority: ['left', 'right'],
                    },
                    hide: {
                        enabled: false,
                    },
                    offset: {
                        enabled: true,
                        offset: '10, 5',
                    },
                },
            });
        }
    },

    hideTooltip(tooltipContent) {
        tooltipContent.classList.remove('isShow');
        tooltipContent.classList.add('isHide');
        tooltipContent.setAttribute('aria-hidden', 'true');
    },

    hideTooltipWithTimeout(tooltipContent) {
        const timeOutId = setTimeout(() => {
            if (!tooltipContent.matches(':hover')) {
                tooltipContent.classList.remove('isShow');
                tooltipContent.classList.add('isHide');
                tooltipContent.setAttribute('aria-hidden', 'true');
                document.querySelector('.checkout-informal__icon')
                    .setAttribute('aria-expanded', 'false');
            }
            clearTimeout(timeOutId);
        }, 300);
    },
};
