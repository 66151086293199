ACC.checkout = {

    _autoload: [
        'initializePiSession',
        'initialize',
        'moveCheckoutSummary',
        'bindCheckO',
        'bindForms',
        'bindSavedPayments',
        'bindPaymentTypeSelect',
        'bindAccountVerificationTypeSelect',
        'bindDeliveryMethodChange', ['bindCardTypeIdentification', $('#card_accountNumber').length !== 0],
        ['bindCardTypeIdentification', $('#card_accountNumber').length],
        'bindContactDetails',
        'bindDisplayInvoiceInformation',
        'clearAddressRadioButtonsData',
        'bindInvoiceDetailsStepSubmit',
        'bindShippingAddressGroupSelection',
        'bindCreditCardFormValidation',
        'bindShippingFormSpecialCharacters',
    ],

    piSessionData: {},

    clickedInCreditCardFormArea: false,

    bindInvoiceDetailsStepSubmit() {
        $('.js-invoice-details-next-button').click((event) => {
            event.preventDefault();
            const addressTypeInput = $('input[type=radio][name=addressGroupType]');
            if (addressTypeInput.length > 0) {
                const checkedButton = addressTypeInput.filter(':checked').val();
                if (checkedButton === 'oneAddress') {
                    ACC.checkout.handleAddressForm('#selectAddressForm');
                } else if (checkedButton === 'searchAddress') {
                    ACC.checkout.validateSearchAddressForm(
                        $('#searchAddressForm'),
                        'checkout.multi.invoiceDetails.billingAddress.notprovided',
                    );
                }
            }
        });
    },

    clearAddressRadioButtonsData() {
        const addressTypeInput = $('input[type=radio][name=addressGroupType]');
        addressTypeInput.change(() => {
            if ($('#addNewAddress').is(':checked')) {
                ACC.checkout.reloadNewBillingAddressForm();
            } else {
                $('.js-address-doctor-edit-address').hide();
                ACC.silentorderpost.clearSearchAddressForm();
                if (ACC.address.isCheckoutFlow()) {
                    ACC.silentorderpost.clearAddressForm();
                }
                $('.address-search ul.address').remove();
                ACC.validation.triggerManualValidation('#placeOrderForm1');
            }
        });
    },

    reloadNewBillingAddressForm() {
        $('#address\\.country').val(ACC.checkout.getDefaultCountryForCountrySelector()).trigger('change');
    },

    getDefaultCountryForCountrySelector() {
        if (window.location.href.indexOf('/delivery-address/add') >= 0) {
            return $('#countrySelector').data('default-country');
        }
        return window.location.href.indexOf('/checkout/quote/create') >= 0
            ? $('#useQuoteDeliveryAddressData').data('default-country')
            : $('#useBillingAddressData').data('default-country');
    },

    bindForms() {
        $(document).on('click', '#addressSubmit', (e) => {
            e.preventDefault();
            const addressTypeInput = $('input[type=radio][name=addressGroupType]');
            if (addressTypeInput.length > 0) {
                const checkedButton = addressTypeInput.filter(':checked').val();
                if (checkedButton === 'oneAddress') {
                    ACC.checkout.handleAddressForm('#selectAddressForm');
                } else if (checkedButton === 'searchAddress') {
                    ACC.checkout.validateSearchAddressForm($('#searchAddressForm'));
                } else if (checkedButton === 'addNewAddress') {
                    ACC.checkout.trimFormInputValues('#addressForm');
                    ACC.checkout.handleAddressForm('#addressForm');
                }
            } else {
                ACC.checkout.handleAddressForm('#addressForm');
            }
        });

        $(document).on('click', '#deliveryMethodSubmit', (e) => {
            e.preventDefault();
            $('#selectDeliveryMethodForm').submit();
        });

        $(document).on('click', '#qualificationUsersSubmit', (e) => {
            e.preventDefault();
            $('#qualifiedUsersForm').submit();
        });

        $(document).on('click', '#paymentTypeSubmit', (e) => {
            e.preventDefault();
            $('#selectPaymentTypeForm').submit();
        });

        $(document).on('click', '#accountVerificationSubmit', (e) => {
            e.preventDefault();
            $('#accountVerificationForm').submit();
        });

        $(document).on('click', '#toggleQualifiedUserAddEmail', (e) => {
            e.preventDefault();
            $('.checkoutQualifiedUserAddEmailSection').toggle();
            $('#toggleQualifiedUserAddEmail > span').toggleClass('glyphicon-chevron-down glyphicon-chevron-up');
        });

        // eslint-disable-next-line max-len
        if ($('.checkoutQualifiedUserAddEmailSection') && $('.checkoutQualifiedUserAddEmailSection >.form-group').hasClass('has-error')) {
            $('.checkoutQualifiedUserAddEmailSection').show();
        }

        $(document).on('click', '.js-place-order-b2c', function (e) {
            e.preventDefault();

            if (ACC.checkout.isSavedAddress()) {
                if (!ACC.checkout.isAddressSearchFieldInvalid()) {
                    ACC.checkout.selectSearchedBillingAddress();
                } else {
                    $('.address-search').addClass('has-error');
                    $('.address-search .help-block').removeClass('hidden');
                    return;
                }
            }
            ACC.buttonhelper.disable($(this));
        });
    },

    validateSearchAddressForm(form, errorGlobalMessageId = 'checkout.multi.deliveryAddress.notprovided') {
        if (form.find('input[name=addressId]').val()) {
            form.submit();
        } else {
            ACC.globalmessages.removeGlobalMessages();
            const globalMessageRequest = {
                globalMessages: [{
                    messageHolder: 'accInfoMsgs',
                    messages: [errorGlobalMessageId],
                }],
            };
            ACC.globalmessages.createGlobalMessage(globalMessageRequest);
        }
    },

    handleAddressForm(selector) {
        $(selector).submit();
    },

    trimFormInputValues(selector) {
        const inputs = document.querySelectorAll(`${selector} input`);
        inputs.forEach((input) => {
            input.value = input.value.trim();
        });
    },

    isAddressSearchFieldInvalid() {
        const addressTypeInput = $('input[type=radio][name=addressGroupType]');
        const checkedButton = addressTypeInput.filter(':checked').val();
        const isUseDefault = checkedButton === 'oneAddress';

        // eslint-disable-next-line max-len
        return ACC.checkout.isSavedAddress() && $("#searchAddressForm input[name='addressId']").val() === '' && !isUseDefault;
    },

    moveCheckoutSummary() {
        if (ACC.config.isCheckoutLayoutSwitchEnabled) return;

        if ($('.page-multiStepCheckoutSummaryPage').length > 0) {
            const mq_maxWidth = window.matchMedia('(max-width:991px)');

            // layout
            const moveTCandBtn = function () {
                const checkoutReview = $('.checkout-review');
                const placeOrderForm = $('.js-place-order-form');

                // viewport check: mobile (991px and under)
                if (mq_maxWidth.matches) {
                    placeOrderForm.insertAfter('.orderTotals');
                    checkoutReview.insertAfter('.orderTotals');
                } else {
                    placeOrderForm.insertAfter('.step-body');
                    checkoutReview.insertAfter('.step-body');
                }
            };

            // call fns on win.load
            $(window).on('load', () => {
                moveTCandBtn();
            });

            // call layout fn on debounced resize
            $(window).smartresize(() => {
                moveTCandBtn();
            });
        }
    },

    isSavedAddress() {
        const addressTypeInput = $('input[type=radio][name=addressGroupType]');
        const checkedButton = addressTypeInput.filter(':checked').val();
        return checkedButton === 'oneAddress' || checkedButton === 'searchAddress';
    },

    selectSearchedBillingAddress() {
        const addressTypeInput = $('input[type=radio][name=addressGroupType]');
        const checkedButton = addressTypeInput.filter(':checked').val();
        const form = checkedButton === 'oneAddress' ? $('#selectAddressForm :input') : $('#searchAddressForm :input');

        $.ajax({
            type: 'POST',
            url: `${ACC.config.encodedContextPath}/checkout/multi/summary/selectBillingAddress`,
            data: $(form).serialize(),
            success(data) {
                billingAddressFormDataResponse = data;
            },
            error(xhr) {
                console.log(xhr.responseText);
            },
        }).done(() => {
            $('#billingAddressFormContainer').html(billingAddressFormDataResponse);
            $('#useDeliveryAddress').prop('checked', false);

            $('#placeOrderForm1').submit();
        });
    },

    processAimswebProducts(targetUrl, updateCustomerUrl, validateAimswebProductsUrl) {
        $('.js-continue-checkout-button').attr('disabled', true);
        $.ajax({
            type: 'GET',
            url: validateAimswebProductsUrl,
            contentType: 'application/json',
            success(response) {
                if (typeof response !== 'undefined' && response !== '') {
                    const messageKey = 'checkout.multi.aimswebVerification.minimumProductsNotice';
                    const globalMessageRequest = {
                        globalMessages: [{
                            messageHolder: 'accErrorMsgs',
                            messages: [messageKey],
                            attributes: [response],
                        }],
                    };
                    $('.js-continue-checkout-button').removeAttr('disabled');
                    ACC.globalmessages.removeGlobalMessages();
                    ACC.globalmessages.createGlobalMessage(globalMessageRequest);
                } else {
                    ACC.checkout.processCheckoutRequest(targetUrl, updateCustomerUrl);
                }
            },
        });
    },

    processCheckoutRequest(targetUrl, updateCustomerUrl) {
        $.post({
            url: updateCustomerUrl,
            data: ACC.checkout.piSessionData,
            success() {
                window.location = targetUrl;
            },
            error() {
                const localizedBusinessError = $('#callIAMErrorMsg').val();
                $('.global-alerts').addClass('alert-danger');
                $('.alert-message').html(ACC.common.encodeHtml(localizedBusinessError));
            },
        });
    },

    bindSavedPayments() {
        $(document).on('click', '.js-saved-payments', (e) => {
            e.preventDefault();

            const title = $('#savedpaymentstitle').html();

            $.colorbox({
                href: '#savedpaymentsbody',
                inline: true,
                opacity: 0.7,
                title,
                close: '<span class="glyphicon glyphicon-remove"></span>',
                onComplete() {
                },
            });
        });
    },

    bindCheckO() {
        // eslint-disable-next-line no-unused-vars
        const cartEntriesError = false;

        // Alternative checkout flows options
        $('.doFlowSelectedChange').change(() => {
            if ($('#selectAltCheckoutFlow').val() === 'multistep-pci') {
                $('#selectPciOption').show();
            } else {
                $('#selectPciOption').hide();
            }
        });

        $('.expressCheckoutButton').click(() => {
            document.getElementById('expressCheckoutCheckbox').checked = true;
        });

        $(document).on('input', '.confirmGuestEmail,.guestEmail', () => {
            const orginalEmail = $('.guestEmail').val();
            const confirmationEmail = $('.confirmGuestEmail').val();

            if (orginalEmail === confirmationEmail) {
                $('.guestCheckoutBtn').removeAttr('disabled');
            } else {
                $('.guestCheckoutBtn').attr('disabled', 'disabled');
            }
        });

        $('.js-continue-checkout-button').click(function (event) {
            event.preventDefault();
            if ($('#cart-context-switch-modal').length !== 0) {
                $('#contextSwitchForm').data('quote-checkout', 'false');
                $('#cartContextSwitchModalTrigger').trigger('click');
                ACC.cart.bindCartContextRadioBtnSelection();
            } else {
                ACC.checkout.continueCheckout(this);
            }
        });
    },

    continueCheckout(el) {
        const targetUrl = $(el).data('target-url');
        const updateCustomerUrl = $(el).data('update-customer-url');
        const validateAimswebProductsUrl = $(el).data('validate-aimsweb-products-url');
        const hasOnlineTrainings = $('.js-online-trainings-add-participants-section').length !== 0;
        if (hasOnlineTrainings && !ACC.onlineTrainings.validateOnlineTrainings(true)) {
            return;
        }
        ACC.checkout.processAimswebProducts(targetUrl, updateCustomerUrl, validateAimswebProductsUrl);
    },

    continueQuoteCheckout(el) {
        const targetUrl = $(el).data('target-url');
        const updateCustomerUrl = $(el).data('update-customer-url');
        ACC.checkout.processCheckoutRequest(targetUrl, updateCustomerUrl);
    },

    redirectToCheckoutPage(checkoutUrl) {
        cartEntriesError = ACC.pickupinstore.validatePickupinStoreCartEntires();
        if (!cartEntriesError) {
            const expressCheckoutObject = $('.express-checkout-checkbox');
            if (expressCheckoutObject.is(':checked')) {
                window.location = expressCheckoutObject.data('expressCheckoutUrl');
            } else {
                let flow = $('#selectAltCheckoutFlow').val();
                if (flow === undefined || flow === '' || flow === 'select-checkout') {
                    // No alternate flow specified, fallback to default behaviour
                    window.location = checkoutUrl;
                } else {
                    // Fix multistep-pci flow
                    if (flow === 'multistep-pci') {
                        flow = 'multistep';
                    }
                    const pci = $('#selectPciOption').val();

                    // Build up the redirect URL
                    const redirectUrl = `${checkoutUrl}/select-flow?flow=${flow}&pci=${pci}`;
                    window.location = redirectUrl;
                }
            }
        }
    },

    bindPaymentTypeSelect() {
        $("input:radio[name='paymentType']").change(() => {
            if (ACC.checkout.isAccountPayment()) {
                ACC.checkout.displayAccountPaymentSection(true);
            } else {
                ACC.checkout.displayAccountPaymentSection(false);
            }
            ACC.checkout.initialize();
        });
    },

    bindAccountVerificationTypeSelect() {
        const $checkboxes = $('[name="verificationType"]');
        const $submitBtn = $('#accountVerificationSubmit');

        function updateState() {
            if ($checkboxes.filter(':checked').length > 0) {
                $submitBtn.prop('disabled', false);
            } else {
                $submitBtn.prop('disabled', true);
            }
        }

        updateState();
        $checkboxes.on('change', updateState);
    },

    isNewAccount() {
        return $('input:radio[name=verificationType]:checked').val() === 'NewAccount';
    },

    initialize() {
        if (ACC.checkout.isAccountPayment()) {
            ACC.checkout.displayAccountPaymentSection(true);
        } else {
            ACC.checkout.displayAccountPaymentSection(false);
        }
    },

    initializePiSession() {
        if (!piSession) { return; }

        ACC.piSessionInitializer.addCallback(() => {
            piSession.getToken((status, token) => {
                ACC.checkout.piSessionData.userID = piSession.userId();
                ACC.checkout.piSessionData.token = token;
            });
        });
    },

    isAccountPayment() {
        return $("input:radio[name='paymentType']:checked").val() === 'ACCOUNT';
    },

    displayAccountPaymentSection(data) {
        ACC.checkout.displayElement($('#accountPaymentDivID'), data);
    },

    displayElement(element, flag) {
        if (flag) {
            element.show();
        } else {
            element.hide();
        }
    },

    bindCardTypeIdentification() {
        const $creditsBox = $('.js-credits-box');
        const cardTypeToCardTypeCodeMap = {
            visa: '001',
            mastercard: '002',
            amex: '003',
            discover: '004',
            maestro: '024',
        };

        // eslint-disable-next-line no-new,no-undef
        new Cleave('#card_accountNumber', {
            creditCard: true,
            onCreditCardTypeChanged(type) {
                const value = cardTypeToCardTypeCodeMap[type] || 'invalid';
                $('#card_cardType').val(value);

                $creditsBox.find('.card-logo').addClass('isDisabled');
                if (value !== 'invalid') {
                    $creditsBox.find(`[data-type="${type}"]`).removeClass('isDisabled');
                }
            },
        });
    },

    bindDeliveryMethodChange() {
        function showMessagesFromDropDown() {
            $('#estimatedDeliveryDescription').html($('#delivery_method').find(':selected').data('estimated-message'));
            // eslint-disable-next-line max-len, prefer-template
            $('#deliveryDescription').html('<i>' + $('#delivery_method').find(':selected').data('delivery-message') + '</i>');
        }

        function showMessagesFromRadioBtn() {
            // eslint-disable-next-line max-len
            $('#estimatedDeliveryDescription').html($("input[name='delivery_method']:checked").data('estimated-message'));
            $('#deliveryDescription').html($("input[name='delivery_method']:checked").data('delivery-message'));
        }
        $('#delivery_method').change(showMessagesFromDropDown);
        $('#selectDeliveryMethodForm input').on('change', showMessagesFromRadioBtn);
        showMessagesFromDropDown();
        showMessagesFromRadioBtn();
    },

    bindContactDetails() {
        ACC.checkout.bindEditContactDetailsButton();
        ACC.checkout.bindSaveContactDetailsButton();
        ACC.checkout.displayContactDetailsModalOnLoad();
    },

    displayContactDetailsModalOnLoad() {
        $(document).ready(() => {
            if (ACC.config.showContactDetailsModalOnLoad) {
                $('.js-edit-contact-details').click();
            }
        });
    },

    bindEditContactDetailsButton() {
        $('.js-edit-contact-details').on('click', function (e) {
            e.preventDefault();
            ACC.checkout.showEditContactDetailsModal($(this));
        });
    },

    showEditContactDetailsModal(triggerButton) {
        const contactDetailsForm = $('#contactDetailsForm');
        const formElement = document.querySelector('#contactDetailsForm');
        ACC.replaceSpecialCharacters.bindSpecialCharactersConversion(formElement);
        $modal.open(null, {
            title: triggerButton.data('modal-title'),
            href: triggerButton.data('modal-content'),
            inline: true,
            className: 'checkoutContactDetails',
            onOpen() {
                contactDetailsForm.valid();
                ACC.validation.triggerManualValidation(contactDetailsForm);
            },
            onClosed() {
                contactDetailsForm.validate().resetForm();
                const closeButton = $('#cboxClose');
                closeButton.off('click');
                closeButton.on('click', () => {
                    $modal.close();
                });
            },
        });
    },

    bindSaveContactDetailsButton() {
        const contactDetailsForm = $('#contactDetailsForm');
        const firstName = contactDetailsForm.find('input[name^=firstName]');
        const lastName = contactDetailsForm.find('input[name^=lastName]');
        const email = contactDetailsForm.find('input[name^=email]');
        const phone = contactDetailsForm.find('input[name^=phone]');
        $('.js-save-contact-details').on('click', (e) => {
            e.preventDefault();
            ACC.checkout.saveContactDetails(firstName, lastName, email, phone);
        });
    },

    saveContactDetails(firstName, lastName, email, phone) {
        const contactDetailsData = {
            firstName: firstName.val(),
            lastName: lastName.val(),
            email: email.val(),
            phone: ACC.phonemasking.collectPhoneNumber(phone),
        };
        $.ajax({
            type: 'POST',
            async: true,
            url: `${ACC.config.encodedContextPath}/checkout/multi/contact-details/update-contact-details`,
            contentType: 'application/json',
            data: JSON.stringify(contactDetailsData),
            success: (responseData) => {
                ACC.checkout.replaceContactDetailsData($.parseHTML(responseData));
                $modal.close();
            },
        }).done(() => {
            ACC.checkout.bindSaveContactDetailsButton();
            ACC.validation.bindFormValidation('#contactDetailsForm');
            ACC.phonemasking.bindPhoneInput($('#contactDetails\\.phone'));
        });
    },

    replaceContactDetailsData(responseData) {
        $('#default-contact-details').html(responseData[0]);
        $('#popup_edit_customer_details').html(responseData[1]);
    },

    isCreateQuoteFlow() {
        return window.location.href.indexOf('/checkout/quote/create') >= 0;
    },

    isContactDetailsFormEmpty(firstName, lastName, email, phone) {
        return !firstName.val().trim().length || !lastName.val().trim().length
            || !email.val().trim().length || !phone.val().trim().length;
    },

    bindDisplayInvoiceInformation() {
        $('.account-cards-actions .c-info-badge').on('click', (e) => {
            e.preventDefault();
            const popup = document.getElementById('invoicePopup');
            popup.classList.toggle('show');
            ACC.checkout.bindCloseInvoiceInformation();
        });
    },

    bindCloseInvoiceInformation() {
        $(document).on('click', (e) => {
            const invoicePopup = document.getElementById('invoicePopup');
            const invoiceInfoBadge = document.getElementById('invoiceInfoBadge');
            if (e.target !== invoiceInfoBadge && e.target !== invoicePopup) {
                if (invoicePopup.classList.contains('show')) {
                    e.preventDefault();
                    invoicePopup.classList.remove('show');
                    $(document).unbind('click');
                }
            }
        });
    },

    bindCreditCardFormValidation() {
        const creditCardInfoDiv = document.getElementById('creditCardInfo');
        if (creditCardInfoDiv) {
            $(document).on('click', (e) => {
                if (this.shouldTriggerCreditCardFormValidation(creditCardInfoDiv, e)) {
                    ACC.validation.validateFormDiv('#creditCardInfo');
                }
            }).on('keyup', (e) => {
                if (e.which === 9 && this.shouldTriggerCreditCardFormValidation(creditCardInfoDiv, e)) {
                    ACC.validation.validateFormDiv('#creditCardInfo');
                }
            });
            $('#creditCardInfo :input').on('click focus', () => {
                this.clickedInCreditCardFormArea = true;
            });
        }
    },

    shouldTriggerCreditCardFormValidation(creditCardInfoDiv, e) {
        return !creditCardInfoDiv.contains(e.target) && this.clickedInCreditCardFormArea === true;
    },

    bindShippingAddressGroupSelection() {
        const shippingRadioButton = $('.shipping-address__group .c-radiobtn__input');
        const initiallySelected = $('.shipping-address__group .c-radiobtn__input:checked')
            .closest('.shipping-address__group');

        initiallySelected.addClass('selected');

        shippingRadioButton.on('click', (event) => {
            // eslint-disable-next-line prefer-arrow-callback
            $('.shipping-address__group').each(function () {
                $(this).removeClass('selected');
            });
            event.target.closest('.shipping-address__group').classList.add('selected');
            ACC.checkout.bindShippingFormSpecialCharacters();
        });
    },

    bindShippingFormSpecialCharacters() {
        const shippingForm = document.querySelector('.shipping-address__group #addressForm');
        if (shippingForm) {
            ACC.replaceSpecialCharacters.bindSpecialCharactersConversion(shippingForm);
        }
    },
};
