ACC.refinements = {

    _autoload: [
        ['bindMoreLessToggles', $('.js-facet-form').length !== 0],
        ['bindMoreStoresToggles', $('.js-facet-form').length !== 0],
        ['init', $('.js-facet-form').length !== 0],
        ['bindSearch', $('.js-facet-form').length !== 0],
        ['bindFacetsToggles', $('.js-facet-form').length !== 0],
    ],

    coords: {},
    storeSearchData: {},

    init() {
        if (ACC.config.locationPopupEnabled.toLowerCase() === 'true') {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    ACC.refinements.coords = position.coords;
                },
                (error) => {
                    // eslint-disable-next-line max-len,no-console
                    console.log(`An error occurred... The error code and message are: ${error.code}/${error.message}`);
                },
            );
        }
    },

    bindSearch() {
        $(document).on('submit', '#user_location_form', (e) => {
            e.preventDefault();
            const q = $('.js-shop-stores-facet .js-shop-store-search-input').val();
            if (q.length > 0) {
                ACC.refinements.getInitStoreData(q);
            }
        });

        $(document).on('click', '#findStoresNearMeAjax', (e) => {
            e.preventDefault();
            ACC.refinements.getInitStoreData(null, ACC.refinements.coords.latitude, ACC.refinements.coords.longitude);
        });
    },

    getInitStoreData(q, latitude, longitude) {
        $('.alert').remove();
        data = {
            q: '',
            page: '0',
        };

        if (q != null) {
            data.q = q;
        }

        if (latitude != null) {
            data.latitude = latitude;
        }

        if (longitude != null) {
            data.longitude = longitude;
        }

        ACC.refinements.storeSearchData = data;
        ACC.refinements.getStoreData();
    },

    getStoreData() {
        url = $('.js-facet-form').data('url');
        $.ajax({
            url,
            data: ACC.refinements.storeSearchData,
            type: 'get',
            success() {
                window.location.reload();
            },
        });
    },

    bindMoreLessToggles() {
        $(document).on('click', '.js-shop-stores-facet .js-facet-change-link', (e) => {
            e.preventDefault();
            $('.js-shop-stores-facet .js-facet-container').hide();
            $('.js-shop-stores-facet .js-facet-form').show();
        });

        $(document).on('change', '.js-facet-checkbox', function () {
            $(this).parents('form').submit();
            return false;
        });

        $(document).on('click', '.js-more-facet-values-link', function (e) {
            e.preventDefault();
            $(this).parents('.js-facet').find('.js-facet-top-values').hide();
            $(this).parents('.js-facet').find('.js-facet-list-hidden').show();

            $(this).parents('.js-facet').find('.js-more-facet-values').hide();
            $(this).parents('.js-facet').find('.js-less-facet-values').show();
        });

        $(document).on('click', '.js-less-facet-values-link', function (e) {
            e.preventDefault();
            $(this).parents('.js-facet').find('.js-facet-top-values').show();
            $(this).parents('.js-facet').find('.js-facet-list-hidden').hide();

            $(this).parents('.js-facet').find('.js-more-facet-values').show();
            $(this).parents('.js-facet').find('.js-less-facet-values').hide();
        });
    },

    bindMoreStoresToggles() {
        $(document).on('click', '.js-shop-stores-facet .js-more-stores-facet-values', (e) => {
            e.preventDefault();
            $('.js-shop-stores-facet ul.js-facet-list li.hidden').slice(0, 5).removeClass('hidden').first()
                .find('.js-facet-checkbox')
                .focus();
            if ($('.js-shop-stores-facet ul.js-facet-list li.hidden').length === 0) {
                $('.js-shop-stores-facet .js-more-stores-facet-values').hide();
            }
        });
    },

    bindFacetsToggles() {
        const activeClass = 'isActive';
        const showFilterBtn = $('.js-show-filters');
        const sideBarContainer = $('.side-bar');
        const maxWindowWidth = '(max-width: 991px)';

        // Accordion button event
        showFilterBtn.on('click', function () {
            if ($(this).hasClass(activeClass)) {
                $(this).removeClass(activeClass);
                sideBarContainer.slideUp();
            } else {
                $(this).addClass(activeClass);
                sideBarContainer.slideDown();
            }
        });

        const activeFacetLength = $('.js-facet-values').find('.applied-filters__tag', '.c-filter__applied').length;
        const isMobile = window.matchMedia(maxWindowWidth).matches;
        const categorySection = $('[data-component=CategorySection]');

        if (categorySection.length && activeFacetLength && isMobile) {
            $('html, body').animate({
                // scroll to the bottom categorySection
                scrollTop: categorySection.offset().top + categorySection.innerHeight(),
            }, 2000);
        }

        // Toggle trigger on resize
        let mobileToggled = true;
        $(window).on('load resize', () => {
            // eslint-disable-next-line no-shadow
            const isMobile = window.matchMedia(maxWindowWidth).matches;

            if (isMobile && mobileToggled) {
                ACC.refinements.showFacets(false);
                mobileToggled = false;
            } else if (!isMobile && !mobileToggled) {
                ACC.refinements.showFacets(true);
                mobileToggled = true;
            }
        });
    },

    showFacets(bool) {
        const sideBarContainer = $('.side-bar');
        const activeFacetLength = $('.js-facet-values').find('.applied-filters__tag', '.c-filter__applied').length;
        const activeClass = 'isActive';
        const dummyLabel = $('.drop-down__dummy-label');
        const sortMobileContainer = $('.js-sort-mobile');
        const sortDesktopContainer = $('.js-sort-desktop');
        const accordions = $('.side-bar-accordion');
        const showFilterBtn = $('.js-show-filters');

        if (bool) {
            // Desktop
            sideBarContainer.show();
            sortMobileContainer.find('.c-dropdown').appendTo(sortDesktopContainer);
            dummyLabel.show();

            //  Show facets
            accordions.each(function () {
                const _this = $(this);

                if (!_this.hasClass('active')) {
                    _this
                        .find('.side-bar-accordion__title').removeClass('toggled').attr('aria-expanded', true)
                        .end()
                        .find('.js-facet-values')
                        .show()
                        .addClass('accordion-panel-show')
                        .end()
                        .addClass('active');
                }
            });
        } else {
            // For mobile
            if (activeFacetLength > 0) {
                showFilterBtn.addClass(activeClass);
                sideBarContainer.slideDown();
            } else {
                showFilterBtn.removeClass(activeClass);
                sideBarContainer.slideUp();
            }

            //  Hide facets
            accordions.each(function () {
                const _this = $(this);

                _this
                    .find('.side-bar-accordion__title').addClass('toggled').attr('aria-expanded', false)
                    .end()
                    .find('.js-facet-values')
                    .hide()
                    .removeClass('accordion-panel-show')
                    .end()
                    .removeClass('active');
            });

            sortDesktopContainer.find('.c-dropdown').appendTo(sortMobileContainer);
            dummyLabel.hide();
        }
    },

    debounce(func, time) {
        let timeout;
        return function () {
            const context = this;
            // eslint-disable-next-line prefer-rest-params
            const args = arguments;
            const later = function () {
                timeout = null;
                func.apply(context, args);
            };
            clearTimeout(timeout);
            timeout = setTimeout(later, time);
        };
    },
};
