ACC.zippay = {

    _autoload: [
        'scrollToFirstInvalidField',
        ['start', $('#zipPayCheckoutPostForm').length !== 0],
    ],

    billingAddressRequiredInputFields: [
        '#zippay\\.address\\.townCity',
        '#zippay\\.address\\.postcode',
        '#zippay\\.address\\.firstName',
        '#zippay\\.address\\.surname',
        '#zippay\\.address\\.line1',
    ],

    billingAddressRequiredSelectFields: [
        '#zippay\\.address\\.country',
    ],

    billingAddressSimplifiedRequiredInputFields: [
        '#zippay\\.address\\.townCity',
        '#zippay\\.address\\.firstName',
        '#zippay\\.address\\.surname',
        '#zippay\\.address\\.line1',
    ],

    resolveBillingAddressRequiredInputFields(countyIsocode) {
        if (countyIsocode) {
            const countryRequiredBillingAddressFormFields = {
                FJ: ACC.zippay.billingAddressSimplifiedRequiredInputFields,
                CK: ACC.zippay.billingAddressSimplifiedRequiredInputFields,
                WS: ACC.zippay.billingAddressSimplifiedRequiredInputFields,
                TO: ACC.zippay.billingAddressSimplifiedRequiredInputFields,
            };
            const foundValue = countryRequiredBillingAddressFormFields[countyIsocode];
            if (foundValue) {
                return foundValue;
            }
        }
        return ACC.zippay.billingAddressRequiredInputFields;
    },

    start() {
        ACC.zippay.attachHandlerForZipCheckoutBtn();
        ACC.zippay.attachBillingAddressHandlers();
        ACC.zippay.attachBillingAddressForm();
        ACC.zippay.billingAddressSelection();
        ACC.zippay.bindAddressTrim();
        ACC.zippay.bindBillingAddressListeners();
    },

    bindBillingAddressListeners() {
        const billingAddressAllInputFields = ACC.zippay.billingAddressRequiredInputFields.concat(
            '#zipPayBillingAddressSection #address\\.townCity',
            '#zipPayBillingAddressSection #address\\.postcode',
            '#zipPayBillingAddressSection #address\\.firstName',
            '#zipPayBillingAddressSection #address\\.surname',
            '#zipPayBillingAddressSection #address\\.line1',
        );
        billingAddressAllInputFields.forEach((selector) => {
            if ($(selector).length) {
                $(selector).on('input', () => {
                    ACC.buttonhelper.disable('.zippay-checkout-form #zipPayCheckout');
                });
                $(selector).focusout(() => {
                    if (ACC.zippay.shouldZipPayCheckoutButtonBeEnabled()
                        || document.querySelector('#zipPayBillingAddressSection #address\\.postcode')) {
                        ACC.buttonhelper.enable('.zippay-checkout-form #zipPayCheckout');
                    }
                });
            }
        });
    },

    isValueTrue(selector) {
        return !!$(selector).val();
    },

    termsAndConditionsChecked() {
        if (ACC.checkoutPreValid) {
            const alert = $('.zippay-checkout-container .help-block');
            const formGroup = $('.zippay-checkout-container .form-group');
            if (ACC.checkoutPreValid.state.zipPayTerms === true) {
                alert.addClass('hidden');
                formGroup.removeClass('has-error');
                return true;
                // eslint-disable-next-line no-else-return
            } else {
                alert.removeClass('hidden');
                formGroup.addClass('has-error');
                return false;
            }
        }

        return true;
    },

    shouldZipPayCheckoutButtonBeEnabled() {
        return ACC.checkoutPreValid.state.zipPayTerms && ACC.zippay.billingInfoFieldsSetCheck();
    },

    billingAddressRequiredSelectFieldsConcat() {
        if ($('#zippay\\.address\\.region').length !== 0) {
            return ACC.zippay.billingAddressRequiredSelectFields.concat('#zippay\\.address\\.region');
        }
        return ACC.zippay.billingAddressRequiredSelectFields;
    },

    billingInfoFieldsSetCheck() {
        const baseFields = ACC.zippay.resolveBillingAddressRequiredInputFields($('#zippay\\.address\\.country').val());
        return ACC.zippay.billingAddressRequiredSelectFieldsConcat().concat(baseFields).every(ACC.zippay.isValueTrue);
    },

    decorateWithBillingAddressRequiredFieldsCheck(func) {
        function areAllRequiredFieldsSet() {
            return ACC.zippay.billingInfoFieldsSetCheck();
        }

        return function () {
            if (areAllRequiredFieldsSet()) {
                func();
            }
        };
    },

    decorateWithBillingAddressChangesCheck(func) {
        let previousBillingAddressForm = null;

        function isBillingAddressChanged(billingAddressForm) {
            if (previousBillingAddressForm === null) {
                return true;
            }

            return JSON.stringify(previousBillingAddressForm) !== JSON.stringify(billingAddressForm);
        }

        return function () {
            const billingAddressForm = ACC.zippay.collectBillingAddressForm();
            if (isBillingAddressChanged(billingAddressForm)) {
                previousBillingAddressForm = billingAddressForm;
                func();
            }
        };
    },

    collectBillingAddressForm() {
        const form = {};
        const component = $('#zipPayCheckoutPostForm');
        ACC.zippay.performTrimBeforeSubmit(component);
        form.billTo_country = component.find($('#zippay\\.address\\.country option:selected')).val();
        form.billTo_firstName = component.find($('#zippay\\.address\\.firstName')).val();
        form.billTo_lastName = component.find($('#zippay\\.address\\.surname')).val();
        form.billTo_street1 = component.find($('#zippay\\.address\\.line1')).val();
        form.billTo_street2 = component.find($('#zippay\\.address\\.line2')).val();
        form.billTo_city = component.find($('#zippay\\.address\\.townCity')).val();
        form.billTo_postalCode = component.find($('#zippay\\.address\\.postcode')).val();
        form.billTo_titleCode = component.find($('#zippay\\.address\\.title option:selected')).val();
        form.billTo_phoneNumber = ACC.phonemasking.collectPhoneNumber(component.find($('#zippay\\.address\\.phone')));
        form.billTo_state = component.find($('#zippay\\.address\\.region option:selected')).val();
        form.billTo_email = component.find($('#zippay\\.address\\.email')).val();
        form.useDeliveryAddress = component.find('#zippayUseDeliveryAddress').prop('checked');
        form.paymentType = 'ZIPPAY';
        return form;
    },

    updateBillingAddress(onDoneFunction, onSuccessFunction, reloadOnError) {
        if (ACC.config.billingAddressUrl === null || ACC.config.billingAddressUrl === '') {
            return;
        }

        const form = ACC.zippay.collectBillingAddressForm();
        const isImportedAddress = form.billTo_firstName === ''
            || form.billTo_lastName === ''
            || form.billTo_street1 === '';
        const isExistingAddress = ACC.zippay.isSavedAddress() || ACC.zippay.useDeliveryAddress();
        if (isExistingAddress && isImportedAddress) {
            ACC.zippay.showErrorMessage('checkout.multi.billing.address.invalid.message');
            ACC.buttonhelper.enable('.zippay-checkout-form #zipPayCheckout');
            return;
        }

        if (!isExistingAddress) {
            sessionStorage.setItem('newAddressUsed', true);
        }

        $.ajax({
            type: 'POST',
            url: ACC.config.encodedContextPath + ACC.config.billingAddressUrl,
            data: form,
            dataType: 'html',
            async: true,
            success: onSuccessFunction,
            error(xhr, status, error) {
                console.error(error.message);
                if (reloadOnError) {
                    window.location.reload();
                }
            },
        }).done(onDoneFunction);
    },

    scrollToFirstInvalidField() {
        const errorElements = $('.has-error');
        const errorVisible = $('.has-error').is(':visible');
        if (errorElements.length && errorVisible) {
            $('html, body').animate({
                scrollTop: $(errorElements[0]).offset().top,
            }, 500);
        }
    },

    checkAddressErrors(...elements) {
        return elements.some((element) => Boolean(element));
    },

    attachHandlerForZipCheckoutBtn() {
        const checkoutWithZipPay = function () {
            if (ACC.zippay.termsAndConditionsChecked()) {
                ACC.buttonhelper.disable('.zippay-checkout-form #zipPayCheckout');
                const postalCodeError = document
                    .querySelector('#zipPayBillingAddressFormContainer .help-block #billTo_postalCode\\.errors');
                const firstNameError = document
                    .querySelector('#zipPayBillingAddressFormContainer .help-block #billTo_firstName\\.errors');
                if (!ACC.zippay.checkAddressErrors(postalCodeError, firstNameError)) {
                    Zip.Checkout.init({
                        checkoutUri: `${ACC.config.encodedContextPath}/checkout/payment/zippay/checkouts`,
                        redirectUri: `${ACC.config.encodedContextPath}/checkout/payment/zippay/charges`,
                        onComplete(args) {
                            console.log('zip:completed', args);
                            if (args.state === 'cancelled') {
                                ACC.checkoutPreValid.addZipPayInputTermsHandler();
                                ACC.checkoutPreValid.toggleZipPayCheckoutBtn();
                                return;
                            }
                            const checkout = args.checkoutId ? `&checkoutId=${args.checkoutId}` : '';
                            const redirectUrl = `${ACC.config.encodedContextPath}/checkout/payment/zippay/charges`;
                            window.location.href = `${redirectUrl}?result=${args.state}${checkout}`;
                        },
                        onError(args) {
                            const response = JSON.parse(args.detail.response);
                            const globalMessageRequest = {
                                globalMessages: [{
                                    messageHolder: 'accErrorMsgs',
                                    messages: [response.errorMessage],
                                }],
                            };
                            ACC.globalmessages.createGlobalMessage(globalMessageRequest);
                        },
                    });
                }
            }
        };

        const submitZipPayFormWithSearchedBillingAddress = function () {
            const addressTypeInput = $('input[type=radio][name=addressGroupType]');
            const checkedButton = addressTypeInput.filter(':checked').val();
            const form = checkedButton === 'oneAddress'
                ? $('#selectAddressForm :input') : $('#searchAddressForm :input');
            // eslint-disable-next-line no-use-before-define
            if (isAddressSearchFieldInvalid()) {
                ACC.buttonhelper.enable('.zippay-checkout-form #zipPayCheckout');
                return;
            }

            $.ajax({
                type: 'POST',
                url: `${ACC.config.encodedContextPath}/checkout/multi/summary/selectBillingAddress`,
                data: $(form).serialize(),
                success(data) {
                    $('#zipPayBillingAddressFormContainer').html(data);
                },
                // eslint-disable-next-line no-unused-vars
                error(xhr, status, error) {
                    console.log(xhr.responseText);
                },
            }).done(() => {
                $('#zipPayUseDeliveryAddress').prop('checked', false);
                // eslint-disable-next-line no-use-before-define
                ACC.zippay.updateBillingAddress(checkoutWithZipPay, updateBillingAddressForm, true);
            });
        };

        const updateBillingAddressForm = function (data) {
            $('#zipPayBillingAddressFormContainer').html(data);
            ACC.phonemasking.bind($('#zippay\\.address\\.phone'));
            ACC.zippay.bindBillingAddressListeners();
        };

        const isAddressSearchFieldInvalid = function () {
            if (ACC.zippay.isSavedAddress() && $("#searchAddressForm input[name='addressId']").val() === '') {
                $('.address-search').addClass('has-error');
                $('.address-search .help-block').removeClass('hidden');
                ACC.buttonhelper.enable('.zippay-checkout-form #zipPayCheckout');
                return true;
            }
            return false;
        };

        // eslint-disable-next-line no-unused-vars
        const getBillToFieldValue = function (fieldName) {
            return $(`#zipPayBillingAddressSection input[name=billTo_${fieldName}]`).val();
        };

        // eslint-disable-next-line no-unused-vars
        const getBillToSelectValue = function (fieldName) {
            // eslint-disable-next-line max-len
            return $(`#zipPayBillingAddressSection select[name=billTo_${fieldName}]:not(:disabled) option:selected`).val();
        };

        const placeOrderWithZipPay = function () {
            if (!ACC.zippay.useDeliveryAddress() && ACC.zippay.isSavedAddress()) {
                ACC.buttonhelper.disable('.zippay-checkout-form #zipPayCheckout');
                submitZipPayFormWithSearchedBillingAddress();
            } else {
                ACC.buttonhelper.disable('.zippay-checkout-form #zipPayCheckout');
                ACC.zippay.updateBillingAddress(checkoutWithZipPay, updateBillingAddressForm, true);
            }
        };

        $('.cs_btn-zippay-checkout, .btn-zippay-checkout').on('click', (e) => {
            e.preventDefault();
            ACC.zippay.handleOrderPlaceAdditionalData();
            placeOrderWithZipPay();
        });
    },

    handleOrderPlaceAdditionalData() {
        // Nothing here, can be overrided to add custom logic
    },

    isSavedAddress() {
        const addressTypeInput = $('input[type=radio][name=addressGroupType]');
        const checkedButton = addressTypeInput.filter(':checked').val();
        return checkedButton === 'oneAddress' || checkedButton === 'searchAddress';
    },

    performTrimBeforeSubmit(component) {
        // eslint-disable-next-line no-unused-vars
        const fields = component.find($('input[id^="zippay\\.address"]')).each(function () {
            this.value = this.value.trim();
        });
        return true;
    },

    attachBillingAddressHandlers() {
        $('#zipPayBillingAddressFormContainer').on('click', '.closeAccAlert', ACC.global.closeAccAlert);
    },

    attachBillingAddressForm() {
        $('#zipPayBillingAddressFormContainer').on('change', '#zipPayBillingCountrySelector :input', function () {
            const countrySelection = $(this).val();
            // eslint-disable-next-line no-unused-vars
            const options = {
                countryIsoCode: countrySelection,
                useDeliveryAddress: false,
            };
            $('#zipPayBillingCountrySelector .form-group').removeClass('has-error');
            $('#zipPayBillingCountrySelector .form-group .help-block').hide();
        });
    },

    billingAddressSelection() {
        let billingAddressFormDataResponse;
        $('.js-zippay-select-billing-address').on('click', function (e) {
            e.preventDefault();
            $.ajax({
                type: 'POST',
                url: `${ACC.config.encodedContextPath}/checkout/multi/summary/selectBillingAddress`,
                data: $(this).closest('#zipPayBillingAddressBookForm').serialize(),
                async: false,
                success(data) {
                    billingAddressFormDataResponse = data;
                },
                // eslint-disable-next-line no-unused-vars
                error(xhr, status, error) {
                    console.log(xhr.responseText);
                },
            }).done(() => {
                $('#zipPayBillingAddressFormContainer').html(billingAddressFormDataResponse);
                ACC.zippay.bindBillingAddressListeners();
                ACC.phonemasking
                    .bind($('#zippay\\.address\\.phone'), $('#zipPayUseBillingAddressData').data('countryisocode'));
                $(ACC.zippay.billingAddressRequiredInputFields[0]).trigger('focusout');
                $('#zipPayUseDeliveryAddress').prop('checked', false);
                $.colorbox.close();
            });
        });
    },

    bindAddressTrim() {
        const component = $('#addressForm');
        if (!component.length) return;
        component.submit(() => {
            ACC.zippay.performTrimBeforeSubmit(component);
        });
    },

    useDeliveryAddress() {
        return $('#zipPayUseDeliveryAddress').prop('checked');
    },

    sendOrderPlaceAdditionalData() {
        const isConsentCheckboxChecked = $('#zipPayCheckoutForm').find('#consentCheckBox').is(':checked');
        const io_bb_value = $('#io_blackbox').length ? $('#io_blackbox').val() : '';
        $.ajax({
            type: 'POST',
            url: `${ACC.config.encodedContextPath}/checkout/sendOrderPlaceAdditionalData`,
            data: {
                consent: isConsentCheckboxChecked,
                deviceId: io_bb_value,
            },
            cache: false,
        });
    },
};
