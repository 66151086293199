ACC.addToCartPdpLearner = {

    _autoload: [
        'bindToAddToCartForm',
    ],

    bindToAddToCartForm() {
        $(document).ready(() => {
            $('.js-enable-btn').removeClass('button--disabled');
            const addToCartForm = $('.add-to-cart-learner-pdp');
            addToCartForm.ajaxForm({
                success: ACC.addToCartPdpLearner.updateCartOnSuccess,
            });
        });
    },

    updateCartOnSuccess(cartResult, statusText, xhr, formElement) {
        ACC.addToCartPdpLearner.updateCartStatus(cartResult, formElement);
        ACC.addToCartPdpLearner.displayAddToCartPopup(cartResult, formElement);
    },

    updateCartStatus(cartResult) {
        ACC.minicart.updateMiniCartDisplay(cartResult.cartData.totalCartItemsCount);
    },

    displayAddToCartPopup(cartResult) {
        $modal.open(null, {
            html: cartResult.addToCartLearnerLayer,
        });
    },
};
