ACC.pickupinstore = {

    _autoload: [
        'bindClickPickupInStoreButton',
        'bindPickupButton',
        'bindPickupClose',
        'bindPickupInStoreSearch',
    ],

    storeId: '',

    unbindPickupPaginationResults() {
        $(document).off('click', '#colorbox .js-pickup-store-pager-prev');
        $(document).off('click', '#colorbox .js-pickup-store-pager-next');
    },

    bindPickupPaginationResults() {
        const listHeight = $('#colorbox .js-pickup-store-list').height();
        const $listitems = $('#colorbox .js-pickup-store-list > li');
        const listItemHeight = $listitems.height();
        const displayCount = 5;
        const totalCount = $listitems.length;
        let curPos = 0;
        // eslint-disable-next-line no-unused-vars
        const pageEndPos = (((totalCount / displayCount) - 1) * (displayCount * listItemHeight)) * -1;

        $('#colorbox .js-pickup-store-pager-item-all').html(totalCount);

        $('#colorbox .store-navigation-pager').show();

        // eslint-disable-next-line no-use-before-define
        checkPosition();

        $(document).on('click', '#colorbox .js-pickup-store-pager-prev', (e) => {
            e.preventDefault();
            $listitems.css('transform', `translateY(${curPos + listHeight}px)`);
            curPos += listHeight;
            // eslint-disable-next-line no-use-before-define
            checkPosition('prev');
        });

        $(document).on('click', '#colorbox .js-pickup-store-pager-next', (e) => {
            e.preventDefault();
            $listitems.css('transform', `translateY(${curPos - listHeight}px)`);
            curPos -= listHeight;
            // eslint-disable-next-line no-use-before-define
            checkPosition('next');
        });

        function checkPosition() {
            const curPage = Math.ceil((curPos / (displayCount * listItemHeight)) * -1) + 1;
            $('#colorbox .js-pickup-store-pager-item-from').html(curPage * displayCount - 4);

            const tocount = (curPage * displayCount > totalCount) ? totalCount : curPage * displayCount;

            if (curPage * displayCount - 4 === 1) {
                $('#colorbox .js-pickup-store-pager-prev').hide();
            } else {
                $('#colorbox .js-pickup-store-pager-prev').show();
            }

            if (curPage * displayCount >= totalCount) {
                $('#colorbox .js-pickup-store-pager-next').hide();
            } else {
                $('#colorbox .js-pickup-store-pager-next').show();
            }

            $('#colorbox .js-pickup-store-pager-item-to').html(tocount);
        }
    },

    bindPickupInStoreQuantity() {
        $('.pdpPickupQtyPlus').click((e) => {
            e.preventDefault();

            const inputQty = $('.js-add-pickup-cart #pdpPickupAddtoCartInput');
            const currentVal = parseInt(inputQty.val(), 10);
            const maxVal = inputQty.data('max');

            // eslint-disable-next-line no-restricted-globals
            if (!isNaN(currentVal) && currentVal < maxVal) {
                inputQty.val(currentVal + 1);
                inputQty.change();
            }
        });

        $('.pdpPickupQtyMinus').click((e) => {
            e.preventDefault();
            const inputQty = $('.js-add-pickup-cart #pdpPickupAddtoCartInput');
            const currentVal = parseInt(inputQty.val(), 10);
            const minVal = inputQty.data('min');

            // eslint-disable-next-line no-restricted-globals
            if (!isNaN(currentVal) && currentVal > minVal) {
                inputQty.val(currentVal - 1);
                inputQty.change();
            }
        });

        $('body').on('keyup', '.js-add-pickup-cart #pdpPickupAddtoCartInput', function (event) {
            const input = $(event.target);
            input.val(this.value.match(/[0-9]*/));
        });
    },

    bindPickupInStoreSearch() {
        $(document).on('click', '#pickupstore_location_search_button', function () {
            // eslint-disable-next-line max-len
            ACC.pickupinstore.locationSearchSubmit($('#locationForSearch').val(), $('#atCartPage').val(), $('#entryNumber').val(), $(this).parents('form').attr('action'));
            return false;
        });

        // eslint-disable-next-line consistent-return
        $(document).on('keypress', '#locationForSearch', function (e) {
            if (e.keyCode === 13) {
                e.preventDefault();
                // eslint-disable-next-line max-len
                ACC.pickupinstore.locationSearchSubmit($('#locationForSearch').val(), $('#atCartPage').val(), $('input.entryNumber').val(), $(this).parents('form').attr('action'));
                return false;
            }
        });
    },

    bindPickupHereInStoreButtonClick() {
        $(document).on('click', '.pickup_add_to_bag_instore_button', function () {
            $(this).prev('.hiddenPickupQty').val($('#pickupQty').val());
        });

        $(document).on('click', '.pickup_here_instore_button', function () {
            $(this).prev('.hiddenPickupQty').val($('#pickupQty').val());
            ACC.colorbox.close();
        });
    },

    locationSearchSubmit(location, cartPage, entryNumber, productCode, latitude, longitude) {
        // eslint-disable-next-line max-len
        $('#colorbox .js-add-to-cart-for-pickup-popup, #colorbox .js-qty-selector-minus, #colorbox .js-qty-selector-input, #colorbox .js-qty-selector-plus').attr('disabled', 'disabled');

        $.ajax({
            url: productCode,
            data: {
                locationQuery: location, cartPage, entryNumber, latitude, longitude,
            },
            type: 'post',
            success(response) {
                ACC.pickupinstore.refreshPickupInStoreColumn(response);
            },
        });
    },

    createListItemHtml(data, id) {
        let item = '';
        item += '<li class="pickup-store-list-entry">';
        // eslint-disable-next-line max-len
        item += `<input type="radio" name="storeNamePost" value="${data.displayName}" id="pickup-entry-${id}" class="js-pickup-store-input" data-id="${id}">`;
        item += `<label for="pickup-entry-${id}" class="js-select-store-label">`;
        item += '<span class="pickup-store-info">';
        item += `<span class="pickup-store-list-entry-name">${data.displayName}</span>`;
        item += `<span class="pickup-store-list-entry-address">${data.line1} ${data.line2}</span>`;
        item += `<span class="pickup-store-list-entry-city">${data.town}</span>`;
        item += '</span>';
        item += '<span class="store-availability">';
        item += `<span class="available">${data.formattedDistance}<br>${data.stockPickup}</span>`;
        item += '</span>';
        item += '</label>';
        item += '</li>';

        return item;
    },

    refreshPickupInStoreColumn(data) {
        data = $.parseJSON(data);
        let listitems = '';

        $('#colorbox .js-pickup-component').data('data', data);

        for (let i = 0; i < data.data.length; i += 1) {
            listitems += ACC.pickupinstore.createListItemHtml(data.data[i], i);
        }

        $('#colorbox .js-pickup-store-list').html(listitems);
        ACC.pickupinstore.unbindPickupPaginationResults();
        ACC.pickupinstore.bindPickupPaginationResults();

        // select the first store
        const firstInput = $('#colorbox .js-pickup-store-input')[0];
        $(firstInput).click();

        // eslint-disable-next-line max-len
        $('#colorbox .js-add-to-cart-for-pickup-popup, #colorbox .js-qty-selector-minus, #colorbox .js-qty-selector-input, #colorbox .js-qty-selector-plus').removeAttr('disabled');
    },

    bindClickPickupInStoreButton() {
        $(document).on('click', '.js-pickup-in-store-button', function (e) {
            e.preventDefault();
            const ele = $(this);
            const productId = `pickupModal_${$(this).attr('id')}`;
            let cartItemProductPostfix = '';
            let productIdNUM = $(this).attr('id');
            productIdNUM = productIdNUM.split('_');
            // eslint-disable-next-line prefer-destructuring
            productIdNUM = productIdNUM[1];

            if (productId !== null) {
                // eslint-disable-next-line no-unused-vars
                cartItemProductPostfix = `_${productId}`;
            }

            const boxContent = $('#popup_store_pickup_form > #pickupModal').clone();
            const titleHeader = $('#pickupTitle > .pickup-header').html();

            ACC.colorbox.open(titleHeader, {
                html: boxContent,
                width: '960px',
                onComplete() {
                    // eslint-disable-next-line max-len
                    $('#colorbox .js-add-to-cart-for-pickup-popup, #colorbox .js-qty-selector-minus, #colorbox .js-qty-selector-input, #colorbox .js-qty-selector-plus').attr('disabled', 'disabled');

                    boxContent.show();
                    ACC.pickupinstore.pickupStorePager();
                    // eslint-disable-next-line no-unused-vars
                    const tabs = $('#colorbox .js-pickup-tabs').accessibleTabs({
                        tabhead: '.tabhead',
                        tabbody: '.tabbody',
                        fx: 'show',
                        fxspeed: 0,
                        currentClass: 'active',
                        autoAnchor: true,
                        cssClassAvailable: true,
                    });

                    $('#colorbox #pickupModal *').each(function () {
                        if ($(this).attr('data-id') !== undefined) {
                            $(this).attr('id', $(this).attr('data-id'));
                            $(this).removeAttr('data-id');
                        }
                    });

                    $('#colorbox input#locationForSearch').focus();

                    // set a unique id
                    $('#colorbox #pickupModal').attr('id', productId);

                    // insert the product image
                    $(`#colorbox #${productId} .thumb`).html(ele.data('img'));

                    // insert the product cart details
                    $(`#colorbox #${productId} .js-pickup-product-price`).html(ele.data('productcart'));

                    const variants = ele.data('productcartVariants');
                    let variantsOut = '';

                    $.each(variants, (key, value) => {
                        variantsOut += `<span>${value}</span>`;
                    });

                    $(`#colorbox #${productId} .js-pickup-product-variants`).html(variantsOut);

                    // insert the product name
                    $(`#colorbox  #${productId} .js-pickup-product-info`).text(ele.data('productname'));

                    // insert the form action
                    $(`#colorbox #${productId} form.searchPOSForm`).attr('action', ele.data('actionurl'));

                    // set a unique id for the form
                    // eslint-disable-next-line max-len
                    $(`#colorbox #${productId} form.searchPOSForm`).attr('id', `pickup_in_store_search_form_product_${productIdNUM}`);

                    // set the quantity, if the quantity is undefined set the quantity
                    // to the data-value defined in the jsp
                    // eslint-disable-next-line max-len
                    $(`#colorbox #${productId} #pdpPickupAddtoCartInput`).attr('value', ($('#pdpPickupAddtoCartInput').val() !== undefined ? $('#pdpPickupAddtoCartInput').val() : ele.data('value')));
                    // set the entry Number
                    $(`#colorbox #${productId} input#entryNumber`).attr('value', ele.data('entrynumber'));
                    // set the cartPage bolean
                    $(`#colorbox #${productId} input#atCartPage`).attr('value', ele.data('cartpage'));

                    if (navigator.geolocation) {
                        navigator.geolocation.getCurrentPosition(
                            (position) => {
                                // eslint-disable-next-line max-len
                                ACC.pickupinstore.locationSearchSubmit('', $('#atCartPage').val(), ele.data('entrynumber'), ele.data('actionurl'), position.coords.latitude, position.coords.longitude);
                            },
                            (error) => {
                                // eslint-disable-next-line max-len
                                console.log(`An error occurred... The error code and message are: ${error.code}/${error.message}`);
                            },
                        );
                    }

                    ACC.product.bindToAddToCartStorePickUpForm();
                },

            });
        });
    },

    pickupStorePager() {
        $(document).on('change', '#colorbox .js-pickup-store-input', function (e) {
            e.preventDefault();

            $('#colorbox .js-pickup-tabs li.first a').click();

            let storeData = $('#colorbox .js-pickup-component').data('data');
            storeData = storeData.data;

            const storeId = $(this).data('id');

            const $ele = $('#colorbox .display-details');

            $.each(storeData[storeId], (key, value) => {
                if (key === 'url') {
                    if (value !== '') {
                        $ele.find('.js-store-image').html(`<img src="${value}" alt="" />`);
                    } else {
                        $ele.find('.js-store-image').html('');
                    }
                } else if (key === 'productcode') {
                    $ele.find('.js-store-productcode').val(value);
                } else if (key === 'openings') {
                    if (value !== '') {
                        const $oele = $ele.find(`.js-store-${key}`);
                        let openings = '';
                        $.each(value, (key2, value2) => {
                            openings += `<dt>${key2}</dt>`;
                            openings += `<dd>${value2}</dd>`;
                        });

                        $oele.html(openings);
                    } else {
                        $ele.find(`.js-store-${key}`).html('');
                    }
                    // eslint-disable-next-line no-empty
                } else if (key === 'specialOpenings') {} else if (value !== '') {
                    $ele.find(`.js-store-${key}`).html(value);
                } else {
                    $ele.find(`.js-store-${key}`).html('');
                }
            });

            $(document).one('click', '#colorbox .js-pickup-map-tab', () => {
                ACC.pickupinstore.storeId = storeData[storeId];
                ACC.global.addGoogleMapsApi('ACC.pickupinstore.drawMap');
            });

            const entry = $('#colorbox .pickup-store-list-entry input:checked');

            $('#add_to_cart_storepickup_form .js-store-id').attr('id', entry.attr('id'));
            $('#add_to_cart_storepickup_form .js-store-id').attr('name', entry.attr('name'));
            $('#add_to_cart_storepickup_form .js-store-id').val(entry.val());

            if (storeData[storeId].stockLevel > 0 || storeData[storeId].stockLevel === '') {
                const input = $('#add_to_cart_storepickup_form .js-qty-selector-input');
                input.data('max', storeData[storeId].stockLevel);
                ACC.productDetail.checkQtySelector(input, 'reset');
                $('#add_to_cart_storepickup_form').show();
            } else {
                $('#add_to_cart_storepickup_form').hide();
            }
        });

        $(document).on('click', '.js-select-store-label', () => {
            $('#colorbox .js-pickup-component').addClass('show-store');
            $('#colorbox #cboxTitle .headline-inner').addClass('hidden-xs hidden-sm');
            $('#colorbox #cboxTitle .back-to-storelist').removeClass('hidden-xs hidden-sm');
        });

        $(document).on('click', '.js-back-to-storelist', () => {
            $('#colorbox .js-pickup-component').removeClass('show-store');
            $('#colorbox #cboxTitle .headline-inner').removeClass('hidden-xs hidden-sm');
            $('#colorbox #cboxTitle .back-to-storelist').addClass('hidden-xs hidden-sm');
        });
    },

    bindPickupButton() {
        $(document).on('click', '.js-pickup-button', function (e) {
            e.preventDefault();
            $e = $(this).parent().nextAll('.js-inline-layer');
            $e.addClass('open');

            // $e.height($e.height())
            const h = $e.height();
            $e.removeClass('open');

            $e.animate({
                height: h,
            });
        });
    },

    bindPickupClose() {
        $(document).on('click', '.js-close-inline-layer', function (e) {
            e.preventDefault();
            $e = $(this).parents('.js-inline-layer');

            $e.animate({
                height: 0,
            });
        });
    },

    checkIfPointOfServiceIsEmpty(cartEntryDeliveryModeForm) {
        return (!cartEntryDeliveryModeForm.find('.pointOfServiceName').text().trim().length);
    },

    validatePickupinStoreCartEntires() {
        let validationErrors = false;
        $('form.cartEntryShippingModeForm').each(function () {
            const formid = `#${$(this).attr('id')}`;
            // eslint-disable-next-line max-len
            if ($(`${formid} input[value=pickUp][checked]`).length && ACC.pickupinstore.checkIfPointOfServiceIsEmpty($(this))) {
                $(this).addClass('shipError');
                validationErrors = true;
            }
        });

        if (validationErrors) {
            $('div#noStoreSelected').show().focus();
            $(window).scrollTop(0);
        }
        return validationErrors;
    },

    drawMap() {
        storeInformation = ACC.pickupinstore.storeId;

        if ($('#colorbox .js-map-canvas').length > 0) {
            $('#colorbox .js-map-canvas').attr('id', 'pickup-map');
            // eslint-disable-next-line no-undef
            const centerPoint = new google.maps.LatLng(storeInformation.storeLatitude, storeInformation.storeLongitude);

            const mapOptions = {
                zoom: 13,
                zoomControl: true,
                panControl: true,
                streetViewControl: false,
                // eslint-disable-next-line no-undef
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                center: centerPoint,
            };

            // eslint-disable-next-line no-undef
            const map = new google.maps.Map(document.getElementById('pickup-map'), mapOptions);

            // eslint-disable-next-line no-undef
            const marker = new google.maps.Marker({
                // eslint-disable-next-line no-undef
                position: new google.maps.LatLng(storeInformation.storeLatitude, storeInformation.storeLongitude),
                map,
                title: storeInformation.name,
                icon: 'https://maps.google.com/mapfiles/markerA.png',
            });
            // eslint-disable-next-line no-undef
            const infowindow = new google.maps.InfoWindow({
                content: storeInformation.name,
                disableAutoPan: true,
            });
            // eslint-disable-next-line no-undef
            google.maps.event.addListener(marker, 'click', () => {
                infowindow.open(map, marker);
            });
        }
    },

};
