// eslint-disable-next-line no-use-before-define
const ACC = ACC || {}; // make sure ACC is available

if ($('#quickOrder').length > 0) {
    ACC.quickorder = {
        _autoload: [
            'bindClearQuickOrderRow',
            'bindAddSkuInputRow',
            'bindResetFormBtn',
            'bindAddToCartClick',
            'bindExpressCheckoutClick',
        ],

        $quickOrderContainer: $('.js-quick-order-container'),
        $quickOrderMinRows: Number($('.js-quick-order-container').data('quickOrderMinRows')),
        $quickOrderMaxRows: Number($('.js-quick-order-container').data('quickOrderMaxRows')),
        $productExistsInFormMsg: $('.js-quick-order-container').data('productExistsInFormMsg'),
        $quickOrderLeavePageMsg: $('#quickOrder').data('gridConfirmMessage'),
        $hiddenSkuInput: 'input.js-hidden-sku-field',
        $addToCartBtn: $('#js-add-to-cart-quick-order-btn-top, #js-add-to-cart-quick-order-btn-bottom'),
        $resetFormBtn: $('#js-reset-quick-order-form-btn-top, #js-reset-quick-order-form-btn-bottom'),
        // eslint-disable-next-line max-len
        $expressCheckoutBtn: $('#js-express-checkout-quick-order-button-top, #js-express-checkout-quick-order-button-bottom'),
        $productInfoContainer: '.js-product-info',
        $productPriceValueContainer: '.js-product-price-value',
        $skuInputField: '.js-sku-input-field',
        $qtyInputField: '.js-quick-order-qty',
        $qtyInputFieldInlineErrorMessage: '#qtyInputFieldInlineErrorMessage',
        $jsLiContainer: 'li.js-li-container',
        $removeQuickOrderRowBtn: '.js-remove-quick-order-row',
        $skuValidationContainer: '.js-sku-validation-container',
        $qtyValidationContainer: '.js-qty-validation-container',
        $productItemTotal: '.js-quick-order-item-total>span.qoTotalPricePlaceholder',
        $classHasError: 'has-error',

        bindResetFormBtn() {
            ACC.quickorder.$resetFormBtn.on('click', ACC.quickorder.clearForm);
        },

        bindAddToCartClick() {
            ACC.quickorder.$addToCartBtn.on('click', ACC.quickorder.addToCart);
        },

        bindExpressCheckoutClick() {
            ACC.quickorder.$expressCheckoutBtn.on('click', ACC.quickorder.initiateExpressCheckout);
        },

        bindAddSkuInputRow() {
            // eslint-disable-next-line max-len
            $(ACC.quickorder.$skuInputField).on('focusin', ACC.quickorder.addInputRow).on('focusout keydown', ACC.quickorder.handleFocusOutOnSkuInput);
            $(ACC.quickorder.$jsLiContainer).on('click', () => {
                ACC.quickorder.handleFocusOutOnSkuInput();
            });
        },

        bindClearQuickOrderRow() {
            $(ACC.quickorder.$removeQuickOrderRowBtn).on('mousedown', ACC.quickorder.clearQuickOrderRow);
        },

        addToCart() {
            $.ajax({
                url: ACC.quickorder.$quickOrderContainer.data('quickOrderAddToCartUrl'),
                type: 'POST',
                dataType: 'json',
                contentType: 'application/json',
                data: ACC.quickorder.getJSONDataForAddToCart(),
                async: false,
                success(response) {
                    ACC.quickorder.handleAddToCartSuccess(response);
                },
                error(jqXHR, textStatus, errorThrown) {
                    // log the error to the console
                    // eslint-disable-next-line no-console
                    console.log(`The following error occurred: ${textStatus}`, errorThrown);
                },
            });
        },

        handleAddToCartSuccess(response) {
            if ($(response.quickOrderErrorData).length > 0) {
                ACC.quickorder.disableBeforeUnloadEvent();
                ACC.quickorder.displayGeneralBannerErrorMessage();
            }
            const lookup = {};
            response.quickOrderErrorData.forEach((el) => {
                lookup[el.sku] = el.errorMsg;
            });

            $(ACC.quickorder.$qtyInputField).each(function () {
                const parentLi = ACC.quickorder.getCurrentParentLi(this);
                const sku = ACC.quickorder.findElement(parentLi, ACC.quickorder.$skuInputField).val();
                const errorMsg = lookup[sku];

                if (errorMsg) {
                    ACC.quickorder.findElement(parentLi, ACC.quickorder.$skuValidationContainer).text(errorMsg);
                } else {
                    ACC.quickorder.clearAllQuickOrderRows();
                }
            });

            ACC.quickorder.handleBeforeUnloadEvent();
            $(document).trigger('analytics_updateQuickOrder', response);

            ACC.minicart.updateMiniCartDisplay(response.cartData.totalCartItemsCount);
            $modal.open(null, {
                html: response.addToCartLayer,
                title: $('#js-add-to-cart-quick-order-btn-top').text().trim(),
                className: 'add_to_cart',
            });
        },

        displayGeneralBannerErrorMessage() {
            ACC.globalmessages.showErrorMessage('quick.order.general.error.message');
        },

        getJSONDataForAddToCart() {
            const skusAsJSON = [];
            $(ACC.quickorder.$qtyInputField).each(function () {
                const qty = Number($(this).val());
                if (qty > 0) {
                    // eslint-disable-next-line max-len
                    const sku = jQuery.trim(ACC.quickorder.findElementInCurrentParentLi(this, ACC.quickorder.$skuInputField).val());
                    skusAsJSON.push({ product: { code: sku }, quantity: qty });
                }
            });
            return JSON.stringify({ cartEntries: skusAsJSON });
        },

        // eslint-disable-next-line consistent-return
        handleFocusOutOnSkuInput(event) {
            if (typeof event === 'undefined') {
                return false;
            }
            // eslint-disable-next-line no-nested-ternary
            const key = event.charCode ? event.charCode : event.keyCode ? event.keyCode : 0;
            if (key === 13) {
                $(event.target).focusout();
            }
            if (event.type === 'focusout') {
                ACC.quickorder.handleGetProduct(event);
                ACC.quickorder.handleBeforeUnloadEvent();
            }
        },

        handleFocusOutOnQtyInput(event) {
            // eslint-disable-next-line no-nested-ternary
            const key = event.charCode ? event.charCode : event.keyCode ? event.keyCode : 0;
            if (key === 13) {
                event.preventDefault();
                const parentLi = ACC.quickorder.getCurrentParentLi(event.target);
                parentLi.next().find(ACC.quickorder.$skuInputField).focus();
                $(event.target).focusout();
            }
            if (event.type === 'focusout') {
                ACC.quickorder.validateAndUpdateItemTotal(event);
                ACC.quickorder.enableDisableAddToCartBtn();
                ACC.quickorder.enableDisableProductQuantityOnPurchaseInfoBtn(event);
                ACC.quickorder.checkLimitSalesValidations(event);
            }
        },

        checkLimitSalesValidations(event) {
            const qtyInput = ACC.quickorder.findElementInCurrentParentLi(event.target, ACC.quickorder.$qtyInputField);
            const currentQty = $.trim(qtyInput.val());
            const minOrderQty = qtyInput.data('min-order-quantity');
            const maxOrderQty = qtyInput.data('max-order-quantity');
            const minimumLimitSaleDefined = minOrderQty !== undefined && minOrderQty != null && minOrderQty !== '';
            const maximumLimitSaleDefined = maxOrderQty !== undefined && maxOrderQty != null && maxOrderQty !== '';
            let errorMessage = null;

            if (minimumLimitSaleDefined && currentQty < minOrderQty) {
                errorMessage = qtyInput.data('min-limit-sale-error-message');
            }
            if (maximumLimitSaleDefined && currentQty > maxOrderQty) {
                maxOrderQtyExceeded = true;
                errorMessage = qtyInput.data('max-limit-sale-error-message');
            }

            ACC.quickorder.clearLimitSalesErrors(event);

            if (errorMessage) {
                // eslint-disable-next-line max-len
                $(qtyInput).after(`<span id=${ACC.quickorder.$qtyInputFieldInlineErrorMessage.replace('#', '')}>${errorMessage}</span>`);
                $(ACC.quickorder.$qtyInputFieldInlineErrorMessage).addClass(ACC.quickorder.$classHasError);
                qtyInput.addClass(ACC.quickorder.$classHasError);
                $('.item__alerts').addClass(ACC.quickorder.$classHasError);
            }
        },

        clearLimitSalesErrors(event) {
            const qtyInput = ACC.quickorder.findElementInCurrentParentLi(event.target, ACC.quickorder.$qtyInputField);
            qtyInput.removeClass(ACC.quickorder.$classHasError);
            $(ACC.quickorder.$qtyInputFieldInlineErrorMessage).remove();
            $('.item__alerts').removeClass(ACC.quickorder.$classHasError);
        },

        clearForm() {
            window.location.reload();
            $('.orderSectionHeader')[0].scrollIntoView();
        },

        validateAndUpdateItemTotal(event) {
            const parentLi = ACC.quickorder.getCurrentParentLi(event.target);
            let qtyValue = jQuery.trim(ACC.productorderform.filterSkuEntry($(event.target).val()));
            // eslint-disable-next-line max-len
            const productCode = jQuery.trim(ACC.quickorder.findElementInCurrentParentLi(parentLi, ACC.quickorder.$skuInputField).val());

            // eslint-disable-next-line no-restricted-globals
            if (isNaN(qtyValue) || qtyValue === '') {
                qtyValue = 0;
                $(event.target).removeClass(ACC.quickorder.$classHasError);
                ACC.quickorder.findElement(parentLi, ACC.quickorder.$qtyValidationContainer).text('');
                $(event.target).val(0);
            } else {
                qtyValue = Number(qtyValue);
                $(event.target).val(qtyValue);
                // eslint-disable-next-line max-len
                let maxQty = jQuery.trim(ACC.quickorder.findElement(parentLi, ACC.quickorder.$qtyInputField).data('maxProductQty'));
                if (maxQty === '') {
                    maxQty = null;
                }
                // eslint-disable-next-line max-len
                const stockLevelStatus = jQuery.trim(ACC.quickorder.findElement(parentLi, ACC.quickorder.$qtyInputField).data('stockLevelStatus'));
                // eslint-disable-next-line max-len
                maxQty = ($.isEmptyObject(maxQty) && stockLevelStatus === 'inStock') ? 'FORCE_IN_STOCK' : Number(maxQty);
                // eslint-disable-next-line no-restricted-globals
                if (!isNaN(maxQty) && qtyValue > maxQty) {
                    $(event.target).addClass(ACC.quickorder.$classHasError);
                    qtyValue = maxQty;
                    $(event.target).val(maxQty);
                } else {
                    $(event.target).removeClass(ACC.quickorder.$classHasError);
                }
            }

            if (qtyValue > 0) {
                ACC.quickorder.renderPrice(parentLi, productCode, qtyValue);
            } else {
                ACC.quickorder.findElement(parentLi, ACC.quickorder.$productItemTotal).text('');
            }
        },

        renderPrice(element, productCode, quantity) {
            $.ajax({
                type: 'GET',
                contentType: 'application/json',
                url: `${ACC.config.encodedContextPath}/quickOrder/updated-price`,
                data: {
                    code: productCode,
                    quantity,
                },
                error() {
                    // eslint-disable-next-line max-len,no-undef
                    const itemPrice = parseFloat(ACC.quickorder.findElement(parentLi, '.js-product-price').data('productPrice'));
                    ACC.quickorder.findElement(element, ACC.quickorder.$productItemTotal)
                        // eslint-disable-next-line no-undef
                        .html(ACC.productorderform.formatTotalsCurrency(itemPrice * qtyValue));
                },
                success(response) {
                    // eslint-disable-next-line max-len
                    let formattedPrice;
                    if (response.value != null) {
                        formattedPrice = Number(response.value.toFixed(2)) * quantity;
                    }
                    const totalPrice = ACC.productorderform.formatTotalsCurrency(formattedPrice);
                    const milionsNumberString = 11;
                    let refactoredPrice = totalPrice;
                    if (totalPrice.length > milionsNumberString) {
                        refactoredPrice = ACC.quickorder.refactorPriceForDisplay(totalPrice);
                    }
                    ACC.quickorder.findElement(element, ACC.quickorder.$productItemTotal)
                        .html(refactoredPrice);
                    // eslint-disable-next-line max-len
                    ACC.quickorder.findElement(element, ACC.quickorder.$productPriceValueContainer).html(response.formattedValue);
                },
            });
        },

        refactorPriceForDisplay(priceString) {
            let reversePrice = ACC.common.reverseString(priceString);
            const periodStep = 4;
            const startIndex = 10;
            // eslint-disable-next-line space-infix-ops
            for (let i=startIndex; i < reversePrice.length; i+=periodStep) {
                if (ACC.quickorder.hasFormattedValueReachedToCurrencySymbol(reversePrice, i)) {
                    break;
                }
                // eslint-disable-next-line prefer-template
                reversePrice = reversePrice.substr(0, i) + ',' + reversePrice.substr(i);
            }
            const refactoredPrice = ACC.common.reverseString(reversePrice);
            // eslint-disable-next-line prefer-template
            return refactoredPrice;
        },

        hasFormattedValueReachedToCurrencySymbol(formattedValue, index) {
            return formattedValue.substr(0, index).indexOf(';') > -1
                    || Number.isNaN(Number(formattedValue.charAt(index)));
        },

        clearQuickOrderRow() {
            const quickOrderMinRows = ACC.quickorder.$quickOrderMinRows;
            const parentLi = ACC.quickorder.getCurrentParentLi(this);
            if ($('.js-ul-container li.js-li-container').length > quickOrderMinRows) {
                parentLi.remove();
            } else {
                ACC.quickorder.findElement(parentLi, ACC.quickorder.$productInfoContainer).remove();
                // eslint-disable-next-line max-len
                ACC.quickorder.findElement(parentLi, ACC.quickorder.$skuInputField).removeClass(ACC.quickorder.$classHasError);
                ACC.quickorder.findElement(parentLi, ACC.quickorder.$skuValidationContainer).text('');
                ACC.quickorder.findElement(parentLi, ACC.quickorder.$skuValidationContainer).addClass('isHidden');
                ACC.quickorder.findElement(parentLi, ACC.quickorder.$skuInputField).val('');
                ACC.quickorder.findElement(parentLi, ACC.quickorder.$hiddenSkuInput).val('');
            }
            ACC.quickorder.enableDisableAddToCartBtn();
            ACC.quickorder.handleBeforeUnloadEvent();
        },

        clearAllQuickOrderRows() {
            const quickOrderMinRows = ACC.quickorder.$quickOrderMinRows;
            $('.js-ul-container li.js-li-container').each(function () {
                const parentLi = ACC.quickorder.getCurrentParentLi(this);
                if (ACC.quickorder.findElement(parentLi, ACC.quickorder.$skuValidationContainer).text() === '') {
                    if ($('.js-ul-container li.js-li-container').length > quickOrderMinRows) {
                        parentLi.remove();
                    } else {
                        ACC.quickorder.findElement(parentLi, ACC.quickorder.$productInfoContainer).remove();
                        ACC.quickorder.findElement(parentLi, ACC.quickorder.$skuValidationContainer).text('');
                        ACC.quickorder.findElement(parentLi, ACC.quickorder.$skuInputField).val('');
                        ACC.quickorder.findElement(parentLi, ACC.quickorder.$hiddenSkuInput).val('');
                    }
                }
            });
            ACC.quickorder.enableDisableAddToCartBtn();
            ACC.quickorder.handleBeforeUnloadEvent();
        },

        addInputRow(event) {
            // eslint-disable-next-line max-len
            if ($('.js-quick-order-container li.js-li-container:last-child').find(ACC.quickorder.$skuInputField).is($(event.target))
                && $(ACC.quickorder.$jsLiContainer).length < ACC.quickorder.$quickOrderMaxRows) {
                const liClone = $('.js-quick-order-container li.js-li-container:first').clone();
                ACC.quickorder.findElement(liClone, ACC.quickorder.$productInfoContainer).remove();
                ACC.quickorder.findElement(liClone, ACC.quickorder.$skuValidationContainer).text('');
                ACC.quickorder.findElement(liClone, ACC.quickorder.$skuValidationContainer).addClass('isHidden');
                ACC.quickorder.findElement(liClone, ACC.quickorder.$hiddenSkuInput).val('');
                const currentSkuInputField = ACC.quickorder.findElement(liClone, ACC.quickorder.$skuInputField);
                currentSkuInputField.val('');
                // eslint-disable-next-line max-len
                currentSkuInputField.focusin(ACC.quickorder.addInputRow).focusout(ACC.quickorder.handleFocusOutOnSkuInput).keydown(ACC.quickorder.handleFocusOutOnSkuInput);
                // eslint-disable-next-line max-len
                ACC.quickorder.findElement(liClone, ACC.quickorder.$removeQuickOrderRowBtn).click(ACC.quickorder.clearQuickOrderRow);
                $('.js-ul-container').append(liClone);
            }
        },

        handleGetProduct(event) {
            const parentLi = ACC.quickorder.getCurrentParentLi(event.target);
            const productCode = $.trim(event.target.value);
            $(event.target).val(productCode);
            if (!ACC.quickorder.isCurrentSkuSameAsPrevious(parentLi, productCode)) {
                if (productCode.length > 0) {
                    ACC.quickorder.findElement(parentLi, ACC.quickorder.$productInfoContainer).remove();

                    if (ACC.quickorder.isDuplicateSku(event.target, productCode)) {
                        // eslint-disable-next-line max-len
                        ACC.quickorder.findElement(parentLi, ACC.quickorder.$skuValidationContainer).removeClass('isHidden');
                        ACC.quickorder.findElement(parentLi, ACC.quickorder.$skuValidationContainer).text('');
                        // eslint-disable-next-line max-len
                        ACC.quickorder.findElement(parentLi, ACC.quickorder.$skuValidationContainer).append(`<span class='help-block quick-order__help-block'>${ACC.quickorder.$productExistsInFormMsg}</span>`);
                    } else {
                        ACC.quickorder.getAndDisplayProductInfo(event, parentLi, productCode);
                    }
                    ACC.quickorder.findElement(parentLi, ACC.quickorder.$hiddenSkuInput).val(productCode);
                } else {
                    $(event.target).removeClass(ACC.quickorder.$classHasError);
                    ACC.quickorder.findElement(parentLi, ACC.quickorder.$skuValidationContainer).text('');
                    ACC.quickorder.findElement(parentLi, ACC.quickorder.$skuValidationContainer).addClass('isHidden');
                    ACC.quickorder.findElement(parentLi, ACC.quickorder.$productInfoContainer).remove();
                    ACC.quickorder.findElement(parentLi, ACC.quickorder.$hiddenSkuInput).val('');
                }
            }
        },

        isCurrentSkuSameAsPrevious(parentLi, productCode) {
            return ACC.quickorder.findElement(parentLi, ACC.quickorder.$hiddenSkuInput).val() === productCode;
        },

        isDuplicateSku(currentInput, productCode) {
            let exists = false;
            // eslint-disable-next-line consistent-return
            $(ACC.quickorder.$skuInputField).each(function () {
                // eslint-disable-next-line max-len
                if ($(this).val().toUpperCase() === productCode.toString().toUpperCase() && !$(this).is($(currentInput))) {
                    exists = true;
                    return false;
                }
            });
            return exists;
        },

        getAndDisplayProductInfo(event, parentLi, productCode) {
            const url = `${ACC.config.encodedContextPath}/quickOrder/productInfo?code=${productCode}`;
            $.getJSON(url, (result) => {
                if (result.errorMsg != null && result.errorMsg.length > 0) {
                    $(event.target).addClass(ACC.quickorder.$classHasError);
                    // eslint-disable-next-line max-len
                    ACC.quickorder.findElement(parentLi, ACC.quickorder.$skuValidationContainer).removeClass('isHidden');
                    ACC.quickorder.findElement(parentLi, ACC.quickorder.$skuValidationContainer).text('');
                    // eslint-disable-next-line max-len
                    ACC.quickorder.findElement(parentLi, ACC.quickorder.$skuValidationContainer).append(`<span class='help-block quick-order__help-block'>${result.errorMsg}</span>`);
                } else {
                    ACC.quickorder.findElement(parentLi, ACC.quickorder.$skuValidationContainer).addClass('isHidden');
                    $(event.target).removeClass(ACC.quickorder.$classHasError);
                    ACC.quickorder.findElement(parentLi, ACC.quickorder.$skuValidationContainer).text('');
                    // eslint-disable-next-line max-len
                    $('#quickOrderRowTemplate').tmpl(result.productData).insertAfter(ACC.quickorder.findElement(parentLi, '.js-sku-container'));
                    // eslint-disable-next-line max-len
                    $('#quickOrderRowTemplate-LS').tmpl(result.productData).insertAfter(ACC.quickorder.findElement(parentLi, '.item__list--item'));
                    const qtyInputField = ACC.quickorder.findElement(parentLi, ACC.quickorder.$qtyInputField);
                    const minOrderQty = qtyInputField.data('min-order-quantity');
                    if (minOrderQty) {
                        qtyInputField.attr({ min: minOrderQty, value: minOrderQty });
                    } else {
                        qtyInputField.attr({ min: 0, value: 1 });
                    }
                    // eslint-disable-next-line max-len
                    qtyInputField.focusout(ACC.quickorder.handleFocusOutOnQtyInput).keydown(ACC.quickorder.handleFocusOutOnQtyInput).keyup(ACC.quickorder.checkLimitSalesValidations);
                    const { callToAction } = result.productData;
                    if (callToAction) {
                        qtyInputField.val(0);
                        qtyInputField.prop('disabled', true);
                    } else {
                        qtyInputField.focus().select();
                    }
                    ACC.quickorder.enableDisableAddToCartBtn();
                }
            });
        },

        initiateExpressCheckout() {
            ACC.quickorder.$expressCheckoutBtn.attr('disabled', 'disabled');
            const isAuthGenerated = ACC.expresscheckout.isUserLoggedIn();
            if (!isAuthGenerated) {
                window.location = `${ACC.config.encodedContextPath}/login`;
            } else {
                $.ajax({
                    url: `${ACC.config.encodedContextPath}/checkout/express-checkout/buy-now`,
                    type: 'POST',
                    // eslint-disable-next-line prefer-template
                    headers: { entryPoint: 'QUICK_ORDER' },
                    dataType: 'json',
                    contentType: 'application/json',
                    data: ACC.quickorder.getJSONDataForAddToCart(),
                    async: false,
                    success(data) {
                        ACC.quickorder.handleBuyNowRedirection(data);
                    },
                    error(jqXHR, textStatus, errorThrown) {
                        ACC.expresscheckout.handleExpressCheckoutRedirectionWithHeaders(jqXHR);
                        // log the error to the console
                        // eslint-disable-next-line no-console
                        console.log(`The following error occurred: ${textStatus}`, errorThrown);
                    },
                });
            }
            ACC.quickorder.enableDisableAddToCartBtn();
        },

        handleBuyNowRedirection(response) {
            if (!response.redirectUrl.includes('buy-now')) {
                ACC.expresscheckout.handleExpressCheckoutResult(response);
            } else {
                $.ajax({
                    url: `${ACC.config.encodedContextPath}/checkout/express-checkout/buy-now/pop-up`,
                    type: 'POST',
                    // eslint-disable-next-line prefer-template
                    headers: { entryPoint: 'QUICK_ORDER' },
                    dataType: 'json',
                    contentType: 'application/json',
                    data: ACC.quickorder.getJSONDataForAddToCart(),
                    async: false,
                    success(data) {
                        ACC.expresscheckout.openMergeCartModalForExpressCheckout(data);
                    },
                    error(jqXHR, textStatus, errorThrown) {
                        ACC.expresscheckout.handleExpressCheckoutRedirectionWithHeaders(jqXHR);
                        // log the error to the console
                        // eslint-disable-next-line no-console
                        console.log(`The following error occurred: ${textStatus}`, errorThrown);
                    },
                });
            }
        },

        handleBeforeUnloadEvent() {
            if (ACC.quickorder.isAnySkuPresent()) {
                ACC.quickorder.disableBeforeUnloadEvent();
                ACC.quickorder.enableBeforeUnloadEvent();
            } else {
                ACC.quickorder.disableBeforeUnloadEvent();
            }
        },

        disableBeforeUnloadEvent() {
            $(window).off('beforeunload', ACC.quickorder.clearAllQuickOrderRows);
        },

        enableBeforeUnloadEvent() {
            $(window).on('beforeunload', ACC.quickorder.clearAllQuickOrderRows);
        },

        beforeUnloadHandler() {
            return ACC.quickorder.$quickOrderLeavePageMsg;
        },

        enableDisableAddToCartBtn() {
            const addToCartButtonEnabled = ACC.quickorder.shouldAddToCartBeEnabled();

            // if there are no items to add, disable addToCartBtn, otherwise, enable it
            if (addToCartButtonEnabled) {
                ACC.quickorder.$addToCartBtn.removeAttr('disabled');
                ACC.quickorder.$expressCheckoutBtn.removeAttr('disabled');
            } else {
                ACC.quickorder.$addToCartBtn.attr('disabled', 'disabled');
                ACC.quickorder.$expressCheckoutBtn.attr('disabled', 'disabled');
            }
        },

        enableDisableProductQuantityOnPurchaseInfoBtn(event) {
            const parentLi = ACC.quickorder.getCurrentParentLi(event.target);
            const qtyValue = jQuery.trim(ACC.productorderform.filterSkuEntry($(event.target).val()));
            // eslint-disable-next-line max-len
            const productCode = jQuery.trim(ACC.quickorder.findElement(parentLi, ACC.quickorder.$qtyInputField).attr('product-code'));

            if (qtyValue > 1) {
                $(`#${productCode}`).show();
            } else {
                $(`#${productCode}`).hide();
            }
        },

        shouldAddToCartBeEnabled() {
            let sum = 0;
            let enable = false;
            // eslint-disable-next-line consistent-return
            $(ACC.quickorder.$qtyInputField).each(function () {
                const str = this.value.trim(); // .trim() may need a shim
                if (str) { // don't send blank values to `parseInt`
                    sum += parseInt(str, 10);
                }
                if (sum >= 1) {
                    enable = true;
                    return false;
                }
            });
            return enable;
        },

        isAnySkuPresent() {
            let present = false;
            // eslint-disable-next-line consistent-return
            $(ACC.quickorder.$skuInputField).each(function () {
                const str = jQuery.trim(this.value); // .trim() may need a shim
                if (str) {
                    present = true;
                    return false;
                }
            });
            return present;
        },

        getCurrentParentLi(currentElement) {
            return $(currentElement).closest(ACC.quickorder.$jsLiContainer);
        },

        findElement(currentElement, toFind) {
            return $(currentElement).find(toFind);
        },

        findElementInCurrentParentLi(currentElement, toFind) {
            return $(currentElement).closest(ACC.quickorder.$jsLiContainer).find(toFind);
        },
    };
}
