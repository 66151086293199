ACC.digitalAccounts = {

    _autoload: [
        'initialize',
        'bindDigitalAccountFormSubmission',
        ['bindOrgSelection', $('.contextSelectorRadioBtn').length > 0],
        'bindOrgSearching',
        'bindOrgAssociation',
        'appendAddQualifiedUserSelectOption',
        'bindDisplayReturnToPreviousStepModal',
        'bindDisplayHelpPopupForInterstitialSummary',
        'digitalAccountsValidationBindingUnit',
    ],

    initialize() {
        ACC.digitalAccountsViewHelper.initializeRadios();
        if ($(`#${ACC.digitalAccounts.getQualifiedUserFormId()}`).length > 0) {
            ACC.digitalAccountsViewHelper.setPreselectedElements();
            ACC.digitalAccounts.bindQualifiedUserSelection();
            ACC.digitalAccounts.bindAddQualifiedUserEmail();
            ACC.digitalAccounts.bindOnEnterHandlers();
            ACC.digitalAccounts.bindAddQualifiedUserFormSubmission();
            ACC.digitalAccountsValidation.validateFieldInputs();
        }
    },

    digitalAccountsValidationBindingUnit() {
        // eslint-disable-next-line no-unused-vars,prefer-template
        const isExpressCheckout = $('#isExpressCheckout_Id').val();
        // eslint-disable-next-line space-before-blocks,indent,space-in-parens
        if (isExpressCheckout != null && isExpressCheckout === 'true'){
            // eslint-disable-next-line no-unused-vars,prefer-template,indent
                const slectedContext = 'context-' + $('#selectedb2bUnit_Id').val();
            // eslint-disable-next-line prefer-template,space-infix-ops,no-undef,space-infix-ops,indent
           $('#'+slectedContext+'').click();
        }
    },

    bindDigitalAccountFormSubmission() {
        const form = $(`#${ACC.digitalAccounts.getQualifiedUserFormId()}`);

        form.submit(() => {
            ACC.digitalAccountsQGlobal.populateQGlobalQualifiedUserDetails(form);
            return form.valid();
        });
    },

    bindAddQualifiedUserFormSubmission() {
        $('.addQualifiedUserFormSubmit').on('click', ACC.digitalAccounts.addQualifiedUserFormSubmitHandler);
    },

    addQualifiedUserFormSubmitHandler() {
        const form = $(`#${ACC.digitalAccounts.getQualifiedUserFormId()}`);
        // eslint-disable-next-line max-len
        const userFormGroup = $('input', "div[id$='digitalAccountsQualifiedUserAddEmailSection']").closest('.form-group');
        if (!userFormGroup.hasClass('has-error')) {
            ACC.digitalAccountsValidation.isEmailValid(form);
        }
    },

    getQualifiedUserFormId() {
        if (ACC.digitalAccountsQGlobal.isQglobalStep()) {
            return ACC.digitalAccountsQGlobal.getQglobalFormId();
        }
        if (ACC.digitalAccountsAimsWeb.isAimswebStep()) {
            return ACC.digitalAccountsAimsWeb.getAimswebFormId();
        }
        return 'qualifiedUserForm';
    },

    bindQualifiedUserSelection() {
        $("select[id$='-qualifiedUserID']").change(function () {
            const selectQualifiedUserID = $(this).attr('id');
            // eslint-disable-next-line max-len
            const selectQualifiedUserEmailSectionID = `#${selectQualifiedUserID.split('-')[0]}-digitalAccountsQualifiedUserAddEmailSection`;
            const selectQualifiedUser = $(this).val();
            if (selectQualifiedUser === '+') {
                ACC.digitalAccountsViewHelper.showElement(selectQualifiedUserEmailSectionID);
            } else {
                ACC.digitalAccountsViewHelper.hideElement(selectQualifiedUserEmailSectionID);
                $(':input', "div[id$='digitalAccountsQualifiedUserAddEmailSection']").val('');
            }
            ACC.digitalAccountsViewHelper.removeQualifiedUserErrorMessage();
        });
    },

    bindAddQualifiedUserEmail() {
        const qualifiedUserSelectOptions = $('.qualifiedUsersSelectorRadioBtn');
        qualifiedUserSelectOptions.on('change', function () {
            if ($(this).is(':checked')) {
                const selectedQualifiedUser = $(this).val();

                if (selectedQualifiedUser === '+') {
                    ACC.digitalAccountsViewHelper.moveEmailField();
                    ACC.digitalAccountsViewHelper.showElement("div[id$='digitalAccountsQualifiedUserAddEmailSection']");
                } else {
                    ACC.digitalAccountsViewHelper.hideElement("div[id$='digitalAccountsQualifiedUserAddEmailSection']");
                    $(':input', "div[id$='digitalAccountsQualifiedUserAddEmailSection']").val('');
                }

                ACC.digitalAccountsValidation.validateFieldInputs();
                $('.qualifiedUsersDiv .c-radiobtn').removeClass('selected');
                $(this).closest('.c-radiobtn').addClass('selected');

                ACC.digitalAccountsViewHelper.removeQualifiedUserErrorMessage();
            }
        });
    },

    appendAddQualifiedUserSelectOption() {
        $.each($("select[id$='-qualifiedUserID']"), (index, value) => {
            const combo = $(`#${$(value).attr('id')}`);
            combo.append($('<option/>', {
                value: '+',
                text: ACC.config.addQualifiedUserLabel,
            }));
        });
    },

    bindOnEnterHandlers() {
        ACC.digitalAccounts.onEnterHandler('#digitalAccountNumber', '#accountNumberSubmit');
        ACC.digitalAccounts.onEnterHandler('#newOrgFormID input#number', '.js-digital-accounts-associate-org');
        ACC.digitalAccounts.onEnterHandler('input#selectedQualifiedUserEmail', '.addQualifiedUserFormSubmit');
        // eslint-disable-next-line max-len
        ACC.digitalAccounts.onEnterHandler('input#existingAimswebSelectedQualifiedUserEmail', '.addQualifiedUserFormSubmit:eq(0)');
        // eslint-disable-next-line max-len
        ACC.digitalAccounts.onEnterHandler('input#newAimswebSelectedQualifiedUserEmail', '.addQualifiedUserFormSubmit:eq(1)');
        ACC.digitalAccounts.onEnterHandler('input#qglobalSelectedQualifiedUserEmail', '.addQualifiedUserFormSubmit');
    },

    //* **** Select Organization Section *****//
    bindOrgSelection() {
        $('.contextSelectorRadioBtn').on('change', function () {
            if ($('.contextSelectorRadioBtn').is(':checked')) {
                const selectedComponent = $(this);
                const selectedContext = selectedComponent.val();
                const isAccountNotListed = selectedContext === '+';

                if (isAccountNotListed) {
                    ACC.digitalAccounts.selectAccountNotListed();
                    $('.js-customer-account-step-continue').attr('disabled', 'disabled');
                    $('.js-digital-accounts-associate-org').attr('disabled', 'disabled');
                } else {
                    ACC.digitalAccountsViewHelper.hideElement('#newOrgFormID');
                    $('.js-customer-account-step-continue').removeAttr('disabled');
                }

                $('.selected').removeClass('selected');
                selectedComponent.parent().toggleClass('selected');
                $('.global-alerts').hide();
                $("[id$='.errors']").hide();
            } else {
                $('.js-customer-account-step-continue').attr('disabled', 'disabled');
            }
        });

        ACC.digitalAccounts.selectOrg();
    },

    selectOrg() {
        $('.contextSelectorRadioBtn').prop('checked', false);

        if (ACC.digitalAccounts.isReorderFlow()) {
            ACC.digitalAccounts.selectReorderOrg();
        }

        if (ACC.digitalAccounts.isNewOrg()) {
            ACC.digitalAccounts.selectNewOrg();
        }
    },

    selectNewOrg() {
        const selectedContextSelector = $(`#context-${window.orgNumber}`);
        if (!$(`#context-${window.orgNumber}`).prop('disabled')) {
            selectedContextSelector.prop('checked', true);
            ACC.buttonhelper.enable('.js-customer-account-step-continue');
        }
        ACC.digitalAccountsViewHelper.toggleSelectedOverlayForElement(selectedContextSelector);

        window.orgNumber = null;
    },

    selectExistingOrg(orgNumber) {
        if (ACC.digitalAccounts.orgOptionExists(orgNumber) && ACC.digitalAccounts.isExistingOrgEnabled(orgNumber)) {
            const existingOption = $(`#context-${orgNumber}`);
            const notListedOption = $('#contextSelector .selected > input');
            existingOption.prop('checked', true);
            ACC.digitalAccountsViewHelper.toggleSelectedOverlayForElement(existingOption);
            ACC.digitalAccountsViewHelper.toggleSelectedOverlayForElement(notListedOption);
            ACC.buttonhelper.enable('.js-customer-account-step-continue');
            return;
        }

        ACC.digitalAccountsService.getCurrentUserContexts(orgNumber, ACC.digitalAccounts.getCurrentUserContextsHandler);
    },

    isExistingOrgEnabled(orgNumber) {
        const existingOption = $(`#context-${orgNumber}`);
        const parent = existingOption.parent();
        return !parent.hasClass('disabled');
    },

    selectAccountNotListed() {
        ACC.digitalAccountsViewHelper.showElement('#newOrgFormID');
        ACC.digitalAccountsViewHelper.showElement('.js-organizationQualifiedUserStep');
        ACC.digitalAccountsViewHelper.hideElement('#qualifiedUserContextSelector');
        ACC.digitalAccountsViewHelper.hideElement('#addQGlobalAccountID');
        ACC.digitalAccountsViewHelper.hideElement('.qualifiedUsersDiv');
        ACC.digitalAccountsViewHelper.hideElement('.js-personalQualifiedUserStep');

        ACC.digitalAccountsViewHelper.resetDivInputs('#newOrgFormID');
        ACC.digitalAccountsViewHelper.resetDivInputs('#digitalAccountsFragmentStepContent');
        ACC.digitalAccountsViewHelper.resetDivInputs('#aimswebStepContent');
        $('#selectedQualifiedUser-qualifiedUserID').parent().addClass('disabled');
        $('#aimswebExisting-verificationType, #aimswebNew-verificationType').parent().addClass('disabled');
    },

    selectReorderOrg() {
        const currentUnitId = $('#defaultB2BUnitDataId').data('myUnitId');
        const currentUnitContext = `context-${currentUnitId}`;
        const currentUnitLabelSelector = `#contextSelector label[for='${currentUnitContext}']`;
        $(currentUnitLabelSelector).trigger('click');
    },

    selectOrgSuccessHandler(newOrgForm) {
        if (newOrgForm !== null) {
            ACC.digitalAccountsViewHelper.hideElement(newOrgForm);
        }
    },

    getCurrentUserContextsHandler(orgNumber, data) {
        $('#contextSelector').replaceWith(data);
        window.orgNumber = orgNumber;
        $(`#context-${orgNumber}`).prop('checked', true);
        ACC.digitalAccounts.bindOrgSelection();
        ACC.digitalAccounts.bindOrgAssociation();
        ACC.digitalAccounts.onEnterHandler('#newOrgFormID input#number', '.js-digital-accounts-associate-org');
    },

    //* **** Search Organization Section *****//
    bindOrgSearching() {
        $('#accountNumberSubmit').click(function (e) {
            e.preventDefault();
            if (ACC.validation.validateFormDiv('#searchForQglobalAccountDivID')) {
                const qglobalAccountNumber = $('#digitalAccountNumber').val();

                ACC.buttonhelper.disable(this).withSpinner();

                ACC.digitalAccountsService.findOrg(
                    qglobalAccountNumber,
                    ACC.digitalAccounts.searchOrgSuccessHandler,
                    ACC.digitalAccounts.searchOrgErrorHandler,
                );
            }
        });
    },

    searchOrgSuccessHandler(data) {
        if (data) {
            const qglobalAccountNumber = $('#digitalAccountNumber').val();
            const existingAccountSelector = `qualifiedUser-${qglobalAccountNumber}`;
            const existingAccount = $(`[id^=${existingAccountSelector}]`);
            if (existingAccount.length > 0) {
                if (!existingAccount.prop('disabled')) {
                    existingAccount.prop('checked', true).change();
                    ACC.digitalAccountsViewHelper.toggleSelectedOverlayForElement(existingAccount);
                }
                ACC.buttonhelper.enable('#accountNumberSubmit');
                $('#digitalAccountNumber').val('');
                return;
            }
            // eslint-disable-next-line max-len
            ACC.digitalAccounts.checkOrgAlreadyLinkedToCurrentUser(data.uid, data.accountNumber, qglobalAccountNumber, data.orgAlreadyLinkedToCurrentUser);
        } else {
            ACC.digitalAccountsViewHelper.showElement('#noQglobalAccountFoundPopupPageId');
            $('#noQglobalAccountFoundPopup').click();
            ACC.buttonhelper.enable('#accountNumberSubmit');
        }
    },

    checkOrgAlreadyLinkedToCurrentUser(orgUid, orgAccountNumber, qglobalAccountNumber, orgLinked) {
        if (orgLinked) {
            ACC.digitalAccountsQGlobal.selectExistingQGlobalAccount(orgUid, orgAccountNumber, qglobalAccountNumber);
        } else {
            ACC.digitalAccountsService.checkOrgParty(
                orgAccountNumber,
                qglobalAccountNumber,
                ACC.digitalAccounts.checkOrgPartyHandler,
            );
        }
    },

    searchOrgErrorHandler(data) {
        if (data.status === 404) {
            ACC.digitalAccountsViewHelper.showElement('#noQglobalAccountFoundPopupPageId');
            $('#noQglobalAccountFoundPopup').click();
        }

        ACC.buttonhelper.enable('#accountNumberSubmit');
    },

    checkOrgPartyHandler(modalContent) {
        if (modalContent) {
            const titleDiv = 'qglobalAccountCheckPopupTitle';
            const title = $(modalContent).filter((index, value) => value.id === titleDiv);

            $modal.open(
                null,
                {
                    title: title.html(),
                    html: modalContent,
                    escKey: false,
                    overlayClose: false,
                    closeButton: true,
                    trapFocus: true,
                },
            );
        }

        ACC.buttonhelper.enable('#accountNumberSubmit');
    },

    //* **** Associate with Organization Section *****//
    bindOrgAssociation() {
        const organizationAssociationButton = $('.js-digital-accounts-associate-org');
        organizationAssociationButton.click((e) => {
            e.preventDefault();
            const orgTepAccountNumber = $('#number').val();

            if ($('.js-digital-accounts-associate-org').closest('form').valid()) {
                organizationAssociationButton.attr('disabled', 'disabled');
                organizationAssociationButton.attr('aria-disabled', 'true').addClass('button-disabled');
                organizationAssociationButton.addClass('processing');
                ACC.digitalAccountsService.validateOrg(
                    orgTepAccountNumber,
                    ACC.digitalAccounts.validateOrgSuccessHandler,
                    ACC.digitalAccounts.associateWithOrg,
                );
            }
        });
    },

    validateOrgSuccessHandler(data) {
        const organizationAssociationButton = $('.js-digital-accounts-associate-org');
        const popupTitle = organizationAssociationButton.attr('data-modal-title');
        const modalContainer = $(data).find('.modal-content--container');
        const survivingUnitName = modalContainer.data('surviving-unit-name');

        if (survivingUnitName) {
            $modal.open(null, { title: popupTitle, html: data });
            ACC.buttonhelper.enable(organizationAssociationButton);
        } else {
            ACC.digitalAccounts.associateWithOrg();
        }
    },

    associateWithOrg() {
        const tepNumber = $('#newOrgFormID').find('input#number').val();
        const formId = '#newOrgFormID';

        if (ACC.digitalAccounts.orgOptionExists(tepNumber)) {
            ACC.digitalAccounts.selectExistingOrg(tepNumber);
            ACC.digitalAccounts.selectOrgSuccessHandler(formId);
            return;
        }
        const orgRequestData = ACC.digitalAccounts.buildAssociateWithOrgRequestData();
        ACC.digitalAccountsService.associateWithOrg(
            orgRequestData,
            ACC.digitalAccounts.associateWithOrgSuccessHandler,
            ACC.digitalAccounts.associateWithOrgErrorHandler,
        );
    },

    buildAssociateWithOrgRequestData() {
        const newOrgForm = $('#newOrgFormID');
        const requestData = {};
        $.each(newOrgForm.find('input'), (key, value) => {
            if ($(value).attr('id')) {
                requestData[$(value).attr('id')] = $(value).val();
            }
        });

        return JSON.stringify(requestData);
    },

    associateWithOrgSuccessHandler(data) {
        if (data) {
            const formId = '#newOrgFormID';
            ACC.digitalAccounts.selectExistingOrg(data.uid);
            ACC.digitalAccounts.selectOrgSuccessHandler(formId);
        } else {
            ACC.digitalAccountsViewHelper.showElement('#associateOrgErrorPopupPageId');
        }

        ACC.buttonhelper.enable('.js-digital-accounts-associate-org');
    },

    associateWithOrgErrorHandler() {
        ACC.digitalAccountsViewHelper.showElement('#associateOrgErrorPopupPageId');
        ACC.buttonhelper.enable('.js-digital-accounts-associate-org');
    },

    sendToOrgAssociation() {
        const associateBtn = $('#associateExistingOrgButton');
        const accountNumber = associateBtn.attr('data-digital-platform-account');
        const orgUid = associateBtn.attr('data-org-uid');
        const orgAccountNumber = associateBtn.attr('data-tep-account-number');

        ACC.digitalAccountsQGlobal.selectExistingQGlobalAccount(orgUid, orgAccountNumber, accountNumber);
        $modal.close();
    },

    //* **** Utility Functions Section *****//
    hasCustomerQualifications() {
        const hasCustomerQualifications = $('.js-digital-accounts-step').data('has-customer-qualifications');
        // eslint-disable-next-line max-len
        return (typeof hasCustomerQualifications === 'boolean' && hasCustomerQualifications) || hasCustomerQualifications === 'true';
    },

    isReorderFlow() {
        return $('.js-digital-accounts-step').data('checkoutType') === 'REORDER';
    },

    isNewOrg() {
        return window.orgNumber;
    },

    isQualifiedUserSelectedWithRadio() {
        // eslint-disable-next-line max-len
        return $('.qualifiedUsersSelectorRadioBtn:checked').length !== 0 && $('.qualifiedUsersSelectorRadioBtn:checked').val() !== '+';
    },

    // eslint-disable-next-line consistent-return
    getSelectedContextValue() {
        const component = $('.contextSelectorRadioBtn:checked');
        if (component.length) {
            return component.val();
        }
    },

    getSelectedContextPartyType() {
        const component = $('.contextSelectorRadioBtn:checked');
        if (component.length > 0) {
            return component.data('partytype-value');
        }
        return $('.js-digital-accounts-step').data('customerContext');
    },

    orgOptionExists(orgNumber) {
        const existingOption = $(`#context-${orgNumber}`);
        return existingOption.length > 0;
    },

    onEnterHandler(input, triggerElement) {
        // eslint-disable-next-line no-unused-expressions
        $(input).keyup(function (event) {
            if (event.keyCode === 13 && $(this).valid()) {
                $(triggerElement).trigger('click');
            }
        });

        $(input).keydown(() => {
            ACC.digitalAccountsViewHelper.hideElement('#associateOrgErrorPopupPageId');
        });
    },

    getQualifiedUsersSelectElementId() {
        if (ACC.digitalAccountsQGlobal.isQglobalStep()) {
            return 'qglobalSelectedQualifiedUser-qualifiedUserID';
        }
        if (ACC.digitalAccountsAimsWeb.isAimswebStep() && !ACC.digitalAccountsQGlobal.hasQGlobalProducts()) {
            if (ACC.digitalAccountsAimsWeb.isAimswebNewFormUsed()) {
                return 'newAimswebSelectedQualifiedUser-qualifiedUserID';
            }
            return 'existingAimswebSelectedQualifiedUser-qualifiedUserID';
        }
        return 'selectedQualifiedUser-qualifiedUserID';
    },

    isQualifiedUsersSelectElementUsed() {
        return $(`#${ACC.digitalAccounts.getQualifiedUsersSelectElementId()}`).length > 0;
    },

    bindDisplayReturnToPreviousStepModal() {
        $('.js-interstitial-step-edit-button').click(function (event) {
            event.preventDefault();
            const previousStepUrl = $(this).data('step-url');
            $.ajax({
                url: `${ACC.config.encodedContextPath}/digital-accounts/returnToPreviousStep`,
                success(data) {
                    ACC.digitalAccounts.displayReturnToPreviousStepModal(data);
                    ACC.digitalAccounts.bindReturnToEditButton(previousStepUrl);
                    ACC.digitalAccounts.bindCloseReturnToPreviousStepModal();
                },
                error(xhr, status, error) {
                    console.error(error.message);
                    window.location.reload();
                },
            });
        });
    },

    bindReturnToEditButton(previousStepUrl) {
        $('.js-return-to-edit-button').click((e) => {
            e.preventDefault();
            window.location = `${ACC.config.encodedContextPath}${previousStepUrl}`;
        });
    },

    bindCloseReturnToPreviousStepModal() {
        $('#colorbox').on('click', '.js-return-to-previous-close-button', $modal.close);
    },

    displayReturnToPreviousStepModal(data) {
        $modal.open(null, {
            html: data.returnToPreviousStepModalContent,
            title: data.returnToPreviousStepModalTitle,
        });
    },

    bindDisplayHelpPopupForInterstitialSummary() {
        $('.interstitial-summary .c-info-badge').click(function (event) {
            event.preventDefault();
            const popupTitle = $(this).data('popup-title');
            const popupContentText = $(this).data('popup-content');
            const popupContentContainer = $("<div className='c-modal-content'></div>");
            popupContentContainer.append(popupContentText);

            $modal.open(null, {
                html: popupContentContainer,
                title: popupTitle,
            });
        });
    },
};
