ACC.futurelink = {
    _autoload: [
        'bindFutureStockLink',
    ],

    bindFutureStockLink() {
        $(document).on('click', '.futureStockLink', function (e) {
            e.preventDefault();
            const url = $(this).attr('href');
            const title = $(this).attr('title');

            ACC.colorbox.open(title, {
                href: url,
            });
        });
    },
};
