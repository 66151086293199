ACC.langcurrency = {

    _autoload: [
        'bindLangCurrencySelector',
    ],

    bindLangCurrencySelector() {
        $('.js-mainHeader').on('change', '#lang-selector', () => {
            $('#lang-form').submit();
        });

        $('#currency-selector').change(() => {
            $('#currency-form').submit();
        });
    },
};
