ACC.payment = {
    activateSavedPaymentButton() {
        $(document).on('click', '.js-saved-payments', (e) => {
            e.preventDefault();

            const title = $('#savedpaymentstitle').html();

            $.colorbox({
                href: '#savedpaymentsbody',
                inline: true,
                opacity: 0.7,
                title,
                close: '<span class="glyphicon glyphicon-remove"></span>',
                onComplete() {
                },
            });
        });
    },
    bindPaymentCardTypeSelect() {
        ACC.payment.filterCardInformationDisplayed();
        $('#card_cardType').change(function () {
            const cardType = $(this).val();
            if (cardType === '024') {
                $('#startDate, #issueNum').show();
            } else {
                $('#startDate, #issueNum').hide();
            }
        });
    },
    filterCardInformationDisplayed() {
        const cardType = $('#card_cardType').val();
        if (cardType === '024') {
            $('#startDate, #issueNum').show();
        } else {
            $('#startDate, #issueNum').hide();
        }
    },
};

$(document).ready(() => {
    const { activateSavedPaymentButton, bindPaymentCardTypeSelect } = ACC.payment;

    if (activateSavedPaymentButton) {
        activateSavedPaymentButton();
    }

    if (bindPaymentCardTypeSelect) {
        bindPaymentCardTypeSelect();
    }

    // [Sep 08, 2021] Removed as failing babel pipeline
    // with (ACC.payment) {
    // activateSavedPaymentButton();
    // bindPaymentCardTypeSelect();
    // }
});
