ACC.cookienotificationfocus = {

    _autoload: [
        'cookieFocus',
    ],

    cookieFocus() {
        const cookienotification = document.getElementById('js-cookie-notification');
        if (!cookienotification) return;
        // eslint-disable-next-line max-len
        const focusEl = cookienotification.querySelectorAll('a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])');
        const firstFocusableEl = focusEl[0]; // first focusable element
        const lastFocusableEl = focusEl[focusEl.length - 1]; // last focusable element
        const KEYCODE_TAB = 9;

        if (cookienotification.style.display === 'block') {
            firstFocusableEl.focus();
        }
        cookienotification.addEventListener('keydown', (e) => {
            const isTabPressed = (e.key === 'Tab' || e.keyCode === KEYCODE_TAB);

            if (!isTabPressed) {
                return;
            }

            if (e.shiftKey) /* shift + tab */ {
                if (document.activeElement === firstFocusableEl) {
                    lastFocusableEl.focus();
                    e.preventDefault();
                }
            } else /* tab */ if (document.activeElement === lastFocusableEl) {
                firstFocusableEl.focus();
                e.preventDefault();
            }
        });
    },
};
