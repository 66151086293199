ACC.consents = {

    _autoload: [
        'bindAcceptConsentsLink',
    ],

    acceptConsents(username, password, escrowTicket, rememberMe, originalReferer, successMessage, orderGUID) {
        function onSuccess() {
            if (ACC.config.isSsoEnabled === 'true') {
                ACC.login.signInWithSso(username, password, rememberMe, originalReferer, successMessage, orderGUID);
            } else if (ACC.config.forgerockSsoEnabled) {
                ACC.forgerock.login(username, password);
            } else {
                ACC.login.signInWithoutSso(username, '', rememberMe, originalReferer, successMessage, orderGUID);
            }
        }

        $.ajax({
            type: 'POST',
            url: `${ACC.config.iamBaseUrl}/login/webacceptconsent`,
            data: { escrowTicket, client_id: ACC.config.iamClientId },
            xhrFields: {
                withCredentials: true,
            },
            success: onSuccess,
            error(jqXHR) {
                let messageKey;
                if (jqXHR.status === 404) {
                    messageKey = 'account.error.account.not.found';
                } else {
                    messageKey = 'account.error.generic';
                }
                const globalMessageRequest = {
                    globalMessages: [{
                        messageHolder: 'accErrorMsgs',
                        messages: [messageKey],
                    }],
                };
                ACC.globalmessages.createGlobalMessage(globalMessageRequest);
            },
        });
    },

    acceptMarketingConsent(username, consent) {
        $.ajax({
            type: 'POST',
            url: `${ACC.config.encodedContextPath}/login/marketing`,
            data: { username, consent },
        });
    },

    bindAcceptConsentsLink() {
        $(document).on('submit', '#acceptConsentsForm', (e) => {
            e.preventDefault();

            if ($('#marketingConsent').length) {
                ACC.consents.acceptMarketingConsent($('#username').val(), $('#marketingConsent').is(':checked'));
            }

            ACC.consents.acceptConsents(
                $('#username').val(),
                $('#password').val(),
                $('#escrowTicket').val(),
                $('#rememberMe').is(':checked'),
                $('#loginReferer').val(),
                '',
                $('#orderCode').val(),
            );
        });
    },
};
