ACC.unifiedWallet = {
    _autoload: [
        ['init', document.querySelector('.walletPaymentCards') || document.querySelector('.wallet-main-block')],
    ],

    init: () => {
        ACC.unifiedWallet.getPiSessionUserId();
        ACC.unifiedWallet.bindModalDialog();
        ACC.unifiedWallet.bindDeleteHandler();
        ACC.unifiedWallet.bindEditModal();
    },

    requiredInputFields: [
        '#address\\.townCity',
        '#address\\.postcode',
        '#address\\.firstName',
        '#address\\.surname',
        '#address\\.line1',
        '#address\\.region',
        '#card_accountNumber',
        '#ExpiryMonth',
        '#ExpiryYear',
        '#card_cvNumber',
    ],

    modalType: '',

    modalTypes: {
        add: 'add',
        edit: 'edit',
    },

    spinner: {
        SHOW_SPINNER: 'spinner',
        show(element) {
            const targetElem = document.querySelector(element);
            targetElem.classList.add(ACC.unifiedWallet.spinner.SHOW_SPINNER);
        },
        hide(element) {
            const targetElem = document.querySelector(element);
            targetElem.classList.remove(ACC.unifiedWallet.spinner.SHOW_SPINNER);
        },
    },

    removePreviousMessage() {
        const globalMessageContainer = document.querySelector('.global-alerts_wrap, .global-alerts');
        if (globalMessageContainer) {
            globalMessageContainer.outerHTML = '';
        }
    },

    getPiSessionUserId() {
        if (!piSession) {
            return;
        }
        ACC.piSessionInitializer.initialize();
        ACC.piSessionInitializer.addCallback(() => {
            piSession.getToken((status, token) => {
                ACC.unifiedWallet.getSavedCardsData(piSession.userId(), token);
            });
        });
    },

    createGlobalMessageRequest: (messages = [], messageHolder = '') => ({
        globalMessages: [{
            messageHolder,
            messages,
        }],
    }),

    showEmptyPage(enabled) {
        const emptyPage = document.querySelector('.wallet-page--empty');
        const isHide = 'isHide';
        if (emptyPage) {
            if (!enabled) {
                emptyPage.classList.add(isHide);
            } else {
                emptyPage.classList.remove(isHide);
            }
        }
    },

    getSavedCardsData(userId, token) {
        if (userId) {
            const url = `${ACC.config.unifiedWalletUrl}/pmc-services/wallet/v1/users/${userId}/payments`;
            const errorPage = document.querySelector('.wallet-page--wrong');
            const checkoutPage = document.getElementById('savedPaymentCards');
            const walletPage = document.querySelector('.wallet-main-block');
            const isHide = 'isHide';
            if (checkoutPage) {
                ACC.unifiedWallet.spinner.show('.walletPaymentCards');
            }
            if (walletPage) {
                ACC.unifiedWallet.spinner.show('.wallet-main-block');
            }

            fetch(url, {
                headers: {
                    'X-Authorization': token,
                },
            })
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    if (data && data.length) {
                        ACC.unifiedWallet.lowerCasePaymentType(data);
                        ACC.unifiedWallet.showEmptyPage(false);
                    } else {
                        ACC.unifiedWallet.showEmptyPage(true);
                    }
                    ACC.unifiedWallet.appendSavedCardsData(data);
                    ACC.validation.addCvvHandler('[name="card_cvNumber"]');
                    ACC.unifiedWallet.autoSelectDefaultCard();
                    // eslint-disable-next-line no-unused-vars
                }).catch((error) => {
                    if (checkoutPage) {
                        ACC.unifiedWallet.spinner.hide('.walletPaymentCards');
                        // eslint-disable-next-line max-len
                        const globalMessageRequest = ACC.unifiedWallet.createGlobalMessageRequest(['unified.wallet.checkout.payments.error.page.title'], 'accErrorMsgs');
                        ACC.globalmessages.createGlobalMessage(globalMessageRequest);
                    }

                    if (walletPage) {
                        ACC.unifiedWallet.spinner.hide('.wallet-main-block');
                        if (errorPage) {
                            document.querySelector('.js-open-new-payment-modal').setAttribute('disabled', 'disabled');
                            errorPage.classList.remove(isHide);
                        }
                        ACC.unifiedWallet.removePreviousMessage();
                        // eslint-disable-next-line max-len
                        const globalMessageRequest = ACC.unifiedWallet.createGlobalMessageRequest(['unified.wallet.display.payment.error'], 'accErrorMsgs');
                        ACC.globalmessages.createGlobalMessage(globalMessageRequest);
                    }
                });
        }
    },

    lowerCasePaymentType: (savedCardsData) => (
        // eslint-disable-next-line array-callback-return
        savedCardsData.map((card) => {
            if (!card.paymentType) {
                return;
            }
            card.paymentTypeModified = card.paymentType.toLowerCase();
        })
    ),

    appendSavedCardsData(data) {
        const savedPaymentCards = $('#savedPaymentCards');
        const walletMainBlock = $('.wallet-main-block');
        if (savedPaymentCards.length) {
            ACC.unifiedWallet.spinner.hide('.walletPaymentCards');
            // eslint-disable-next-line max-len
            GLOBAL_PAYMENT_DATA = ACC.unifiedWalletSorting.sortPreferredPayment(data, ACC.config.unifiedWalletCheckoutSavedCardMax);
            // eslint-disable-next-line max-len
            savedPaymentCards.find($('.saved-card-error')).after($('#savedPaymentCardTemplate').tmpl(GLOBAL_PAYMENT_DATA));
            ACC.silentorderpost.bindSavedCards();
        }
        if (walletMainBlock.length) {
            ACC.unifiedWallet.spinner.hide('.wallet-main-block');
            // eslint-disable-next-line max-len
            GLOBAL_PAYMENT_DATA = ACC.unifiedWalletSorting.sortPreferredPayment(data, ACC.config.unifiedWalletSavedCardMax);
            // eslint-disable-next-line max-len
            walletMainBlock.find($('.wallet-cards__container')).html($('#savedPaymentCardTemplateWallet').tmpl(GLOBAL_PAYMENT_DATA));
        }
        if (GLOBAL_PAYMENT_DATA.length === 0) {
            ACC.unifiedWallet.showEmptyPage(true);
        }
    },

    setBillingAddressForSavedCard(cardsData, savedCardId) {
        const selectedCardData = cardsData.filter((filterCardData) => filterCardData.paymentId === savedCardId);
        const selectedCardDataBillingAddress = selectedCardData[0].billingAddress;

        // eslint-disable-next-line no-return-assign
        return ACC.unifiedWallet.billingAddress = {
            billTo_city: selectedCardDataBillingAddress.city,
            billTo_country: selectedCardDataBillingAddress.countryISO,
            billTo_firstName: selectedCardDataBillingAddress.firstName,
            billTo_lastName: selectedCardDataBillingAddress.lastName,
            billTo_phoneNumber: selectedCardDataBillingAddress.phone,
            billTo_postalCode: selectedCardDataBillingAddress.postalCode,
            billTo_state: selectedCardDataBillingAddress.stateISO,
            billTo_street1: selectedCardDataBillingAddress.streetAddress,
            billTo_street2: selectedCardDataBillingAddress.streetNumber,
        };
    },

    getPaymentCardMasked(cardsData, savedCardId) {
        const selectedCardData = cardsData.filter((filterCardData) => filterCardData.paymentId === savedCardId);
        return selectedCardData[0].paymentCardMasked;
    },

    removeTabIndex() {
        const billingAddressForm = document.querySelector('.page-wallet #billingAddressForm');
        const inputFields = billingAddressForm.querySelectorAll('input, select');
        if (inputFields.length) {
            inputFields.forEach((item) => {
                if (item.hasAttribute('tabindex')) {
                    item.removeAttribute('tabindex');
                }
            });
        }
    },

    bindModalDialog() {
        $('.js-open-new-payment-modal').on('click', (e) => {
            e.preventDefault();
            const popupAddPaymentId = '#popup_add_new_payment';
            const addPaymentBtnClass = '.js-new-payment-btn';
            // eslint-disable-next-line prefer-const
            let { modalType, modalTypes } = ACC.unifiedWallet;
            modalType = modalTypes.add;

            const submitSOPForm = () => {
                ACC.unifiedWallet.submitSOPForm(modalType);
            };

            const updateBillingAddressForm = (data) => {
                ACC.unifiedWallet.setHandlersOnPopupWindow(data, popupAddPaymentId);

                // eslint-disable-next-line no-shadow
                $(addPaymentBtnClass).on('click', (e) => {
                    e.preventDefault();

                    ACC.secureacceptance.reloadSopForm(ACC.config.unifiedWalletSopFormActionUrl, '', () => {
                        ACC.buttonhelper.disable(addPaymentBtnClass).withSpinner();
                        // calling current function in add new payment method, as all event listeners are deleted after
                        // call to backend
                        // eslint-disable-next-line max-len
                        ACC.unifiedWallet.processPayment(submitSOPForm, updateBillingAddressForm, ACC.config.addBillingAddressUrl);
                    });
                });

                $('.js-close-new-payment-modal').on('click', () => {
                    $modal.close();
                });

                // eslint-disable-next-line no-use-before-define
                changeCountryHandler();
            };

            const changeCountryHandler = () => {
                // eslint-disable-next-line no-shadow
                $('#address\\.country').on('change', (e) => {
                    $.ajax({
                        type: 'GET',
                        // eslint-disable-next-line max-len
                        url: `${ACC.config.encodedContextPath}/my-account/wallet/add/payment/modal?country=${e.target.value}`,
                        dataType: 'html',
                        sync: true,
                        success: (data) => {
                            $('#ccBillingAddressForm').html($(data).find('#ccBillingAddressForm'));
                            ACC.validation.bindFormValidation();
                            ACC.unifiedWallet.validateForm();
                            changeCountryHandler();
                        },
                        error: (xhr, status, error) => {
                            // eslint-disable-next-line no-console
                            console.error(error.message);
                        },
                    });
                });
            };

            $modal.open(null, {
                inline: true,
                href: popupAddPaymentId,
                className: 'addPaymentModal',
                // eslint-disable-next-line no-unused-vars
                onOpen(event, ui) {
                    fetch(`${ACC.config.encodedContextPath}/my-account/wallet/add/payment/modal`, {
                        dataType: 'html',
                    })
                        .then((response) => {
                            if (response.redirected === true) {
                                window.location.reload();
                            }
                            return response.text();
                        })
                        .then((data) => {
                            updateBillingAddressForm(data);

                            ACC.checkoutTooltip.setTooltip('cvv', '#addNewPaymentSOP');
                        })
                        .catch((xhr, status, error) => {
                            // eslint-disable-next-line no-console
                            console.error(error.message);
                            $modal.close();
                            // eslint-disable-next-line max-len
                            const globalMessageRequest = ACC.unifiedWallet.createGlobalMessageRequest(['unified.wallet.general.error'], 'accErrorMsgs');
                            ACC.globalmessages.createGlobalMessage(globalMessageRequest);
                        });
                },
            });
        });
    },

    autoSelectDefaultCard() {
        const defaultCard = document.querySelector('.card-info__preferred');
        if (defaultCard) {
            defaultCard.closest('.c-radiobtn').click();
        }
    },

    loadSopFormFragment(paymentId = '', onDoneFunction = () => {}) {
        $.ajax({
            type: 'POST',
            headers: { CSRFToken: ACC.config.CSRFToken },
            url: `${ACC.config.encodedContextPath}/my-account/wallet/payment/sa/sop${paymentId}`,
            success(data) {
                $('#sopDirectCyberSourceBox').html(data);
                onDoneFunction();
            },
            error(xhr, status, error) {
                // eslint-disable-next-line no-console
                console.log(error);
            },
        });
    },

    fillSopForm: () => {
        const getBillToFieldValue = (fieldName) => $(`input[name=billTo_${fieldName}]`).val();

        const getBillToSelectValue = (fieldName) => $(`select[name=billTo_${fieldName}] option:selected`).val();

        const sopForm = ACC.unifiedWallet.getSopForm();
        sopForm.find('#card_type').val(ACC.unifiedWallet.getCardType());
        sopForm.find('#card_number').val(ACC.unifiedWallet.getCardNumber());
        sopForm.find('#card_expiry_date').val(ACC.unifiedWallet.getCardExpireDate());
        sopForm.find('#card_cvn').val(ACC.unifiedWallet.getCardCvnNumber());
        sopForm.find('#bill_to_forename').val(getBillToFieldValue('firstName'));
        sopForm.find('#bill_to_surname').val(getBillToFieldValue('lastName'));
        sopForm.find('#bill_to_address_line1').val(getBillToFieldValue('street1'));
        sopForm.find('#bill_to_address_line2').val(getBillToFieldValue('street2'));
        sopForm.find('#bill_to_address_city').val(getBillToFieldValue('city'));
        sopForm.find('#bill_to_address_country').val(getBillToSelectValue('country'));
        sopForm.find('#bill_to_address_postal_code').val(getBillToFieldValue('postalCode'));
        sopForm.find('#bill_to_address_state').val(getBillToSelectValue('state'));

        const preferredPayment = $('#preferredPayment');
        if (preferredPayment.length) {
            sopForm.find('#merchant_defined_data98').val(preferredPayment.prop('checked'));
        }
        const bill_to_email = getBillToFieldValue('email');
        if (bill_to_email) {
            sopForm.find('#bill_to_email').val(bill_to_email);
        }
        if (ACC.config.isCybersourceDirectIntegrationEnabled) {
            sopForm.attr('target', '_blank');
        }
        return sopForm;
    },

    submitSOPForm(modalType, paymentID) {
        const sopForm = ACC.unifiedWallet.fillSopForm();
        sopForm.submit();
        ACC.unifiedWallet.checkCardValidation(modalType, paymentID);
    },

    setHandlersOnPopupWindow: (data, selector) => {
        $(selector).html(data);
        ACC.validation.bindFormValidation();
        ACC.checkout.bindCardTypeIdentification();
        ACC.unifiedWallet.validateForm();
    },

    encryptCardNumber: (cardNumber) => `**** **** **** ${cardNumber.substr(-4)}`,

    isEmptyFields() {
        const firstName = document.querySelector('[name="billTo_firstName"]');
        const lastName = document.querySelector('[name="billTo_lastName"]');
        const streetAddress = document.querySelector('[name="billTo_street1"]');
        const city = document.querySelector('[name="billTo_city"]');
        const state = document.querySelector('[name="billTo_state"]');
        const postalCode = document.querySelector('[name="billTo_postalCode"]');

        return !((firstName && firstName.value) || (lastName && lastName.value)
            || (streetAddress && streetAddress.value) || (city && city.value)
            || (state && state.value) || (postalCode && postalCode.value));
    },

    bindEditModal: () => {
        $('.wallet-main-block').on('click', '.wallet-card-controls__edit', (e) => {
            e.preventDefault();
            const addPaymentBtnClass = '.js-new-payment-btn';
            const paymentID = e.target.getAttribute('data-payment-id');
            const modalID = e.target.getAttribute('data-modal-content');
            const card = GLOBAL_PAYMENT_DATA.find((item) => item.paymentId === +paymentID);
            // eslint-disable-next-line prefer-const
            let { modalType, modalTypes } = ACC.unifiedWallet;
            modalType = modalTypes.edit;

            const submitSOPForm = () => {
                ACC.unifiedWallet.submitSOPForm(modalType, paymentID);
            };

            const {
                billingAddress,
                paymentCardMasked,
                paymentTypeModified,
                preferredPayment,
                validToMonth,
                validToYear,
            } = card;
            const billingAddressCountry = billingAddress.countryISO;

            const changeCountryHandlerOnEdit = () => {
                // eslint-disable-next-line no-shadow
                $('#address\\.country').on('change', (e) => {
                    $.ajax({
                        type: 'GET',
                        // eslint-disable-next-line max-len
                        url: `${ACC.config.encodedContextPath}/my-account/wallet/update/payment/modal?country=${e.target.value}`,
                        dataType: 'html',
                        sync: true,
                        success: (data) => {
                            $('#ccBillingAddressForm').html($(data).find('#ccBillingAddressForm'));
                            ACC.validation.bindFormValidation();
                            ACC.unifiedWallet.validateForm();
                            changeCountryHandlerOnEdit();
                            // eslint-disable-next-line no-use-before-define
                            billingAddressCountryCheck();
                        },
                        error: (xhr, status, error) => {
                            // eslint-disable-next-line no-console
                            console.error(error.message);
                            $modal.close();
                            // eslint-disable-next-line max-len
                            const globalMessageRequest = ACC.unifiedWallet.createGlobalMessageRequest(['unified.wallet.general.error'], 'accErrorMsgs');
                            ACC.globalmessages.createGlobalMessage(globalMessageRequest);
                        },
                    });
                });
            };

            const billingAddressCountryCheck = () => {
                const billingAddressCountrySelected = $('select[name=billTo_country] option:selected').val();
                if (billingAddressCountry === billingAddressCountrySelected) {
                    // eslint-disable-next-line no-use-before-define
                    fillValues();
                }
            };

            const updateBillingAddressForm = (data) => {
                ACC.unifiedWallet.setHandlersOnPopupWindow(data, modalID);

                // eslint-disable-next-line no-shadow
                $(addPaymentBtnClass).on('click', (e) => {
                    e.preventDefault();

                    const sopUrl = ACC.config.unifiedWalletSopFormActionUrl;
                    ACC.secureacceptance.reloadSopForm(sopUrl, { paymentId: paymentID }, () => {
                        ACC.buttonhelper.disable(addPaymentBtnClass).withSpinner();
                        // calling current function in add new payment method, as all event listeners are deleted after
                        // call to backend
                        // eslint-disable-next-line max-len
                        ACC.unifiedWallet.processPayment(submitSOPForm, updateBillingAddressForm, ACC.config.updateBillingAddressUrl);
                    });
                });
                changeCountryHandlerOnEdit();
            };

            const fillBillingAddressCountry = () => {
                $('select[name=billTo_country] option:selected').val(billingAddressCountry).change();
            };

            const fillValues = () => {
                const encryptedCardNumber = ACC.unifiedWallet.encryptCardNumber(paymentCardMasked);

                if (preferredPayment) {
                    $('#preferredPayment').prop('checked', true);
                }

                $('.card-logo').addClass('isDisabled');
                $(`.card-logo[data-type='${paymentTypeModified}']`).removeClass('isDisabled');
                $('input[name=billTo_firstName]').val(billingAddress.firstName);
                $('input[name=billTo_lastName]').val(billingAddress.lastName);
                $('input[name=billTo_street1]').val(billingAddress.streetAddress);
                $('input[name=billTo_street2]').val(billingAddress.streetNumber);
                $('input[name=billTo_city]').val(billingAddress.city);
                $('input[name=billTo_postalCode]').val(billingAddress.postalCode);
                $('select[name=billTo_state] option').removeAttr('selected');
                if (billingAddress.stateISO !== null) {
                    $('select[name=billTo_state]').val(billingAddress.stateISO.substr(-2)).change();
                }
                // eslint-disable-next-line max-len
                $('#card_accountNumber').attr('placeholder', encryptedCardNumber).css('background-color', '#F5F5F5').focus(function () {
                    $(this).attr('placeholder', '').css('background-color', '#FFFFFF');
                });
                $('#ExpiryMonth').val(Number(validToMonth));
                $('#ExpiryYear').val(validToYear);
            };

            $modal.open(null, {
                inline: true,
                href: modalID,
                className: 'editPaymentModal',
                onOpen: () => {
                    fetch(`${ACC.config.encodedContextPath}/my-account/wallet/update/payment/modal`, {
                        dataType: 'html',
                    })
                        .then((response) => {
                            if (response.redirected === true) {
                                window.location.reload();
                            }
                            return response.text();
                        })
                        .then((data) => {
                            updateBillingAddressForm(data);
                            fillBillingAddressCountry();
                            ACC.checkoutTooltip.setTooltip('cvv', '#addNewPaymentSOP');
                            $('.js-close-new-payment-modal').on('click', () => {
                                $modal.close();
                            });
                            // eslint-disable-next-line max-len
                            const cardNumber = document.querySelector('[data-sitename="learneruk"] ~ #colorbox.editPaymentModal #card_accountNumber, [data-sitename="learnerca"] ~ #colorbox.editPaymentModal #card_accountNumber');
                            if (cardNumber) {
                                cardNumber.addEventListener('input', () => {
                                    document.querySelector('.card-logo-discover').classList.add('isDisabled');
                                });
                            }
                        }).catch(() => {
                            $modal.close();
                            // eslint-disable-next-line max-len
                            const globalMessageRequest = ACC.unifiedWallet.createGlobalMessageRequest(['unified.wallet.general.error'], 'accErrorMsgs');
                            ACC.globalmessages.createGlobalMessage(globalMessageRequest);
                        });
                },
            });
        });
    },

    getPiSessionToken: () => {
        let currentToken;
        piSession.getToken((status, token) => {
            currentToken = token;
        });
        return currentToken;
    },

    bindDeleteHandler: () => {
        const userID = piSession.userId();
        const deleteModal = document.querySelector('.delete-modal');

        $('.wallet-main-block').on('click', '.wallet-card-controls__delete', function (e) {
            e.preventDefault();
            const paymentID = this.getAttribute('data-payment-id');
            const card = GLOBAL_PAYMENT_DATA.filter((item) => item.paymentId === +paymentID);
            const piSessionToken = ACC.unifiedWallet.getPiSessionToken();
            deleteModal.innerHTML = '';

            $modal.open(null, {
                className: 'delete-payment-modal',
                inline: true,
                href: '#popup_delete_payment',
                onOpen() {
                    $('#popup_delete_payment').append($('#deletePaymentModal').tmpl(card));

                    const cancelDeleteButton = document.querySelector('.button-cancel-delete');
                    const deleteCardButton = document.querySelector('.button-delete-card');
                    if (cancelDeleteButton) {
                        cancelDeleteButton.addEventListener('click', () => $modal.close(this));
                    }
                    if (deleteCardButton) {
                        deleteCardButton.addEventListener('click', () => {
                            // eslint-disable-next-line max-len
                            const url = `${ACC.config.unifiedWalletUrl}/pmc-services/wallet/v1/users/${userID}/payments/${paymentID}`;
                            fetch(url, {
                                method: 'DELETE',
                                headers: {
                                    'X-Authorization': piSessionToken,
                                },
                            })
                                // eslint-disable-next-line no-unused-vars
                                .then((response) => {
                                    ACC.buttonhelper.disable(deleteCardButton).withSpinner();
                                    ACC.unifiedWallet.getSavedCardsData(userID, piSessionToken);
                                    $modal.close();
                                    ACC.unifiedWallet.removePreviousMessage();
                                    // eslint-disable-next-line max-len
                                    const globalMessageRequest = ACC.unifiedWallet.createGlobalMessageRequest(['unified.wallet.remove.payment.success'], 'accConfMsgs');
                                    ACC.globalmessages.createGlobalMessage(globalMessageRequest);
                                    // eslint-disable-next-line no-unused-vars
                                }).catch((error) => {
                                    $modal.close();
                                    ACC.unifiedWallet.removePreviousMessage();
                                    // eslint-disable-next-line max-len
                                    const globalMessageRequest = ACC.unifiedWallet.createGlobalMessageRequest(['unified.wallet.remove.payment.error'], 'accErrorMsgs');
                                    ACC.globalmessages.createGlobalMessage(globalMessageRequest);
                                });
                        });
                    }
                },
            });
            document.querySelector('.delete-modal__heading').focus();
        });
    },

    validateForm() {
        const activeBtn = '.js-new-payment-btn';

        ACC.unifiedWallet.requiredInputFields.forEach((selector) => {
            $(selector).on('focusout', () => {
                let isValid = true;
                // eslint-disable-next-line no-restricted-syntax
                for (const input of ACC.unifiedWallet.requiredInputFields) {
                    if (!isValid) {
                        return;
                    }

                    if ($(input).length && !ACC.unifiedWallet.validateInput($(input))) {
                        isValid = false;
                        ACC.buttonhelper.disable(activeBtn);
                        return;
                    }
                    isValid = true;
                    ACC.buttonhelper.enable(activeBtn);
                }
            });
        });
    },

    validateInput: ($input) => ($input.is('select') ? $input.val() : !!$input.val().length),

    checkCardValidation: (modalType, paymentID) => {
        fetch(`${ACC.config.encodedContextPath}/my-account/wallet/iscardvalidated`)
            .then((res) => res.json())
            .then((res) => {
                const activeBtn = '.js-new-payment-btn';
                if (res.typeError === 'CyberSource') {
                    // TODO Need to show inline alert
                    // eslint-disable-next-line max-len
                    const sopUrl = ACC.config.unifiedWalletSopFormActionUrl;
                    ACC.secureacceptance.reloadSopIframe(sopUrl);
                    if (ACC.config.isCybersourceDirectIntegrationEnabled) {
                        if (paymentID) {
                            ACC.unifiedWallet.loadSopFormFragment(`?paymentId=${paymentID}`);
                        } else {
                            ACC.unifiedWallet.loadSopFormFragment();
                        }
                    }
                    const paymentErrorMsg = document.getElementById('payment-error-msg');
                    if (res.addPaymentResponseMessage) {
                        paymentErrorMsg.innerHTML = res.addPaymentResponseMessage;
                        paymentErrorMsg.style.display = 'block';
                    }
                    // Need to remove spinner
                    ACC.buttonhelper.enable(activeBtn);
                    ACC.buttonhelper.disable(activeBtn);
                    return;
                }
                if (!res.isCardValidated && !res.addPaymentResponseMessage.length) {
                    // waiting response from Hybris
                    setTimeout(() => {
                        ACC.unifiedWallet.checkCardValidation(modalType);
                    }, 2000);
                    return;
                }
                if (res.isCardValidated) {
                    ACC.unifiedWallet.getSavedCardsData(piSession.userId(), ACC.unifiedWallet.getPiSessionToken());
                    $modal.close();
                    if (modalType === ACC.unifiedWallet.modalTypes.add) {
                        ACC.unifiedWallet.removePreviousMessage();
                        // eslint-disable-next-line max-len
                        const globalMessageRequest = ACC.unifiedWallet.createGlobalMessageRequest(['unified.wallet.add.payment.success'], 'accConfMsgs');
                        ACC.globalmessages.createGlobalMessage(globalMessageRequest);
                    }
                    if (modalType === ACC.unifiedWallet.modalTypes.edit) {
                        ACC.unifiedWallet.removePreviousMessage();
                        // eslint-disable-next-line max-len
                        const globalMessageRequest = ACC.unifiedWallet.createGlobalMessageRequest(['unified.wallet.update.payment.success'], 'accConfMsgs');
                        ACC.globalmessages.createGlobalMessage(globalMessageRequest);
                    }
                } else if (!res.isCardValidated && res.addPaymentResponseMessage.length) {
                    ACC.unifiedWallet.removePreviousMessage();
                    // eslint-disable-next-line max-len
                    const globalMessageRequest = ACC.unifiedWallet.createGlobalMessageRequest(['unified.wallet.update.payment.error'], 'accErrorMsgs');
                    $modal.close();
                    ACC.globalmessages.createGlobalMessage(globalMessageRequest);
                }
            });
    },

    collectUpdatePaymentAddressForm() {
        const form = {};
        const component = $('#addNewPaymentSOP');
        ACC.secureacceptance.performTrimBeforeSubmit(component);
        form.billTo_country = component.find($('#address\\.country option:selected')).val();
        form.billTo_firstName = component.find($('#address\\.firstName')).val();
        form.billTo_lastName = component.find($('#address\\.surname')).val();
        form.billTo_street1 = component.find($('#address\\.line1')).val();
        form.billTo_street2 = component.find($('#address\\.line2')).val();
        form.billTo_city = component.find($('#address\\.townCity')).val();
        form.billTo_postalCode = component.find($('#address\\.postcode')).val();
        form.billTo_titleCode = component.find($('#address\\.title option:selected')).val();
        form.billTo_state = component.find($('#address\\.region option:selected')).val();
        form.billTo_email = component.find($('#address\\.email')).val();
        form.card_accountNumber = component.find($('#card_accountNumber')).val();
        form.card_cardType = component.find($('#card_cardType')).val();
        form.card_cvNumber = component.find($('#card_cvNumber')).val();
        form.card_expirationMonth = component.find($('#ExpiryMonth')).val();
        form.card_expirationYear = component.find($('#ExpiryYear')).val();
        return form;
    },

    processPayment(onDoneFunction, onValidationFail, billingAddressValidationUrl) {
        if (billingAddressValidationUrl === null || billingAddressValidationUrl === '') {
            return;
        }
        const form = ACC.unifiedWallet.collectUpdatePaymentAddressForm();
        $.ajax({
            type: 'POST',
            url: ACC.config.encodedContextPath + billingAddressValidationUrl,
            data: form,
            dataType: 'html',
            async: true,
            success: (data) => {
                if ($(data).find('#isSuccess').val() === 'true') {
                    onDoneFunction();
                } else {
                    onValidationFail(data);
                }
            },
            error: (xhr, status, error) => {
                // eslint-disable-next-line no-console
                console.error('billingAddressUrl', error);
                $modal.close();
            },
        });
    },

    getSopForm() {
        if (ACC.config.isCybersourceDirectIntegrationEnabled) {
            return $('#sopRequestForm');
        }
        return $('#sopRequestIframe').contents().find('#sopRequestForm');
    },

    getCardType: () => $('#card_cardType option:selected').val() || $('#card_cardType').val(),

    getCardNumber: () => $('#card_accountNumber').val().replace(/\s/g, ''),

    getCardCvnNumber: () => $('#card_cvNumber').val(),

    getCardExpireDate: () => `${$('#ExpiryMonth option:selected').text()}-${$('#ExpiryYear option:selected').text()}`,

    getCardExpireYear: () => $('#ExpiryYear option:selected').val(),

    getCardExpireMonth: () => $('#ExpiryMonth option:selected').val(),
};
