ACC.digitalAccountsQGlobal = {
    _autoload: [
        'bindSelectedQGlobalAccount',
        'bindQglobalStepRefresh',
        'bindAddQGlobalAccount',
    ],

    bindSelectedQGlobalAccount() {
        const qGlobalAccountSelectorOption = $('.qGlobalAccountSelectorRadioBtn');
        qGlobalAccountSelectorOption.on('change', function () {
            if (qGlobalAccountSelectorOption.is(':checked')) {
                $('#qualifiedUserContextSelector .selected').removeClass('selected');
                const selectedComponent = $(this);
                selectedComponent.parent().toggleClass('selected');
            }
        });
    },

    hasQGlobalProducts() {
        const component = $('.contextSelectorRadioBtn:checked');
        if (component.length > 0) {
            return component.data('hasqglobalproducts');
        }
        return $('.js-digital-accounts-step').data('hasqglobalproducts');
    },

    isQGlobalNewValid() {
        const isQGlobalNewSelected = $('#qglobalNew-verificationType').is(':checked');
        const qGlobalQualifiedUserSelected = $('#qglobalSelectedQualifiedUser-qualifiedUserID option:selected');
        // eslint-disable-next-line max-len
        const isQGlobalQualifiedUserSelectedWithSelect = qGlobalQualifiedUserSelected.val() !== undefined && qGlobalQualifiedUserSelected.val() !== '' && qGlobalQualifiedUserSelected.val() !== '+';
        const isQualifiedSelectedWithRadio = ACC.digitalAccounts.isQualifiedUserSelectedWithRadio();
        const isQGlobalQualifiedUserSelected = isQGlobalQualifiedUserSelectedWithSelect || isQualifiedSelectedWithRadio;

        if (ACC.digitalAccounts.getSelectedContextPartyType() === 'ORGANIZATION') {
            return isQGlobalNewSelected && isQGlobalQualifiedUserSelected;
        }
        return isQGlobalNewSelected && ACC.digitalAccounts.hasCustomerQualifications();
    },

    isQGlobalStepValid() {
        const qGlobalAccountSelector = $('#qualifiedUserContextSelector .selected > input');
        if (!qGlobalAccountSelector.length) {
            return ACC.digitalAccountsQGlobal.isQGlobalNewValid();
        }
        if (!qGlobalAccountSelector.val()) {
            return false;
        }
        // eslint-disable-next-line max-len
        const isValidQGlobalAccountSelected = qGlobalAccountSelector.val() !== '' && qGlobalAccountSelector.val() !== '+';

        return isValidQGlobalAccountSelected || ACC.digitalAccountsQGlobal.isQGlobalNewValid();
    },

    bindAddQGlobalAccount() {
        const qGlobalAccountSelectorOption = $('.qGlobalAccountSelectorRadioBtn');
        qGlobalAccountSelectorOption.on('change', function () {
            const divId = '#addQGlobalAccountID';
            ACC.digitalAccountsViewHelper.resetDivInputs(divId);
            $('#addQGlobalAccountID .selected').removeClass('selected');

            if (qGlobalAccountSelectorOption.is(':checked')) {
                const selectedQGlobalAccount = $(this).val();
                if (selectedQGlobalAccount === '+') {
                    ACC.digitalAccountsViewHelper.showElement(divId);
                    $(divId).addClass('accountNotListedForQGlobalStep2');
                } else {
                    ACC.digitalAccountsValidation.validateFieldInputs();
                    ACC.digitalAccountsViewHelper.hideElement(divId);
                }
            }

            ACC.digitalAccountsValidation.validateFieldInputs();
        });
    },

    bindQglobalStepRefresh() {
        $('#qglobalExisting-verificationType, #qglobalNew-verificationType').click(function () {
            ACC.digitalAccountsViewHelper.showRadioChildContent($(this));
            ACC.digitalAccountsViewHelper.enableDisableRadios('#addQGlobalAccountID', false);
            ACC.digitalAccountsValidation.validateFieldInputs();
            const selectedComponent = $(this);
            $('#addQGlobalAccountID .selected').removeClass('selected');
            selectedComponent.parent().toggleClass('selected');
        });
    },

    selectExistingQGlobalAccount(organizationUid, orgAccountNumber, qglobalAccountNumber) {
        $('#updateContextForm-organizationUid').val(organizationUid);
        $('#updateContextForm-orgAccountNumber').val(orgAccountNumber);
        $('#updateContextForm-customerUid').val(qglobalAccountNumber);
        $('#updateContextForm').submit();
    },

    isQglobalStep() {
        return $('#qglobalPlatformForm').length > 0;
    },

    getQglobalFormId() {
        return 'qglobalPlatformForm';
    },

    // eslint-disable-next-line no-unused-vars
    populateQGlobalQualifiedUserDetails(form) {
        const qGlobalAccountSelectorOption = $('.qGlobalAccountSelectorRadioBtn:checked');
        if (qGlobalAccountSelectorOption.length && qGlobalAccountSelectorOption.val() !== '+') {
            $('#qu_tepAccountNumber').val(qGlobalAccountSelectorOption.data('tepaccountnumber'));
            // eslint-disable-next-line max-len
            $('#qu_digitalPlatformAccountNumber').val(qGlobalAccountSelectorOption.data('digitalplatformaccountnumber'));
            $('#qu_qualifiedUserMdmPartyNumber').val(qGlobalAccountSelectorOption.data('qualifiedusermdmpartynumber'));
            $('#qu_qualifiedUserTepPartyNumber').val(qGlobalAccountSelectorOption.data('qualifieduserteppartynumber'));
            $('#qu_qualifiedUserAccountNumber').val(qGlobalAccountSelectorOption.data('qualifieduseraccountnumber'));
            $('#qu_qualifiedUserTitle').val(qGlobalAccountSelectorOption.data('qualifiedusertitle'));
            $('#qu_qualifiedUserFirstName').val(qGlobalAccountSelectorOption.data('qualifieduserfirstname'));
            $('#qu_qualifiedUserLastName').val(qGlobalAccountSelectorOption.data('qualifieduserlastname'));
            $('#qu_qualifiedUserMiddleName').val(qGlobalAccountSelectorOption.data('qualifiedusermiddlename'));
            $('#qu_qualifiedUserEmail').val(qGlobalAccountSelectorOption.data('qualifieduseremail'));
            // eslint-disable-next-line max-len
            $('#qu_qualifiedUserExtensionNumber').val(qGlobalAccountSelectorOption.data('qualifieduserextensionnumber'));
            $('#qu_qualifiedUserJobTitleCode').val(qGlobalAccountSelectorOption.data('qualifieduserjobtitlecode'));
            // eslint-disable-next-line max-len
            $('#qu_qualifiedUserPhoneCountryCode').val(qGlobalAccountSelectorOption.data('qualifieduserphonecountrycode'));
            $('#qu_qualifiedUserPhoneNumber').val(qGlobalAccountSelectorOption.data('qualifieduserphonenumber'));
            // eslint-disable-next-line max-len
            $('#qu_qualifiedUserQualificationLevel').val(qGlobalAccountSelectorOption.data('qualifieduserqualificationlevel'));
            // eslint-disable-next-line max-len
            $('#qu_qualifiedUserSalesforceId').val(qGlobalAccountSelectorOption.data('qualifiedusersalesforceid'));
        }
    },
};
