ACC.buttonhelper = {

    DISABLED_STATE: 'disabled',
    ENABLED_STATE: 'enabled',
    SPINNER_ACTIVE_STATE: 'processing',

    disable(element, text) {
        const $element = $(element);

        $element.attr('disabled', 'disabled');
        $element.attr('aria-disabled', 'true').addClass('button-disabled');
        ACC.buttonhelper.bindMessage($element, text, ACC.buttonhelper.DISABLED_STATE);

        function withSpinner() {
            $element.addClass(ACC.buttonhelper.SPINNER_ACTIVE_STATE);
        }

        return { withSpinner };
    },

    enable(element, text) {
        const $element = $(element);

        $element.removeAttr('disabled');
        $element.attr('aria-disabled', 'false').removeClass('button-disabled');
        $element.removeClass(ACC.buttonhelper.SPINNER_ACTIVE_STATE);

        ACC.buttonhelper.bindMessage($element, text, ACC.buttonhelper.ENABLED_STATE);
    },

    ariaDisable(element, text) {
        const $element = $(element);

        $element.attr('aria-disabled', 'true').addClass('button-disabled');
        ACC.buttonhelper.bindMessage($element, text, ACC.buttonhelper.DISABLED_STATE);

        function withSpinner() {
            $element.addClass(ACC.buttonhelper.SPINNER_ACTIVE_STATE);
        }

        return { withSpinner };
    },

    bindMessage(element, text, state) {
        let message = text;

        if (text === undefined) {
            message = ACC.buttonhelper.getMessage(element, text, state);
        }

        if (message) $(element).html(message);
    },

    getMessage(element, text, state) {
        return text || ACC.buttonhelper.getLocalizedMessage(element, state);
    },

    getLocalizedMessage(element, state) {
        const message = $(element).data(`${state}-message`);
        return message || '';
    },
};
