ACC.checkoutsteps = {

    _autoload: [
        'permeateLinks',
        'bindSelectCustomerAccountButton',
        'bindSelectQualifiedUserButton',
    ],

    expressCheckoutFunnelText: 'express checkout',

    permeateLinks() {
        const step = document.querySelectorAll('.step-head');
        let number = 0;

        $(document).on('click', '.js-checkout-step', function (e) {
            e.preventDefault();
            const link = $(this).closest('a').attr('href');
            if (link !== undefined) {
                window.location = link;
            }
        });

        [].forEach.call(step, (event) => {
            number += 1;
            event.setAttribute('checkout', number);
        });

        const interstitialBackground = document.querySelector('.interstitialPage');
        if (interstitialBackground) {
            interstitialBackground.parentElement.parentElement.style.background = '#f2f2f2';
        }

        const qualifiedUserContextSelector = document.getElementById('qualifiedUserContextSelector');
        if (qualifiedUserContextSelector && qualifiedUserContextSelector.childElementCount > 0) {
            document.getElementById('addQGlobalAccountID').style.padding = '0 20px';
        }
    },

    bindSelectCustomerAccountButton() {
        $('.js-customer-account-step-continue').on('click', ACC.checkoutsteps.sendECAnalyticsDataForCustomer);
    },

    bindSelectQualifiedUserButton() {
        $('.js-qualified-user-step-continue').on('click', ACC.checkoutsteps.sendECAnalyticsDataForQualifiedUser);
    },

    sendECAnalyticsDataForCustomer() {
        if (ACC.checkoutsteps.isExpressCheckoutFlow()) {
            const dataLayer = window.dataLayer || [];
            const selectedOption = document.getElementsByClassName('c-radiobtn c-boxed selected')[0];
            const selectedOptionText = selectedOption.getElementsByClassName('c-radiobtn__check')[0].textContent;

            const analyticsBody = {
                funnel: ACC.checkoutsteps.expressCheckoutFunnelText,
                optionAccount: selectedOptionText,
                stageName: 'checkout interstitial account options',
            };
            ACC.checkoutsteps.pushECAnalyticsData(dataLayer, analyticsBody);
        }
    },

    sendECAnalyticsDataForQualifiedUser() {
        if (ACC.checkoutsteps.isExpressCheckoutFlow()) {
            const dataLayer = window.dataLayer || [];
            const analyticsBody = {
                funnel: ACC.checkoutsteps.expressCheckoutFunnelText,
                stageName: ACC.checkoutsteps.getStageNameForUserSelection(),
            };
            ACC.checkoutsteps.pushECAnalyticsData(dataLayer, analyticsBody);
        }
    },

    isExpressCheckoutFlow() {
        // eslint-disable-next-line max-len
        return ACC.config.isExpressCheckoutEnabled && window.location.href.includes(ACC.config.expressCheckoutInterstitialUri);
    },

    pushECAnalyticsData(dataLayer, analyticsBody) {
        dataLayer.push({
            event: 'processStart',
            processName: 'checkout update',
            data: analyticsBody,
        });
    },

    getStageNameForUserSelection() {
        const currentUrl = window.location.href;
        let result = '';
        if (currentUrl.includes(ACC.config.expressCheckoutQglobalUri)) {
            result = 'checkout interstitial q-global options';
        } else if (currentUrl.includes(ACC.config.expressCheckoutAimsWebUri)) {
            result = 'checkout interstitial aimsWeb options';
        } else if (currentUrl.includes(ACC.config.expressCheckoutQualifiedUserUri)) {
            result = 'checkout interstitial qualifications options';
        }
        return result;
    },
};
