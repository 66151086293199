ACC.carousel = {

    _autoload: [
        ['bindCarousel', $('.js-owl-carousel').length > 0],
        'bindJCarousel',
        ['relatedCarousel', $('.carousel--related').length > 0],
    ],

    carouselConfig: {
        default: {
            navigation: true,
            navigationText: [
                "<span class='glyphicon glyphicon-chevron-left'></span>",
                "<span class='glyphicon glyphicon-chevron-right'></span>",
            ],
            pagination: false,
            itemsCustom: [[0, 2], [640, 4], [1024, 5], [1400, 7]],
        },
        'rotating-image': {
            navigation: false,
            pagination: true,
            singleItem: true,
        },
        'lazy-reference': {
            navigation: true,
            navigationText: [
                "<span class='glyphicon glyphicon-chevron-left'></span>",
                "<span class='glyphicon glyphicon-chevron-right'></span>",
            ],
            pagination: false,
            itemsDesktop: [5000, 7],
            itemsDesktopSmall: [1200, 5],
            itemsTablet: [768, 4],
            itemsMobile: [480, 3],
            lazyLoad: true,
        },
    },

    bindCarousel() {
        $('.js-owl-carousel').each(function () {
            const $c = $(this);
            $.each(ACC.carousel.carouselConfig, (key, config) => {
                if ($c.hasClass(`js-owl-${key}`)) {
                    const $e = $(`.js-owl-${key}`);
                    $e.owlCarousel(config);
                }
            });
        });
    },

    bindJCarousel() {
        $('.modal').colorbox({
            onComplete() {
                ACC.common.refreshScreenReaderBuffer();
            },
            onClosed() {
                ACC.common.refreshScreenReaderBuffer();
            },
        });
    },

    // Related Items ( Product Page ) Carousel
    relatedCarousel() {
        const owl = $('.carousel--related .owl-carousel');
        owl.owlCarousel({
            items: 3,
            center: true,
            pagination: false,
            responsive: true,
            navigation: true,
            navigationText: [
                '<button></button>',
                '<button></button>',
            ],
            scrollPerPage: false,
            slideSpeed: 200,
            rewindSpeed: 200,
            paginationSpeed: 200,
            responsiveBaseWidth: $('.carousel--related'),
            afterInit() {
                owl.find('.owl-wrapper').each(function () {
                    const w = $(this).width() / 2;
                    $(this).width(w);
                });
            },
            afterUpdate() {
                owl.find('.owl-wrapper').each(function () {
                    const w = $(this).width() / 2;
                    $(this).width(w);
                });
            },
        });
    },
};
