ACC.preferencesSPA = {

    _autoload: [
        ['init', document.getElementById('preference-wrapper')],
        ['update', document.getElementById('preference-wrapper')],
    ],

    wrapperId: 'preference-wrapper',

    init() {
        const host = document.getElementById(ACC.preferencesSPA.wrapperId).getAttribute('data-env');
        const props = {
            host,
            onUpdate: ACC.preferencesSPA.updateHandler,
            onSuccess: ACC.preferencesSPA.debounce(ACC.preferencesSPA.successHandler),
            onCancel: ACC.preferencesSPA.cancelHandler,
            onError: ACC.preferencesSPA.debounce(ACC.preferencesSPA.errorHandler),
        };

        window.createPreferenceOptions(ACC.preferencesSPA.wrapperId, props);
        $('div.preferences').addClass('spinnerActive');
    },

    update() {
        window.addEventListener('load', () => {
            this.waitForPreferencesWrapper('.preference-wrapper', () => {
                ACC.preferencesSPA.replaceTextWithGlobalMSG();
                $('div.preferences').removeClass('spinnerActive');
            });
        });
    },

    updateHandler() {
        ACC.preferencesSPA.showPrimaryBtn(true);
    },

    successHandler() {
        ACC.preferencesSPA.showPrimaryBtn(false);

        const globalMessageRequest = {
            globalMessages: [{
                messageHolder: 'accConfMsgs',
                messages: ['text.preferences.successes'],
            }],
        };
        ACC.globalmessages.removeGlobalMessages();
        ACC.globalmessages.createGlobalMessage(globalMessageRequest);
    },

    cancelHandler() {
        window.location = document.getElementById(ACC.preferencesSPA.wrapperId).getAttribute('data-homepage');
    },

    errorHandler() {
        ACC.preferencesSPA.showPrimaryBtn(false);

        const globalMessageRequest = {
            globalMessages: [{
                messageHolder: 'accErrorMsgs',
                messages: ['text.preferences.error'],
            }],
        };
        ACC.globalmessages.removeGlobalMessages();
        ACC.globalmessages.createGlobalMessage(globalMessageRequest);
    },

    showPrimaryBtn(show) {
        const btn = document.querySelector('.button__v2__primary__bordered');
        if (show) {
            btn.classList.add('disable');
        } else {
            btn.classList.remove('disable');
        }
    },

    replaceTextWithGlobalMSG() {
        const marketingTitle = $('div.pt-1 > h2.toggle-option-title');
        const marketingDescription = $('div.pt-1 .toggle-option-description');
        const marketingText = $('div.pt-1 .toggle-option-title.col-10');
        const privacyTitle = $('h2.pb-3.toggle-option-title');
        const privacyProfilingText = $('.mb-4.toggle-option-description');
        const cancelBtn = $('.preference-footer button:first-child');
        const updateBtn = $('.preference-footer button:last-child');

        marketingTitle.text(ACC.config.upcMarketingTitle);
        marketingDescription.text(ACC.config.upcMarketingDescriptionText);
        marketingText.text(ACC.config.upcGlobalMarketingText);
        privacyTitle.text(ACC.config.upcPrivacyTitle);
        privacyProfilingText.text(ACC.config.upcPrivacyUserProfilingText);
        cancelBtn.text(ACC.config.upcCancelButton);
        updateBtn.text(ACC.config.upcUpdateButton);
    },

    waitForPreferencesWrapper(wrapper, callback) {
        if ($(wrapper).length) {
            callback();
        } else {
            setTimeout(() => {
                this.waitForPreferencesWrapper(wrapper, callback);
            }, 200);
        }
    },

    debounce(func, time = 300) {
        let timeout;
        return function () {
            const context = this;
            // eslint-disable-next-line prefer-rest-params
            const args = arguments;
            const later = function () {
                timeout = null;
                func.apply(context, args);
            };
            clearTimeout(timeout);
            timeout = setTimeout(later, time);
        };
    },
};
