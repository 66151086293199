ACC.silentorderpost = {

    _autoload: [
        'bindUseDeliveryAddress',
        'bindSubmitSilentOrderPostForm',
        'bindCreditCardAddressForm',
        ['bindSavedCards', $('.savedCardRadioBtn').length !== 0],
        'bindSavedCardAddYourCardAgain',
        'selectErrorCard',
        'bindPayByPORadioButton'],

    spinner: $(`<img src='${ACC.config.commonResourcePath}/images/spinner.gif' />`),

    selectErrorCard() {
        const selectedCardId = $('#selectedCardId').val();
        // eslint-disable-next-line no-undef
        const selectedCardIdSanitized = DOMPurify.sanitize(selectedCardId);
        if (typeof selectedCardIdSanitized === 'undefined' || selectedCardIdSanitized === '') return;
        const savedCard = $(`#savedCard-${selectedCardIdSanitized}`);
        savedCard.prop('checked', true);
        $('.selected').removeClass('selected');
        savedCard.parent().toggleClass('selected');
        if (selectedCardIdSanitized === 'newCard') {
            ACC.silentorderpost.hideSavedCardBillingAddress();
            ACC.silentorderpost.hideSavedCardCvvInput();
            ACC.silentorderpost.showPaymentForm();
            ACC.silentorderpost.bindCreditCardAddressForm();
            const useDeliveryAddress = $('#useDeliveryAddress');
            ACC.silentorderpost.toggleBillingAddressForm(useDeliveryAddress);
            ACC.silentorderpost.hidePayByPOBillingAddressSection();
        } else {
            $('#savedCardBillingAddressData').html(savedCard.data('cardbillingaddress'));
            ACC.silentorderpost.showSavedCardBillingAddress();
            ACC.silentorderpost.hidePaymentForm();
            const cardCvvId = `#cardCvv-${selectedCardIdSanitized}`;

            $(cardCvvId).css('display', 'flex');
            $(cardCvvId).show();
            $(`#removeEntry_${selectedCardIdSanitized}`).show();

            const cardCvvNumberId = `#savedCardCvvNumber-${selectedCardIdSanitized}`;
            if (!$(cardCvvNumberId).val()) {
                ACC.secureacceptance.show($(cardCvvNumberId), ACC.secureacceptance.getErrorMessage($(cardCvvNumberId)));
            }
            ACC.silentorderpost.addEventToRemoveCard(selectedCardIdSanitized);

            const options = {
                countryIsoCode: savedCard.data('countryisocode'),
                useDeliveryAddress: false,
                savedCardId: selectedCardIdSanitized,
            };
            ACC.silentorderpost.displayCreditCardAddressForm(options, ACC.silentorderpost.fillCountrySelectBox);
            ACC.silentorderpost.hidePayByPOBillingAddressSection();
        }
    },

    bindUseDeliveryAddress() {
        const component = $('#useDeliveryAddress');
        if (!component.length) return;
        component.on('click', () => {
            ACC.silentorderpost.enableSaveCardCheckBox();
            if (component.is(':checked')) {
                ACC.silentorderpost.hideBillingAddressForm();
                ACC.silentorderpost.preFillBillingForm();
            } else {
                ACC.silentorderpost.clearSearchAddressForm();
                $('#billingAddressFormContainer ul.address').remove();
                $('.address-search ul.address').remove();
                ACC.silentorderpost.showBillingAddressForm();
                ACC.silentorderpost.clearAddressForm();
                ACC.silentorderpost.enableAddressForm();
            }
        });
        ACC.silentorderpost.bindCreditCardAddressForm();
        ACC.silentorderpost.toggleBillingAddressForm(component);
    },

    bindSavedCards() {
        const savedCardButton = $('.savedCardRadioBtn');
        const payByPO = $('.payByPORadioBtn');
        const isQuoteCheckout = payByPO.data('is-quote-checkout');
        if (!savedCardButton.length) ACC.silentorderpost.showPaymentForm();
        savedCardButton.on('click', function () {
            const countryIsoCode = { countryIsoCode: $('#useDeliveryAddressData').data('countryisocode') };
            ACC.secureacceptance.updatePaymentTypeSection(countryIsoCode, 'CARD', isQuoteCheckout);
            if (savedCardButton.is(':checked')) {
                const selectedComponent = $(this);
                $('.selected').removeClass('selected');
                payByPO.removeClass('selected');
                payByPO.prop('checked', false);
                ACC.silentorderpost.hidePayByPOInputField();
                ACC.silentorderpost.hidePayByPOBillingAddressSection();
                selectedComponent.parent().toggleClass('selected');
                ACC.silentorderpost.resetDivInputs('#purchaseOrderNumber');
                ACC.silentorderpost.resetDivInputs('#newCreditCardFormId');
                if (selectedComponent.attr('id') === 'savedCard-newCard') {
                    ACC.silentorderpost.hideSavedCardBillingAddress();
                    ACC.silentorderpost.hideSavedCardCvvInput();
                    ACC.silentorderpost.hideRemoveCardButton();
                    ACC.silentorderpost.showPaymentForm();
                    const useDeliveryAddress = $('#useDeliveryAddress');
                    ACC.silentorderpost.bindCreditCardAddressForm();
                    ACC.silentorderpost.toggleBillingAddressForm(useDeliveryAddress);
                    ACC.silentorderpost.bindSelectAddNewBillingAddress();
                    const newCreditCardForm = document.querySelector('#newCreditCardFormId');
                    ACC.replaceSpecialCharacters.bindSpecialCharactersConversion(newCreditCardForm);
                } else {
                    ACC.silentorderpost.selectExistingCard(selectedComponent);
                }
            }
        });

        if ($('#savedPaymentCards>.selected').length !== 0) {
            return;
        }

        savedCardButton.prop('checked', false);

        if ($('#savedPaymentCards').children().length === 3) {
            const firstSavedCard = $(':nth-child(2)', $('#savedPaymentCards')).find(':input');
            if (typeof firstSavedCard.val() === 'undefined') return;
            const savedCardId = firstSavedCard.data('savedcardid');
            $('#savedCardBillingAddressData').html($(`#savedCard-${savedCardId}`).data('cardbillingaddress'));
            ACC.silentorderpost.hideSavedCardBillingAddress();
            ACC.silentorderpost.hidePayByPOBillingAddressSection();
            ACC.silentorderpost.hidePaymentForm();
            ACC.silentorderpost.addEventToRemoveCard(savedCardId);
            const options = {
                countryIsoCode: $(`#savedCard-${savedCardId}`).data('countryisocode'),
                useDeliveryAddress: false,
                savedCardId,
            };

            ACC.silentorderpost.displayCreditCardAddressForm(options, ACC.silentorderpost.fillCountrySelectBox);
        }
    },

    bindPayByPORadioButton() {
        const savedCardButton = $('.savedCardRadioBtn');
        const payByPO = $('.payByPORadioBtn');
        const isQuoteCheckout = payByPO.data('is-quote-checkout');
        payByPO.on('click', () => {
            const countryIsoCode = { countryIsoCode: $('#useDeliveryAddressData').data('countryisocode') };
            ACC.secureacceptance.updatePaymentTypeSection(countryIsoCode, 'ACCOUNT', isQuoteCheckout);
            $('#savedPaymentCards').find('input').hide();
            ACC.silentorderpost.hideSavedCardCvvInput();
            ACC.silentorderpost.hideRemoveCardButton();
            ACC.silentorderpost.showPayByPOInputField();
            ACC.silentorderpost.hidePaymentForm();
            ACC.silentorderpost.hideSavedCardBillingAddress();
            payByPO.parent().parent().addClass('selected');
            savedCardButton.prop('checked', false);
            savedCardButton.parent().removeClass('selected');
            ACC.silentorderpost.showPayByPOBillingAddressSection();
            ACC.silentorderpost.resetDivInputs('#savedPaymentCards');
            ACC.silentorderpost.resetDivInputs('#newCreditCardFormId');
        });
    },

    selectExistingCard(selectedComponent) {
        $('#savedCardBillingAddressData').html(selectedComponent.data('cardbillingaddress'));
        ACC.silentorderpost.showSavedCardBillingAddress();
        ACC.silentorderpost.hideSavedCardCvvInput();
        ACC.silentorderpost.hideRemoveCardButton();
        const savedCardId = selectedComponent.data('savedcardid');
        $(`#cardCvv-${savedCardId}`).css('display', 'flex');
        $('#savedPaymentCards').find('input').show();
        $(`#removeEntry_${savedCardId}`).show();
        ACC.silentorderpost.addEventToRemoveCard(savedCardId);
        ACC.silentorderpost.hidePaymentForm();
        ACC.silentorderpost.resetDivInputs('#purchaseOrderNumber');
        ACC.silentorderpost.resetDivInputs('#newCreditCardFormId');
        ACC.silentorderpost.updateBillingAddressFromSavedCard(savedCardId);
        ACC.silentorderpost.recalculateCartUsingSavedCard(savedCardId);
    },

    recalculateCartUsingSavedCard(savedCardId) {
        $.ajax({
            // eslint-disable-next-line max-len
            url: `${ACC.config.encodedContextPath}/checkout/multi/summary/recalculateCartUsingSavedCard?savedCardId=${savedCardId}`,
            dataType: 'text',
            success(data) {
                if (data === 'true') {
                    ACC.secureacceptance.reloadOrderTotals();
                }
            },
            error(xhr, status, error) {
                console.error(error.message);
                window.location.reload();
            },
        });
    },

    updateBillingAddressFromSavedCard(savedCardId) {
        // eslint-disable-next-line max-len
        const form = ACC.config.isUnifiedWalletEnabled ? ACC.unifiedWallet.setBillingAddressForSavedCard(GLOBAL_PAYMENT_DATA, savedCardId) : ACC.secureacceptance.collectSavedCardAddress();
        $.ajax({
            type: 'POST',
            url: `${ACC.config.encodedContextPath}/checkout/multi/validate/updateBillingAddressForSavedCard`,
            data: form,
            dataType: 'html',
            async: true,
            error(xhr, status, error) {
                console.error(error.message);
                window.location.reload();
            },
        });
    },

    bindSavedCardAddYourCardAgain() {
        $('.savedCardAddYourCardAgain').on('click', (e) => {
            e.preventDefault();
            $('.savedCardRadioBtn').prop('checked', false);
            $('#savedCard-newCard').prop('checked', true);
            $('.selected').removeClass('selected');
            $('#savedCard-newCard').parent().toggleClass('selected');
            ACC.silentorderpost.hideSavedCardBillingAddress();
            ACC.silentorderpost.hideSavedCardCvvInput();
            ACC.silentorderpost.hideRemoveCardButton();
            ACC.silentorderpost.showPaymentForm();
            const useDeliveryAddress = $('#useDeliveryAddress');
            ACC.silentorderpost.bindCreditCardAddressForm();
            ACC.silentorderpost.toggleBillingAddressForm(useDeliveryAddress);
        });
    },

    addEventToRemoveCard(savedCardId) {
        const component = $(`#removeCardButton_${savedCardId}`);
        if (!component.length) return;
        component.on('click', (e) => {
            e.preventDefault();

            // Ajax call to remove the card
            $.ajax({
                type: 'POST',
                url: `${ACC.config.encodedContextPath}/checkout/multi/summary/${savedCardId}/removeCard`,
                async: false,
                error() {
                    const messageKey = 'checkout.multi.summary.card.remove.error';
                    const globalMessageRequest = {
                        globalMessages: [{
                            messageHolder: 'accErrorMsgs',
                            messages: [messageKey],
                        }],
                    };
                    ACC.globalmessages.createGlobalMessage(globalMessageRequest);
                },
                success() {
                    // used window replace in order not to allow the user to go back
                    window.location.replace(`${ACC.config.encodedContextPath}/checkout/multi/summary/enter`);
                },
                timeout: 3000, // sets request timeout to 3 seconds
            });
        });
    },

    preFillBillingForm() {
        const options = ACC.config.isAssessmentsCheckoutV2Enabled
            // eslint-disable-next-line max-len
            ? { countryIsoCode: $('#useDeliveryAddressData').data('countryisocode'), usePersonalPrimaryBillingAddress: true }
            : { countryIsoCode: $('#useDeliveryAddressData').data('countryisocode'), useDeliveryAddress: true };
        ACC.silentorderpost.enableAddressForm();
        ACC.silentorderpost.displayCreditCardAddressForm(options, () => {
            ACC.silentorderpost.useDeliveryAddressSelected();
            ACC.silentorderpost.reloadOrderTotalsWithPersonalPrimaryAddressSet();
        });
        ACC.silentorderpost.disableAddressForm();
    },

    reloadOrderTotalsWithPersonalPrimaryAddressSet() {
        if ($('#isCheckoutV2PersonalContextAndDigitalOnly').val() === 'true') {
            ACC.silentorderpost.usePersonalPrimaryBillingAddress();
        }
    },

    usePersonalPrimaryBillingAddress() {
        $.ajax({
            url: `${ACC.config.encodedContextPath}/checkout/multi/summary/usePersonalPrimaryBillingAddress`,
            success() {
                ACC.secureacceptance.reloadOrderTotals();
            },
            error(xhr, status, error) {
                console.error(error.message);
                window.location.reload();
            },
        });
    },

    preFillBillingFormForQuoteCheckout() {
        // eslint-disable-next-line max-len
        const options = { countryIsoCode: $('#useDeliveryAddressData').data('countryisocode'), useDeliveryAddress: false };
        ACC.silentorderpost.enableAddressForm();
        ACC.silentorderpost.displayCreditCardAddressForm(options, ACC.silentorderpost.fillCountrySelectBox);
    },

    preFillBillingFormWhenPaymentError() {
        // eslint-disable-next-line max-len
        const options = { countryIsoCode: $('#useBillingAddressData').data('countryisocode'), useDeliveryAddress: false, hasPaymentErrors: true };
        ACC.silentorderpost.enableAddressForm();
        ACC.silentorderpost.displayCreditCardAddressForm(options, ACC.silentorderpost.fillCountrySelectBox);
    },
    preFillBillingFormWhenNoDeliveryAddress() {
        // eslint-disable-next-line max-len
        const options = { countryIsoCode: $('#useBillingAddressData').data('countryisocode'), useDeliveryAddress: false, hasPaymentErrors: true };
        ACC.silentorderpost.enableAddressForm();
        // eslint-disable-next-line max-len
        ACC.silentorderpost.displayCreditCardAddressForm(options, () => $('#address\\.country').val($('#useBillingAddressData').data('countryisocode')));
    },

    bindSubmitSilentOrderPostForm() {
        $('.submit_silentOrderPostForm').click(function () {
            ACC.common.blockFormAndShowProcessingMessage($(this));
            $('.billingAddressForm').filter(':hidden').remove();
            ACC.silentorderpost.enableAddressForm();
            $('#silentOrderPostForm').submit();
        });
    },

    bindCycleFocusEvent() {
        $('#lastInTheForm').blur(() => {
            $('#silentOrderPostForm [tabindex$="10"]').focus();
        });
    },

    isEmpty(obj) {
        if (typeof obj === 'undefined' || obj === null || obj === '') return true;
        return false;
    },

    disableAddressForm() {
        $('input[id^="address\\."]').prop('disabled', true);
        $('select[id^="address\\."]').prop('disabled', true);
        $('select.form-control').prev().addClass('disabled');
    },

    enableAddressForm() {
        $('input[id^="address\\."]').prop('disabled', false);
        $('select[id^="address\\."]').prop('disabled', false);
        $('select.form-control').prev().removeClass('disabled');
    },

    clearAddressForm() {
        $('input[id^="address\\."]').val('');
        $('input[id^="address\\."]').prop('defaultValue', '');
        $('select[id^="address\\."]').filter(function () {
            return !this.id.match(/country/);
        }).val('');
    },

    clearSearchAddressForm() {
        $('#searchAddressForm').find('input[name=addressId]').val(null);
    },

    fillCountrySelectBox() {
        $('#address\\.country').val($('#useDeliveryAddressData').data('countryisocode'));
    },

    useDeliveryAddressSelected() {
        if ($('#useDeliveryAddress').is(':checked')) {
            ACC.silentorderpost.fillCountrySelectBox();
            ACC.silentorderpost.disableAddressForm();
        } else {
            ACC.silentorderpost.clearAddressForm();
            ACC.silentorderpost.enableAddressForm();
        }
    },

    enableSaveCardCheckBox() {
        const isWorldwideCountrySelected = $('#worldwideCountrySelected').data('worldwide-country-selected');
        const cardSaveCheckBox = $('#card_save_payment_info');
        if (isWorldwideCountrySelected === true) {
            cardSaveCheckBox.prop('checked', false);
            cardSaveCheckBox.attr('disabled', 'disabled');
            $('.js-card-save-payment-info-alert').show();
        } else {
            cardSaveCheckBox.removeAttr('disabled');
            $('.js-card-save-payment-info-alert').hide();
        }
    },

    bindCreditCardAddressForm() {
        $('#billingCountrySelector :input').on('change', function () {
            const countrySelection = $(this).val();
            const options = {
                countryIsoCode: countrySelection,
                useDeliveryAddress: false,
            };
            ACC.silentorderpost.displayCreditCardAddressForm(options);
        });
    },

    displayCreditCardAddressForm(options, callback) {
        $.ajax({
            url: `${ACC.config.encodedContextPath}/checkout/multi/sop/billingaddressform`,
            async: true,
            data: options,
            dataType: 'html',
            beforeSend() {
                $('#billingAddressForm').html(ACC.silentorderpost.spinner);
            },
        }).done((data) => {
            $('#billingAddressForm').html(data);
            ACC.validation.bindFormValidation();
            // eslint-disable-next-line max-len
            ACC.phonemasking.bind($('#address\\.phone'), options.countryIsoCode === 'UM' ? 'US' : options.countryIsoCode);
            if (ACC.config.countriesWithoutPostalCodeValidation.includes(options.countryIsoCode)) {
                $('#silentOrderPostForm').validate().settings.rules.billTo_postalCode = {
                    required: false,
                };
            } else if (ACC.config.countriesWithPostCodeValidation.includes(options.countryIsoCode)) {
                $('#silentOrderPostForm').validate().settings.rules.billTo_postalCode = {
                    required: true,
                    minlength: 1,
                    maxlength: 30,
                    postCodeInvalid: true,
                };
            }

            if (typeof callback === 'function') {
                callback.call();
            }
            ACC.silentorderpost.enableSaveCardCheckBox();
            if (ACC.config.isHidePostalCodeFieldByCountryListEnabled) {
                ACC.address.hidePostalZipCode(options.countryIsoCode);
            }
        });
    },

    isDeliveryAddressSameAsBilling() {
        const deliveryAddress = $('#useDeliveryAddressData');
        const billingAddress = $('#useBillingAddressData');
        return (deliveryAddress.data('titlecode') === billingAddress.data('titlecode')
               && deliveryAddress.data('firstname') === billingAddress.data('firstname')
               && deliveryAddress.data('lastname') === billingAddress.data('lastname')
               && deliveryAddress.data('line1') === billingAddress.data('line1')
               && deliveryAddress.data('line2') === billingAddress.data('line2')
               && deliveryAddress.data('line3') === billingAddress.data('line3')
               && deliveryAddress.data('town') === billingAddress.data('town')
               && deliveryAddress.data('postalcode') === billingAddress.data('postalcode')
               && deliveryAddress.data('countryisocode') === billingAddress.data('countryisocode')
               && deliveryAddress.data('regionisocode') === billingAddress.data('regionisocode'));
    },

    toggleBillingAddressForm(useDeliveryAddressCheckbox) {
        const hasPaymentErrors = $('#useBillingAddressData').data('haspaymenterrors');
        const isQuoteCheckout = useDeliveryAddressCheckbox.data('is-quote-checkout');
        const isReorderCheckout = useDeliveryAddressCheckbox.data('is-reorder-checkout');
        const deliveryAddressSameAsBilling = ACC.silentorderpost.isDeliveryAddressSameAsBilling();
        if (useDeliveryAddressCheckbox.length === 0) {
            ACC.silentorderpost.preFillBillingFormWhenNoDeliveryAddress();
        } else if (isQuoteCheckout || isReorderCheckout || hasPaymentErrors) {
            useDeliveryAddressCheckbox.prop('checked', false);
            ACC.silentorderpost.showBillingAddressForm();
            if (hasPaymentErrors && !deliveryAddressSameAsBilling) {
                ACC.silentorderpost.preFillBillingFormWhenPaymentError();
                ACC.silentorderpost.revealNewAddressForm(useDeliveryAddressCheckbox);
            } else if (deliveryAddressSameAsBilling) {
                if ($('#addNewAddress').is(':checked')) {
                    ACC.silentorderpost.revealNewAddressForm(useDeliveryAddressCheckbox);
                } else {
                    useDeliveryAddressCheckbox.prop('checked', true);
                    ACC.silentorderpost.hideBillingAddressForm();
                    ACC.silentorderpost.preFillBillingForm();
                }
            } else {
                ACC.silentorderpost.preFillBillingFormForQuoteCheckout();
            }
        } else {
            useDeliveryAddressCheckbox.prop('checked', true);
            ACC.silentorderpost.hideBillingAddressForm();
            ACC.silentorderpost.preFillBillingForm();
            ACC.silentorderpost.hidePayByPOBillingAddressSection();
        }
    },

    bindSelectAddNewBillingAddress() {
        const addressTypeInput = $('input[type=radio][name=addressGroupType]');
        addressTypeInput.change(() => {
            if ($('#addNewAddress').is(':checked')) {
                const billingAddressForm = $('#billingAddressForm');
                const billingAddressFormCountrySelector = $('#billingCountrySelector');
                ACC.silentorderpost.bindCreditCardAddressForm();
                $(billingAddressForm).find($('#isNewAddress')).val(true);
                $('.address-search ul.address').remove();
                ACC.silentorderpost.clearSearchAddressForm();
                ACC.checkout.reloadNewBillingAddressForm();
                $('#billingAddressFormContainer ul.address').remove();
                billingAddressForm.show();
                billingAddressFormCountrySelector.show();
            }
            $('.js-address-doctor-edit-address').hide();
        });
    },

    resetDivInputs(divId) {
        ACC.digitalAccountsViewHelper.resetCommonDivInputs(divId);
    },

    resetCommonDivInputs(divId) {
        const divInputs = $(':input', divId);
        divInputs.not(':button, :submit, :reset, :checkbox, :radio, [name="countryIso"]').val('');
        divInputs.not(':button, :submit, :reset, [name="countryIso"]')
            .prop('checked', false)
            .prop('selected', false);
    },

    revealNewAddressForm(useDeliveryAddressCheckbox) {
        if (sessionStorage.getItem('newAddressUsed')) {
            sessionStorage.removeItem('newAddressUsed');
            useDeliveryAddressCheckbox.prop('checked', false);
            $('#addNewAddress').click();
        }
    },
    showBillingAddressForm() {
        $('#ccBillingAddressForm').show();
        $('#billingAddressForm').show();
        $('#billingCountrySelector').show();
        $('input[name=addressGroupType]').first().click();
        $('#ccDeliveryAddress').hide();
        ACC.utils.preselectValue('#address\\.country');
        ACC.silentorderpost.hidePayByPOBillingAddressSection();
    },

    hideBillingAddressForm() {
        $('#quoteBillingAddressFormContainer').hide();
        $('#ccBillingAddressForm').hide();
        $('#ccDeliveryAddress').show();
    },

    showPaymentForm() {
        $('.checkout-paymentmethod').show();
    },

    hidePaymentForm() {
        $('.checkout-paymentmethod').hide();
    },

    showSavedCardBillingAddress() {
        $('#savedCardBillingAddress').show();
    },

    hideSavedCardBillingAddress() {
        $('#savedCardBillingAddress').hide();
    },

    showPayByPOInputField() {
        $('#purchaseOrderNumber').show();
    },

    hidePayByPOInputField() {
        $('#purchaseOrderNumber').hide();
    },

    showPayByPOBillingAddressSection() {
        $('#payByPOBillingAddressSection').show();
    },

    hidePayByPOBillingAddressSection() {
        $('#payByPOBillingAddressSection').hide();
    },

    hideSavedCardCvvInput() {
        $('#savedPaymentCards .c-saved-card .saved-card-cvv').removeClass('show-cvv-error-spacing');
        $('.saved-card-cvv').hide();
    },

    hideRemoveCardButton() {
        $('.js-cartItemDetailBtn').hide();
    },

    preselectCard() {
        const reorderType = 'REORDER';
        const orgWithTemporaryAccNo = $('#silentOrderPostForm').data('orgwithtemporaryaccno');
        const applyUpfrontPaymentPolicy = $('#silentOrderPostForm').data('shouldApplyUpfrontPaymentPolicy');

        if ($('#checkoutType').val() === reorderType) {
            const previouslySelectedCard = $('.savedCardRadioBtn[data-checked="true"]');
            const hasPaymentErrors = $('#useBillingAddressData').data('haspaymenterrors');

            if (previouslySelectedCard.length && !hasPaymentErrors) {
                // unselect all cards
                $('#savedPaymentCards .selected').removeClass('selected');
                // highlight saved card
                previouslySelectedCard.click();
                ACC.silentorderpost.hidePayByPOBillingAddressSection();
            } else {
                $('.payment-info').html('');
            }
        } else {
            const savedCardButton = $('.savedCardRadioBtn');
            const payByPO = $('.payByPORadioBtn');
            // eslint-disable-next-line max-len
            if (savedCardButton.length === 1 && (payByPO.length === 0 || ACC.silentorderpost.payByPoIsDisabled(payByPO, orgWithTemporaryAccNo, applyUpfrontPaymentPolicy))) {
                $('.savedCardRadioBtn').click();
            }
            if (ACC.config.isAssessmentsCheckoutV2Enabled && savedCardButton.length === 2) {
                $('.savedCardRadioBtn:first').click();
            }
        }
    },

    payByPoIsDisabled(payByPO, orgWithTemporaryAccNo, applyUpfrontPaymentPolicy) {
        return payByPO.length === 1 && (orgWithTemporaryAccNo === true || applyUpfrontPaymentPolicy === true);
    },
};
