ACC.cart = {

    _autoload: [
        ['scrollToPromoSection', $("#js-voucher-apply-btn[data-success='true']").length !== 0],
        'bindHelp',
        'cartRestoration',
        'bindCartPage',
        'bindMultiDEntryRemoval',
        'bindMultidCartProduct',
        'bindCloseQuoteModal',
        'bindQuoteContinueButton',
        ['bindApplyVoucher', $('#js-voucher-apply-btn').length !== 0],
        ['bindToReleaseVoucher', $('#js-applied-vouchers').length !== 0],
        ['bindQuoteCreate', $('.js-create-quote-button').length !== 0],
    ],

    scrollToPromoSection() {
        const offSet = $(window).height() / 2;
        $('html,body').animate({
            scrollTop: $('#js-voucher-apply-btn').offset().top - offSet,
        });
    },

    bindHelp() {
        $(document).on('click', '.js-cart-help', function (e) {
            e.preventDefault();
            const title = $(this).data('help');
            ACC.colorbox.open(title, {
                html: $('.js-help-popup-content').html(),
            });
        });
    },

    cartRestoration() {
        $('.cartRestoration').click(function () {
            const sCartUrl = $(this).data('cartUrl');
            window.location = sCartUrl;
        });
    },

    bindCartPage() {
        // link to display the multi-d grid in read-only mode
        $(document).on('click', '.js-show-editable-grid', function (event) {
            ACC.cart.populateAndShowEditableGrid(this, event);
        });
    },

    bindMultiDEntryRemoval() {
        $(document).on('click', '.js-submit-remove-product-multi-d', function () {
            const itemIndex = $(this).data('index');
            const $form = $(`#updateCartForm${itemIndex}`);
            const initialCartQuantity = $form.find('input[name=initialQuantity]');
            const cartQuantity = $form.find('input[name=quantity]');
            // eslint-disable-next-line no-unused-vars
            const entryNumber = $form.find('input[name=entryNumber]').val();
            // eslint-disable-next-line no-unused-vars
            const productCode = $form.find('input[name=productCode]').val();

            cartQuantity.val(0);
            initialCartQuantity.val(0);

            const method = $form.attr('method') ? $form.attr('method').toUpperCase() : 'GET';
            $.ajax({
                url: $form.attr('action'),
                data: $form.serialize(),
                type: method,
                success() {
                    window.location.reload();
                },
                error() {
                    // eslint-disable-next-line no-undef
                    alert(`Failed to remove quantity. Error details [${xht}, ${textStatus}, ${ex}]`);
                },

            });
        });
    },

    populateAndShowEditableGrid(element) {
        const readOnly = $(element).data('readOnlyMultidGrid');
        const itemIndex = $(element).data('index');
        grid = $(`#ajaxGrid${itemIndex}`);

        const gridEntries = $(`#grid${itemIndex}`);
        const strSubEntries = gridEntries.data('sub-entries');
        const arrSubEntries = strSubEntries.split(',');
        const firstVariantCode = arrSubEntries[0].split(':')[0];

        $(element).toggleClass('open');

        const targetUrl = gridEntries.data('target-url');

        // eslint-disable-next-line no-new-object
        const mapCodeQuantity = new Object();

        for (let i = 0; i < arrSubEntries.length; i += 1) {
            const arrValue = arrSubEntries[i].split(':');
            // eslint-disable-next-line prefer-destructuring
            mapCodeQuantity[arrValue[0]] = arrValue[1];
        }

        if (grid.children('#cartOrderGridForm').length > 0) {
            grid.slideToggle('slow');
        } else {
            const method = 'GET';
            $.ajax({
                url: targetUrl,
                data: { productCode: firstVariantCode, readOnly },
                type: method,
                success(data) {
                    // eslint-disable-next-line no-undef
                    grid.html(DOMPurify.sanitize(data));
                    $('#ajaxGrid').removeAttr('id');
                    const $gridContainer = grid.find('.product-grid-container');
                    const numGrids = $gridContainer.length;
                    for (let i = 0; i < numGrids; i += 1) {
                        ACC.cart.getProductQuantity($gridContainer.eq(i), mapCodeQuantity, i);
                    }
                    grid.slideDown('slow');
                    ACC.cart.coreCartGridTableActions(element, mapCodeQuantity);
                    ACC.productorderform.coreTableScrollActions(grid.children('#cartOrderGridForm'));
                },
                error(xht, textStatus, ex) {
                    alert(`Failed to get variant matrix. Error details [${xht}, ${textStatus}, ${ex}]`);
                },

            });
        }
    },

    coreCartGridTableActions(element, mapCodeQuantity) {
        ACC.productorderform.bindUpdateFutureStockButton('.update_future_stock_button');
        ACC.productorderform.bindVariantSelect($('.variant-select-btn'), 'cartOrderGridForm');
        const itemIndex = $(element).data('index');
        const skuQuantityClass = '.sku-quantity';

        let quantityBefore = 0;
        const grid = $(`#ajaxGrid${itemIndex} .product-grid-container`);

        grid.on('focusin', skuQuantityClass, function () {
            quantityBefore = jQuery.trim(this.value);

            $(this).parents('tr').next('.variant-summary').remove();
            if ($(this).parents('table').data(ACC.productorderform.selectedVariantData)) {
                // eslint-disable-next-line max-len
                ACC.productorderform.selectedVariants = $(this).parents('table').data(ACC.productorderform.selectedVariantData);
            } else {
                ACC.productorderform.selectedVariants = [];
            }

            if (quantityBefore === '') {
                quantityBefore = 0;
                this.value = 0;
            }
        });

        grid.on('focusout keypress', skuQuantityClass, function (event) {
            const code = event.keyCode || event.which || event.charCode;

            if (code !== 13 && code !== undefined) {
                return;
            }

            let quantityAfter = 0;
            let gridLevelTotalPrice = '';

            const indexPattern = '[0-9]+';
            const currentIndex = parseInt($(this).attr('id').match(indexPattern), 10);

            this.value = ACC.productorderform.filterSkuEntry(this.value);

            quantityAfter = jQuery.trim(this.value);
            const variantCode = $(`input[id='cartEntries[${currentIndex}].sku']`).val();

            // eslint-disable-next-line no-restricted-globals
            if (isNaN(jQuery.trim(this.value))) {
                this.value = 0;
            }

            if (quantityAfter === '') {
                quantityAfter = 0;
                this.value = 0;
            }

            const $gridTotalValue = grid.find(`[data-grid-total-id=total_value_${currentIndex}]`);
            const currentPrice = $(`input[id='productPrice[${currentIndex}]']`).val();

            if (quantityAfter > 0) {
                // eslint-disable-next-line max-len
                gridLevelTotalPrice = ACC.productorderform.formatTotalsCurrency(parseFloat(currentPrice) * parseInt(quantityAfter, 10));
            }

            $gridTotalValue.html(gridLevelTotalPrice);

            const _this = this;
            const priceSibling = $(this).siblings('.price');
            const propSibling = $(this).siblings('.variant-prop');
            const currentSkuId = $(this).next('.td_stock').data('sku-id');
            const currentBaseTotal = $(this).siblings('.data-grid-total');

            if (this.value !== quantityBefore) {
                let newVariant = true;
                ACC.productorderform.selectedVariants.forEach((item, index) => {
                    if (item.id === currentSkuId) {
                        newVariant = false;

                        if (_this.value === '0' || _this.value === 0) {
                            ACC.productorderform.selectedVariants.splice(index, 1);
                        } else {
                            ACC.productorderform.selectedVariants[index].quantity = _this.value;
                            // eslint-disable-next-line max-len
                            ACC.productorderform.selectedVariants[index].total = ACC.productorderform.updateVariantTotal(priceSibling, _this.value, currentBaseTotal);
                        }
                    }
                });

                if (newVariant && this.value > 0) {
                    // update variantData
                    ACC.productorderform.selectedVariants.push({
                        id: currentSkuId,
                        size: propSibling.data('variant-prop'),
                        quantity: _this.value,
                        total: ACC.productorderform.updateVariantTotal(priceSibling, _this.value, currentBaseTotal),
                    });
                }
            }
            ACC.productorderform.showSelectedVariant($(this).parents('table'));
            if (this.value > 0 && this.value !== quantityBefore) {
                $(this).parents('table').addClass('selected');
            } else if (ACC.productorderform.selectedVariants.length === 0) {
                $(this).parents('table').removeClass('selected').find('.variant-summary')
                    .remove();
            }

            if (quantityBefore !== quantityAfter) {
                const method = 'POST';
                $.ajax({
                    url: `${ACC.config.encodedContextPath}/cart/updateMultiD`,
                    data: { productCode: variantCode, quantity: quantityAfter, entryNumber: -1 },
                    type: method,
                    success(data) {
                        ACC.cart.refreshCartData(data, -1, quantityAfter, itemIndex);
                        mapCodeQuantity[variantCode] = quantityAfter;
                    },
                    error(xhr) {
                        const redirectUrl = xhr.getResponseHeader('redirectUrl');
                        const connection = xhr.getResponseHeader('Connection');
                        // check if error leads to a redirect
                        if (redirectUrl !== null) {
                            window.location = redirectUrl;
                            // check if error is caused by a closed connection
                        } else if (connection === 'close') {
                            window.location.reload();
                        }
                    },
                });
            }
        });
    },

    refreshCartData(cartData, entryNum, quantity, itemIndex) {
        // if cart is empty, we need to reload the whole page
        if (cartData.entries.length === 0) {
            window.location.reload();
        } else {
            let form;

            if (entryNum === -1) { // grouped item
                form = $(`.js-qty-form${itemIndex}`);
                const productCode = form.find('input[name=productCode]').val();

                // eslint-disable-next-line no-shadow
                let quantity = 0;
                let entryPrice = 0;
                for (let i = 0; i < cartData.entries.length; i += 1) {
                    const entry = cartData.entries[i];
                    if (entry.product.code === productCode) {
                        quantity = entry.quantity;
                        entryPrice = entry.totalPrice;
                        ACC.cart.updateEntryNumbersForCartMenuData(entry);
                        break;
                    }
                }

                if (quantity === 0) {
                    window.location.reload();
                } else {
                    form.find('.qtyValue').html(quantity);
                    form.parent().parent().find('.js-item-total').html(entryPrice.formattedValue);
                }
            }

            ACC.cart.refreshCartPageWithJSONResponse(cartData);
        }
    },

    refreshCartPageWithJSONResponse(cartData) {
        // refresh mini cart
        ACC.minicart.updateMiniCartDisplay();
        $('.js-cart-top-totals').html($('#cartTopTotalSectionTemplate').tmpl(cartData));
        $('div .cartpotproline').remove();
        $('div .cartproline').remove();
        $('.js-cart-totals').remove();
        $('#ajaxCartPotentialPromotionSection').html($('#cartPotentialPromotionSectionTemplate').tmpl(cartData));
        $('#ajaxCartPromotionSection').html($('#cartPromotionSectionTemplate').tmpl(cartData));
        $('#ajaxCart').html($('#cartTotalsTemplate').tmpl(cartData));
        ACC.quote.bindQuoteDiscount();
    },

    updateEntryNumbersForCartMenuData(entry) {
        let entryNumbers = '';
        $.each(entry.entries, (index, subEntry) => {
            if (index !== 0) {
                entryNumbers += ';';
            }
            entryNumbers += subEntry.entryNumber;
        });
        $('.js-execute-entry-action-button').data('actionEntryNumbers', entryNumbers);
    },

    getProductQuantity(gridContainer, mapData) {
        const tables = gridContainer.find('table');

        $.each(tables, (index, currentTable) => {
            const skus = jQuery.map($(currentTable).find("input[type='hidden'].sku"), (o) => o.value);
            const quantities = jQuery.map($(currentTable).find("input[type='textbox'].sku-quantity"), (o) => o);
            const selectedVariants = [];

            // eslint-disable-next-line no-shadow
            $.each(skus, (index, skuId) => {
                const quantity = mapData[skuId];
                if (quantity !== undefined) {
                    quantities[index].value = quantity;

                    const indexPattern = '[0-9]+';
                    const currentIndex = parseInt(quantities[index].id.match(indexPattern), 10);
                    const gridTotalValue = gridContainer.find(`[data-grid-total-id=total_value_${currentIndex}]`);
                    let gridLevelTotalPrice = '';
                    const currentPrice = $(`input[id='productPrice[${currentIndex}]']`).val();
                    if (quantity > 0) {
                        // eslint-disable-next-line max-len
                        gridLevelTotalPrice = ACC.productorderform.formatTotalsCurrency(parseFloat(currentPrice) * parseInt(quantity, 10));
                    }
                    gridTotalValue.html(gridLevelTotalPrice);

                    selectedVariants.push({
                        id: skuId,
                        size: $(quantities[index]).siblings('.variant-prop').data('variant-prop'),
                        quantity,
                        total: gridLevelTotalPrice,
                    });
                }
            });

            if (selectedVariants.length !== 0) {
                $.tmpl(ACC.productorderform.$variantSummaryTemplate, {
                    variants: selectedVariants,
                }).appendTo($(currentTable).addClass('selected'));
                // eslint-disable-next-line max-len
                $(currentTable).find('.variant-summary .variant-property').html($(currentTable).find('.variant-detail').data('variant-property'));
                $(currentTable).data(ACC.productorderform.selectedVariantData, selectedVariants);
            }
        });
    },

    bindMultidCartProduct() {
        // link to display the multi-d grid in read-only mode
        $(document).on('click', '.showQuantityProduct', function (event) {
            ACC.multidgrid.populateAndShowGrid(this, event, true);
        });

        // link to display the multi-d grid in read-only mode
        $(document).on('click', '.showQuantityProductOverlay', function (event) {
            ACC.multidgrid.populateAndShowGridOverlay(this, event);
        });
    },

    bindApplyVoucher() {
        $('#js-voucher-apply-btn').on('click', (e) => {
            ACC.cart.handleApplyVoucher(e);
        });
    },

    handleApplyVoucher() {
        const voucherCode = $.trim($('#js-voucher-code-text').val());
        if (voucherCode !== '' && voucherCode.length > 0) {
            $('#applyVoucherForm').submit();
        }
    },

    bindToReleaseVoucher() {
        $('.js-release-voucher-remove-btn').on('click', function () {
            $(this).closest('form').submit();
        });
    },

    bindQuoteCreate() {
        ACC.cart.handlePromotionModal({
            actionButtonSelector: '.js-create-quote-button',
            modalWindowSelector: '#js-quote-promotion-modal',
            modalTitleDataAttributeName: 'quote-modal-title',
            actionButtonUrlDataAttributeName: 'target-url',
            actionButtonCustomerUrlDataAttributeName: 'update-customer-url',
            validateAimswebProductsUrl: 'validate-aimsweb-products-url',
            modalExpirationDataAttributeName: 'quote-promotion-expiration',
            cancelButtonSelector: '#js-quote-promotion-modal #cancelButton',
            continueButtonSelector: '#js-quote-promotion-modal #continueButton',
        });
    },

    handlePromotionModal(options) {
        const modalWindow = $(options.modalWindowSelector);
        const validateAimswebProductsUrl = $(options.actionButtonSelector).data(options.validateAimswebProductsUrl);

        $(options.actionButtonSelector).click((e) => {
            e.preventDefault();
            $.ajax({
                type: 'GET',
                url: validateAimswebProductsUrl,
                contentType: 'application/json',
                success(response) {
                    if (typeof response !== 'undefined' && response !== '') {
                        const messageKey = 'checkout.multi.aimswebVerification.minimumProductsNotice';
                        ACC.globalmessages.showErrorMessageWithAttributes(messageKey, [response]);
                    } else {
                        const expiration = modalWindow.data(options.modalExpirationDataAttributeName);
                        if (expiration) {
                            if (options.initializeCallback) {
                                options.initializeCallback();
                            }
                            const modalContent = $(options.actionButtonSelector).data('modal-content');
                            $modal.open(null, {
                                html: $(modalContent).html(),
                                title: $(options.actionButtonSelector).data('modal-title'),
                            });
                        } else if ($('#cart-context-switch-modal').length !== 0) {
                            $('#contextSwitchForm').data('quote-checkout', 'true');
                            $('#cartContextSwitchModalTrigger').trigger('click');
                            ACC.cart.bindCartContextRadioBtnSelection();
                        } else {
                            ACC.checkout.continueQuoteCheckout(options.actionButtonSelector);
                        }
                    }
                },
            });
        });
    },

    bindCartContextRadioBtnSelection() {
        const radioButtons = $('#cboxWrapper .js-cart-context-selector-radio-btn');
        radioButtons.find('input:radio:checked').parent().addClass('selected');
        radioButtons.on('click', function () {
            const that = $(this);
            $('.selected').removeClass('selected');
            that.addClass('selected');
            that.children('input:radio').prop('checked', true);
        });
    },

    bindCloseQuoteModal() {
        $('#colorbox').on('click', '#cancelButton', $modal.close);
    },

    bindQuoteContinueButton() {
        const targetUrl = $('.js-create-quote-button').data('target-url');
        const updateCustomerUrl = $('.js-create-quote-button').data('update-customer-url');
        $('#colorbox').on('click', '#continueButton', (event) => {
            event.preventDefault();
            $modal.close();
            ACC.checkout.processCheckoutRequest(targetUrl, updateCustomerUrl);
        });
    },
};
