// eslint-disable-next-line no-unused-vars,no-undef
const pluginInstance = new PearsonBreadcrumbs({
    element: document.getElementsByClassName('js-c-breadcrumb'),
    container: document.getElementsByClassName('c-breadcrumb__container'),
    breadcrumbList: document.getElementsByClassName('c-breadcrumb__list'),
    breadcrumbItemTag: '.js-c-breadcrumb li',
    // eslint-disable-next-line max-len
    truncationHtml: '<li class="truncation"><button data-toggle="tooltip" data-placement="top" title="Expand">&hellip;</button></li>',
    showAll: 'show-children--all',
    showFirstLast: 'show-children--first-last',
    truncClass: '.truncation',
    padding: 30,
});
