ACC.orderConfirmation = {
    _autoload: [
        ['start', $('.order-conformation').length !== 0],
    ],

    start() {
        ACC.orderConfirmation.showOrderOnHoldModal();
        ACC.orderConfirmation.fetchAccessCodes();
    },

    surveyCookie: 'mcxSurveyQuarantine',
    isSurveyPresent: false,

    spinner: $(`<img src='${ACC.config.commonResourcePath}/images/spinner.gif' alt="loading"/>`),

    showOrderOnHoldModal() {
        const onHoldModal = $('#js-order-on-hold');
        const isOrderOnHold = onHoldModal.data('order-on-hold');

        if (isOrderOnHold) {
            $modal.open(null, {
                title: onHoldModal.data('modal-title'),
                href: onHoldModal.data('modal-content'),
                inline: true,
            });
        }
    },

    fetchAccessCodes() {
        const items = document.getElementsByClassName('access-codes-info');
        for (let i = 0; i < items.length; i += 1) {
            const productCode = items[i].getAttribute('data-product-code');
            const container = $(`#js-access-codes-wrapper-${productCode}`);
            if (container.data('access-codes-loaded') === true) {
                container.removeClass('hidden');
            } else {
                ACC.orderConfirmation.setSurveyCookie();
                const orderCode = items[i].getAttribute('data-order-code');
                ACC.orderConfirmation.getAccessCodes(productCode, orderCode, 0);
            }
        }
    },

    getAccessCodes(productCode, orderCode, tryCount) {
        $.ajax({
            async: false,
            type: 'GET',
            url: `${ACC.config.encodedContextPath}/checkout/accessCodes`,
            data: $.param({ productCode, orderCode }),
            beforeSend() {
                ACC.spinner.start();
            },
            success: (response) => {
                if (response.length > 0) {
                    ACC.spinner.stop();
                    ACC.orderConfirmation.showSurvey();
                    const accessCodesContainer = $(`#js-access-codes-wrapper-${productCode}`).removeClass('hidden');
                    ACC.orderConfirmation.appendAccessCodes(accessCodesContainer, response, productCode);
                    accessCodesContainer.next('#js-access-codes-redeem-url').removeClass('hidden');
                } else {
                    // eslint-disable-next-line no-lonely-if
                    if (tryCount <= ACC.config.accessCodesRetryLimit) {
                        clearTimeout();
                        setTimeout(() => {
                            tryCount += 1;
                            ACC.orderConfirmation.getAccessCodes(productCode, orderCode, tryCount);
                        }, ACC.config.accessCodesWaitTime);
                    } else {
                        ACC.spinner.stop();
                        ACC.orderConfirmation.showSurvey();
                    }
                }
            },
        });
    },

    appendAccessCodes(accessCodesContainer, accessCodesList, productCode) {
        accessCodesContainer.append(ACC.orderConfirmation.createTitleElement());
        accessCodesContainer.append(ACC.orderConfirmation.createWrapperElement(productCode));
        const accessCodesUlElement = $(`#access-code__list-${productCode}`);

        accessCodesList.forEach((accessCode) => {
            accessCodesUlElement.append(ACC.orderConfirmation.createAccessCodeElement(accessCode));
        });

        ACC.redeemDigitalProduct.copyToClipboard();
    },

    createTitleElement() {
        return $(`
               <div class="access-code__title"> ${ACC.config.accessCodesTitle}</div>
            `);
    },

    createWrapperElement(productCode) {
        return $(`
               <div class="access-code"> <ul id="access-code__list-${productCode}" class="access-code__list">
            `);
    },

    createAccessCodeElement(accessCode) {
        return $(`
            <li class="js-access-code-copy">
                <div class="access-code__text-wrapper">
                    <a href="#" class="access-code__text js-access-code-text">${accessCode}</a>
                </div>
                <div class="access-code__copy">
                    <span>${ACC.config.accessCodesCopy}</span>
                </div>
                <div class="access-code__copied">
                    <span>${ACC.config.accessCodesCopied}</span>
                </div>
            </li>
        `);
    },

    setSurveyCookie() {
        if (!ACC.cookie.getCookie(ACC.orderConfirmation.surveyCookie)) {
            ACC.cookie.setCookie(ACC.orderConfirmation.surveyCookie, ACC.orderConfirmation.surveyCookie, 1);
        } else {
            ACC.orderConfirmation.isSurveyPresent = true;
        }
    },

    showSurvey() {
        if (!ACC.orderConfirmation.isSurveyPresent) {
            ACC.cookie.setCookie(ACC.orderConfirmation.surveyCookie, ACC.orderConfirmation.surveyCookie, 0);
            $('#mcxInvitationModal').show();
        }
    },
};
