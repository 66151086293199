ACC.digitalAccountsModal = {
    _autoload: ['bindListeners'],

    bindListeners() {
        ACC.digitalAccountsModal.closeModal();
        ACC.digitalAccountsModal.closePersonModal();
        ACC.digitalAccountsModal.bindAddOrgModal();
    },

    closePersonModal() {
        $('#colorbox').on('click', '.js-person-modal-ok-button', $modal.close);
    },

    closeModal() {
        $('#colorbox').on('click', '.js-associate-modal-close-button', $modal.close);
    },

    bindAddOrgModal() {
        $('#colorbox').on('click', '#registerMergedOrg', () => {
            $modal.close();
            $('#number').val('');
        });
    },
};
