ACC.product = {

    _autoload: [
        'bindToAddToCartForm',
        'enableStorePickupButton',
        'enableVariantSelectors',
        'bindFacets',
        'focusPriceAndOrderingOnBtnClick',
        'programTabsClick',
        'filterAccordionClick',
        'bindDisplayAttributeInfo',
        'bindSharePopup',
        'displayNeedHelpPopup',
        'needHelpPrintOnly',
        'relatedItemsPrint',
        'displayCallToActionPopup',
        'closeModal',
        'promoBoxCallToAction',
        'preferredFormatClicked',
        'tieredPricingToggle',
        'handleWebinarsPagination',
        'preselectFormatAndFilter',
        'bindProductLimitSalesQuantityValidation',
        'quantityZero',
        'addToCartCrossSelling',
        'bindCrossSellInputValidation',
    ],

    addToCartCrossSelling() {
        $(document).on('submit', '.cross-sell-product-ordering', function (e) {
            e.preventDefault();
            const formElement = $(this).closest('form.product-ordering');
            const productCode = formElement.find('input[name=productCode]').val();
            const qty = formElement.find('input[name=qty]').val();
            $.ajax({
                async: false,
                type: 'POST',
                url: `${ACC.config.encodedContextPath}/cart/add/crosSelling`,
                data: $.param({ productCode, qty }),
                success: () => {
                    // eslint-disable-next-line max-len
                    ACC.buttonhelper.disable($(this).children('.crossSellingButton'), ACC.config.addedToCart);
                    formElement.find('input[name=qty]').attr('disabled', true);
                },
            });
        });
    },

    closeModal() {
        $('#colorbox').on('click', '.js-mini-cart-close-button', $modal.close);
    },

    quantityZero() {
        const qty = document.getElementsByClassName('js-product-qty-input');
        if (qty.length > 0) {
            [].forEach.call(qty, (e) => {
                const btn = e.parentElement.parentElement.parentElement.querySelector('.js-add-to-cart');
                // eslint-disable-next-line max-len
                const buyNowBtn = e.parentElement.parentElement.parentElement.parentElement.querySelector('.js-buy-now-button');
                e.addEventListener('change', () => {
                    if (e.value > 0) {
                        btn.disabled = false;
                        buyNowBtn.disabled = false;
                    } else {
                        btn.disabled = true;
                        buyNowBtn.disabled = true;
                    }
                });
            });
        }
    },

    bindFacets() {
        // Open Facets modal
        $('.js-show-facets').on('click', function () {
            $modal.open(this);
            $('#cboxLoadedContent').find('.accordion-panel-show').removeClass('accordion-panel-show');
        });

        // Enable toggling of open facets in modal.
        // This is necessary so the toggles work within the dynamically loaded content into modals.
        $('.js-product-facet .js-facet-name').on('click', function () {
            // console.log('#cboxLoadedContent: ' + $(this).closest('#cboxLoadedContent').length);
            if ($(this).parent().hasClass('active') && $(this).closest('#cboxLoadedContent').length) {
                $(this).parent().removeClass('active');
                $(this).closest('#product-facet').find('.active').removeClass('active')
                    .find('.facet-data')
                    .toggleClass('toggled');
                $(this).next().hide().removeClass('accordion-panel-show')
                    .css('opacity', '0');
            } else if ($(this).closest('#cboxLoadedContent').length > 0) {
                $(this).parent().addClass('active');
                $(this).find('.facet-data').addClass('toggled');
                $(this).next().show().css('opacity', '1')
                    .addClass('accordion-panel-show');
            }
        });
    },

    enableAddToCartButton() {
        $('.js-enable-btn').each(function () {
            if (!($(this).hasClass('outOfStock') || $(this).hasClass('out-of-stock'))) {
                $(this).prop('disabled', false);
            }
        });
    },

    enableVariantSelectors() {
        $('.variant-select').prop('disabled', false);
    },

    bindToAddToCartForm() {
        $('.add_to_cart_form').ajaxForm({
            success: ACC.product.updateCartOnSuccess,
        });
    },

    bindToAddToCartStorePickUpForm() {
        const addToCartStorePickUpForm = $('#colorbox #add_to_cart_storepickup_form');
        addToCartStorePickUpForm.ajaxForm({
            success: ACC.product.displayAddToCartPopup,
        });
    },

    initiateExpressCheckoutForProduct(element) {
        // eslint-disable-next-line prefer-template
        const productElement = '#' + element;
        $(productElement).attr('disabled', 'disabled');
        const isAuthGenerated = ACC.expresscheckout.isUserLoggedIn();
        if (!isAuthGenerated) {
            window.location = `${ACC.config.encodedContextPath}/login`;
        } else {
            const productCode = $(productElement).attr('data-product-code');
            // eslint-disable-next-line prefer-template
            const quantity = $('#qty-' + productCode).val();
            $.ajax({
                url: `${ACC.config.encodedContextPath}/checkout/express-checkout/buy-now`,
                type: 'POST',
                dataType: 'json',
                contentType: 'application/json',
                // eslint-disable-next-line prefer-template
                headers: { entryPoint: 'PDP' },
                // eslint-disable-next-line prefer-template
                data: ACC.expresscheckout.getJSONDataForExpressCheckout(productCode + '-' + quantity),
                async: false,
                success(response) {
                    ACC.product.handleBuyNowRedirection(response, productCode, quantity);
                },
                error(jqXHR, textStatus, errorThrown) {
                    ACC.expresscheckout.handleExpressCheckoutRedirectionWithHeaders(jqXHR);
                    // log the error to the console
                    // eslint-disable-next-line no-console
                    console.log(`The following error occurred: ${textStatus}`, errorThrown);
                },
            });
        }
        $(productElement).removeAttr('disabled');
    },

    handleBuyNowRedirection(response, productCode, quantity) {
        if (!response.redirectUrl.includes('buy-now')) {
            ACC.expresscheckout.handleExpressCheckoutResult(response);
        } else {
            $.ajax({
                url: `${ACC.config.encodedContextPath}/checkout/express-checkout/buy-now/pop-up`,
                type: 'POST',
                // eslint-disable-next-line prefer-template
                headers: { entryPoint: 'PDP' },
                dataType: 'json',
                contentType: 'application/json',
                // eslint-disable-next-line prefer-template
                data: ACC.expresscheckout.getJSONDataForExpressCheckout(productCode + '-' + quantity),
                async: false,
                success(data) {
                    ACC.expresscheckout.openMergeCartModalForExpressCheckout(data);
                },
                error(jqXHR, textStatus, errorThrown) {
                    ACC.expresscheckout.handleExpressCheckoutRedirectionWithHeaders(jqXHR);
                    // log the error to the console
                    // eslint-disable-next-line no-console
                    console.log(`The following error occurred: ${textStatus}`, errorThrown);
                },
            });
        }
    },

    enableStorePickupButton() {
        $('.js-pickup-in-store-button').prop('disabled', false);
    },

    updateCartOnSuccess(cartResult, statusText, xhr, formElement) {
        if (xhr.status === 200 && xhr.responseText) {
            if (cartResult.isFeatureAddToCartModelEnabled && cartResult.isFeatureAddToCartModelEnabled === 'true') {
                ACC.product.updateCartStatus(cartResult, formElement);
                ACC.product.displayAddToCartPopup(cartResult, formElement);
            } else {
                const url = `${ACC.config.encodedContextPath}/cart`;
                window.location.replace(url);
            }
        }
    },

    updateCartStatus(cartResult, formElement) {
        ACC.minicart.updateMiniCartDisplay(cartResult.cartData.totalCartItemsCount);
        $(document).trigger('analytics_updateCart', cartResult, formElement);
    },

    displayAddToCartPopup(cartResult, formElement) {
        $modal.open(null, {
            html: cartResult.addToCartLayer,
            title: formElement.find('.js-add-to-cart').attr('data-modal-title'),
            className: 'add_to_cart',
        });
    },

    focusPriceAndOrderingOnBtnClick() {
        $('#pricing-tab-btn').click(() => {
            $('html, body').stop().animate({
                scrollTop: $('.c-tabs-list').offset().top,
            }, 1000);
            document.getElementById('pricing-ordering').click();
        });
    },

    programTabsClick() {
        $(document).ready(() => {
            const tabsLiASelector = 'ul.c-tabs-list li button';
            $(tabsLiASelector).click(function (e) {
                // Prevent a tag from opening link
                e.preventDefault();
                $(this).parent().find('li:hidden').show();
                // Save tab ID link
                const tab_id = $(this).data('tab-id');
                const currentTabContent = $(`#${tab_id}`);

                const aemContentTabContainer = currentTabContent.find('#aemContentTabContainer');
                if ($(this).parent().parent().hasClass('tabpopup')) {
                    // De-select all the tabs
                    $(tabsLiASelector).removeClass('current-tab');
                    $('.c-tab-content').removeClass('current-tab-content');
                    $(tabsLiASelector).attr('aria-selected', 'false');

                    // Select this tab
                    $(this).addClass('current-tab');
                    currentTabContent.addClass('current-tab-content');
                    $(this).attr('aria-selected', 'true');
                    $('.tabpopup').css('display', 'none');
                    if (aemContentTabContainer.length > 0) {
                        ACC.product.pullAemTabContent();
                    }
                } else {
                    // De-select all the tabs
                    $('.current-tab').removeClass('current-tab');
                    $('.c-tab-content').removeClass('current-tab-content');
                    $(tabsLiASelector).attr('aria-selected', 'false');

                    // Select this tab
                    $(this).addClass('current-tab');
                    currentTabContent.addClass('current-tab-content');
                    $(this).attr('aria-selected', 'true');
                    if (aemContentTabContainer.length > 0) {
                        ACC.product.pullAemTabContent();
                    }
                }
            });
        });
    },

    preferredFormatClicked() {
        if ($('.page-programDetailsPageV2').length) {
            $('.preferred-format__card .preferred-format__button').on('click', function () {
                const filtersSlot = $('.program-page__filters-slot');
                const preferredFormatCard = $(this).parent();
                if (filtersSlot.hasClass('filters-active') && !preferredFormatCard.hasClass('isActive')) {
                    filtersSlot.removeClass('filters-active');
                } else {
                    filtersSlot.addClass('filters-active');
                }
            });
        }
    },

    filterAccordionClick() {
        $(document).ready(() => {
            $('.js-accordion').click(function () {
                const filterContent = $(this).next();
                $(filterContent).toggle();
            });
        });
    },

    pullAemTabContent() {
        const aemContentTabContainer = $('#aemContentTabContainer');
        if (aemContentTabContainer.length > 0) {
            $.get(aemContentTabContainer.data('aemurl'), (data) => {
                // eslint-disable-next-line no-undef
                aemContentTabContainer.html(DOMPurify.sanitize(data));

                if (data.length > 0) {
                    const eventListing = $('.c-eventListing');

                    // eslint-disable-next-line no-inner-declarations
                    function fixDateFormat(dateBlock) {
                        let newTime;
                        let newDate;
                        const startDateValue = $(dateBlock.find('time.c-eventListing__date.startDate')).html().trim();
                        const starTimeValue = $(dateBlock.find('time.c-eventListing__time.startDate')).html().trim();
                        // eslint-disable-next-line max-len
                        const startDate = new Date($(dateBlock.find('time.c-eventListing__date.startDate')).attr('datetime'));

                        const endDateValue = $(dateBlock.find('time.c-eventListing__date.endDate')).html().trim();
                        const endTimeValue = $(dateBlock.find('time.c-eventListing__time.endDate')).html().trim();
                        // eslint-disable-next-line max-len
                        const endDate = new Date($(dateBlock.find('time.c-eventListing__date.endDate')).attr('datetime'));

                        const noDateError = (startDate <= endDate);
                        const timeZone = $(dateBlock.find('time.timeZone')).attr('zone').trim();

                        function removeEndDate() {
                            $(dateBlock.find('time.c-eventListing__date.endDate')).remove();
                            $(dateBlock.find('time.c-eventListing__time.endDate')).remove();
                            $(dateBlock.find('time.timeZone')).remove();
                        }

                        // special case if spans across two days
                        function eventEndingNextDay() {
                            // eslint-disable-next-line max-len
                            return !!((startDate.getDate() !== endDate.getDate() && ((endDate - startDate) / 1000 / 60 / 60) < 24 && noDateError));
                        }

                        // special case if spans across two days / ending second day
                        if (eventEndingNextDay()) {
                            removeEndDate();
                            $(dateBlock.find('time.c-eventListing__time.startDate')).remove();
                            // eslint-disable-next-line max-len
                            newDate = `${startDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })} <span>${starTimeValue.toLowerCase()}</span> - ${endDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric' })} <span>${endTimeValue.toLowerCase()} ${timeZone}</span>`;
                            // eslint-disable-next-line max-len
                            $(dateBlock.find('time.c-eventListing__date.startDate')).html(newDate).addClass('nextDayEnding');
                        } else if ((endDate.getFullYear() > startDate.getFullYear()) && noDateError) {
                            removeEndDate();

                            newTime = `${starTimeValue.toLowerCase()} - ${endTimeValue.toLowerCase()} ${timeZone}`;
                            $(dateBlock.find('time.c-eventListing__time.startDate')).html(newTime);

                            newDate = `${startDateValue} - ${endDateValue}`;
                            // eslint-disable-next-line max-len
                            newDate = `${startDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })} - ${endDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })}`;
                            $(dateBlock.find('time.c-eventListing__date.startDate')).html(newDate);
                            // eslint-disable-next-line max-len
                        } else if (((endDate.getMonth() > startDate.getMonth()) || (endDate.getDate() > startDate.getDate())) && noDateError) {
                            removeEndDate();
                            newTime = `${starTimeValue.toLowerCase()} - ${endTimeValue.toLowerCase()} ${timeZone}`;
                            $(dateBlock.find('time.c-eventListing__time.startDate')).html(newTime);
                            newDate = `${startDateValue}-${endDateValue}`;

                            // eslint-disable-next-line max-len
                            newDate = `${startDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric' })} - ${endDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })}`;
                            $(dateBlock.find('time.c-eventListing__date.startDate')).html(newDate);
                        } else if (noDateError) {
                            removeEndDate();
                            newTime = `${starTimeValue.toLowerCase()} - ${endTimeValue.toLowerCase()} ${timeZone}`;
                            $(dateBlock.find('time.c-eventListing__time.startDate')).html(newTime);
                        } else {
                            console.error(`dateError!! startDate: ${startDate} | endDate: ${endDate}`);
                        }
                    }
                    (function eventListingFn() {
                        if (eventListing.length > 0) {
                            eventListing.find('.c-eventListing__informations').each(function () {
                                if ($(this).find('.c-eventListing__recorded').length === 0) {
                                    fixDateFormat($(this));
                                }
                            });
                        }
                    }());

                    // Check if there are upcoming events and hide "Sorry, there are no events to display."
                    // Also check wether there are two "Sorry, there are no events to display." messages and delete one.

                    const hideNoResultsMessage = $('.hide-no-results-message');

                    if (hideNoResultsMessage.find('.no-upcoming-events').length > 0) {
                        if (hideNoResultsMessage.find('.c-eventListing__contentAll').length > 0) {
                            $('.no-upcoming-events').addClass('hidden');
                        }
                        $('.no-upcoming-events:eq(1)').remove();
                    }

                    aemContentTabContainer.addClass('is-visible');
                }
            });
        }
    },

    displayNeedHelpPopup() {
        $(document).ready(() => {
            $('#help-link').click(function (e) {
                e.preventDefault();
                $(this).attr('data-modal-title', ACC.needHelpPopupTitle);
                $(this).attr('data-modal-content', $('.supportContent').html());
                $modal.open(this);
            });
        });
    },

    displayCallToActionPopup() {
        $(document).ready(() => {
            $('.js-callToActionModal').on('click', function (e) {
                e.preventDefault();
                // Get next element content ( for this modal )
                const content = $(this).next().html();
                const title = $(this).data('modal-title');
                // and assign it as data attribute so modal can
                // pick them up and use it.
                $(this).attr('data-modal-title', title.replace(/(<([^>]+)>)/ig, ''));
                $(this).attr('data-modal-content', content);
                // Trigger modal
                $modal.open(this);
            });
        });
    },

    needHelpPrintOnly() {
        $(document).ready(() => {
            if ($('body').hasClass('page-programDetails')) {
                const needHelpPrintHeadline = ACC.needHelpPopupTitle;
                // eslint-disable-next-line no-undef
                const content = DOMPurify.sanitize($('.supportContent').html());

                const printHelp = [
                    '<div class="needHelpPrintCont print-only">',
                    `<h4>${needHelpPrintHeadline}</h4>`,
                    content,
                    '</div>',
                ].join('\n');

                $('.magic-box__container').append(printHelp);
            }
        });
    },

    relatedItemsPrint() {
        $(document).ready(() => {
            if ($('body').is('.page-programDetails, .page-programDetailsPageV2')) {
                const relatedList = $('<ul class="link-list print-only" />');

                $('.carousel--related').find('.carouselItem__name').each(function () {
                    relatedList.append(`<li>${$(this).text()}</li>`);
                });

                $('.carousel--related').append(relatedList);
            }
        });
    },

    bindDisplayAttributeInfo() {
        $('.js-display-attribute-info').on('click', function () {
            $modal.open(this);
        });
    },

    showTabsPanel() {
        const tabsWrapper = document.querySelector('.tabs-wrapper');
        if (!tabsWrapper) return;

        ACC.product.emptyTab(tabsWrapper);
        // Don't show tabs if we don't have content.
        if (tabsWrapper.style.display !== 'block' && tabsWrapper.querySelectorAll('.tab-content').length) {
            tabsWrapper.style.display = 'block';
            ACC.product.addTabActiveClass(tabsWrapper);
            // eslint-disable-next-line no-undef
            calcWidth();
        }
    },

    emptyTab(tabsWrapper) {
        const tabContent = Array.prototype.slice.call(tabsWrapper.querySelectorAll('.tab-content'));
        // Remove empty tab
        tabContent.forEach((element) => {
            if (!element.querySelector('.tab-content__inner').innerText.trim().length) {
                const { id } = element;
                const tabTitle = tabsWrapper.querySelectorAll(`a[data-tab-id=${id}]`)[0];

                tabTitle.parentElement.remove();
                element.remove();
            }
        });
    },

    addTabActiveClass(tabsWrapper) {
        const tabContent = tabsWrapper.querySelectorAll('.tab-content');
        // Active class for tab content
        tabContent[0].classList.add('current-tab-content');
        // Active class for tab title
        tabsWrapper.querySelectorAll('a[data-tab-id]')[0].classList.add('current-tab');
    },

    bindSharePopup() {
        $('.modal-trigger__social-share').on('click', function () {
            $modal.open(this);
        });

        $(document).on('click', '#copy-share-link', () => {
            const copyPasteInput = $('input#copyInput');
            copyPasteInput.select();
            document.execCommand('copy');
            // eslint-disable-next-line no-use-before-define
            highlight(copyPasteInput);
        });

        function highlight(el) {
            el.addClass('highlight');
            setTimeout(() => {
                el.removeClass('highlight');
            }, 2000);
        }
    },

    pricingOrderingAutoExpand() {
        if ($('#pricing-ordering-content')) {
            const poContainer = $('#pricing-ordering-content > .tab-content__inner');
            const pricingGrid = $('#pricing-ordering-content');
            const fp = pricingGrid.find('.pricing-grid__list-price').first();
            const fpComponent = fp.closest('.accordion__component');
            const fpParent = fpComponent.parent('.accordion__content');
            const fpParentPrevHeader = fpParent.prev('.accordion__header');
            const triggerOne = fpComponent.find('.accordion__trigger');
            const triggerTwo = fpParentPrevHeader.find('.accordion__trigger');
            // eslint-disable-next-line max-len
            const triggerThree = fpParentPrevHeader.parents('.accordion__content').prev('.accordion__header').find('.accordion__trigger');

            triggerOne
                .add(triggerTwo)
                .add(triggerThree);

            // DOM changes complete; reveal container
            poContainer.addClass('is-visible');
        }
    },

    promoBoxCallToAction() {
        $('.js-promo-box').on('click', function () {
            const url = $(this).attr('data-promoBox-url');
            window.open(url);
        });
    },

    tieredPricingToggle() {
        $('.js-tiered-pricing-toggle').on('click', function (e) {
            e.preventDefault();
            $(this).parent().toggleClass('isToggled');
            $(this).parent().next('.pricing-grid__tiered-discounts-body').slideToggle();
        });
    },

    handleWebinarsPagination() {
        // eslint-disable-next-line max-len
        $('#aemContentTabContainer').on('click', '.event-list-nav-right, .event-list-nav-left', function webinarsPagination(event) {
            event.preventDefault();

            const HREF = this.getAttribute('href');

            $('html, body').animate({
                scrollTop: $('.c-tabs-wrapper').offset().top,
            }, 2000);

            $.get({
                url: HREF,
            }).done((data) => {
                const HTML = document.createRange().createContextualFragment(data);
                const $webinarsContentWrapper = document.querySelector('#webinars-content #aemContentTabContainer');
                const $webinarscontent = HTML.querySelector('[role="main"]');
                $webinarsContentWrapper.innerHTML = '';
                $webinarsContentWrapper.appendChild($webinarscontent);
            });
        });
    },

    preselectFormatAndFilter() {
        setTimeout(() => {
            if ($('.page-programDetailsPageV2').length) {
                if (window.location.search !== '') {
                    const paramsArray = window.location.search.substr(1).split('&');
                    paramsArray.forEach((element) => {
                        const param = element.split('=');
                        const paramName = param[0];
                        const paramValues = param[1].includes(',') ? param[1].split(',') : param[1];

                        if (paramName === 'format') {
                            $('.preferred-format__button').each(function () {
                                const format = this;
                                if (format.value === paramValues) {
                                    format.click();
                                    $('.program-page__filters-slot').addClass('filters-active');

                                    document.getElementById('js-available-formats').scrollIntoView({
                                        behavior: 'smooth',
                                    });
                                }
                            });
                        } else {
                            const facets = document.querySelectorAll('[data-group-code]');
                            const facetsGroup = [];
                            facets.forEach((facet) => {
                                if (facet.dataset.groupCode === paramName) {
                                    facetsGroup.push(facet);
                                }
                            });
                            // eslint-disable-next-line max-len,no-unused-expressions,no-use-before-define
                            typeof (paramValues) === 'string' ? selectFacet(new Array(paramValues), facetsGroup) : selectFacet(paramValues, facetsGroup);
                        }
                    });
                }

                // eslint-disable-next-line no-inner-declarations
                function selectFacet(URLparameters, facetValues) {
                    URLparameters.forEach((parameter) => {
                        for (let i = 0; i < facetValues.length; i += 1) {
                            const facetValue = facetValues[i];
                            if (facetValue.value === parameter) {
                                facetValue.click();
                            }
                        }
                    });
                }
            }
        }, 1000);
    },

    bindProductLimitSalesQuantityValidation() {
        $(document).on('input', '.js-product-qty-input', function () {
            const parentWrapper = $(this).parent();
            const errorWrapper = $(this).siblings('.help-block');
            const error = errorWrapper.find('.has-error');
            const max = $(this).data('max-order-quantity');
            const min = $(this).data('min-order-quantity');
            if (min !== undefined && min !== '' && $(this).val() < min) {
                parentWrapper.addClass('has-error');
                errorWrapper.removeClass('hidden');
                error.text($(this).data('min-order-quantity-error'));
            } else if (max !== undefined && max !== '' && $(this).val() > max) {
                parentWrapper.addClass('has-error');
                errorWrapper.removeClass('hidden');
                error.text($(this).data('max-order-quantity-error'));
            } else {
                parentWrapper.removeClass('has-error');
                errorWrapper.addClass('hidden');
            }
        });
    },

    bindCrossSellInputValidation() {
        $(document).on('focusout', '.js-cross-sell-qty-input', (e) => {
            ACC.product.validateCrossSellItem(e);
        });
    },

    validateCrossSellItem(event) {
        const qtyValue = parseInt($(event.target).val(), 10);
        if (Number.isNaN(qtyValue) || qtyValue <= 0) {
            ACC.product.disableCrossSellingAddToCartButton(event.target, true);
        } else {
            ACC.product.disableCrossSellingAddToCartButton(event.target, false);
        }
    },

    disableCrossSellingAddToCartButton(element, disabled) {
        element.parentElement.parentElement.parentElement.querySelector('.js-add-to-cart').disabled = disabled;
    },

};

$(document).ready(() => {
    // $ajaxCallEvent = true;
    ACC.product.enableAddToCartButton();
    ACC.product.showTabsPanel();
    ACC.product.pricingOrderingAutoExpand();
});
