ACC.secureacceptance = {

    _autoload: [
        'scrollToFirstInvalidField',
        ['start', $('#silentOrderPostForm').length !== 0],
        ['createSopIframe', $('#silentOrderPostForm').length === 0 && $('.page-update-payment-method').length !== 0],
    ],

    billingAddressRequiredInputFields: [
        '#address\\.townCity',
        '#address\\.postcode',
        '#address\\.firstName',
        '#address\\.surname',
        '#address\\.line1',
    ],

    billingAddressSimplifiedRequiredInputFields: [
        '#address\\.townCity',
        '#address\\.firstName',
        '#address\\.surname',
        '#address\\.line1',
    ],

    vertexRequiredFields: [
        '#address\\.townCity',
        '#address\\.region',
        '#address\\.postcode',
    ],

    resolveBillingAddressRequiredInputFields(countyIsocode) {
        if (countyIsocode) {
            const countryRequiredBillingAddressFormFields = {
                FJ: ACC.secureacceptance.billingAddressSimplifiedRequiredInputFields,
                CK: ACC.secureacceptance.billingAddressSimplifiedRequiredInputFields,
                WS: ACC.secureacceptance.billingAddressSimplifiedRequiredInputFields,
                TO: ACC.secureacceptance.billingAddressSimplifiedRequiredInputFields,
                HK: ACC.secureacceptance.billingAddressSimplifiedRequiredInputFields,
                MO: ACC.secureacceptance.billingAddressSimplifiedRequiredInputFields,
            };
            const foundValue = countryRequiredBillingAddressFormFields[countyIsocode];
            if (foundValue) {
                return foundValue;
            }
        }
        return ACC.secureacceptance.billingAddressRequiredInputFields;
    },

    billingAddressRequiredSelectFields: [
        '#address\\.country',
    ],

    paymentInfo: [
        '#card_accountNumber',
        '#ExpiryMonth',
        '#ExpiryYear',
        '#card_cvNumber',
    ],

    zipValidationCountryIso: [
        'US',
        'CA',
    ],

    taxCalculated: false,

    sopFormSrc() {
        return $('#sopFormAction').val();
    },

    start() {
        ACC.secureacceptance.attachHandlerForPlaceOrderBtn();
        ACC.secureacceptance.initSOPIframeDialog();
        ACC.secureacceptance.initDefaultPaymentMode();
        ACC.secureacceptance.attachHandlerForPaymentModeRadioBtn();
        ACC.secureacceptance.attachBillingAddressHandlers();
        ACC.secureacceptance.attachBillingAddressForm();
        ACC.secureacceptance.billingAddressSelection();
        ACC.secureacceptance.bindAddressTrim();
        ACC.secureacceptance.bindBillingAddressListeners();
        ACC.secureacceptance.addCvvHandler();
        ACC.secureacceptance.loadSopFormFragment();
        ACC.secureacceptance.createSopIframe();
        ACC.secureacceptance.validateZipCode();
        ACC.secureacceptance.validateWithCalculationStateZipAndCity();
    },

    isCheckoutZipChangeApplicable() {
        const selectedCountryValue = $('#address\\.country').val();
        return ACC.secureacceptance.zipValidationCountryIso.includes(selectedCountryValue)
            && (ACC.utils.isHedTheme() || ACC.utils.isMojoTheme())
            && ACC.config.checkoutZipChangesEnabled;
    },

    validateZipCode() {
        if (ACC.secureacceptance.isCheckoutZipChangeApplicable()) {
            $(document).on('input', '#address\\.postcode', () => {
                ACC.validation.validateFormElement('silentOrderPostForm', 'address\\.postcode');
            });
        }
    },

    validateWithCalculationStateZipAndCity() {
        let timer;
        const waitTime = 1000;
        const isShippingAddressAbsent = !$('.checkout-order-summary-list-heading').length;
        ACC.secureacceptance.vertexRequiredFields.forEach((selector) => {
            $(document).on('input', selector, () => {
                if (ACC.secureacceptance.isCheckoutZipChangeApplicable()
                    && ACC.secureacceptance.areVertexRequiredFieldsFilled()) {
                    if (ACC.validation.validateFormElement('silentOrderPostForm', 'address\\.postcode')
                        && isShippingAddressAbsent) {
                        // eslint-disable-next-line max-len
                        ACC.buttonhelper.disable('.place-order-form #placeOrder', ACC.config.calculatingTaxLabel).withSpinner();
                    }
                    clearTimeout(timer);
                    timer = setTimeout(() => {
                        if (ACC.validation.validateFormElement('silentOrderPostForm', 'address\\.postcode')) {
                            const form = ACC.secureacceptance.collectBillingAddressForm();
                            ACC.secureacceptance.validateBillingAddress(form);
                        } else {
                            $('.place-order-form #placeOrder').removeClass(ACC.buttonhelper.SPINNER_ACTIVE_STATE);
                        }
                    }, waitTime);
                }
            });
        });
    },

    validateBillingAddress(form) {
        const activeElementId = document.activeElement.id;
        const isShippingAddressAbsent = !$('.checkout-order-summary-list-heading').length;
        $.ajax({
            type: 'POST',
            url: `${ACC.config.encodedContextPath}/checkout/multi/validate-billing-address`,
            data: form,
            async: false,
            success(data) {
                ACC.secureacceptance.updateBillingAddressForm(data);
                ACC.utils.setFocusOnElementById(activeElementId);
                if (isShippingAddressAbsent
                    // eslint-disable-next-line max-len
                    && ACC.secureacceptance.isCheckoutZipChangeApplicableForFunction(!ACC.validation.hasFormErrors('silentOrderPostForm'))) {
                    ACC.secureacceptance.recalculateOrder();
                    ACC.secureacceptance.taxCalculated = true;
                }
                if (!ACC.secureacceptance.isCardSaved()
                    // eslint-disable-next-line no-mixed-operators
                    && ACC.secureacceptance.shouldPlaceOrderButtonBeEnabled()
                    // eslint-disable-next-line no-mixed-operators
                    || ACC.secureacceptance.isCardSaved()) {
                    ACC.buttonhelper.enable('.place-order-form #placeOrder');
                } else {
                    ACC.buttonhelper.disable('.place-order-form #placeOrder', ACC.config.placeOrderButtonLabel);
                    $('.place-order-form #placeOrder').removeClass(ACC.buttonhelper.SPINNER_ACTIVE_STATE);
                    ACC.secureacceptance.scrollToFirstInvalidField();
                }
            },
        });
    },

    formatCanadaPostalCode() {
        if ((ACC.utils.isHedTheme() || ACC.utils.isMojoTheme())
            && ACC.config.checkoutZipChangesEnabled) {
            ACC.address.formatCanadaPostalCode();
        }
    },

    isCheckoutZipChangeApplicableForFunction(booleanFunction) {
        const countryISo = document.getElementById('address.country').value;
        if (ACC.secureacceptance.zipValidationCountryIso.includes(countryISo)
            && (ACC.utils.isHedTheme() || ACC.utils.isMojoTheme())) {
            return ACC.config.checkoutZipChangesEnabled ? booleanFunction : true;
        }
        return true;
    },

    recalculateOrder(recalculateOrderDeferred) {
        const updateOrderTotals = function () {
            const form = ACC.secureacceptance.collectBillingAddressForm();
            $.ajax({
                url: `${ACC.config.encodedContextPath}/checkout/multi/orderTotals`,
                dataType: 'html',
                data: form,
                async: true,
                success(data) {
                    if (ACC.checkoutPreValid) {
                        ACC.checkoutPreValid.state.hasCalculatedTaxes = true;
                        ACC.checkoutPreValid.togglePlaceBtn(ACC.checkoutPreValid.getCardState());
                    }

                    const voucherItem = $(data).find('.c-cart-voucher__item');

                    // update order totals displayed to the customer
                    $('.orderTotals').html(data);
                    if (voucherItem.length > 0) {
                        ACC.cartvoucher.showSuccessMessage();
                    }
                    $('.taxMessage').html('Estimated tax');
                    ACC.secureacceptance.reloadSopForm();
                    if (recalculateOrderDeferred) {
                        recalculateOrderDeferred.resolve();
                    }
                    // bind country label to phonenumber
                    ACC.phonemasking.bind($('#address\\.phone'), $('#address\\.country').val());
                },
                error(xhr, status, error) {
                    console.error(error.message);
                    window.location.reload();
                },
            });
        };

        const updateTopValidationMessageAndOrderTotals = function (result) {
            const $errorMessage = $(result).filter('#topErrorMessage');
            if ($errorMessage.length) {
                const message = $errorMessage.val();
                const globalMessageRequest = {
                    globalMessages: [{
                        messageHolder: 'accErrorMsgsInline',
                        messages: [message],
                    }],
                };
                ACC.globalmessages.createGlobalMessage(globalMessageRequest);
            }
            updateOrderTotals();
        };

        if (ACC.config.isZipCityMismatchGlobalAlertEnabled) {
            if (ACC.secureacceptance.isCheckoutZipChangeApplicable()) {
                updateTopValidationMessageAndOrderTotals();
            } else {
                ACC.secureacceptance.updateBillingAddress(updateTopValidationMessageAndOrderTotals);
            }
        } else if (ACC.secureacceptance.isCheckoutZipChangeApplicable()) {
            updateOrderTotals();
        } else {
            ACC.secureacceptance.updateBillingAddress(updateOrderTotals);
        }
        if (ACC.config.showCalculatingTaxesLabel) {
            ACC.buttonhelper.ariaDisable('.place-order-form #placeOrder', ACC.config.calculatingTaxLabel).withSpinner();
        } else {
            ACC.buttonhelper.ariaDisable('.place-order-form #placeOrder', ACC.config.placeOrderButtonLabel);
        }
    },

    reloadOrderTotals() {
        const form = ACC.secureacceptance.isSavedCard()
            ? ACC.secureacceptance.collectSavedCardAddress()
            : ACC.secureacceptance.collectBillingAddressForm();
        $.ajax({
            url: `${ACC.config.encodedContextPath}/checkout/multi/orderTotals`,
            data: form,
            dataType: 'html',
            async: false,
            success(data) {
                $('.orderTotals').html(data);
                ACC.secureacceptance.reloadSopForm();
            },
            error(xhr, status, error) {
                console.error(error.message);
                window.location.reload();
            },
        });
    },

    bindBillingAddressListeners() {
        // check if shipping address is absent
        const isShippingAddressAbsent = !$('.checkout-order-summary-list-heading').length;
        const recalculateOrder = ACC.secureacceptance.recalculateOrderWithDecoratorsCall();
        // eslint-disable-next-line max-len
        const requiredFieldsVertexTax = ACC.secureacceptance.isCheckoutZipChangeApplicable() ? ACC.secureacceptance.vertexRequiredFields : ACC.secureacceptance.billingAddressRequiredInputFields;
        ACC.secureacceptance.formatCanadaPostalCode();
        if (ACC.validation.hasFormErrors('silentOrderPostForm')
            && ACC.secureacceptance.isCheckoutZipChangeApplicable()) {
            return;
        }
        if (isShippingAddressAbsent) {
            ACC.secureacceptance.billingAddressRequiredSelectFieldsConcat().forEach((selector) => {
                $(selector).change(recalculateOrder);
            });
        }
        ACC.secureacceptance.billingAddressRequiredInputFields.forEach((selector) => {
            $(selector).on('focusout', () => {
                $('#silentOrderPostForm').validate().element(selector);
            });
            $(selector).on('input focusout', (e) => {
                const addressDoctorUseThisAddressEnabled = !$('.js-address-doctor-use-this-address').is(':disabled');
                if (isShippingAddressAbsent) {
                    ACC.buttonhelper.ariaDisable('.place-order-form #placeOrder', ACC.config.placeOrderButtonLabel);
                    if (ACC.checkoutPreValid
                        // eslint-disable-next-line max-len
                        && ACC.secureacceptance.isCheckoutZipChangeApplicableForFunction(ACC.secureacceptance.vertexRequiredFields.includes(selector))
                        && e.type === 'input') {
                        ACC.checkoutPreValid.state.hasCalculatedTaxes = false;
                    }
                } else if (addressDoctorUseThisAddressEnabled) {
                    ACC.buttonhelper.disable('.place-order-form #placeOrder', ACC.config.placeOrderButtonLabel);
                } else {
                    ACC.buttonhelper.ariaDisable('.place-order-form #placeOrder', ACC.config.placeOrderButtonLabel);
                }
                if (!ACC.secureacceptance.isCardSaved()
                    // eslint-disable-next-line no-mixed-operators
                    && ACC.secureacceptance.shouldPlaceOrderButtonBeEnabled()
                    // eslint-disable-next-line no-mixed-operators
                    || ACC.secureacceptance.isCardSaved()) {
                    ACC.buttonhelper.enable('.place-order-form #placeOrder');
                } else {
                    $('.taxMessage').html('Estimated tax');
                }
            });
        });
        requiredFieldsVertexTax.forEach((selector) => {
            // eslint-disable-next-line max-len
            const eventType = ACC.utils.isMojoTheme() || (ACC.utils.isHedTheme() && ACC.config.checkoutZipChangesEnabled) ? 'input' : 'focusout';
            $(selector).on(eventType, () => {
                // eslint-disable-next-line max-len
                if (($('#placeOrder').hasClass('button-disabled') || ACC.config.checkoutZipChangesEnabled)
                    // eslint-disable-next-line max-len
                    && ACC.secureacceptance.isCheckoutZipChangeApplicableForFunction(!ACC.validation.hasFormErrors('silentOrderPostForm'))
                    // eslint-disable-next-line max-len
                    && ACC.secureacceptance.isCheckoutZipChangeApplicableForFunction(ACC.secureacceptance.areVertexRequiredFieldsFilled())) {
                    // eslint-disable-next-line max-len
                    if ((isShippingAddressAbsent && !ACC.secureacceptance.isCheckoutZipChangeApplicable())
                        || !ACC.secureacceptance.taxCalculated) {
                        recalculateOrder();
                    }
                }
            });
        });
    },

    recalculateOrderWithDecoratorsCall() {
        return ACC.secureacceptance.decorateWithExecutionNoiseHandling(
            ACC.secureacceptance.decorateWithBillingAddressRequiredFieldsCheck(
                ACC.secureacceptance.decorateWithBillingAddressChangesCheck(
                    ACC.secureacceptance.wrapWithOneByOneExecutionHandling(
                        ACC.secureacceptance.recalculateOrder,
                    ),
                ),
            ),
            ACC.secureacceptance.getRecalculateOrderNoiseTimeout(),
        );
    },

    isValueTrue(selector) {
        return !!$(selector).val();
    },

    paymentInfoFieldsSetCheck() {
        return ACC.secureacceptance.paymentInfo.every(ACC.secureacceptance.isValueTrue);
    },

    isCardSaved() {
        return $('#savedCard-newCard').length && !$('#savedCard-newCard').is(':checked');
    },

    shouldPlaceOrderButtonBeEnabled() {
        return ACC.checkoutPreValid && ACC.checkoutPreValid.state.terms && ACC.checkoutPreValid.state.hasCalculatedTaxes
            && ACC.secureacceptance.billingInfoFieldsSetCheck()
            && ACC.secureacceptance.paymentInfoFieldsSetCheck()
            && ACC.validation.validateFormElement('silentOrderPostForm', 'address\\.postcode')
            // eslint-disable-next-line max-len
            && ACC.secureacceptance.isCheckoutZipChangeApplicableForFunction(ACC.secureacceptance.isBillingAddressFormFilled())
            // eslint-disable-next-line max-len
            && ACC.secureacceptance.isCheckoutZipChangeApplicableForFunction(!ACC.validation.hasFormErrors('silentOrderPostForm'));
    },

    isBillingAddressFormFilled() {
        let isBillingAddressFormFilled = true;
        ACC.secureacceptance.billingAddressRequiredInputFields.forEach((selector) => {
            if (!$(selector).val()) {
                isBillingAddressFormFilled = false;
            }
        });
        return isBillingAddressFormFilled;
    },

    billingAddressRequiredSelectFieldsConcat() {
        if ($('#address\\.region').length !== 0) {
            return ACC.secureacceptance.billingAddressRequiredSelectFields.concat('#address\\.region');
        }
        return ACC.secureacceptance.billingAddressRequiredSelectFields;
    },

    billingInfoFieldsSetCheck() {
        const baseFields = ACC.secureacceptance.resolveBillingAddressRequiredInputFields($('#address\\.country').val());
        // eslint-disable-next-line max-len
        return ACC.secureacceptance.billingAddressRequiredSelectFieldsConcat().concat(baseFields).every(ACC.secureacceptance.isValueTrue);
    },

    decorateWithBillingAddressRequiredFieldsCheck(func) {
        function areAllRequiredFieldsSet() {
            return ACC.secureacceptance.billingInfoFieldsSetCheck();
        }

        return function () {
            if (areAllRequiredFieldsSet()) {
                func();
            }
        };
    },

    decorateWithBillingAddressChangesCheck(func) {
        return function () {
            func();
        };
    },

    /*
      Designed to wrap event handlers and take care that the events will be executed one by one in sequence.
     */
    wrapWithOneByOneExecutionHandling(func) {
        let promise = null;

        function isPromiseResolved() {
            return promise === null || promise.state() !== 'pending';
        }

        function createDeferredExecution() {
            const deferred = $.Deferred();
            promise = deferred.promise();
            return deferred;
        }

        function execute() {
            if (isPromiseResolved()) {
                func(createDeferredExecution());
                return;
            }
            promise.always(execute);
        }

        return execute;
    },

    /*
      Designed to decorate event handlers that can happen too frequently but in fact only last one does matter.
      For example form validation or order recalculation that happens on form input.
     */
    decorateWithExecutionNoiseHandling(func, timeout) {
        let lastExecutionAttemptNumber = 0;

        function executeIfNoNewExecutionAttemptOccurred(executionAttemptNumber) {
            if (executionAttemptNumber === lastExecutionAttemptNumber
                // eslint-disable-next-line max-len
                && !ACC.secureacceptance.isCheckoutZipChangeApplicable()) {
                func();
            }
        }

        return function () {
            lastExecutionAttemptNumber += 1;
            setTimeout(executeIfNoNewExecutionAttemptOccurred, timeout, lastExecutionAttemptNumber);
        };
    },

    getRecalculateOrderNoiseTimeout() {
        if (ACC.config && ACC.config.recalculateOrderNoiseTimeout) {
            return ACC.config.recalculateOrderNoiseTimeout;
        }

        return 500;
    },

    identifyBillingAddressFormContainer() {
        if (window.location.href.indexOf('/invoice-details') >= 0) {
            return (window.location.href.indexOf('/checkout/quote/create') >= 0)
                ? $('#quoteBillingAddressFormContainer')
                : $('#billingAddressFormContainer');
        }

        if ((window.location.href.indexOf('/checkout/quote/create') >= 0)) {
            return $('#quoteBillingAddressFormContainer');
        }

        return $('.payByPORadioBtn').is(':checked')
            ? $('#learnerSopPaymentDetailsForm')
            : $('#silentOrderPostForm');
    },

    collectBillingAddressForm() {
        const form = {};
        const component = ACC.secureacceptance.identifyBillingAddressFormContainer();

        if (!ACC.utils.isMojoTheme()) {
            ACC.secureacceptance.performTrimBeforeSubmit(component);
        }
        form.billTo_country = component.find($('#address\\.country option:selected')).val();
        form.billTo_firstName = component.find($('#address\\.firstName')).val();
        form.billTo_lastName = component.find($('#address\\.surname')).val();
        form.billTo_street1 = component.find($('#address\\.line1')).val();
        form.billTo_street2 = component.find($('#address\\.line2')).val();
        form.billTo_street3 = component.find($('#address\\.line3')).val();
        form.billTo_city = component.find($('#address\\.townCity')).val();
        form.billTo_postalCode = component.find($('#address\\.postcode')).val();
        form.billTo_titleCode = component.find($('#address\\.title option:selected')).val();
        form.billTo_phoneNumber = ACC.phonemasking.collectPhoneNumber(component.find($('#address\\.phone')));
        form.billTo_state = component.find($('#address\\.region option:selected')).val();
        form.billTo_email = component.find($('#address\\.email')).val();
        form.useDeliveryAddress = component.find('#useDeliveryAddress').prop('checked');
        form.savePaymentInfo = component.find('#card_save_payment_info').prop('checked');
        form.isNewAddress = component.find($('#isNewAddress')).val();
        form.saveInAddressBook = component.find('#saveBillingAddressInMyAddressBook').prop('checked');

        return form;
    },

    collectSavedCardAddress() {
        const form = {};
        form.billTo_country = $('.savedCardRadioBtn', '.c-saved-card.selected').data('countryisocode');
        form.billTo_firstName = $('.savedCardRadioBtn', '.c-saved-card.selected').data('firstname');
        form.billTo_lastName = $('.savedCardRadioBtn', '.c-saved-card.selected').data('lastname');
        form.billTo_street1 = $('.savedCardRadioBtn', '.c-saved-card.selected').data('line1');
        form.billTo_street2 = $('.savedCardRadioBtn', '.c-saved-card.selected').data('line2');
        form.billTo_street3 = $('.savedCardRadioBtn', '.c-saved-card.selected').data('line3');
        form.billTo_city = $('.savedCardRadioBtn', '.c-saved-card.selected').data('town');
        form.billTo_postalCode = $('.savedCardRadioBtn', '.c-saved-card.selected').data('postalcode');
        form.billTo_phoneNumber = $('.savedCardRadioBtn', '.c-saved-card.selected').data('phonenumber');
        form.billTo_state = $('.savedCardRadioBtn', '.c-saved-card.selected').data('regionisocode');
        form.billTo_email = $('.savedCardRadioBtn', '.c-saved-card.selected').data('email');

        return form;
    },

    isImportedBillingAddress() {
        const form = ACC.secureacceptance.collectBillingAddressForm();
        // eslint-disable-next-line max-len
        const isImportedAddress = form.billTo_firstName === '' || form.billTo_lastName === '' || form.billTo_street1 === '';
        const isExistingAddress = ACC.secureacceptance.isSavedAddress() || ACC.secureacceptance.useDeliveryAddress();
        return isExistingAddress && isImportedAddress;
    },

    updateBillingAddress(onDoneFunction, onSuccessFunction, reloadOnError, requiresValidation) {
        if (ACC.config.billingAddressUrl === null || ACC.config.billingAddressUrl === '') {
            return;
        }

        const form = ACC.secureacceptance.collectBillingAddressForm();
        const isExistingAddress = ACC.secureacceptance.isSavedAddress() || ACC.secureacceptance.useDeliveryAddress();
        if (!ACC.config.isAssessmentsCheckoutV2Enabled && ACC.secureacceptance.isImportedBillingAddress()) {
            ACC.secureacceptance.showErrorMessage('checkout.multi.billing.address.invalid.message');
            ACC.buttonhelper.enable('.place-order-form #placeOrder');
            return;
        }
        form.isNewAddress = !isExistingAddress;

        if (!isExistingAddress) {
            sessionStorage.setItem('newAddressUsed', true);
        }

        $.ajax({
            type: 'POST',
            url: ACC.config.encodedContextPath + ACC.config.billingAddressUrl,
            data: form,
            dataType: 'html',
            async: false,
            success: onSuccessFunction,
            error(xhr, status, error) {
                console.error(error.message);
                if (reloadOnError) {
                    window.location.reload();
                }
            },
        }).done(() => {
            if (onDoneFunction instanceof Function) {
                onDoneFunction();
            } else if (requiresValidation) {
                ACC.addressDoctor.checkShowingSuggestedAddressesPopup(
                    form,
                    '/checkout/multi/validate/showSuggestedAddressesPopup',
                    false,
                );
            }
        });
    },

    isAddressSearchFieldInvalid() {
        if (ACC.secureacceptance.isSavedAddress() && $("#searchAddressForm input[name='addressId']").val() === '') {
            $('.address-search').addClass('has-error');
            $('.address-search .help-block').removeClass('hidden');
            ACC.buttonhelper.enable('.place-order-form #placeOrder');
            return true;
        }
        return false;
    },

    checkOrderStatusInterval(cartGuid, timeout) {
        setInterval(() => {
            ACC.secureacceptance.checkOrderStatus(cartGuid);
        }, timeout);
    },

    checkOrderStatus(cartGuid) {
        $.ajax({
            url: `${ACC.config.encodedContextPath}/checkout/payment/sa/isorderplaced`,
            cache: false,
            dataType: 'json',
            data: {
                cartGuid,
            },
            success(orderCode) {
                if (orderCode) {
                    window.location = `${ACC.config.encodedContextPath}/checkout/orderConfirmation/${orderCode}`;
                }
            },
        });
    },

    termsAndConditionsChecked() {
        if (ACC.checkoutPreValid) {
            const alert = $('.place-order-form .help-block');
            const formGroup = $('.place-order-form .form-group');
            if (ACC.checkoutPreValid.state.terms === true) {
                alert.addClass('hidden');
                formGroup.removeClass('has-error');
                return true;
            }
            alert.removeClass('hidden');
            formGroup.addClass('has-error');
            return false;
        }

        return true;
    },

    showSavedCardsError() {
        const alert = $('#savedPaymentCards .saved-card-error');
        alert.removeClass('hidden');
        alert.addClass('has-error');
        ACC.secureacceptance.scrollToFirstInvalidField();
    },

    initSOPIframeDialog() {
        const $sopIframeCbox = $('#sopIframeCbox');
        const $body = $('body');
        let $width = $(window).width();
        let $height = $(window).height();

        if ($width > 767) {
            $width = 570;
            $height = 500;
        }

        if ($sopIframeCbox) {
            $sopIframeCbox.dialog({
                title: 'Payment Details',
                autoOpen: false,
                width: $width,
                height: $height,
                draggable: false,
                resizable: false,
                dialogClass: 'no-close',
                modal: true,
                open() {
                    $body.addClass('stop-scrolling');
                },
                close() {
                    $body.removeClass('stop-scrolling');
                },
            });
        }
    },

    startSOPIframeTimeout($sopRequestIframe) {
        let previousSOPIframeTimeout = null;
        $sopRequestIframe.on('load', () => {
            if (previousSOPIframeTimeout) {
                clearTimeout(previousSOPIframeTimeout);
            }

            previousSOPIframeTimeout = setTimeout(() => {
                $('#sopIframeCbox').dialog('open');
            }, 3000);
        });
    },

    initDefaultPaymentMode() {
        const creditCardMode = $('#paymentMode_1_creditcard');
        if (creditCardMode) {
            creditCardMode.prop('checked', true);
        }
    },

    attachHandlerForPaymentModeRadioBtn() {
        const initKlarnaWidget = function () {
            if (!KLARNA.loaded) {
                // Do not try to load it more than once.
                KLARNA.loaded = true;
                $.ajax({
                    url: `${ACC.config.encodedContextPath}/checkout/payment/klarna/createSession`,
                    cache: false,
                    dataType: 'json',
                    data: {
                        CSRFToken: ACC.config.CSRFToken,
                    },
                    method: 'POST',
                    success(resp) {
                        if (resp.success) {
                            KLARNA.sessionId = resp.data.klarnaSessionId;
                            if (KLARNA.sessionId) {
                                // Only if session was created - initialize Klarna widget.
                                // eslint-disable-next-line no-unused-vars
                                $.getScript(KLARNA.sdkURL, (data, textStatus, jqxhr) => {
                                    console.log('Klarna SDK was loaded');
                                    $('#klarna_load_progress').remove();
                                });
                            }
                        }
                    },
                });
            }
        };
        const disableVisaCheckoutBtn = function () {
            $('.placeOrderBtnDiv').show();
            $('.visaCheckoutBtnDiv').hide();
        };
        const enableVisaCheckoutBtn = function () {
            $('.placeOrderBtnDiv').hide();
            $('.visaCheckoutBtnDiv').show();
        };
        const paymentModeBtn = $('input[name=paymentMode]');

        if (paymentModeBtn) {
            // eslint-disable-next-line no-unused-vars
            paymentModeBtn.on('click', (e) => {
                const creditCardDetails = $('.creditCardDetails');
                const klarnaContainer = $('#klarna_container');

                // eslint-disable-next-line no-use-before-define
                disableVisaCheckoutBtn();
                klarnaContainer.hide();
                creditCardDetails.hide();

                const selectedPaymentType = $("input[type='radio'][name='paymentMode']:checked")
                    .attr('data-payment-type');

                // eslint-disable-next-line default-case
                switch (selectedPaymentType) {
                case 'CREDIT_CARD':
                    creditCardDetails.show();
                    break;
                case 'VISA_CHECKOUT':
                    // eslint-disable-next-line no-use-before-define
                    enableVisaCheckoutBtn();
                    break;
                case 'KLI':
                    klarnaContainer.show();
                    // eslint-disable-next-line no-use-before-define
                    initKlarnaWidget();
                    break;
                }
            });
        }
    },

    handleOrderPlaceAdditionalData() {
        // Nothing here, can be overrided to add custom logic
    },

    performTrimBeforeSubmit(component) {
        // eslint-disable-next-line no-unused-vars
        const fields = component.find($('input[id^="address"]')).each(function () {
            this.value = this.value.trim();
        });
        return true;
    },

    scrollToFirstInvalidField() {
        const errorElements = $('.has-error');
        const errorVisible = $('.has-error').is(':visible');
        if (errorElements.length && errorVisible) {
            if (ACC.utils.isMojoTheme()) {
                document.querySelectorAll('.has-error').forEach((elem) => {
                    const errorId = elem.querySelectorAll('.help-block > span')[0].getAttribute('id');
                    if (elem.getElementsByTagName('input')[0] !== undefined) {
                        elem.getElementsByTagName('input')[0].setAttribute('aria-describedby', errorId);
                    } else {
                        elem.getElementsByTagName('select')[0].setAttribute('aria-describedby', errorId);
                    }
                    errorElements[0].getElementsByTagName('input')[0].focus();
                });
            }
            $('html, body').animate({
                scrollTop: $(errorElements[0]).offset().top,
            }, 500);
        }
    },

    submitHOPForm() {
        if (ACC.secureacceptance.termsAndConditionsChecked()) {
            const hopForm = $('#hopRequestForm');
            hopForm.submit();
        }
    },

    submitSOPForm() {
        if (ACC.secureacceptance.isCreditCardPayment()
            && ACC.secureacceptance.isValidCardData(true)
            && ACC.secureacceptance.termsAndConditionsChecked()
            && ACC.secureacceptance.isValidBillingAddressData()) {
            const sopForm = ACC.secureacceptance.getSopForm();

            if ($('.savedCardRadioBtn:checked').attr('id') === 'savedCard-newCard'
                || typeof $('.savedCardRadioBtn:checked').attr('id') === 'undefined') {
                ACC.secureacceptance.populateFirstAndLastNameForSopForm(sopForm);
                sopForm.find('#card_type').val(ACC.secureacceptance.getCardType());
                sopForm.find('#card_number').val(ACC.secureacceptance.getCardNumber());
                sopForm.find('#card_expiry_date').val(ACC.secureacceptance.getCardExpireDate());
                sopForm.find('#card_cvn').val(ACC.secureacceptance.getCardCvnNumber());
                sopForm.find('#bill_to_address_line1').val(ACC.secureacceptance.getBillToFieldValue('street1'));
                sopForm.find('#bill_to_address_line2').val(ACC.secureacceptance.getBillToFieldValue('street2'));
                sopForm.find('#merchant_defined_data3').val(ACC.secureacceptance.getBillToFieldValue('street3'));
                sopForm.find('#bill_to_address_city').val(ACC.secureacceptance.getBillToFieldValue('city'));
                sopForm.find('#bill_to_address_country').val(ACC.secureacceptance.getBillToSelectValue('country'));
                sopForm.find('#bill_to_address_postal_code')
                    .val(ACC.secureacceptance.getBillToFieldValue('postalCode'));
                sopForm.find('#bill_to_address_state').val(ACC.secureacceptance.getBillToSelectValue('state'));
                sopForm.find('#bill_to_phone')
                    .val(ACC.phonemasking.collectPhoneNumber($('input[name=billTo_phoneNumber]')));
                const savePaymentInfoCheckbox = $('#card_save_payment_info');
                if (savePaymentInfoCheckbox.length) {
                    sopForm.find('#merchant_defined_data98').val(savePaymentInfoCheckbox.prop('checked'));
                }

                if (ACC.config.isOfacEnabled || ACC.config.isSiftEnabled) {
                    ACC.secureacceptance.siftOfacConfirmation();
                } else {
                    ACC.secureacceptance.submitForm(sopForm);
                }
            } else {
                const savedCardId = $('.savedCardRadioBtn:checked').data('savedcardid');
                const cardCvv = $(`#savedCardCvvNumber-${savedCardId}`).val();
                const subscriptionId = $('.savedCardRadioBtn:checked').val();
                const transactionUuid = sopForm.find('#transaction_uuid').val();
                const signedDateTime = sopForm.find('#signed_date_time').val();
                const savedCard = {
                    cardId: savedCardId, subscriptionId, cardCvv, transactionUuid, signedDateTime,
                };
                $.ajax({
                    type: 'POST',
                    url: `${ACC.config.encodedContextPath}/checkout/payment/sa/updateRequest`,
                    contentType: 'application/json',
                    data: JSON.stringify(savedCard),
                    dataType: 'json',
                    async: false,
                    success(data) {
                        sopForm.find('#signature').val($.parseJSON(data).signature);
                        sopForm.find('#transaction_type').val('authorization');
                        sopForm.find('#card_cvn').val(cardCvv);

                        /* need to explicitly send this in order for cybersource to pass it back in the response
                           with the same format including the country code prefix; for some reason cybersource drops
                           the country code prefix when using saved card and causes place order to fail */
                        sopForm.find('#bill_to_phone')
                            .val($('.savedCardRadioBtn', '.c-saved-card.selected').data('phonenumber'));

                        if (subscriptionId.length) {
                            sopForm.find('#payment_token').val(subscriptionId);
                        }

                        if (ACC.config.isOfacEnabled || ACC.config.isSiftEnabled) {
                            ACC.secureacceptance.siftOfacConfirmation();
                        } else {
                            ACC.secureacceptance.submitForm(sopForm);
                        }
                    },
                    error(xhr, status, error) {
                        console.error(error.message);
                        const messageKey = 'checkout.place.order.payment.saved.card.error';
                        const globalMessageRequest = {
                            globalMessages: [{
                                messageHolder: 'accErrorMsgs',
                                messages: [messageKey],
                            }],
                        };
                        ACC.globalmessages.createGlobalMessage(globalMessageRequest);
                        ACC.buttonhelper.enable('.place-order-form #placeOrder');
                    },
                });
            }
            return;
        }

        ACC.buttonhelper.enable('.place-order-form #placeOrder');
    },

    populateFirstAndLastNameForSopForm(sopForm) {
        let firstName;
        let lastName;
        if (ACC.config.isAssessmentsCheckoutV2Enabled) {
            firstName = $('#newCreditCardFormId').find('input[name=cardOwnerFirstName]').val();
            lastName = $('#newCreditCardFormId').find('input[name=cardOwnerLastName]').val();
        } else {
            firstName = ACC.secureacceptance.getBillToFieldValue('firstName');
            lastName = ACC.secureacceptance.getBillToFieldValue('lastName');
        }
        sopForm.find('#bill_to_forename').val(firstName);
        sopForm.find('#bill_to_surname').val(lastName);
    },

    submitForm(sopForm) {
        sopForm.submit();
        const sopRequestIframe = $('#sopRequestIframe');
        const cartGuid = sopRequestIframe.contents().find('#cart_guid').val();
        ACC.secureacceptance.checkOrderStatusInterval(cartGuid, 10000);
        if (ACC.config.isSopIframeEnabled) {
            ACC.secureacceptance.startSOPIframeTimeout(sopRequestIframe);
        }
    },

    placeOrderWithPayPal() {
        if (ACC.secureacceptance.termsAndConditionsChecked()) {
            $('.checkout-paymentmethod').after(`<form id="paypalPlaceOrder" action="${ACC.config.encodedContextPath
            }/checkout/payment/paypal/startFlow" method="get"></form>`);
            $('#paypalPlaceOrder').submit();
        }
    },

    placeOrderWithInternal() {
        $('.checkout-paymentmethod').after(`<form id="internalPlaceOrder" action="${ACC.config.encodedContextPath
        }/checkout/payment/internal/placeInternalOrder" method="post">`
                 + `<input type="hidden" name="CSRFToken" value="${ACC.config.CSRFToken}" /> `
                            + '</form>');
        $('#placeOrderInternal').addClass('button-disabled');
        $('#internalPlaceOrder').submit();
    },

    isCreditCardPayment() {
        return ACC.secureacceptance.getPaymentType() === 'CREDIT_CARD';
    },

    isValidBillingAddressData() {
        ACC.secureacceptance.scrollToFirstInvalidField();
        return ACC.secureacceptance.isSavedCard()
            || ($('#isSuccess').val() === 'true')
            || ACC.secureacceptance.isSavedAddress()
            || ACC.secureacceptance.useDeliveryAddress();
    },

    updateBillingAddressForm(data) {
        $('#billingAddressFormContainer').html(data);
        ACC.utils.preselectValue('#address\\.country');
        const addressCountry = $('#address\\.country');
        ACC.phonemasking.bind($('#address\\.phone'), addressCountry ? addressCountry.val() : null);
        ACC.secureacceptance.bindBillingAddressListeners();
        if ($('#isCheckoutV2PersonalContextAndDigitalOnly').val() === 'true') {
            ACC.secureacceptance.reloadOrderTotals();
        }
    },

    cartValidation(successFunction, failFunction, dataToSend) {
        $.ajax({
            type: 'POST',
            async: false,
            headers: {
                'Content-Type': 'application/json',
                CSRFToken: ACC.config.CSRFToken,
            },
            url: `${ACC.config.encodedContextPath}/checkout/validate/cart`,
            data: JSON.stringify(dataToSend),
        }).done((data) => {
            if (data.decision) {
                successFunction();
            } else {
                failFunction(data);
            }
        }).fail((xhr, status, error) => {
            console.log(error.message);
            successFunction();
        });
    },

    showCartValidationError(data) {
        const messageKey = data.errorCode;
        const messageAttribute = data.supportLink;
        const globalMessageRequest = {
            globalMessages: [{
                messageHolder: 'accErrorMsgs',
                messages: [messageKey],
                attributes: [messageAttribute],
            }],
        };
        ACC.globalmessages.removeGlobalMessages();
        ACC.globalmessages.createGlobalMessage(globalMessageRequest);
    },

    showCartValidationErrorForCreditCard(data) {
        ACC.secureacceptance.showCartValidationError(data);
        const placeOrderButton = $('.place-order-form #placeOrder');
        ACC.secureacceptance.showDisabledButton(placeOrderButton);
        ACC.secureacceptance.reloadSopForm();
    },

    showDisabledButton(placeOrderButton) {
        if (document.querySelector('.page-mojo-checkout')) {
            ACC.secureacceptance.clearPaymentData();
            ACC.buttonhelper.disable(placeOrderButton, ACC.config.placeOrderButtonLabel);
            placeOrderButton.removeClass(ACC.buttonhelper.SPINNER_ACTIVE_STATE);
        } else {
            ACC.buttonhelper.enable(placeOrderButton);
            placeOrderButton.blur();
        }
    },

    clearPaymentData() {
        ACC.secureacceptance.paymentInfo.forEach((input) => {
            $(input).val('');
        });
    },

    siftOfacConfirmation() {
        const sopForm = ACC.secureacceptance.getSopForm();

        ACC.secureacceptance.cartValidation(
            () => ACC.secureacceptance.submitForm(sopForm),
            ACC.secureacceptance.showCartValidationErrorForCreditCard,
            ACC.secureacceptance.getFormForSiftOfacOrderCheck(),
        );
    },

    siftOfacConfirmationForPayByPo() {
        ACC.secureacceptance.cartValidation(
            () => $('#placeOrderForm1').submit(),
            ACC.secureacceptance.showCartValidationError,
            {},
        );
    },

    getFormForSiftOfacOrderCheck() {
        let form = {};
        const savedCardId = $('.savedCardRadioBtn:checked').data('savedcardid');
        ACC.buttonhelper.ariaDisable('.place-order-form #placeOrder').withSpinner();
        if (ACC.secureacceptance.isSavedCard() && ACC.config.isUnifiedWalletEnabled) {
            form = ACC.config.isUnifiedWalletEnabled
                ? ACC.unifiedWallet.setBillingAddressForSavedCard(GLOBAL_PAYMENT_DATA, savedCardId)
                : ACC.secureacceptance.collectBillingAddressForm();
            form.cartNumberMasked = ACC.unifiedWallet.getPaymentCardMasked(GLOBAL_PAYMENT_DATA, savedCardId);
        } else {
            form = ACC.secureacceptance.isSavedCard()
                ? ACC.secureacceptance.collectSavedCardAddress()
                : ACC.secureacceptance.collectBillingAddressForm();
            form.cartNumberMasked = ACC.secureacceptance.isSavedCard()
                ? $('.savedCardRadioBtn', '.c-saved-card.selected').data('cardnumber')
                : ACC.secureacceptance.encryptCardNumber(ACC.secureacceptance.getCardNumber());
            if (ACC.config.isAssessmentsCheckoutV2Enabled && !ACC.secureacceptance.isSavedCard()) {
                form.billTo_firstName = $('#newCreditCardFormId').find('input[name=cardOwnerFirstName]').val();
                form.billTo_lastName = $('#newCreditCardFormId').find('input[name=cardOwnerLastName]').val();
            }
        }
        return form;
    },

    encryptCardNumber: (cardNumber) => {
        return `${cardNumber.substring(0, 6)}******${cardNumber.substring(cardNumber.length - 4)}`;
    },

    placeOrderWithCard() {
        if ($('#hopRequestForm').length) {
            ACC.secureacceptance.submitHOPForm();
        } else if (ACC.secureacceptance.isSavedCard()) {
            const placeOrderSelector = '.place-order-form #placeOrder';
            const calculatingTaxesMessage = $(placeOrderSelector).data('alternate-message');

            // eslint-disable-next-line no-unused-expressions
            calculatingTaxesMessage ? ACC.buttonhelper.ariaDisable(placeOrderSelector, calculatingTaxesMessage)
                : ACC.buttonhelper.ariaDisable(placeOrderSelector);

            ACC.secureacceptance.submitSOPFormWithCartCheck();
        } else if (!ACC.secureacceptance.useDeliveryAddress() && ACC.secureacceptance.isSavedAddress()) {
            ACC.buttonhelper.ariaDisable('.place-order-form #placeOrder');
            ACC.secureacceptance.submitFormWithSearchedBillingAddress();
        } else {
            ACC.buttonhelper.ariaDisable('.place-order-form #placeOrder').withSpinner();

            if (ACC.config.billingAddressDoctorEnabled && !ACC.secureacceptance.useDeliveryAddress()) {
                ACC.secureacceptance.submitSOPFormWithCartCheck();
            } else {
                ACC.secureacceptance.updateBillingAddress(
                    ACC.secureacceptance.submitSOPFormWithCartCheck,
                    ACC.secureacceptance.updateBillingAddressForm,
                    true,
                );
            }
        }
    },

    isSavedCard() {
        return $('#savedCard-newCard').length && !$('#savedCard-newCard').is(':checked');
    },

    populateAddressId(input) {
        input.val(input.data('address-id'));
        return input;
    },

    placeOrderWithAlternativePayment() {
        const alternativePaymentCode = ACC.common.encodeHtml(
            $("input[type='radio'][name='paymentMode']:checked").val(),
        );

        if (ACC.secureacceptance.termsAndConditionsChecked()) {
            $('.checkout-paymentmethod').after(
                // eslint-disable-next-line max-len
                `<form id="alternativePlaceOrder" action="${ACC.config.encodedContextPath}/checkout/payment/ap/pay" method="post">`
                + `<input type="hidden" name="paymentModeCode" value="${alternativePaymentCode}" /> `
                + '</form>',
            );
            $('#alternativePlaceOrder').submit();
        }
    },

    placeOrderWithKlarna() {
        if (ACC.secureacceptance.termsAndConditionsChecked()) {
            if (!KLARNA.loadResponse || !KLARNA.loadResponse.show_form || KLARNA.loadResponse.errors) {
                window.location = `${ACC.config.encodedContextPath}/checkout/multi/summary/cybsview/payment/error`;
            }
            Klarna.Credit.authorize({}, (res) => {
                KLARNA.authResponse = res;
                console.log(JSON.stringify(res));

                if (KLARNA.authResponse.approved === true) {
                    const alternativePaymentCode = ACC.common.encodeHtml(
                        $("input[type='radio'][name='paymentMode']:checked").val(),
                    );
                    $('.checkout-paymentmethod').after(
                        // eslint-disable-next-line max-len
                        `<form id="alternativePlaceOrder" action="${ACC.config.encodedContextPath}/checkout/payment/ap/pay" method="post">`
                        + `<input type="hidden" name="paymentModeCode" value="${alternativePaymentCode}" /> `
                        // eslint-disable-next-line max-len
                        + `<input type="hidden" name="klarnaAuthToken" value="${KLARNA.authResponse.authorization_token}" /> `
                        + '</form>',
                    );
                    $('#alternativePlaceOrder').submit();
                } else if (KLARNA.authResponse.show_form === false) {
                    window.location = `${ACC.config.encodedContextPath}/checkout/multi/summary/cybsview/payment/error`;
                }
            });
        }
    },

    getBillToFieldValue(fieldName) {
        const creditCardWrappers = '.creditCardDetails,.creditcard-nz-wrapper';
        return $(creditCardWrappers).find(`input[name=billTo_${fieldName}]`).val();
    },

    getBillToSelectValue(fieldName) {
        const creditCardWrappers = '.creditCardDetails,.creditcard-nz-wrapper';
        const value = $(creditCardWrappers).find(`select[name=billTo_${fieldName}] option:selected`).val();
        return fieldName === 'state' ? ACC.secureacceptance.getBillToState(value) : value;
    },

    getBillToState(value) {
        let state = null;
        const isValueDefined = value !== null && value !== undefined;
        if (isValueDefined && value.includes('-') && ACC.config.billingAddressDoctorEnabledWithState) {
            // eslint-disable-next-line prefer-destructuring
            state = value.split('-')[1];
        }
        return state != null ? state : value;
    },

    getPaymentType() {
        const paymentTypeRadio = $("input[type='radio'][name='paymentMode']:checked");
        const paymentTypeHidden = $("input[name='paymentMode']");
        if (paymentTypeRadio.length) {
            return paymentTypeRadio.attr('data-payment-type');
        }
        return paymentTypeHidden.attr('data-payment-type');
    },

    handlerPlaceOrderBtn() {
        const payByPO = $('.payByPORadioBtn');
        const isQuoteCheckout = payByPO.data('is-quote-checkout');
        if (payByPO.is(':checked')) {
            const purchaseOrderNumber = $('#purchaseOrderNumberInput').val().replace(/\s/g, '');
            ACC.secureacceptance.updateCartModelPaymentType('ACCOUNT', purchaseOrderNumber, isQuoteCheckout);
            ACC.secureacceptance.placeOrderWithPO();
        } else {
            const internalCheck = $('.internalRadioButton');
            if (internalCheck !== undefined && internalCheck.is(':checked')) {
                console.log('internal');
                ACC.secureacceptance.placeOrderWithInternal();
            } else {
                ACC.secureacceptance.updateCartModelPaymentType('CARD', '', isQuoteCheckout);
                ACC.secureacceptance.handleOrderPlaceAdditionalData();
                const paymentType = ACC.secureacceptance.getPaymentType();

                switch (paymentType) {
                case 'CREDIT_CARD':
                    ACC.secureacceptance.placeOrderWithCard();
                    if (ACC.accessibilityHelpers) {
                        ACC.accessibilityHelpers.setAriaInvalidAttributes();
                    }
                    break;
                case 'PAY_PAL':
                    ACC.secureacceptance.placeOrderWithPayPal();
                    break;
                case 'ALTERNATIVE_PAYMENT':
                    ACC.secureacceptance.placeOrderWithAlternativePayment();
                    break;
                case 'KLI':
                    ACC.secureacceptance.placeOrderWithKlarna();
                    break;
                case 'INTERNAL_PAYMENT':
                    ACC.secureacceptance.placeOrderWithInternal();
                    break;
                default:
                    break;
                }
            }
        }
    },

    attachHandlerForPlaceOrderBtn() {
        $('.cs_btn-place-order, .btn-place-order').on('click', (e) => {
            e.preventDefault();
            ACC.buttonhelper.ariaDisable('.place-order-form #placeOrder').withSpinner();
            setTimeout(() => {
                ACC.secureacceptance.handlerPlaceOrderBtn();
            }, 0);
        });
    },

    placeOrderWithPO() {
        if (ACC.config.isAssessmentsCheckoutV2Enabled) {
            if (ACC.config.isOfacEnabled || ACC.config.isSiftEnabled) {
                ACC.secureacceptance.siftOfacConfirmationForPayByPo();
            } else {
                $('#placeOrderForm1').submit();
            }
        } else if (ACC.secureacceptance.isSavedAddress()) {
            if (!ACC.secureacceptance.isAddressSearchFieldInvalidForPayByPO()) {
                ACC.secureacceptance.selectSearchedBillingAddressForPayByPO(false);
            } else {
                $('.address-search').addClass('has-error');
                $('.address-search .help-block').removeClass('hidden');
                return;
            }
        }
        ACC.buttonhelper.ariaDisable($(this));
    },

    isAddressSearchFieldInvalidForPayByPO() {
        const addressTypeInput = $('input[type=radio][name=addressGroupType]');
        const checkedButton = addressTypeInput.filter(':checked').val();
        const isUseDefault = checkedButton === 'oneAddress';

        // eslint-disable-next-line max-len
        return ACC.secureacceptance.isSavedAddress() && $("#searchAddressForm input[name='addressId']").val() === '' && !isUseDefault;
    },

    selectSearchedBillingAddressForPayByPO(requiresValidation) {
        if (ACC.config.billingAddressDoctorEnabled && requiresValidation) {
            const addressTypeInput = $('input[type=radio][name=addressGroupType]');
            const checkedButton = addressTypeInput.filter(':checked').val();
            const form = checkedButton === 'oneAddress'
                ? $('#selectAddressForm :input') : $('#searchAddressForm :input');

            $.ajax({
                type: 'POST',
                url: `${ACC.config.encodedContextPath}/checkout/multi/summary/selectBillingAddress`,
                data: $(form).serialize(),
                success(data) {
                    billingAddressFormDataResponse = data;
                },
                // eslint-disable-next-line no-unused-vars
                error(xhr, status, error) {
                    console.log(xhr.responseText);
                },
            }).done(() => {
                $('#billingAddressFormContainer').html(billingAddressFormDataResponse);
                $('#useDeliveryAddress').prop('checked', false);
                ACC.addressDoctor.checkShowingSuggestedAddressesPopup(
                    ACC.secureacceptance.collectBillingAddressForm(),
                    '/checkout/multi/validate/showSuggestedAddressesPopup',
                    false,
                );
            });
        } else {
            ACC.secureacceptance.selectBillingAddress(null, requiresValidation);
            if (!ACC.secureacceptance.isImportedBillingAddress()) {
                if (ACC.config.isOfacEnabled || ACC.config.isSiftEnabled) {
                    ACC.secureacceptance.siftOfacConfirmationForPayByPo();
                } else {
                    $('#placeOrderForm1').submit();
                }
            }
        }
    },

    updateCartModelPaymentType(paymentTypeCode, purchaseOrderNumber, isQuoteCheckout) {
        let updateCartWithPaymentTypeUrl = '';
        if (isQuoteCheckout) {
            updateCartWithPaymentTypeUrl = '/checkout/quote/order/multi/summary/updateCartWithPaymentType';
        } else {
            updateCartWithPaymentTypeUrl = '/checkout/multi/summary/updateCartWithPaymentType';
        }
        $.ajax({
            type: 'POST',
            async: false,
            url: ACC.config.encodedContextPath + updateCartWithPaymentTypeUrl,
            data: {
                paymentTypeCode,
                purchaseOrderNumber,
            },
            // eslint-disable-next-line no-unused-vars
            error(xhr, ajaxOptions, thrownError) {
                if (xhr.status === 404) {
                    alert(`status:${xhr.status}, status text: ${xhr.statusText}`);
                }
            },
        });
    },

    updatePaymentTypeSection(countryIsoCode, paymentTypeCode, isQuoteCheckout) {
        const payByCard = paymentTypeCode;
        let toggleRadioPayByUrl = isQuoteCheckout ? '/checkout/quote/order/multi/summary' : '/checkout/multi/summary';
        if (payByCard === 'CARD') {
            toggleRadioPayByUrl += '/toggleRadioPayByCard';
        } else {
            toggleRadioPayByUrl += '/toggleRadioPayByPO';
        }
        $.ajax({
            type: 'GET',
            // eslint-disable-next-line max-len
            url: `${ACC.config.encodedContextPath + toggleRadioPayByUrl}?countryIsoCode=${countryIsoCode.countryIsoCode}`,
            dataType: 'html',
            async: false,
            success(data) {
                $('#searchAddress').remove();
                $('#searchAddressForm').remove();
                if (payByCard === 'CARD') {
                    $('#removablePayByPoSection').remove();
                    $('#payByCard').replaceWith(data);
                } else {
                    $('#removablePayByCard').remove();
                    $('#payByPoSection').replaceWith(data);
                }
                ACC.global.bindRadioToggleWithChildContent();
                ACC.address.bindFocusOnSearchInput();
                ACC.address.searchAddress();
                ACC.validation.addValidationMethods();
                ACC.validation.bindValidation();
            },
            // eslint-disable-next-line no-unused-vars
            error(xhr, ajaxOptions, thrownError) {
                if (xhr.status === 404) {
                    alert(`status:${xhr.status}, status text: ${xhr.statusText}`);
                }
            },
        });
    },

    submitSOPFormWithCartCheck() {
        const sopForm = ACC.secureacceptance.getSopForm();
        let isCartValid;
        $.ajax({
            type: 'GET',
            url: `${ACC.config.encodedContextPath}/checkout/multi/summary/cartValidation`,
            data: { amount: sopForm.find('#amount').val(), currency: sopForm.find('#currency').val() },
            dataType: 'json',
            async: false,
            cache: false,
            success(data) {
                isCartValid = data;
            },
            error(xhr, status, error) {
                console.error(error.message);
                window.location.reload();
            },
        }).done(() => {
            if (isCartValid === 'true') {
                ACC.secureacceptance.submitSOPForm();
            } else {
                window.location.reload();
            }
        });
    },

    submitFormWithSearchedBillingAddress(isValidationRequired) {
        if (ACC.config.billingAddressDoctorEnabled) {
            const addressTypeInput = $('input[type=radio][name=addressGroupType]');
            const checkedButton = addressTypeInput.filter(':checked').val();
            // eslint-disable-next-line no-nested-ternary,no-unused-expressions
            !isValidationRequired ? (checkedButton === 'oneAddress'
                ? ACC.secureacceptance.selectBillingAddress(ACC.secureacceptance.submitSOPFormWithCartCheck, false)
                : ACC.secureacceptance.submitSOPFormWithCartCheck())
                : ACC.secureacceptance.selectBillingAddress(null, true);
        } else {
            if (ACC.secureacceptance.isAddressSearchFieldInvalid()) {
                ACC.buttonhelper.enable('.place-order-form #placeOrder');
                return;
            }
            ACC.secureacceptance.selectBillingAddress(ACC.secureacceptance.submitSOPFormWithCartCheck, false);
        }
    },

    selectBillingAddress(functionCallback, requiresValidation) {
        const addressTypeInput = $('input[type=radio][name=addressGroupType]');
        const checkedButton = addressTypeInput.filter(':checked').val();
        const form = checkedButton === 'oneAddress'
            ? ACC.secureacceptance.populateAddressId($('#selectAddressForm :input'))
            : $('#searchAddressForm :input');
        let url;
        let container;

        if (window.location.href.indexOf('/checkout/quote/create') >= 0) {
            url = '/checkout/quote/create/multi/summary/selectBillingAddress';
            container = $('#quoteBillingAddressFormContainer');
        } else {
            url = '/checkout/multi/summary/selectBillingAddress';
            container = $('#billingAddressFormContainer');
        }

        $.ajax({
            type: 'POST',
            headers: {
                CSRFToken: ACC.config.CSRFToken,
            },
            url: ACC.config.encodedContextPath + url,
            data: $(form).serialize(),
            async: false,
            success(data) {
                container.html(data);
            },
            // eslint-disable-next-line no-unused-vars
            error(xhr, status, error) {
                console.log(xhr.responseText);
            },
        }).done(() => {
            $('#useDeliveryAddress').prop('checked', false);
            requiresValidation = $('#isAddressValidatedByAD').val() === 'true' ? false : requiresValidation;
            if (window.location.href.indexOf('/checkout/quote/create') >= 0) {
                ACC.quote.validateBillingAddress(null, requiresValidation);
            } else {
                ACC.secureacceptance.updateBillingAddress(
                    functionCallback,
                    ACC.secureacceptance.updateBillingAddressForm,
                    true,
                    requiresValidation,
                );
            }
        });
    },

    vcSuccess(payment) {
        $('.checkout-paymentmethod').after(
            // eslint-disable-next-line max-len
            `<form id="visaCheckoutPlaceOrder" action="${ACC.config.encodedContextPath}/checkout/payment/vc/success" method="post">`
            + `<input type="hidden" name="callId" value="${payment.callid}" /> `
            + `<input type="hidden" name="CSRFToken" value="${ACC.config.CSRFToken}" /> `
            + '</form>',
        );
        $('#visaCheckoutPlaceOrder').submit();
    },

    // eslint-disable-next-line no-unused-vars
    vcCancel(payment) {
        window.location = `${ACC.config.encodedContextPath}/checkout/multi/summary/cybsview`;
    },

    // eslint-disable-next-line no-unused-vars
    vcError(payment, error) {
        window.location = `${ACC.config.encodedContextPath}/checkout/multi/summary/cybsview/payment/error`;
    },

    vcTermsAndConditions(event) {
        const termsAccepted = ACC.secureacceptance.termsAndConditionsChecked();
        if (!termsAccepted) {
            jQuery.event.fix(event).stopImmediatePropagation();
        }
    },

    attachBillingAddressHandlers() {
        $('#billingAddressFormContainer').on('click', '.closeAccAlert', ACC.global.closeAccAlert);
    },

    attachBillingAddressForm() {
        $('#billingAddressFormContainer').on('change', '#billingCountrySelector :input', function () {
            const countrySelection = $(this).val();
            const options = {
                countryIsoCode: countrySelection,
                useDeliveryAddress: false,
            };
            $('#billingCountrySelector .form-group').removeClass('has-error');
            $('#billingCountrySelector .form-group .help-block').hide();
            ACC.silentorderpost.displayCreditCardAddressForm(options);
        });
    },

    billingAddressSelection() {
        let billingAddressFormDataResponse;
        $('.js-select-billing-address').on('click', function (e) {
            e.preventDefault();
            $.ajax({
                type: 'POST',
                url: `${ACC.config.encodedContextPath}/checkout/multi/summary/selectBillingAddress`,
                data: $(this).closest('#billingAddressBookForm').serialize(),
                async: false,
                success(data) {
                    billingAddressFormDataResponse = data;
                },
                // eslint-disable-next-line no-unused-vars
                error(xhr, status, error) {
                    console.log(xhr.responseText);
                },
            }).done(() => {
                $('#billingAddressFormContainer').html(billingAddressFormDataResponse);
                ACC.secureacceptance.bindBillingAddressListeners();
                // eslint-disable-next-line max-len
                const eventType = ACC.utils.isMojoTheme() || (ACC.utils.isHedTheme() && ACC.config.checkoutZipChangesEnabled) ? 'input' : 'focusout';
                $(ACC.secureacceptance.billingAddressRequiredInputFields[0]).trigger(eventType);
                $('#useDeliveryAddress').prop('checked', false);
                $.colorbox.close();
            });
        });
    },

    bindAddressTrim() {
        const component = $('#addressForm');
        if (!component.length) return;
        component.submit(() => {
            ACC.secureacceptance.performTrimBeforeSubmit(component);
        });
    },

    showErrorMessage(messageKey) {
        const globalMessageRequest = {
            globalMessages: [{
                messageHolder: 'accErrorMsgs',
                messages: [messageKey],
            }],
        };
        ACC.globalmessages.removeGlobalMessages();
        ACC.globalmessages.createGlobalMessage(globalMessageRequest);
    },

    isSavedAddress() {
        const addressTypeInput = $('input[type=radio][name=addressGroupType]');
        const checkedButton = addressTypeInput.filter(':checked').val();
        return checkedButton === 'oneAddress' || checkedButton === 'searchAddress';
    },

    useDeliveryAddress() {
        return $('#useDeliveryAddress').prop('checked');
    },

    addCvvHandler() {
        // Allow type only numbers and backspace for cvv input
        $('#card_cvNumber').on('keypress', (e) => {
            // 0 for null values
            const isNotNull = e.which !== 0;

            // 8 for backspace
            const isNotBackspace = e.which !== 8;

            // 48-57 for 0-9 numbers
            const isNotNumber = e.which < 48 || e.which > 57;

            if (isNotNull && isNotBackspace && isNotNumber) {
                e.preventDefault();
            }
        });
    },

    getSopForm() {
        if (ACC.config.isCybersourceDirectIntegrationEnabled) {
            return $('#sopRequestForm');
        }
        const $sopRequestIframe = $('#sopRequestIframe');
        return $sopRequestIframe.contents().find('#sopRequestForm');
    },

    loadSopFormFragment(onDoneFunction = () => {}) {
        $.ajax({
            type: 'POST',
            headers: { CSRFToken: ACC.config.CSRFToken },
            url: ACC.config.encodedContextPath + ACC.config.sopFragmentUrl,
            async: false,
            success(data) {
                $('#sopDirectCyberSourceBox').html(data);
                onDoneFunction();
            },
            error(xhr, status, error) {
                console.error(error.message);
                const messageKey = 'account.error.generic';
                const globalMessageRequest = {
                    globalMessages: [{
                        messageHolder: 'accErrorMsgs',
                        messages: [messageKey],
                    }],
                };
                ACC.globalmessages.createGlobalMessage(globalMessageRequest);
                ACC.buttonhelper.enable('.place-order-form #placeOrder');
            },
        });
    },

    reloadSopForm(sopFormSrc = ACC.secureacceptance.sopFormSrc(), data = '', onDoneFunction = () => {}) {
        if (ACC.config.isCybersourceDirectIntegrationEnabled) {
            if (document.querySelector('.wallet-main-block')) {
                ACC.unifiedWallet.loadSopFormFragment((data && data.paymentId)
                    ? `?paymentId=${data.paymentId}` : '', onDoneFunction);
            } else {
                ACC.secureacceptance.loadSopFormFragment(onDoneFunction);
            }
        } else {
            ACC.secureacceptance.reloadSopIframe(sopFormSrc, data, false, onDoneFunction);
        }
    },

    createSopIframe() {
        if ($('#sopSubscriptionNumber').length) {
            ACC.secureacceptance.reloadSopIframe(
                ACC.secureacceptance.sopFormSrc(),
                { subscriptionNumber: $('#sopSubscriptionNumber').val() },
            );
        } else {
            ACC.secureacceptance.reloadSopIframe(
                ACC.secureacceptance.sopFormSrc(),
                undefined,
                false,
                ACC.secureacceptance.isAssessmentsSite() ? ACC.silentorderpost.preselectCard : () => {},
            );
        }
    },

    reloadSopIframe(sopUrl, data, fromSubmitSOPForm = false, onDoneFunction = () => {}) {
        if (!$('#sopRequestIframe').length) {
            return;
        }
        $('#sopFormSrc').val(sopUrl);
        $.ajax({
            url: ACC.config.encodedContextPath + sopUrl,
            type: 'POST',
            async: false,
            data,
            // eslint-disable-next-line no-shadow
            success(data) {
                document.querySelector('#sopRequestIframe').contentWindow.document.body.innerHTML = data;
                if (ACC.updatePayment && ACC.updatePayment.updateForSavedCard && fromSubmitSOPForm) {
                    ACC.updatePayment.updateForSavedCard();
                }
                onDoneFunction();
            },
            error(xhr, status, error) {
                console.error(error.message);
                const messageKey = 'account.error.generic';
                const globalMessageRequest = {
                    globalMessages: [{
                        messageHolder: 'accErrorMsgs',
                        messages: [messageKey],
                    }],
                };
                ACC.globalmessages.createGlobalMessage(globalMessageRequest);
            },
        });
    },

    isValidCardData(shouldValidateCardType) {
        let fieldsToValidate;
        const savedCards = $('.savedCardRadioBtn');
        if (savedCards.length > 2 && !savedCards.is(':checked')) {
            ACC.secureacceptance.termsAndConditionsChecked();
            ACC.secureacceptance.showSavedCardsError();
            return false;
        }
        if (ACC.secureacceptance.isNewCard()) {
            fieldsToValidate = [
                { value: ACC.secureacceptance.getCardType(), ctrl: '#card_cardType' },
                { value: ACC.secureacceptance.getCardNumber(), ctrl: '#card_accountNumber' },
                { value: ACC.secureacceptance.getCardExpireYear(), ctrl: '#ExpiryYear' },
                { value: ACC.secureacceptance.getCardExpireMonth(), ctrl: '#ExpiryMonth' },
                { value: ACC.secureacceptance.getCardCvnNumber(), ctrl: '#card_cvNumber' },
            ];
        } else {
            const savedCardId = $('.savedCardRadioBtn:checked').data('savedcardid');
            fieldsToValidate = [
                { value: $(`#savedCardCvvNumber-${savedCardId}`).val(), ctrl: `#savedCardCvvNumber-${savedCardId}` },
            ];
        }
        let isValid = true;

        fieldsToValidate.forEach((field) => {
            ACC.secureacceptance.hide($(field.ctrl));
            if (!field.value) {
                ACC.secureacceptance.show($(field.ctrl), ACC.secureacceptance.getErrorMessage($(field.ctrl)));
                isValid = false;
            }
        });
        if (ACC.secureacceptance.isNewCard() && shouldValidateCardType) {
            // eslint-disable-next-line no-bitwise
            isValid &= ACC.secureacceptance.validateCardType();
        }

        return isValid;
    },

    show(control, message) {
        ACC.secureacceptance.getOrCreateErrorContainer(control, message).removeClass('collapse');
        control.closest('.form-group').addClass('has-error');
        const cvvErrorWrapper = "<div class='cvv-error-wrapper'></div>";
        const saveCardId = $('.savedCardRadioBtn:checked').attr('id');
        const alreadyWrapped = !!control.parents('.cvv-error-wrapper').length;
        const cvvErrorMessage = control.closest('.form-group').find('#savedCvvErrorMessage');
        if (control.attr('name') === 'card_cvNumber' && ACC.utils.isHedTheme()) {
            control.siblings('.help-block').attr('aria-hidden', 'true');
        }
        if (cvvErrorMessage.length && ACC.utils.isHedTheme()) {
            cvvErrorMessage.text(message);
        }
        if (saveCardId !== 'savedCard-newCard' && typeof saveCardId !== 'undefined' && !alreadyWrapped) {
            control.closest('.form-group').find('.help-block, .input').wrapAll(cvvErrorWrapper);
            if (!ACC.utils.isHedTheme()) {
                control.closest('.form-group').find('.help-block').attr('role', 'alert');
            }
        }
    },

    isNewCard() {
        // eslint-disable-next-line max-len
        return $('.savedCardRadioBtn:checked').attr('id') === 'savedCard-newCard' || $('.savedCardRadioBtn:checked').attr('id') === 'updateCard-newCard' || typeof $('.savedCardRadioBtn:checked').attr('id') === 'undefined';
    },

    hide(control) {
        const cvvErrorMessage = control.closest('.form-group').find('#savedCvvErrorMessage');
        if (cvvErrorMessage.length && ACC.utils.isHedTheme()) {
            cvvErrorMessage.text('');
        }
        control.next('.help-block').remove();
        control.closest('.form-group').removeClass('has-error');
    },

    getErrorMessage(control) {
        return control.closest('[data-error-msg]').data('error-msg');
    },

    getOrCreateErrorContainer(forControl, message) {
        const sibling = forControl.next('.help-block');
        const attrDescribedByName = forControl.attr('aria-describedby');
        if (sibling.length !== 0) {
            return sibling;
        }
        if (message != null) {
            if (ACC.utils.isHedTheme()) {
                forControl.after(`<div class="help-block">${message}</div>`);
            } else {
                forControl
                    .after(`<div id="${attrDescribedByName}" class="help-block" hidden="hidden">${message}</div>`);
            }
        }
        return forControl.next('.help-block');
    },

    validateCardType() {
        if ($('#card_accountNumber').length && $('#card_cardType').val() === 'invalid') {
            if (ACC.config.showInvalidCardBannerMessage === 'true') {
                ACC.secureacceptance.showErrorMessage('checkout.multi.card.type.invalid.message');
            } else {
                ACC.secureacceptance.show($('#card_accountNumber'), ACC.config.cardTypeNotSupportedMessage);
            }
            return false;
        }
        return true;
    },

    areVertexRequiredFieldsFilled() {
        let areVertexFilled = true;
        ACC.secureacceptance.vertexRequiredFields.forEach((selector) => {
            if (!$(selector).val()) {
                areVertexFilled = false;
            }
        });
        return areVertexFilled;
    },

    isZipValid(formId) {
        const validationResult = ACC.validation.validateFormElement(formId, 'address\\.postcode');
        if (!validationResult) {
            return false;
        }
        return true;
    },

    getCardType() {
        return $('#card_cardType option:selected').val() || $('#card_cardType').val();
    },

    getCardNumber() {
        return $('#card_accountNumber').val().replace(/\s/g, '');
    },

    getCardCvnNumber() {
        return $('#card_cvNumber').val();
    },

    getCardExpireDate() {
        return `${$('#ExpiryMonth option:selected').text()}-${$('#ExpiryYear option:selected').text()}`;
    },

    getCardExpireYear() {
        return $('#ExpiryYear option:selected').val();
    },

    getCardExpireMonth() {
        return $('#ExpiryMonth option:selected').val();
    },

    isUsAssessments() {
        return ACC.config.encodedContextPath.indexOf('usassessments') > 0;
    },

    isCaAssessments() {
        return ACC.config.encodedContextPath.indexOf('caassessments') > 0;
    },

    isUkAssessments() {
        return ACC.config.encodedContextPath.indexOf('ukassessments') > 0;
    },

    isAuAssessments() {
        return ACC.config.encodedContextPath.indexOf('auassessments') > 0;
    },

    isNzAssessments() {
        return ACC.config.encodedContextPath.indexOf('nzassessments') > 0;
    },

    isAsiaAssessments() {
        return ACC.config.encodedContextPath.indexOf('asiaassessments') > 0;
    },

    isSgAssessments() {
        return ACC.config.encodedContextPath.indexOf('sgassessments') > 0;
    },

    isAssessmentsSite() {
        return ACC.secureacceptance.isUsAssessments()
            || ACC.secureacceptance.isCaAssessments()
            || ACC.secureacceptance.isUkAssessments()
            || ACC.secureacceptance.isAuAssessments()
            || ACC.secureacceptance.isNzAssessments()
            || ACC.secureacceptance.isAsiaAssessments()
            || ACC.secureacceptance.isSgAssessments();
    },
};
