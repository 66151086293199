ACC.unifiedWalletSorting = {
    _autoload: [
        [document.querySelector('#unifiedWalletWrapper, #savedPaymentCards')
        && document.querySelector('#unifiedWalletWrapper, #savedPaymentCards').length !== 0],
    ],

    expiredIndexes: [],

    paypalIndexes: [],

    removeCards: (cardItems, indexes) => {
        return cardItems.filter((item, index) => indexes.indexOf(index) === -1);
    },

    // eslint-disable-next-line consistent-return
    sortPreferredPayment: (data, sortParameter) => {
        const cards = data.slice();
        cards.forEach((item, index) => {
            // eslint-disable-next-line no-prototype-builtins
            if (item.hasOwnProperty('preferredPayment') && item.preferredPayment) {
                cards.splice(0, 0, cards.splice(index, 1)[0]);
            }
        });
        cards.forEach((item, index) => {
            // eslint-disable-next-line no-prototype-builtins
            if (item.hasOwnProperty('paymentType') && item.paymentType === 'PayPal') {
                ACC.unifiedWalletSorting.paypalIndexes.push(index);
            }
        });
        cards.forEach((item, index) => {
            const today = new Date();
            const expiryDate = new Date();
            expiryDate.setFullYear(+item.validToYear, +item.validToMonth, 1);

            if (expiryDate < today) {
                item.expired = true;
                ACC.unifiedWalletSorting.expiredIndexes.push(index);
            } else {
                item.expired = false;
            }
        });

        if (document.querySelector('#unifiedWalletWrapper') || document.querySelector('.wallet-main-block')) {
            // eslint-disable-next-line max-len
            return ACC.unifiedWalletSorting.removeCards(cards, ACC.unifiedWalletSorting.paypalIndexes).slice(0, sortParameter);
        }

        if (document.querySelector('#savedPaymentCards')) {
            // eslint-disable-next-line max-len
            const removableCards = ACC.unifiedWalletSorting.expiredIndexes.concat(ACC.unifiedWalletSorting.paypalIndexes);
            return ACC.unifiedWalletSorting.removeCards(cards, removableCards).slice(0, sortParameter);
        }
    },
};
