ACC.coookienotification = {
    _autoload: [
        'checkCookieNotification',
        ['bindCookieNotificationClick', $('.js-cookie-notification-accept').length !== 0],
    ],

    bindCookieNotificationClick() {
        $('.js-cookie-notification-learn-more').on('click', () => {
            ACC.coookienotification.acceptCookies();
            $('.cookie-alert--bottom').hide();
            window.open($('.js-cookie-notification-learn-more').data('cookie-policy-url'));
        });

        $('.js-cookie-notification-accept').on('click', () => {
            ACC.coookienotification.acceptCookies();
            $('.cookie-alert--bottom').hide();
        });
    },
    checkCookieNotification() {
        // landing on hybris pages from aem side
        const cookieNotification = $.cookie('cookie-notification');

        if (cookieNotification) {
            ACC.coookienotification.postStatus(cookieNotification);
        }
    },
    acceptCookies() {
        $.cookie('cookie-notification', 'ACCEPTED', { path: '/', secure: true });
        ACC.coookienotification.postStatus('ACCEPTED');
    },
    postStatus(cookieNotificationStatus) {
        $.post(
            `${ACC.config.encodedContextPath}/cookies-accepted`,
            { status: cookieNotificationStatus },
        );
    },
};
