ACC.refundProduct = {

    _autoload: [
        'initComponent',
    ],

    initComponent() {
        const component = $('#pearsonReturnForm');
        const secondSelect = $('select[id^="secondReasonSelectId"]');

        if (!component.length) return;

        ACC.refundProduct.toggleTextarea(false);
        ACC.refundProduct.toggleSelect(false);

        const mainReasonSelect = $('.mainReasonSelect').filter(function () {
            return this.getAttribute('data') != null;
        });
        mainReasonSelect.on('change', function () {
            const url = `${window.location.origin + window.location.pathname}/secondReason`;
            const index = this.getAttribute('data');
            const data = {
                value: this.value,
            };
            ACC.refundProduct.send(url, data, index, ACC.refundProduct.onSuccess);
        });

        secondSelect.on('change', function () {
            const url = `${window.location.origin + window.location.pathname}/getAdditionalInfo`;
            const index = this.getAttribute('data');
            const primaryReason = $(`#mainReasonSelectId${index}`);
            const data = {
                primaryReason: primaryReason.children('option:selected').val(),
                secondaryReason: this.value,
            };
            ACC.refundProduct.send(url, data, index, ACC.refundProduct.visibilityTextarea);
        });
    },

    send(inUrl, inData, index, callBack) {
        $.ajax({
            url: inUrl,
            data: inData,
            type: 'post',
            success(response) {
                callBack(response, index);
            },
        });
    },

    onSuccess(response, index) {
        const secondReasonSelect = $(`#secondReasonSelectId${index}`);
        const value = response.secondaryRefundReasons;
        if (value.length) {
            secondReasonSelect.find('option:not(:first-child)').remove();
            // eslint-disable-next-line no-shadow
            $.each(value, (key, value) => {
                secondReasonSelect.append($(`<option value="${value.value}">${value.description}</option>`));
            });
            ACC.refundProduct.toggleSelect(true, index);
        } else {
            ACC.refundProduct.toggleSelect(false, index);
        }

        ACC.refundProduct.visibilityTextarea(response.additionalData, index);
    },

    visibilityTextarea(additionalData, index) {
        if (additionalData && additionalData.copyShown) {
            const requiredCopyShown = $(`#requiredCopyShownId${index}`);
            requiredCopyShown.text(additionalData.copyShown);
            ACC.refundProduct.toggleTextarea(true, index);
        } else {
            ACC.refundProduct.toggleTextarea(false, index);
        }
    },

    toggleTextarea(bool, index) {
        const textarea = index ? $(`#requiredTextAreaId${index}`) : $('textarea[id^="requiredTextAreaId"]');
        const parentTextarea = textarea.closest('.row');
        if (bool) {
            parentTextarea.show();
        } else {
            parentTextarea.hide();
        }
    },

    toggleSelect(bool, index) {
        const secondSelect = index ? $(`#secondReasonSelectId${index}`) : $('select[id^="secondReasonSelectId"]');
        const parentSecondSelect = secondSelect.closest('.row');
        if (bool) {
            parentSecondSelect.show();
        } else {
            parentSecondSelect.hide();
        }
    },
};
