ACC.savedcarts = {

    _autoload: [
        ['bindRestoreSavedCartClick', $('.js-restore-saved-cart').length !== 0],
        ['bindDeleteSavedCartLink', $('.js-delete-saved-cart').length !== 0],
        ['bindDeleteConfirmLink', $('.js-savedcart_delete_confirm').length !== 0],
        ['bindSaveCartForm', $('.js-save-cart-link').length !== 0 || $('.js-update-saved-cart').length !== 0],
        ['bindUpdateUploadingSavedCarts', $('.js-uploading-saved-carts-update').length !== 0],
        'cutId',
    ],

    getParentElementOfError(element) {
        return $(element).closest('.form-group');
    },

    $savedCartRestoreBtn: {},
    $currentCartName: {},

    bindRestoreSavedCartClick() {
        // Open restore modal
        $('.js-restore-saved-cart').on('click', function (e) {
            e.preventDefault();
            const $this = $(this);
            const cartId = $this.data('savedcart-id');
            // URL for fetching content for modal
            const url = `${ACC.config.encodedContextPath + ACC.config.savedCartsContext}/${cartId}/restore`;

            // Assigning faked HREF attribute since modal is fetching content from that location
            $this.attr('href', url);
            $modal.open($this);
        });
        // Close restore modal
        $('#colorbox').on('click', '.js-cancel-restore-btn', $modal.close);
        // Restore cart
        $('#colorbox').on('click', '.js-save-cart-restore-btn', function () {
            // Check if we are keeping a copy of cart in my saved carts section
            const keepRestoredCart = $('#colorbox .js-keep-restored-cart').prop('checked');
            const preventSaveActiveCart = $('#colorbox .js-prevent-save-active-cart').prop('checked');
            const cartName = $('#activeCartName').val();
            const url = $(this).data('restore-url');
            // Data object for restoring cart
            const postData = {
                preventSaveActiveCart,
                keepRestoredCart,
                cartName,
            };
            $.post(url, postData).done(() => {
                // eslint-disable-next-line no-shadow
                const url = `${ACC.config.encodedContextPath}/cart`;
                window.location.replace(url);
            }).fail((result) => {
                const errorMessageWithoutQuotes = result.responseText.slice(1, -1);
                $('.js-restore-current-cart-form').addClass('has-error');
                $('.js-restore-error-container').html(errorMessageWithoutQuotes);
            });
        });
    },

    bindRestoreModalHandlers() {
        ACC.savedcarts.$savedCartRestoreBtn = $('.js-save-cart-restore-btn');
        ACC.savedcarts.$currentCartName = $('.js-current-cart-name');

        $('.js-prevent-save-active-cart').on('change', function () {
            if ($(this).prop('checked') === true) {
                ACC.savedcarts.$currentCartName.attr('disabled', 'disabled');
                ACC.savedcarts.$savedCartRestoreBtn.removeAttr('disabled');
            } else {
                ACC.savedcarts.$currentCartName.removeAttr('disabled');
                const inputVal = ACC.savedcarts.$currentCartName.val();
                if (inputVal === '' && inputVal.length === 0) {
                    ACC.savedcarts.$savedCartRestoreBtn.attr('disabled', 'disabled');
                }
            }
        });

        ACC.savedcarts.$currentCartName.on('focus', () => {
            $('.js-restore-current-cart-form').removeClass('has-error');
            $('.js-restore-error-container').html('');
        });

        ACC.savedcarts.$currentCartName.on('blur', function () {
            if (this.value === '' && this.value.length === 0) {
                ACC.savedcarts.$savedCartRestoreBtn.attr('disabled', 'disabled');
            } else {
                ACC.savedcarts.$savedCartRestoreBtn.removeAttr('disabled');
            }
        });
    },

    bindPostRestoreSavedCartLink() {},

    // Open delete saved cart modal
    bindDeleteSavedCartLink() {
        $('.js-delete-saved-cart').on('click', function (e) {
            e.preventDefault();
            $modal.open(this);
        });
    },

    bindDeleteConfirmLink() {
        $('.js-savedcart_delete_confirm').on('click', function () {
            const cartId = $(this).data('savedcart-id');
            const url = ACC.config.encodedContextPath + ACC.config.savedCartsContext;
            $.ajax({
                url: `${url}/${cartId}/delete`,
                type: 'DELETE',
                success() {
                    $modal.close();
                    window.location.replace(url);
                },
            });
        });

        // Close delete saved cart modal
        $('.js-savedcart_delete_confirm_cancel').on('click', $modal.close);
    },

    saveCartOnClickHandler(saveCartButton) {
        // Wrapped in a variable, so we can call it with argument as a
        // callback function
        const authStatus = ACC.common.isAuthenticated();
        if (authStatus === 'authenticated') {
            const $asyncModal = $modal.open(saveCartButton);
            ACC.common.checkAuthStatusBeforeAction($asyncModal, authStatus);
        } else {
            window.location.href = ACC.config.loginUrl;
        }
    },

    bindSaveCartForm() {
        ACC.savedcarts.charactersLeftInit();
        // eslint-disable-next-line no-unused-vars
        const form = $('#saveCartForm');
        // eslint-disable-next-line no-unused-vars
        const saveCart = false;

        // Update save & update cart
        // ( Trigger 'Save cart' modal opening )
        $('.js-save-cart-link, .js-update-saved-cart').on('click', function () {
            ACC.savedcarts.saveCartOnClickHandler(this);
        });

        // Close 'Save Cart' modal window
        $('#cancelSaveCartButton').on('click', $modal.close);

        const alphaNumericRegex = /^[A-Za-z0-9 ]*$/;
        const saveCartName = $('#saveCartName');
        let saveCartNameTrimmedValue = saveCartName.val().trim();
        saveCartName.keyup(function () {
            saveCartNameTrimmedValue = this.value.trim();
            // enable the save cart button
            // eslint-disable-next-line max-len
            $('#saveCart #saveCartButton').prop('disabled', () => !saveCartNameTrimmedValue || !saveCartNameTrimmedValue.match(alphaNumericRegex));
            //  Track characters left
            // eslint-disable-next-line max-len
            $('#colorbox #remain').text(`${ACC.savedcarts.countCharactersLeft(this)} ${$('#localized_val').attr('value')}`);
            if (!saveCartNameTrimmedValue.match(alphaNumericRegex)) {
                const alphanumericError = $('#saveCartAlphanumericError').data('alphanumericError');
                $('#colorbox #saveCartAlphanumericError').text(alphanumericError);
                ACC.savedcarts.getParentElementOfError(this).addClass('has-error');
                $('#colorbox #remain').addClass('hidden');
            } else {
                $('#colorbox #saveCartAlphanumericError').text('');
                ACC.savedcarts.getParentElementOfError(this).removeClass('has-error');
                $('#colorbox #remain').removeClass('hidden');
            }
        });

        $('#saveCartDescription').keyup(function () {
            // Track characters left
            // eslint-disable-next-line max-len
            $('#colorbox #remainTextArea').text(`${ACC.savedcarts.countCharactersLeft(this)} ${$('#localized_val').attr('value')}`);
            // eslint-disable-next-line max-len
            $('#saveCart #saveCartButton').prop('disabled', () => !saveCartNameTrimmedValue || !saveCartNameTrimmedValue.match(alphaNumericRegex));
        });

        // Close 'Save Cart' modal
        $('#saveCart #saveCartButton').on('click', $modal.close);

        $('#saveCartForm').on('submit', () => {
            ACC.savedcarts.disableSaveCartButton(true);
        });
    },

    /**
    * Counts and returns number of characters left within target textfield.
    * @param   {target|string} - target element
    * @param   {maxchars|integer} - maximum number of characters allowed. Optional, defaults to 245
    * @returns {integer} - returns number of characters left
    */
    countCharactersLeft(target) {
        if (!target) { return false; }

        // Use maxLength attribute to attach character limit for fields
        const maxchars = target.maxLength || 245;
        const tlength = $(target).val().length;

        return maxchars - parseInt(tlength, 10);
    },

    charactersLeftInit() {
        // Trigger remaining chars left
        // eslint-disable-next-line max-len
        $('#remain').text(`${ACC.savedcarts.countCharactersLeft('#saveCartName')} ${$('#localized_val').attr('value')}`);
        // eslint-disable-next-line max-len
        $('#remainTextArea').text(`${ACC.savedcarts.countCharactersLeft('#saveCartDescription')} ${$('#localized_val').attr('value')}`);
    },

    disableSaveCartButton(value) {
        $('#saveCart #saveCartButton').prop('disabled', value);
    },

    bindUpdateUploadingSavedCarts() {
        const cartIdRowMapping = $('.js-uploading-saved-carts-update').data('idRowMapping');
        const refresh = $('.js-uploading-saved-carts-update').data('refreshCart');
        if (cartIdRowMapping && refresh) {
            const interval = $('.js-uploading-saved-carts-update').data('refreshInterval');
            const arrCartIdAndRow = cartIdRowMapping.split(',');
            // eslint-disable-next-line no-new-object
            const mapCartRow = new Object();
            const cartCodes = [];
            for (let i = 0; i < arrCartIdAndRow.length; i += 1) {
                const arrValue = arrCartIdAndRow[i].split(':');
                if (arrValue !== '') {
                    // eslint-disable-next-line prefer-destructuring
                    mapCartRow[arrValue[0]] = arrValue[1];
                    cartCodes.push(arrValue[0]);
                }
            }

            if (cartCodes.length > 0) {
                setTimeout(() => { ACC.savedcarts.refreshWorker(cartCodes, mapCartRow, interval); }, interval);
            }
        }
    },

    refreshWorker(cartCodes, mapCartRow, interval) {
        $.ajax({
            dataType: 'json',
            url: `${ACC.config.encodedContextPath + ACC.config.savedCartsContext}/uploadingCarts`,
            data: {
                cartCodes,
            },
            type: 'GET',
            traditional: true,
            success(data) {
                if (data !== undefined) {
                    const hidden = 'hidden';
                    const rowId = '#row-';
                    for (let i = 0; i < data.length; i += 1) {
                        const cart = data[i];

                        const index = $.inArray(cart.code, cartCodes);
                        if (index > -1) {
                            cartCodes.splice(index, 1);
                        }
                        const rowIdIndex = mapCartRow[cart.code];
                        if (rowIdIndex !== undefined) {
                            const rowSelector = rowId + rowIdIndex;
                            $(`${rowSelector} .js-saved-cart-name`).removeClass('not-active');
                            $(`${rowSelector} .js-saved-cart-date`).removeClass('hidden');
                            $(`${rowSelector} .js-file-importing`).remove();
                            $(`${rowSelector} .js-saved-cart-description`).text(cart.description);
                            const numberOfItems = cart.entries.length;
                            $(`${rowSelector} .js-saved-cart-number-of-items`).text(numberOfItems);
                            $(`${rowSelector} .js-saved-cart-total`).text(cart.totalPrice.formattedValue);
                            if (numberOfItems > 0) {
                                $(`${rowSelector} .js-restore-saved-cart`).removeClass(hidden);
                            }
                            $(`${rowSelector} .js-delete-saved-cart`).removeClass(hidden);
                        }
                    }
                }

                if (cartCodes.length > 0) {
                    setTimeout(() => { ACC.savedcarts.refreshWorker(cartCodes, mapCartRow, interval); }, interval);
                }
            },
        });
    },

    cutId() {
        const elements = document.querySelectorAll('.saved-cart-id');
        [].forEach.call(elements, (e) => {
            const id = e.innerHTML.trim();
            if (id.length > 7) e.innerHTML = id.substr(id.length - 7, id.length);
        });
    },
};
