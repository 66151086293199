ACC.forgottenpassword = {

    _autoload: [
        ['bindEmailChange', $('#forgottenPwdEmail').length !== 0],
        ['bindForgottenPwdSubmitForm', $('#forgottenPwdForm').length !== 0],
    ],

    captchaIsPresent: $('[data-recaptcha-control="getForgottenPwdInvisibleRecaptcha"]').length,

    bindForgottenPwdSubmitForm() {
        const form = $('#forgottenPwdForm');
        form.submit(function () {
            if ($(this).valid()) {
                if (ACC.forgottenpassword.captchaIsPresent) {
                    // eslint-disable-next-line no-undef
                    grecaptcha.execute();
                } else {
                    ACC.forgottenpassword.handleSubmit();
                }
            }
            return false;
        });
    },

    bindEmailChange() {
        $('#forgottenPwdEmail').on('keyup input', ACC.forgottenpassword.enableFormSubmit);
    },

    handleSubmit() {
        const form = $('#forgottenPwdForm');
        const submitBtn = $('.js-forgottenPwd-submit');
        const actionUrl = form.attr('action');
        const formData = form.serialize();
        const successRedirectUrl = form.data('success-redirect');

        $.post({
            url: actionUrl,
            data: formData,
            beforeSend() {
                ACC.buttonhelper.disable(submitBtn);
            },
            success() {
                window.location = successRedirectUrl;
            },
            error(error) {
                ACC.buttonhelper.enable(submitBtn);
                submitBtn.prop('disabled', true);

                form.validate().showErrors({
                    email: error.responseJSON,
                });

                if (ACC.forgottenpassword.captchaIsPresent) {
                    // eslint-disable-next-line no-undef
                    grecaptcha.reset();
                }
            },
        });
    },

    enableFormSubmit() {
        const form = $('#forgottenPwdForm');
        const submitBtn = $('.js-forgottenPwd-submit');
        submitBtn.prop('disabled', !form.valid());
    },
};

// eslint-disable-next-line no-unused-vars
const recaptchaforgottenPasswordSubmitCallback = function () {
    ACC.forgottenpassword.handleSubmit();
};
