ACC.cms = {
    loadComponent(id, type, target, onSuccess, onError) {
        const self = this;
        if (id) {
            $.ajax({
                url: `${ACC.config.contextPath}/cms/component?componentUid=${id}`,
                cache: false,
                type: 'GET',
                success(result) {
                    reprocess = result.indexOf('js-responsive-image') > -1;
                    self.insertHtml(result, target, reprocess);
                    if (onSuccess) {
                        onSuccess(result, id, type, target);
                    }
                },
                error(result) {
                    if (onError) {
                        onError(result, id, type, target);
                    }
                },
            });
        }
    },

    insertHtml(html, target, reprocess) {
        if (target) {
            $(target).html(html);
            if (reprocess) {
                ACC.global.reprocessImages();
            }
        }
    },
};
