ACC.changeusername = {

    _autoload: [
        'initialize',
        'trackFormChanges',
        'showCancelUsernameChangeConfirmation',
    ],

    initialize() {
        if ($('#updateUsernameToken').length > 0 && piSession) {
            piSession.getToken((status, token) => {
                $('#updateUsernameToken').val(token);
                $('#updateUsernameUserId').val(piSession.userId());
            });
        }
    },

    trackFormChanges() {
        $('#updatePearsonPasswordForm').on('change', () => {
            $('#updatePearsonPasswordForm').data('changed', true);
        });
    },

    showCancelUsernameChangeConfirmation() {
        $(document).on('click', '.js-cancel-change-Username-popup-button', function () {
            if ($('#updatePearsonPasswordForm').data('changed')) {
                const popupTitle = $(this).data('popupTitle');

                ACC.colorbox.open(popupTitle, {
                    inline: true,
                    height: false,
                    href: '#popup_confirm_password_change_cancel',
                    onComplete() {
                        $(this).colorbox.resize();
                    },
                });
            } else {
                window.location = $(this).data('cancelUrl');
            }
        });
    },
};
