ACC.quote = {

    _autoload: [
        ['bindAddComment', $('#js-quote-comments').length !== 0],
        'bindMyQuoteGenerateOrder',
        'bindQuoteListMyQuoteGenerateOrder',
        ['bindAddEntryComment', $('.js-quote-entry-comments').length !== 0],
        ['toggleMoreComments', $('#js-quote-comments').length !== 0],
        ['toggleLessComments', $('#js-quote-comments').length !== 0],
        ['displayLessComments', $('#js-quote-comments').length !== 0],
        ['quoteDetailsNavigation', $('.js-quote-actions').length !== 0],
        ['bindQuoteButtons', $('.js-btn-quote').length !== 0],
        ['bindEditQuoteButton', $('.js-quote-edit-btn').length !== 0],
        ['bindSubmitConfirmation', $('.js-quote-submit-btn').length !== 0],
        ['bindCancelConfirmation', $('.js-quote-cancel-btn').length !== 0],
        ['bindName', $('#js-quote-name').length !== 0],
        ['bindDescription', $('#js-quote-description').length !== 0],
        ['bindExpirationTime', $('#js-quote-expiration-time').length !== 0],
        ['bindCheckoutConfirmation', $('.js-quote-checkout-btn').length !== 0],
        ['bindEditConfirmation', $('.js-quote-warning-btn').length !== 0],
        ['bindQuoteDiscount', $('.js-quote-discount-link').length !== 0],
        ['bindNewCartClick', $('.new__cart--link').length !== 0],
        ['bindRemoveQuoteClick', $('.js-delete-quote').length !== 0],
        ['bindCreateNewQuoteClick', $('.js-create-new-quote-btn').length !== 0],
        ['placeQuoteWithTermsCheck', $('.js-generate-quote').length !== 0],
        ['useDeliveryAddress', $('#useQuoteDeliveryAddress').length !== 0],
        // eslint-disable-next-line max-len
        ['displayNewAddressFormOnLoad', $('#useQuoteDeliveryAddressData').length !== 0 && $('#useQuoteDeliveryAddress').length === 0],
        ['quoteBillingAddressSelection', $('.js-select-quote-billing-address').length !== 0],
        ['billingAddressChange', $('#quoteBillingAddressFormContainer').length !== 0],
        ['bindCancelQuote', $('.js-cancel-quote-button').length !== 0],
        ['closeModals', $('.js-close-modal').length],
        ['showExpiredQuotes', $('.js-show-expired-quotes').length !== 0],
        ['getQuotesByUnit', $('#unitSelector').length !== 0],
        ['bindShowQuoteModal', $('#quote-created').length !== 0],
    ],

    showExpiredQuotes() {
        const showExpiredQuotesCheckbox = $('.js-show-expired-quotes');
        if (!showExpiredQuotesCheckbox.length) return;
        showExpiredQuotesCheckbox.on('change', () => {
            ACC.quote.submitQuoteContextForm();
        });
    },

    addSelectedContextValue(inputId) {
        const contextSelector = $('#unitSelector');
        if (contextSelector.length) {
            $(`#${inputId}`).val($('#unitSelector option:selected').val());
        }
    },

    getQuotesByUnit() {
        const contextSelector = $('#unitSelector');
        if (!contextSelector.length) return;
        contextSelector.on('change', () => {
            ACC.quote.submitQuoteContextForm();
        });
    },

    submitQuoteContextForm() {
        const showExpiredQuotesCheckbox = $('.js-show-expired-quotes');
        if (showExpiredQuotesCheckbox.is(':checked')) {
            ACC.quote.addSelectedContextValue('selectedUnitExpiredQuotes');
            $('#showExpiredQuotesForm').submit();
        } else {
            ACC.quote.addSelectedContextValue('selectedUnitAvailableQuotes');
            $('#showAvailableQuotesForm').submit();
        }
    },

    closeModals() {
        $('.js-close-modal').on('click', $modal.close);
    },

    bindEditQuoteButton() {
        $('.js-quote-edit-btn').on('click', function () {
            const sUrl = $(this).data('quoteEditUrl');
            window.location = sUrl;
        });
    },

    bindNewCartClick() {
        $('.new__cart--link').bind('click', function (event) {
            $(this).unbind(event);
        });
    },

    bindAddComment() {
        $(document).on(
            'keypress',
            '#js-quote-comments #comment',
            (event) => {
                const key = event.keyCode;

                // If the user has pressed enter
                if (key === 13) {
                    if ($('#comment').val().trim() === '') {
                        return false;
                    }
                    event.preventDefault();
                    ACC.quote.quoteCommentSubmit($('#comment').val());
                    $('#comment').val('');
                    return false;
                }
                return true;
            },
        );
    },

    bindAddEntryComment() {
        $(document).on(
            'keypress',
            '.js-quote-entry-comments',
            function (event) {
                const key = event.keyCode;

                if (key === 13) {
                    event.preventDefault();
                    ACC.quote.quoteEntryCommentSubmit($(this).val(), $(this).data('entry-number'));
                    return false;
                }
                return true;
            },
        );
    },

    bindQuoteButtons() {
        $('.js-save-quote-btn').click(function () {
            const url = $(this).data('saveQuoteUrl');
            $('#quoteForm').attr('action', url).submit();
        });
        $('.js-submit-quote-btn').click(function () {
            const url = $(this).data('submitQuoteUrl');
            $('#quoteForm').attr('action', url).submit();
        });
        $('.js-accept-quote-btn').click(function () {
            const url = $(this).data('acceptQuoteUrl');
            $('#quoteForm').attr('action', url).submit();
        });
    },

    quoteCommentSubmit(comment) {
        const quoteComments = $('#js-quote-comments');
        const addCommentUrl = `${quoteComments.data('quote-base-link')}comment`;
        const showAllComments = quoteComments.data('show-all-comments');

        $.ajax({
            url: addCommentUrl,
            data: {
                comment,
            },
            type: 'post',
            success() {
                ACC.quote.onCommentSuccess(showAllComments);
            },
        });
    },

    quoteEntryCommentSubmit(comment, entryNumber) {
        if (!comment || !comment.length) {
            return;
        }

        const quoteComments = $('#js-quote-comments');
        const addEntryCommentUrl = `${quoteComments.data('quote-base-link')}entry/comment`;

        $.ajax({
            url: addEntryCommentUrl,
            data: {
                comment,
                entryNumber,
            },
            type: 'post',
            success() {
                ACC.quote.onEntryCommentSuccess(entryNumber);
            },
        });
    },

    // eslint-disable-next-line no-unused-vars
    onCommentSuccess(showAllComments) {},

    onEntryCommentSuccess(entryNumber) {
        $(`#entryComment_${entryNumber}`).val('');
    },

    toggleMoreComments() {
        $(document).on('click', '#moreCommentsAnchor', ACC.quote.displayMoreComments);
        $(document).on('click', '.js-more-entry-comments-anchor', ACC.quote.displayMoreEntryComments);
    },

    toggleLessComments() {
        $(document).on('click', '#lessCommentsAnchor', ACC.quote.displayLessComments);
        $(document).on('click', '.js-less-entry-comments-anchor', ACC.quote.displayLessEntryComments);
    },

    displayMoreComments(e) {
        e.preventDefault();
        ACC.quote.displayComments('true');
    },

    displayMoreEntryComments(e) {
        e.preventDefault();
        ACC.quote.displayEntryComments($(this).data('entry-number'), 'true');
    },

    displayLessComments(e) {
        if (e !== undefined) {
            e.preventDefault();
        }
        ACC.quote.displayComments('false');
    },

    displayLessEntryComments(e) {
        e.preventDefault();
        ACC.quote.displayEntryComments($(this).data('entry-number'), 'false');
    },

    displayComments(showAll) {
        const quoteComments = $('#js-quote-comments');
        const currentCommentsShown = quoteComments.data('current-comments-shown');
        const comments = $('[id^="comment_"]');

        // iterate over comments. If showAll, just show, otherwise check if max comments is reached then hide
        for (let i = 0; i < comments.length; i += 1) {
            if (showAll === 'true') {
                $(comments[i]).show();
            } else if (i < currentCommentsShown) {
                $(comments[i]).show();
            } else {
                $(comments[i]).hide();
            }
        }

        // toggle anchors
        if (showAll === 'false') {
            $('#moreCommentsAnchor').show();
            $('#lessCommentsAnchor').hide();
            quoteComments.data('show-all-comments', false);
        } else {
            $('#moreCommentsAnchor').hide();
            $('#lessCommentsAnchor').show();
            quoteComments.data('show-all-comments', true);
        }

        return false;
    },

    displayEntryComments(entryNumber, showAll) {
        const quoteEntryComments = $(`#entryCommentListDiv_${entryNumber}`);
        const comments = quoteEntryComments.find(`[id^="entryComment_${entryNumber}"]`);

        showAll = showAll || `${quoteEntryComments.data('show-all-entry-comments')}`;

        // iterate over comments. If showAll, just show, otherwise check if max comments is reached then hide
        for (let i = 0; i < comments.length; i += 1) {
            if (showAll === 'true') {
                $(comments[i]).show();
            } else if (i < 4) {
                $(comments[i]).show();
            } else {
                $(comments[i]).hide();
            }
        }

        // toggle anchors
        if (showAll === 'false') {
            quoteEntryComments.find('.js-more-entry-comments-anchor').show();
            quoteEntryComments.find('.js-less-entry-comments-anchor').hide();
            quoteEntryComments.data('show-all-entry-comments', false);
        } else {
            quoteEntryComments.find('.js-more-entry-comments-anchor').hide();
            quoteEntryComments.find('.js-less-entry-comments-anchor').show();
            quoteEntryComments.data('show-all-entry-comments', true);
        }

        return false;
    },

    quoteDetailsNavigation() {
        $('.js-quote-actions').on('click', function () {
            $(this).parent().find('nav').toggleClass('display-none');
        });
    },

    bindSubmitConfirmation() {
        ACC.quote.handleConfirmationModal({
            actionButtonSelector: '.js-quote-submit-btn',
            modalWindowSelector: '#js-quote-submit-modal',
            modalTitleDataAttributeName: 'submit-confirmation-modal-title',
            cancelButtonSelector: '#js-quote-submit-modal #submitNoButton',
        });

        $('#quoteSubmitForm').submit(function (event) {
            const quoteForm = $('#quoteForm');

            if (quoteForm.is('form')) {
                event.preventDefault();

                const submitUrl = $(this).prop('action');

                quoteForm.prop('action', submitUrl);
                quoteForm.submit();
            }
        });
    },

    bindCheckoutConfirmation() {
        ACC.quote.handleConfirmationModal({
            actionButtonSelector: '.js-quote-checkout-btn',
            modalWindowSelector: '#js-quote-checkout-modal',
            modalTitleDataAttributeName: 'submit-confirmation-modal-title',
            cancelButtonSelector: '#js-quote-checkout-modal #submitNoButton',
        });
    },

    bindCancelConfirmation() {
        ACC.quote.handleConfirmationModal({
            actionButtonSelector: '.js-quote-cancel-btn',
            modalWindowSelector: '#js-quote-cancel-modal',
            modalTitleDataAttributeName: 'cancel-confirmation-modal-title',
            cancelButtonSelector: '#js-quote-cancel-modal #cancelNoButton',
        });
    },

    bindEditConfirmation() {
        ACC.quote.handleConfirmationModal({
            actionButtonSelector: '.js-quote-warning-btn',
            modalWindowSelector: '#js-quote-edit-modal',
            modalTitleDataAttributeName: 'edit-confirmation-modal-title',
            cancelButtonSelector: '#js-quote-edit-modal #cancelEditNoButton',
            confirmButtonSelector: '#js-quote-edit-modal #cancelEditYesButton',
        });
    },

    handleConfirmationModal(options) {
        $(options.actionButtonSelector).click((e) => {
            e.preventDefault();

            const modalWindow = $(options.modalWindowSelector);
            const title = modalWindow.data(options.modalTitleDataAttributeName);

            if (options.initializeCallback) {
                options.initializeCallback();
            }

            ACC.colorbox.open(title, {
                inline: true,
                href: modalWindow,
                // width: "480px",
                escKey: false,
                overlayClose: false,
                onComplete() {
                    ACC.colorbox.resize();
                },
            });
        });

        $(options.cancelButtonSelector).click((e) => {
            e.preventDefault();
            ACC.colorbox.close();
        });

        $(options.confirmButtonSelector).click((e) => {
            e.preventDefault();
            ACC.colorbox.close();
            const sUrl = $(options.actionButtonSelector).data('quoteEditUrl');
            window.location = sUrl;
        });
    },

    bindQuoteDiscount() {
        ACC.quote.handleDiscountModal({
            actionButtonSelector: '.js-quote-discount-link',
            modalWindowSelector: '#js-quote-discount-modal',
            modalTitleDataAttributeName: 'quote-modal-title',
            modalTotalDataAttributeName: 'quote-modal-total',
            modalQuoteDiscountDataAttributeName: 'quote-modal-quote-discount',
            modalCurrencyDataAttributeName: 'quote-modal-currency',
            cancelButtonSelector: '#js-quote-discount-modal #cancelButton',
        });
    },

    handleDiscountModal(options) {
        const modalWindow = $(options.modalWindowSelector);
        const total = parseFloat(modalWindow.data(options.modalTotalDataAttributeName));
        const quoteDiscount = parseFloat(modalWindow.data(options.modalQuoteDiscountDataAttributeName));
        const currency = modalWindow.data(options.modalCurrencyDataAttributeName);

        $(options.actionButtonSelector).click((e) => {
            e.preventDefault();

            const title = modalWindow.data(options.modalTitleDataAttributeName);

            if (options.initializeCallback) {
                options.initializeCallback();
            }

            ACC.colorbox.open(title, {
                inline: true,
                href: modalWindow,
                // width: "480px",
                onComplete() {
                    ACC.colorbox.resize();
                    const percent = (quoteDiscount / total) * 100;
                    const adjustTotal = (total - quoteDiscount).toFixed(2);
                    $('#js-quote-discount-by-percentage').val(percent.toFixed(2));
                    $('#js-quote-discount-by-amount').val(quoteDiscount.toFixed(2));
                    $('#js-quote-discount-adjust-total').val(adjustTotal);
                },
            });
        });

        $(options.cancelButtonSelector).click((e) => {
            e.preventDefault();
            ACC.colorbox.close();
        });

        function enableSubmit() {
            $('#js-quote-discount-by-percentage').css('border-color', '#cccccc');
            $('#js-quote-discount-by-amount').css('border-color', '#cccccc');
            $('#js-quote-discount-adjust-total').css('border-color', '#cccccc');
            $('#submitButton').prop('disabled', false);
        }
        function resetIntial(val) {
            // eslint-disable-next-line no-restricted-globals
            if (isNaN(parseFloat(val))) {
                val = 0.00;
                val = val.toFixed(2);
            }
            return val;
        }
        function updateByPercentage() {
            const percent = parseFloat($('#js-quote-discount-by-percentage').val());
            // input validation
            if (percent > 100 || percent < 0) {
                $('#js-quote-discount-by-percentage').css('border-color', 'red');
                $('#submitButton').prop('disabled', true);
            } else {
                enableSubmit();
            }
            const discountAmount = ((total * percent) / 100).toFixed(2);
            $('#js-quote-discount-by-amount').val(resetIntial(discountAmount));
            const remainTotal = (total - discountAmount).toFixed(2);
            $('#js-quote-discount-adjust-total').val(resetIntial(remainTotal));
            $('#js-quote-discount-rate').val(resetIntial(percent));
            $('#js-quote-discount-type').val('PERCENT');

            // eslint-disable-next-line no-use-before-define
            updateNewTotal(remainTotal);
        }

        $('#js-quote-discount-by-percentage').keyup(updateByPercentage);
        $('#js-quote-discount-by-percentage').change(updateByPercentage);
        // eslint-disable-next-line no-use-before-define
        $('#js-quote-discount-by-percentage').blur(reset);
        // eslint-disable-next-line no-use-before-define
        $('#js-quote-discount-by-percentage').keypress(holdPreviousValue);

        function reset() {
            const per = $('#js-quote-discount-by-percentage').val();
            const amt = $('#js-quote-discount-by-amount').val();
            const tot = $('#js-quote-discount-adjust-total').val();
            if (per === '') { $('#js-quote-discount-by-percentage').val('0.00'); }
            if (amt === '') { $('#js-quote-discount-by-amount').val('0.00'); }
            if (tot === '' || tot === 0.00) { $('#js-quote-discount-adjust-total').val(total); }
        }

        function holdPreviousValue(event) {
            const $this = $(this);
            if ((event.which !== 46 || $this.val().indexOf('.') !== -1)
                && ((event.which < 48 || event.which > 57)
                    && (event.which !== 0 && event.which !== 8))) {
                event.preventDefault();
            }

            const text = $(this).val();
            if ((event.which === 46) && (text.indexOf('.') === -1)) {
                setTimeout(function () {
                    if ($(this).val().substring($this.val().indexOf('.')).length > 3) {
                        $this.val($this.val().substring(0, $this.val().indexOf('.') + 3));
                    }
                }, 1);
            }

            if ((text.indexOf('.') !== -1)
                && (text.substring(text.indexOf('.')).length > 2)
                && (event.which !== 0 && event.which !== 8) && ($(this)[0].selectionStart >= text.length - 2)) {
                event.preventDefault();
            }
        }

        function updateByAmount() {
            const discountAmount = parseFloat($('#js-quote-discount-by-amount').val());

            // input validation
            if (discountAmount > total || discountAmount < 0) {
                $('#js-quote-discount-by-amount').css('border-color', 'red');
                $('#submitButton').prop('disabled', true);
            } else {
                enableSubmit();
            }

            const percent = ((discountAmount / total) * 100).toFixed(2);
            $('#js-quote-discount-by-percentage').val(resetIntial(percent));
            const remainTotal = (total - discountAmount).toFixed(2);
            $('#js-quote-discount-adjust-total').val(resetIntial(remainTotal));
            $('#js-quote-discount-rate').val(resetIntial(discountAmount));
            $('#js-quote-discount-type').val('ABSOLUTE');

            // eslint-disable-next-line no-use-before-define
            updateNewTotal(remainTotal);
        }

        $('#js-quote-discount-by-amount').keyup(updateByAmount);
        $('#js-quote-discount-by-amount').change(updateByAmount);
        $('#js-quote-discount-by-amount').keypress(holdPreviousValue);
        $('#js-quote-discount-by-amount').blur(reset);

        function updateByAdjustTotal() {
            const adujstTotal = parseFloat($('#js-quote-discount-adjust-total').val());

            // input validation
            if (adujstTotal > total || adujstTotal < 0) {
                $('#js-quote-discount-adjust-total').css('border-color', 'red');
                $('#submitButton').prop('disabled', true);
            } else {
                enableSubmit();
            }

            const discountAmount = (total - adujstTotal).toFixed(2);
            $('#js-quote-discount-by-amount').val(resetIntial(discountAmount));
            const percent = ((discountAmount / total) * 100).toFixed(2);
            $('#js-quote-discount-by-percentage').val(resetIntial(percent));
            $('#js-quote-discount-rate').val(resetIntial(adujstTotal));
            $('#js-quote-discount-type').val('TARGET');

            // eslint-disable-next-line no-use-before-define
            updateNewTotal(adujstTotal);
        }

        $('#js-quote-discount-adjust-total').keyup(updateByAdjustTotal);
        $('#js-quote-discount-adjust-total').change(updateByAdjustTotal);
        $('#js-quote-discount-adjust-total').keypress(holdPreviousValue);
        $('#js-quote-discount-adjust-total').blur(reset);

        function updateNewTotal(newTotalNumber) {
            // eslint-disable-next-line no-restricted-globals
            if (isNaN(parseFloat(newTotalNumber))) {
                newTotalNumber = total;
            }
            // eslint-disable-next-line no-redeclare,max-len
            const newTotal = parseFloat(newTotalNumber).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'); // format num to money
            $('#js-quote-discount-new-total').text(currency.concat(newTotal));
        }
    },

    bindName() {
        $('#js-quote-name').on('focusout', () => {
            ACC.quote.updateMetadata();
        });
    },

    bindDescription() {
        $('#js-quote-description').on('focusout', () => {
            ACC.quote.updateMetadata();
        });
    },

    updateMetadata() {
        const quoteForm = $('#quoteFormDiv');
        const updateMetadataUrl = quoteForm.data('metadata-url');
        const name = $('#js-quote-name').val().trim();
        const description = $('#js-quote-description').val();
        const nameWrapperElement = $('#js-quote-name-wrapper');

        if (name && name.length) {
            nameWrapperElement.removeClass('has-error');

            $.ajax({
                url: updateMetadataUrl,
                data: {
                    name,
                    description,
                },
                type: 'POST',
                success() {
                    $('.js-modal-quote-description').text(description);
                    $('.js-modal-quote-name').text(name);
                },
            });
        } else if (!nameWrapperElement.hasClass('has-error')) {
            nameWrapperElement.addClass('has-error');
        }
    },

    bindExpirationTime() {
        const expirationTimeWrapperElement = $('#js-quote-expiration-time');
        const dateFormatForDatePicker = expirationTimeWrapperElement.data('date-format-for-date-picker');
        const minOfferValidityPeriodDays = expirationTimeWrapperElement.data('min-offer-validity-period-days');

        const minDate = new Date();
        minDate.setDate(minDate.getDate() + minOfferValidityPeriodDays);

        $('#expirationTime').datepicker({
            dateFormat: dateFormatForDatePicker,
            constrainInput: true,
            minDate,
            onSelect() {
                ACC.quote.handleExpirationTimeUpdate(
                    expirationTimeWrapperElement,
                    dateFormatForDatePicker,
                    minOfferValidityPeriodDays,
                );
            },
        });

        $('#expirationTime').change(() => {
            ACC.quote.handleExpirationTimeUpdate(
                expirationTimeWrapperElement,
                dateFormatForDatePicker,
                minOfferValidityPeriodDays,
            );
        });

        $(document).on('click', '.js-open-datepicker-quote-expiration-time', () => {
            $('#expirationTime').datepicker('show');
        });
    },

    handleExpirationTimeUpdate(expirationTimeWrapperElement, dateFormat, minOfferValidityPeriodDays) {
        const expirationTimeElement = $('#expirationTime');
        const expirationTime = expirationTimeElement.val();

        if (ACC.quote.validateExpirationTime(dateFormat, expirationTime, minOfferValidityPeriodDays)) {
            ACC.quote.updateExpirationTime(expirationTime.trim());
            expirationTimeWrapperElement.removeClass('has-error');
        } else if (!expirationTimeWrapperElement.hasClass('has-error')) {
            expirationTimeWrapperElement.addClass('has-error');
        }
    },

    validateExpirationTime(dateFormat, value, minOfferValidityPeriodDays) {
        try {
            if (value) {
                const selectedDate = $.datepicker.parseDate(dateFormat, value);

                const validDate = new Date();
                validDate.setHours(0, 0, 0, 0);
                validDate.setDate(validDate.getDate() + minOfferValidityPeriodDays);

                return selectedDate >= validDate;
            }
            return true;
        } catch (error) {
            return false;
        }
    },

    updateExpirationTime(expirationTime) {
        const url = $('#js-quote-expiration-time').data('expiration-time-url');
        $.ajax({
            url,
            type: 'POST',
            data: { expirationTime },
            error() {
                const expirationTimeWrapperElement = $('#js-quote-expiration-time');
                if (!expirationTimeWrapperElement.hasClass('has-error')) {
                    expirationTimeWrapperElement.addClass('has-error');
                }
            },
        });
    },

    bindMyQuoteGenerateOrder() {
        $('.js-order-quote').click(function (event) {
            if (!ACC.onlineTrainings.validateOnlineTrainings(true)) {
                event.preventDefault();
                return;
            }
            if ($(this).hasClass('js-order-quote-cart-has-entries')) {
                ACC.quote.orderFromQuoteButtonOnClick(this);
            }
        });
    },

    bindQuoteListMyQuoteGenerateOrder() {
        $('.js-quote-list-order-quote').click(function (event) {
            const quoteCode = $(this).data('quote-code');
            const validQuoteOnlineTrainingsData = ACC.onlineTrainings.validateQuoteOnlineTrainingsData(quoteCode);

            if (!validQuoteOnlineTrainingsData) {
                event.preventDefault();
                const quoteDetailsLink = $(this).data('quote-details-link');
                // eslint-disable-next-line no-undef
                const url = new URL(DOMPurify.sanitize(quoteDetailsLink), DOMPurify.sanitize(window.location.origin));
                // eslint-disable-next-line no-undef
                const params = new URLSearchParams(DOMPurify.sanitize(url.search));
                params.append('onlineTrainingsError', true);
                url.search = params.toString();
                window.location.href = url.href;
            }
            if ($(this).hasClass('js-order-quote-cart-has-entries')) {
                ACC.quote.orderFromQuoteButtonOnClick(this);
            }
        });
    },

    bindRemoveQuoteClick() {
        $('.js-delete-quote').click(function (event) {
            event.preventDefault();
            // In case where modal content has a ID selector, avoid adding content
            // as 'data-modal-content' like for regular cases, since it creates
            // duplicated HTML inside DOM, which is hard to test ( QA alarmed )
            // Call plugin like this, passing additional arguments to colorbox
            // plugin, to add modal content with 'href' attribute with 'inline'
            // property set to 'true' to not duplicate DOM elements.
            $modal.open(this, {
                href: this.href.substring(this.href.lastIndexOf('#')),
                inline: true,
            });
        });
        $('.js-quote_delete_confirm_modal #quoteDeleteButton').click(function (e) {
            e.preventDefault();
            const quoteCode = $(this).data('quotetodelete-id');
            $modal.close();
            $(`#deleteQuoteActionForm_${quoteCode}`).submit();
        });
    },

    bindCreateNewQuoteClick() {
        $('.js-create-new-quote-btn').click(function (event) {
            event.preventDefault();

            $(this).attr('data-modal-title', $('#js-create-new-quote-modal').data('create-new-quote-modal'));
            $(this).attr('data-modal-content', '#js-create-new-quote-modal');

            $modal.open(this);
        });

        const continueShoppingBtn = $('.js-continue-shopping');
        continueShoppingBtn.click((e) => {
            e.preventDefault();
            $modal.close();
            window.location = continueShoppingBtn.data('target-url');
        });
    },

    bindShowQuoteModal() {
        const modalTrigger = $('#quoteCreatedPopupTrigger');
        if (modalTrigger.data('display-modal') === true) {
            modalTrigger.click();
        }
    },

    placeQuoteWithTermsCheck() {
        const generateQuoteBtn = $('.js-generate-quote');
        const createQuoteForm = $('#placeOrderForm1');
        generateQuoteBtn.click((e) => {
            e.preventDefault();

            const termsAccepted = ACC.secureacceptance.termsAndConditionsChecked();
            const useDeliveryAddress = $('#useQuoteDeliveryAddress').prop('checked');

            ACC.buttonhelper.disable(e.target);

            if (termsAccepted && useDeliveryAddress) {
                createQuoteForm.submit();
                return;
            }

            if (termsAccepted && ACC.quote.isSavedAddress()) {
                ACC.quote.quoteSearchedBillingAddressSelection(generateQuoteBtn);
                return;
            }

            if (termsAccepted) {
                // eslint-disable-next-line no-unused-expressions
                ACC.config.addressDoctorEnabled ? createQuoteForm.submit()
                    : ACC.quote.validateBillingAddress(generateQuoteBtn);
                return;
            }

            ACC.buttonhelper.enable(e.target);
        });
    },
    isAddressSearchFieldInvalid() {
        if ($("#searchAddressForm input[name='addressId']").val() === '') {
            $('.address-search').addClass('has-error');
            $('.address-search .help-block').removeClass('hidden');
            return true;
        }
        return false;
    },

    validateBillingAddress(clickedEl, addressRequiresValidation) {
        if (ACC.quote.isSavedAddress() && ACC.quote.isAddressSearchFieldInvalid()) {
            ACC.buttonhelper.enable(clickedEl);
            return;
        }

        const form = {};
        form.countryIso = $('#quoteBillingAddressForm #address\\.country option:selected').val();
        form.firstName = $('#quoteBillingAddressForm #address\\.firstName').val();
        form.lastName = $('#quoteBillingAddressForm #address\\.surname').val();
        form.line1 = $('#quoteBillingAddressForm #address\\.line1').val();
        form.line2 = $('#quoteBillingAddressForm #address\\.line2').val();
        form.line3 = $('#quoteAddressLine3').val();
        form.townCity = $('#quoteBillingAddressForm #address\\.townCity').val();
        form.postcode = $('#quoteBillingAddressForm #address\\.postcode').val();
        form.titleCode = $('#quoteBillingAddressForm #address\\.title option:selected').val();
        form.phone = ACC.phonemasking.collectPhoneNumber($('#quoteBillingAddressForm #address\\.phone'));
        form.regionIso = $('#quoteBillingAddressForm #address\\.region option:selected').val();
        form.email = $('#quoteBillingAddressForm #address\\.email').val();

        let billingAddressFormDataResponse;

        $.ajax({
            type: 'POST',
            url: `${ACC.config.encodedContextPath}/checkout/quote/create/multi/validate/billingAddress`,
            data: form,
            dataType: 'html',
            async: false,
            success(data) {
                billingAddressFormDataResponse = data;
            },
            error() {
                ACC.buttonhelper.enable(clickedEl);
            },
        }).done(() => {
            $('#quoteBillingAddressFormContainer').html(billingAddressFormDataResponse);
            $('#useQuoteDeliveryAddress').prop('checked', false);
            ACC.utils.preselectValue('#address\\.country');

            ACC.secureacceptance.scrollToFirstInvalidField();

            if (addressRequiresValidation) {
                ACC.addressDoctor.checkShowingSuggestedAddressesPopup(
                    form,
                    '/checkout/multi/validate/showQuoteSuggestedAddressesPopup',
                    true,
                );
                ACC.validation.bindFormValidation('#quoteBillingAddressForm');
            } else if (clickedEl !== null && ($('#isSuccess').val() === 'true' || $('#isSuccess').length === 0)) {
                $('#placeOrderForm1').submit();
            } else {
                ACC.buttonhelper.enable(clickedEl);
                if ($('#isCheckoutV2PersonalContextAndDigitalOnly').val() === 'true') {
                    ACC.secureacceptance.reloadOrderTotals();
                }
            }
        });
    },

    updateAddress(form) {
        let billingAddressFormDataResponse;
        $.ajax({
            type: 'POST',
            url: `${ACC.config.encodedContextPath}/checkout/quote/create/multi/select/suggestion/billingAddress`,
            data: form,
            success(data) {
                billingAddressFormDataResponse = data;
            },
            error(xhr, status, error) {
                // eslint-disable-next-line no-console
                console.log(xhr.responseText);
                if (error) {
                    // eslint-disable-next-line no-console
                    console.error(error.message);
                }
            },
        }).done(() => {
            $('#quoteBillingAddressFormContainer').html(billingAddressFormDataResponse);
            $('#useQuoteDeliveryAddress').prop('checked', false);
            ACC.utils.preselectValue('#address\\.country');
            ACC.addressDoctor.updateQuoteAddressInfo(form);
            ACC.buttonhelper.enable('.js-address-doctor-use-this-address');
            if ($('#isCheckoutV2PersonalContextAndDigitalOnly').val() === 'true') {
                ACC.secureacceptance.reloadOrderTotals();
            }
        });
    },

    useDeliveryAddress() {
        const useQuoteDeliveryAddressCheckbox = $('#useQuoteDeliveryAddress');
        if (useQuoteDeliveryAddressCheckbox.is(':checked')) {
            // eslint-disable-next-line max-len
            const options = ACC.quote.getDeliveryOptions();
            ACC.silentorderpost.enableAddressForm();
            ACC.quote.displayBillingAddressForm(options, ACC.quote.useDeliveryAddressSelected);
            ACC.silentorderpost.disableAddressForm();
        }
        useQuoteDeliveryAddressCheckbox.on('click', () => {
            let options;
            if (useQuoteDeliveryAddressCheckbox.is(':checked')) {
                options = ACC.quote.getDeliveryOptions();
                ACC.silentorderpost.enableAddressForm();
                ACC.silentorderpost.clearSearchAddressForm();
                ACC.quote.displayBillingAddressForm(options, ACC.quote.useDeliveryAddressSelected);
                ACC.silentorderpost.disableAddressForm();
                $('#quoteDeliveryAddress').show();
                $('.quote-search-billing-address').hide();
            } else {
                options = ACC.quote.getDeliveryOptions();
                ACC.quote.displayBillingAddressForm(options, ACC.quote.useDeliveryAddressSelected);
                $('.address-search ul.address').remove();
                ACC.silentorderpost.clearSearchAddressForm();
                ACC.silentorderpost.clearAddressForm();
                $('#quoteDeliveryAddress').hide();
                $('.quote-search-billing-address').show();
                $('.js-address-doctor-edit-address').hide();
                $('input[name=addressGroupType]').first().click();
                // eslint-disable-next-line max-len
                const quoteBillingForm = document.querySelector('#quoteBillingAddressFormContainer');
                ACC.replaceSpecialCharacters.bindSpecialCharactersConversion(quoteBillingForm);
            }
        });
    },

    getDeliveryOptions() {
        return ACC.config.isAssessmentsCheckoutV2Enabled
            // eslint-disable-next-line max-len
            ? { countryIsoCode: $('#useQuoteDeliveryAddressData').data('countryisocode'), usePersonalPrimaryBillingAddress: true }
            // eslint-disable-next-line max-len
            : { countryIsoCode: $('#useQuoteDeliveryAddressData').data('countryisocode'), useQuoteDeliveryAddress: true };
    },

    displayNewAddressFormOnLoad() {
        if ($('#useQuoteDeliveryAddress').length === 0 && $('#quoteDeliveryAddress').length === 0) {
            ACC.quote.displayBillingAddressForm(ACC.quote.getDeliveryOptions(), ACC.quote.useDeliveryAddressSelected);
            $('#quoteDeliveryAddress').hide();
            $('.quote-search-billing-address').show();
        }
    },

    useDeliveryAddressSelected() {
        if ($('#useQuoteDeliveryAddress').is(':checked')) {
            $('#address\\.country').val($('#useQuoteDeliveryAddressData').data('countryisocode'));
            ACC.silentorderpost.disableAddressForm();
            ACC.validation.triggerManualValidation('#placeOrderForm1');
        } else {
            ACC.silentorderpost.clearAddressForm();
            ACC.silentorderpost.clearSearchAddressForm();
            ACC.silentorderpost.enableAddressForm();
        }
    },

    billingAddressChange() {
        $('#quoteBillingAddressFormContainer').on('change', '#quoteBillingCountrySelector :input', function () {
            const countrySelection = $(this).val();
            const options = {
                countryIsoCode: countrySelection,
                useQuoteDeliveryAddress: false,
            };
            ACC.quote.displayBillingAddressForm(options);
        });
    },

    displayBillingAddressForm(options, callback) {
        $.ajax({
            url: `${ACC.config.encodedContextPath}/checkout/quote/create/multi/summary/billingaddressform`,
            data: options,
            dataType: 'html',
            beforeSend() {
                $('#quoteBillingAddressFormContainer').html(ACC.silentorderpost.spinner);
            },
        }).done((data) => {
            $('#quoteBillingAddressFormContainer').html(data);
            // eslint-disable-next-line max-len
            ACC.phonemasking.bind($('#address\\.phone'), options.countryIsoCode === 'UM' ? 'US' : options.countryIsoCode);
            const quoteBillingAddressForm = $('#quoteBillingAddressForm');
            ACC.validation.bindFormValidation(quoteBillingAddressForm);
            ACC.utils.preselectValue('#address\\.country');
            ACC.replaceSpecialCharacters.bindSpecialCharactersConversion(quoteBillingAddressForm[0]);
            if (ACC.config.countriesWithoutPostalCodeValidation.includes(options.countryIsoCode)) {
                quoteBillingAddressForm.validate().settings.rules.postcode = {
                    required: false,
                };
            } else if (ACC.config.countriesWithPostCodeValidation.includes(options.countryIsoCode)) {
                quoteBillingAddressForm.validate().settings.rules.postcode = {
                    required: true,
                    minlength: 1,
                    maxlength: 30,
                    postCodeInvalid: true,
                };
            }

            if (typeof callback === 'function') {
                callback.call();
            }
            ACC.address.hidePostalZipCode(options.countryIsoCode);
        });
    },

    quoteSearchedBillingAddressSelection(clickedEl, addressRequiresValidation) {
        const addressTypeInput = $('input[type=radio][name=addressGroupType]');
        const checkedButton = addressTypeInput.filter(':checked').val();
        const form = checkedButton === 'oneAddress' ? $('#selectAddressForm :input') : $('#searchAddressForm :input');

        $.ajax({
            type: 'POST',
            url: `${ACC.config.encodedContextPath}/checkout/quote/create/multi/summary/selectBillingAddress`,
            data: form.serialize(),
            success(data) {
                billingAddressFormDataResponse = data;
            },
            error(xhr) {
                // eslint-disable-next-line no-console
                console.log(xhr.responseText);
                ACC.buttonhelper.enable(clickedEl);
            },
        }).done(() => {
            $('#quoteBillingAddressFormContainer').html(billingAddressFormDataResponse);
            $('#useQuoteDeliveryAddress').prop('checked', false);
            const addressPresent = $('#isSuccess').val() === 'true';
            const companyAccountUsed = $('#isSuccess').length === 0;

            if (addressPresent || companyAccountUsed) {
                if (addressRequiresValidation) {
                    ACC.addressDoctor.showSuggestedAddressesPopup();
                } else {
                    $('#placeOrderForm1').submit();
                }
            }
        });
    },

    quoteBillingAddressSelection() {
        let billingAddressFormDataResponse;
        $('.js-select-quote-billing-address').on('click', function (e) {
            e.preventDefault();
            $.ajax({
                type: 'POST',
                url: `${ACC.config.encodedContextPath}/checkout/quote/create/multi/summary/selectBillingAddress`,
                data: $(this).closest('#billingAddressBookForm').serialize(),
                async: true,
                success(data) {
                    billingAddressFormDataResponse = data;
                },
                error(xhr) {
                    // eslint-disable-next-line no-console
                    console.log(xhr.responseText);
                },
            }).done(() => {
                $('#quoteBillingAddressFormContainer').html(billingAddressFormDataResponse);
                $('#useQuoteDeliveryAddress').prop('checked', false);
                $.colorbox.close();
            });
        });
    },

    bindCancelQuote() {
        // eslint-disable-next-line no-unused-vars
        const modalWindow = $('#js-cancel-quote-modal');
        const actionButton = $('.js-cancel-quote-button');
        actionButton.click(function (e) {
            e.preventDefault();
            $modal.open(this);
        });
        $('#js-cancel-quote-modal #cancelButton').click((e) => {
            e.preventDefault();
            $modal.close();
            $('#cancelQuoteActionForm').submit();
        });
        $('#js-cancel-quote-modal #returnButton').click((e) => {
            e.preventDefault();
            $modal.close();
        });
    },

    isSavedAddress() {
        const addressTypeInput = $('input[type=radio][name=addressGroupType]');
        const checkedButton = addressTypeInput.filter(':checked').val();
        return checkedButton === 'oneAddress' || checkedButton === 'searchAddress';
    },

    copyToCartButtonOnClick(el) {
        $.when($modal.open(el)).then(() => {
            const submitBtn = $('.js-copy-to-cart-submit:visible');
            ACC.quote.submitButtonQuoteModal(submitBtn);
        });
    },

    orderFromQuoteButtonOnClick(el) {
        $.when($modal.open(el)).then(() => {
            const submitBtn = $('.js-order-from-quote-submit:visible');
            ACC.quote.submitButtonQuoteModal(submitBtn);
        });
    },

    submitButtonQuoteModal(submitBtn) {
        const shouldNotSaveCartCheckbox = $('input[name=shouldNotSaveCart]:visible');
        const savedCartNameInput = $('input[name=savedCartName]:visible');

        savedCartNameInput.keyup(() => {
            submitBtn.prop('disabled', !savedCartNameInput.val().trim());
        });
        shouldNotSaveCartCheckbox.change(() => {
            const checkboxIsChecked = shouldNotSaveCartCheckbox.is(':checked');
            savedCartNameInput.prop('disabled', checkboxIsChecked);
            submitBtn.prop('disabled', !checkboxIsChecked && !savedCartNameInput.val().trim());
        });
    },
};

$(document).ready(() => {
    // toggles the whole quote block
    $('.js-quote-toggle-btn').click(function () {
        $(this).toggleClass('open');
        $('#quote__form--collapse').slideToggle(400);
        if ($(this).hasClass('open')) {
            $(this).addClass('collapsed');
        } else {
            $(this).removeClass('collapsed');
        }
    });

    // toggles only the comments
    $('.js-quote-comments-btn').click(function () {
        $(this).toggleClass('open');
        $('#comments__collapse').slideToggle(400);
        if ($(this).hasClass('open')) {
            $(this).addClass('collapsed');
        } else {
            $(this).removeClass('collapsed');
        }
    });
});
