(function (root, factory) {
    const pluginName = 'FiltersToggle';

    if (typeof define === 'function' && define.amd) {
        define([], factory(pluginName));
    } else if (typeof exports === 'object') {
        module.exports = factory(pluginName);
    } else {
        root[pluginName] = factory(pluginName);
    }

// eslint-disable-next-line no-unused-vars
}(this, (pluginName) => {
    const defaults = {
        hiddenClass: '',
        toggleClass: '',
        categoryClass: '',
        showFiltersClass: '',
        isVisibleClass: '',
        textShowMore: 'Show more +',
        textShowLess: 'Show less -',
        numberOfElementsToShow: 5,
        showMoreOrLessClass: '',
        toggleListClass: '',
        appliedFiltersId: 'appliedFilters',
        appliedFiltersTitleId: 'appliedFiltersTitle',
        componentClass: '.c-filter',
        checkboxClass: 'c-checkbox__input',
        appliedFiltersClass: 'c-filter__applied',
        titleClass: 'c-filter__title',
        productClass: 'program-page__item',
        overallCountClass: '',
        productContentClass: '.program-page__content',
        formatCardClass: 'preferred-format__button',
    };
    /**
   * Merge defaults with user options
   * @param {Object} defaults Default settings
   * @param {Object} options User options
   */
    const extend = function (target, options) {
        let prop; const
            extended = {};
        // eslint-disable-next-line no-restricted-syntax
        for (prop in defaults) {
            if (Object.prototype.hasOwnProperty.call(defaults, prop)) {
                extended[prop] = defaults[prop];
            }
        }
        // eslint-disable-next-line no-restricted-syntax
        for (prop in options) {
            if (Object.prototype.hasOwnProperty.call(options, prop)) {
                extended[prop] = options[prop];
            }
        }
        return extended;
    };

    /**
   * DOM Elements validation
   * @private
   * @param {Object} options User options
   * @returns {Boolean} true if all required DOM elements are present
   */
    const checkForRequredDOMElements = function (options) {
        const component = document.querySelector(options.componentClass);
        // Check if filters component DOM element is present
        if (!component) return;

        // eslint-disable-next-line consistent-return
        return true;
    };

    /**
   * Show an element
   * @param {Object} options User options
   * @param {DOMNode} elem list of filters
   * @function
   */
    const show = function (elem, options) {
    // Get the natural height of the element
        const getHeight = function () {
            elem.style.display = 'block'; // Make it visible
            const height = `${elem.scrollHeight}px`; // Get it's height
            elem.style.display = ''; //  Hide it again
            return height;
        };

        const height = getHeight(); // Get the natural height
        elem.classList.add(options.isVisibleClass); // Make the element visible
        elem.style.height = height; // Update the max-height

        // Once the transition is complete, remove the inline max-height so the content can scale responsively
        window.setTimeout(() => {
            elem.style.height = '';
        }, 350);
    };

    /**
   * Hide an element
   * @param {Object} options User options
   * @param {DOMNode} elem list of filters
   * @function
   */
    const hide = function (elem, options) {
    // Give the element a height to change from
        elem.style.height = `${elem.scrollHeight}px`;

        // Set the height back to 0
        window.setTimeout(() => {
            elem.style.height = '0';
        }, 0);

        // When the transition is complete, hide it
        window.setTimeout(() => {
            elem.classList.remove(options.isVisibleClass);
        }, 350);
    };

    /**
   * Toggle element visibility
   * @param {Object} options User options
   * @param {DOMNode} elem list of filters
   * @function
   */
    const toggle = function (elem, options) {
    // If the element is visible, hide it
        if (elem.classList.contains(options.isVisibleClass)) {
            hide(elem, options);
            return;
        }

        // Otherwise, show it
        show(elem, options);
    };

    /**
   * Toggle list of filters
   * @param {Object} options User options
   * @function
   */
    const toggleMenu = function (options) {
    // Button - toggle list of filters
        const btn = document.getElementsByClassName(options.toggleListClass);

        [].forEach.call(btn, (element) => {
            element.addEventListener('click', function (e) {
                // Sets accoridon buttons to active/notActive
                this.classList.toggle(options.toggleClass);
                // Sets "aria-expanded" to true/false for dropdown buttons
                // eslint-disable-next-line max-len
                this.setAttribute('aria-expanded', e.target.getAttribute('aria-expanded') === 'true' ? 'false' : 'true');
                // Toggle accordion button
                toggle(this.parentNode.nextElementSibling, options);
                // eslint-disable-next-line max-len,no-unused-expressions
                this.parentNode.nextElementSibling.getAttribute('hidden') === 'true' ? this.parentNode.nextElementSibling.removeAttribute('hidden') : this.parentNode.nextElementSibling.setAttribute('hidden', true);
            }, false);
        });
    };

    /**
   * Change state of applied filters
   * @param {Object} options User options
   * @param {function} removeAppliedFilterButton remove applied filter button
   * @param {function} addOrRemoveFilterWithCheckbox create or remove applied filter with checkbox
   * @function
   */
    const appliedFilters = function (options) {
    // Collect filters component
        const component = document.querySelector(options.componentClass);
        // Collect DOM element where applied filters will be shown
        const list = document.getElementById(options.appliedFiltersId);
        // Collect all checkbox elements
        const checkboxFields = component.getElementsByClassName(options.checkboxClass);
        // Collect all applied filters buttons
        const appliedBtn = list.getElementsByClassName(options.appliedFiltersClass);
        // eslint-disable-next-line no-use-before-define
        removeAppliedFilterButton(options, component, list, checkboxFields);
        // eslint-disable-next-line no-use-before-define
        addOrRemoveFilterWithCheckbox(options, checkboxFields, list, component, appliedBtn);
    };

    /**
   * Return string with first capital letter
   * @param {String} s String which will be capitalized
   * @function
   */
    const capitalize = function (s) {
        if (typeof s !== 'string') return '';
        return s.charAt(0).toUpperCase() + s.slice(1);
    };

    /**
   * Update product visibility
   * Set product visibility to true if contains all applied filter
   * @function
   */
    // eslint-disable-next-line consistent-return
    const updateProductVisibility = function () {
        // If there are no filters selected, show all products
        if (window.ppState.appliedFilters.length === 0) {
            // eslint-disable-next-line array-callback-return
            return window.ppState.products.map((product) => {
                if (product.dataset.itemFormats.includes(window.ppState.formats)) product.dataset.itemVisibility = true;
            });
        }

        // eslint-disable-next-line array-callback-return
        window.ppState.products.map((product) => {
            let count = 0;

            // Go through applied filters
            // eslint-disable-next-line array-callback-return
            window.ppState.appliedFilters.map((filter) => {
                if (product.dataset[filter.category] && product.dataset[filter.category].includes(filter.value)) {
                    count += 1;
                }
            });

            // Display product if it has all applied filters
            // eslint-disable-next-line max-len
            if (product.dataset.itemFormats.includes(window.ppState.formats)) product.dataset.itemVisibility = count === window.ppState.appliedFilters.length;
        });
    };

    /**
   * Add selected filter, update items visibility and call pagination update function
   @param {String} filterCategory Filter category
   @param {String} filterValue Filter value
   @param {function} paginationUpdate Update pagination
   */
    const addAppliedFilter = function (filterCategory, filterValue, paginationUpdate) {
        window.ppState.appliedFilters.push({
            category: filterCategory,
            value: filterValue,
        });

        updateProductVisibility();
        paginationUpdate();
    };

    /**
   * Remove selected filter, update items visibility and call pagination update and sort update functions
   @param {String} filterCategory Filter category
   @param {String} filterValue Filter value
   @param {function} paginationUpdate Update pagination
   @param {function} updateSort Update sort
   */
    const removeAppliedFilter = function (filterCategory, filterValue, paginationUpdate, updateSort) {
        const newArr = window.ppState.appliedFilters;

        window.ppState.appliedFilters = newArr.filter((el) => {
            if (el.category === filterCategory && el.value === filterValue) {
                return false;
            }
            return true;
        });

        updateProductVisibility();
        paginationUpdate();
        updateSort();
    };

    /**
   * Create or remove applied filter button element
   * @param {Object} options User options
   * @param {DOMNode} checkboxFields list of checkbox elements
   * @param {DOMNode} list DOM element where applied filters will be shown
   * @param {DOMNode} component filters component
   * @param {DOMNode} appliedBtn list of applied filters buttons
   * @param {function} createAppliedFilter create applied filter button
   * @param {function} removeFilterWithCheckox remove applied filter button
   * @param {function} addTitle add title for applied filters
   * @function
   */
    const addOrRemoveFilterWithCheckbox = function (options, checkboxFields, list, component, appliedBtn) {
    // Attach event listener to each checkbox element
        [].forEach.call(checkboxFields, (elem) => {
            elem.addEventListener('change', function (e) {
                const filterValue = this.value;
                let filterCategory = this.dataset.groupCode;
                filterCategory = filterCategory.toLowerCase();
                filterCategory = `item${capitalize(filterCategory)}`;

                if (e.target.checked) {
                    addAppliedFilter(filterCategory, filterValue, options.paginationUpdate);
                    // eslint-disable-next-line no-use-before-define
                    showOrHideFilter(checkboxFields);
                    // eslint-disable-next-line no-use-before-define
                    showOrHideCategory(options);
                    // eslint-disable-next-line no-use-before-define
                    overallCount(options);
                } else {
                    removeAppliedFilter(filterCategory, filterValue, options.paginationUpdate, options.updateSort);
                    // eslint-disable-next-line no-use-before-define
                    showOrHideFilter(checkboxFields);
                    // eslint-disable-next-line no-use-before-define
                    showOrHideCategory(options);
                    // eslint-disable-next-line no-use-before-define
                    overallCount(options);
                }

                // Gets checkbox text
                const checkboxText = this.nextElementSibling.childNodes[1].innerHTML;

                // Checks if checkbox is checked
                if (this.checked === true) {
                    // eslint-disable-next-line no-use-before-define
                    createAppliedFilter(options, list, checkboxText, filterCategory, filterValue);
                } else {
                    // eslint-disable-next-line no-use-before-define
                    removeFilterWithCheckox(appliedBtn, checkboxText, list);
                }

                // Checks if applied filters button exists
                if (appliedBtn.length > 0) {
                    // Checks if title for applied filters doesn't exists
                    if (!document.getElementById(options.appliedFiltersTitleId)) {
                        // eslint-disable-next-line no-use-before-define
                        addTitle(options, component, list);
                    }
                    // eslint-disable-next-line max-len
                } else if (document.getElementById(options.appliedFiltersTitleId)) { // Checks if title for applied filters exists
                    // Remove child (title for applied filters) from filters component
                    component.removeChild(document.getElementById(options.appliedFiltersTitleId));
                }
            });
        });
    };

    /**
   * Create applied filter button element through checkbox
   * @param {Object} options User options
   * @param {DOMNode} list DOM element where applied filters will be shown
   * @param {DOMNode} checkboxText checkbox label text
   * @function
   */
    const createAppliedFilter = function (options, list, checkboxText, filterCategory, filterValue) {
    // Creates DOM element - li
        const li = document.createElement('li');
        // Creates DOM element - button
        const btn = document.createElement('button');
        // Adds class to the button element
        btn.classList.add(options.appliedFiltersClass);
        // Adds text to the button element
        btn.innerHTML = checkboxText;
        btn.dataset.filterValue = filterValue;
        btn.dataset.groupCode = filterCategory;
        // Appends the button to parent element
        li.appendChild(btn);
        // Appends li to parent element
        list.appendChild(li);
    };

    /**
   * Remove applied filter button through checkbox
   * @param {DOMNode} appliedBtn list of applied filters buttons
   * @param {DOMNode} checkboxText checkbox label text
   * @param {DOMNode} list DOM element where applied filters will be shown
   * @function
   */
    const removeFilterWithCheckox = function (appliedBtn, checkboxText, list) {
    // for each applied filter button compares the text with checkbox label text
        [].forEach.call(appliedBtn, (el) => {
            if (el.innerHTML === checkboxText) {
                // Removes applied filter button from parent element
                list.removeChild(el.parentElement);
            }
        });
    };

    /**
   * Create title for applied filters
   * @param {Object} options User options
   * @param {DOMNode} component filters component
   * @param {DOMNode} list DOM element where applied filters will be shown
   * @function
   */
    const addTitle = function (options, component, list) {
    // Create DOM element - h6
        const title = document.createElement('h6');
        // Adds class to DOM element
        title.classList.add(options.titleClass);
        // Sets id to DOM element
        title.setAttribute('id', options.appliedFiltersTitleId);
        // Creates text node
        const text = document.createTextNode('Applied filters');
        // Append text to DOM element
        title.appendChild(text);
        // Append DOM element to parent element
        component.insertBefore(title, list);
    };

    /**
   * Create or remove applied filter button element
   * @param {Object} options User options
   * @param {DOMNode} component filters component
   * @param {DOMNode} list DOM element where applied filters will be shown
   * @param {DOMNode} label list of checkbox elements
   * @function
   */
    const removeAppliedFilterButton = function (options, component, list, label) {
    // Attach event listener to DOM element
        list.addEventListener('click', function (e) {
            // Gets text from applied filter button
            const textBtn = e.target.innerHTML;
            // for each checkbox compare button text with checkbox label text
            // eslint-disable-next-line no-shadow
            [].forEach.call(label, (e) => {
                // Gets checkbox label text
                const checkboxText = e.nextElementSibling.childNodes[1].innerHTML;
                // Checks if text button is the same as checkbox label text
                if (textBtn === checkboxText) e.checked = false; // Unchecking the checkbox
            });
            // Removes applied filter button
            this.removeChild(e.target.parentElement);
            // Checks if parent element is empty
            // eslint-disable-next-line max-len
            if (list.innerHTML.trim() === '') component.removeChild(document.getElementById(options.appliedFiltersTitleId)); // Removes title for applied filters

            const { filterValue } = e.target.dataset;
            const filterCategory = e.target.dataset.groupCode;

            removeAppliedFilter(filterCategory, filterValue, options.paginationUpdate, options.updateSort);
            // eslint-disable-next-line no-use-before-define
            showOrHideFilter(label);
            // eslint-disable-next-line no-use-before-define
            showOrHideCategory(options);
            // eslint-disable-next-line no-use-before-define
            overallCount(options);
        });
    };

    /**
   * Show or hide filter - depends on the number beside the filter
   * @param {DOMNode} label list of checkbox elements
   * @function
   */
    const showOrHideFilter = function (label) {
        let count = 0;
        // For each filter updates the number - depends on products
        [].forEach.call(label, (element) => {
            let category = element.dataset.groupCode;
            category = category.toLowerCase();
            category = `item${capitalize(category)}`;
            window.ppState.products.forEach((el) => {
                // Checks product visibility
                if (el.dataset.itemVisibility === 'true') {
                    // For every filter increases the count - depends on products
                    if (el.dataset[category] && el.dataset[category].includes(element.value)) count += 1;
                }
            });
            // Change number for every filter
            element.nextElementSibling.childNodes[3].innerHTML = `(${count})`;
            // Sets data attribute display - depends on filter number
            if (!count) {
                element.parentElement.dataset.display = 'none';
            } else {
                element.parentElement.dataset.display = 'display';
            }
            count = 0;
        });
    };

    /**
   * Overall products count
   * @param {Object} options User options
   * @function
   */
    const overallCount = function (options) {
        let count = 0;
        // Check if product vidibility
        window.ppState.products.forEach((el) => {
            if (el.dataset.itemVisibility === 'true') {
                count += 1;
            }
        });
        // Change overall products number
        const overall = document.getElementsByClassName(options.overallCountClass);
        [].forEach.call(overall, (el) => {
            el.childNodes[1].innerHTML = count;
        });
    };

    /**
   * Show/hide category if has/hasn't filters
   * @param {Object} options User options
   * @function
   */
    const showOrHideCategory = function (options) {
        const categoryEl = document.getElementsByClassName(options.categoryClass);
        // For each category checks if filters exists
        [].forEach.call(categoryEl, (el) => {
            const filtersByCategory = el.getElementsByClassName(options.checkboxClass);
            let InvisibleFilters = 0;
            const filtersLength = filtersByCategory.length;
            // Check every filter number
            [].forEach.call(filtersByCategory, (elem) => {
                // Check value of filter data attribute display
                if (elem.parentElement.dataset.display === 'none') InvisibleFilters += 1;
                // Sets parent data attribute (show/hide parent element - depends on filters)
                if (InvisibleFilters === filtersLength) {
                    el.previousElementSibling.dataset.display = 'none';
                    el.classList.remove(options.isVisibleClass);
                } else {
                    el.previousElementSibling.dataset.display = 'display';
                    // eslint-disable-next-line max-len
                    if (el.previousElementSibling.childNodes[1].getAttribute('aria-expanded') === 'true') el.classList.add(options.isVisibleClass);
                }
            });
        });
    };

    /**
   * Default state of products
   * @param {Object} options User options
   * @function
   */
    const defaultState = function (options) {
    // Gets all products
        let products = document.getElementsByClassName(options.productClass);
        // Convert product nodeList to array
        products = Array.prototype.slice.call(products);
        // Sets default state for products
        window.ppState = {
            products,
            appliedFilters: [],
            formats: '',
        };
    };

    /**
   * Default state of filters
   * @param {Object} options User options
   * @function
   */
    const defaultFilterNumber = function (options) {
        const label = document.getElementsByClassName(options.checkboxClass);
        showOrHideFilter(label);
        showOrHideCategory(options);
        overallCount(options);
    };

    /**
   * Format selection
   * @param {Object} options User options
   * @function
   */
    const formatSelection = function (options) {
    // Collect all format buttons
        const formats = document.getElementsByClassName(options.formatCardClass);
        // Collect content of program page
        const content = document.querySelector(options.productContentClass);
        // Get all products
        const formatProducts = window.ppState.products;
        // show all products when Program has no format cards visible
        if (formats.length === 0) {
            content.dataset.contentVisibility = 'true';
            defaultFilterNumber(options);
            // eslint-disable-next-line no-use-before-define
            resetFilters(options);
            options.paginationUpdate();
        }
        // For every format shows correct products
        [].forEach.call(formats, (element) => {
            element.addEventListener('click', (elem) => {
                const isFormatActive = elem.target.parentElement.classList.contains('isActive');
                // Reset all format button on default
                [].forEach.call(formats, (active) => {
                    // eslint-disable-next-line max-len
                    if (active.parentElement.classList.contains('isActive')) active.parentElement.classList.remove('isActive');
                });
                if (isFormatActive) {
                    // Hide content of program page
                    if (content.dataset.contentVisibility === 'true') {
                        content.dataset.contentVisibility = 'false';
                    }
                    // Set visibility for all products to false
                    formatProducts.forEach((el) => {
                        el.dataset.itemVisibility = 'false';
                    });
                } else {
                    // Sets format button to active state
                    elem.target.parentElement.classList.add('isActive');
                    // Sets format state for products
                    window.ppState.formats = element.value;
                    // Sets content of program page to visible
                    if (content.dataset.contentVisibility === 'false') {
                        content.dataset.contentVisibility = 'true';
                    }
                    // Reset visibility for the products
                    formatProducts.forEach((el) => {
                        el.dataset.itemVisibility = 'true';
                    });
                    // Sets visibility for the products - depends on format
                    formatProducts.forEach((el) => {
                        if (!el.dataset.itemFormats.includes(element.value)) el.dataset.itemVisibility = 'false';
                    });

                    if (window.innerWidth <= 768) {
                        const btn = document.getElementsByClassName(options.toggleListClass);
                        // eslint-disable-next-line no-shadow
                        [].forEach.call(btn, (element) => {
                            element.classList.remove(options.toggleClass);
                            element.setAttribute('aria-expanded', 'false');
                        });
                        const category = document.getElementsByClassName(options.categoryClass);
                        // eslint-disable-next-line no-shadow
                        [].forEach.call(category, (element) => {
                            if (element.classList.contains(options.isVisibleClass)) {
                                element.classList.remove(options.isVisibleClass);
                            }
                        });
                    }

                    // Scroll to producst section when tapping on stacked format tiles
                    if (window.innerWidth < 768) {
                        document.querySelector('#products').scrollIntoView({
                            behavior: 'smooth',
                        });
                    }

                    defaultFilterNumber(options);
                    // eslint-disable-next-line no-use-before-define
                    resetFilters(options);
                    options.paginationUpdate();
                    options.updateSort();
                }
            });
        });
    };

    /**
   * Reset filters to default state
   * @param {Object} options User options
   * @function
   */
    const resetFilters = function (options) {
    // Collect filters component
        const component = document.querySelector(options.componentClass);
        // Collect DOM element where applied filters will be shown
        const list = document.getElementById(options.appliedFiltersId);
        // Collect all checkbox elements
        const label = component.getElementsByClassName(options.checkboxClass);

        if (!document.getElementById(options.appliedFiltersTitleId)) return;
        // Remove all applied filters
        list.innerHTML = '';
        // eslint-disable-next-line max-len
        if (list.innerHTML.trim() === '') component.removeChild(document.getElementById(options.appliedFiltersTitleId)); // Removes title for applied filters
        [].forEach.call(label, (e) => {
            e.checked = false; // Unchecking the checkbox
        });
        // Reset applied filters state
        window.ppState.appliedFilters = [];
    };

    /**
   * Plugin Object
   * @param {Object} options User options
   * @constructor
   */
    function Plugin(options) {
        this.options = extend(defaults, options);

        this.init();
    }

    /**
   * Plugin prototype
   * @public
   * @constructor
   */
    Plugin.prototype = {
        init() {
            // Check for component exists in the DOM
            if (checkForRequredDOMElements(this.options)) {
                // default state of products
                defaultState(this.options);
                // TODO: toggle
                toggleMenu(this.options);
                // Create or remove applied filters
                appliedFilters(this.options);
                // Formats function
                formatSelection(this.options);
            }
        },
    };
    return Plugin;
}));
