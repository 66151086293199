ACC.cookieNotificationToggle = {

    _autoload: [
        'aemCookieRemoval',
    ],

    /* $aemCookie
    Purpose: remove aemCookie from DOM
    Note: .cookie-notification gets included with
    ajax call for AEM footer content; since store
    uses its own version we do not need to display
    or render this element. See footer.less for
    display suppression but let's do it one better
    and remove it from the DOM entirely
    ================================================== */
    aemCookieRemoval() {
        const aemCookie = $('footer').find('div.cookie-notification');
        if (aemCookie.length) {
            aemCookie.remove();
        }
    },
};
