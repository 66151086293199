ACC.tieredpricing = {
    _autoload: [
        'showPercentageDiscounts',
    ],

    discountSuffix: $('.js-tiered-discounts').data('discount-suffix'),

    showPercentageDiscounts() {
        $.each($('.program-page__item'), function () {
            ACC.tieredpricing.displayProductDiscounts(this);
        });
    },

    displayProductDiscounts(product) {
        const firstTieredPrice = $(product).find('.js-tiered-discounts__item').first()
            .find('.js-tiered-discounts__item-price')
            .data('tiered-price');
        const basePrice = parseFloat(firstTieredPrice);

        $.each($(product).find('.js-tiered-discounts__item:not(:first-child)'), function () {
            const item = $(this);

            const currentPrice = parseFloat(item.find('.js-tiered-discounts__item-price').data('tiered-price'));

            ACC.tieredpricing.displayItemDiscount(item, basePrice, currentPrice);
        });
    },

    displayItemDiscount(item, basePrice, currentPrice) {
        const discount = ACC.tieredpricing.calculatePercentageDiscount(basePrice, currentPrice);

        if (discount && discount > 0) {
            item.find('.js-tiered-discounts__item-discount')
                .text(discount + ACC.tieredpricing.discountSuffix);
        }
    },

    calculatePercentageDiscount(a, b) {
        return Math.round(100 * ((a - b) / a));
    },
};
