ACC.header = {

    _autoload: [
        'loadDynamicHeader',
    ],

    loadDynamicHeader() {
        $.get(`${ACC.config.encodedContextPath}/commerce-header-v2`, (data) => {
            $('#custom-header').html(data);
            if (ACC.config.isUserSignedIn) {
                ACC.iamActions.bindLogoutAction();
            }
        });
    },
};
