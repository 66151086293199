const oDoc = document;

ACC.navigation = {

    _autoload: [
        'headerContextSwitch',
        'orderToolsNavigation',
        'jumpToAnchor',
        'mobileCtxMenu',
        ['bindMergedUnitsMessage', $('.js-update-merged-units-message-button').length !== 0],
        ['bindMergedCustomerNotification', $('.js-remove-merged-customer-notification').length !== 0],
    ],

    myAccountNavigation() {
        ACC.navigation.myNavigation(false, null);
    },

    myNavigation(isCompany, companyName) {
        let oListItem;
        let oLink;
        let i;
        // copy the site logo
        $('.js-mobile-logo').html($('.js-site-logo a').clone());

        // Add the order form img in the navigation
        $('.nav-form').html($('<span class="glyphicon glyphicon-list-alt"></span>'));

        let aAcctData = [];
        const sSignBtn = '';

        // my account items
        const oMyAccountData = isCompany ? $('.companyNavComponent') : $('.accNavComponent');

        // the my Account hook for the desktop
        const oMMainNavDesktop = isCompany ? $('.js-secondaryNavCompany > ul') : $('.js-secondaryNavAccount > ul');

        const contextSecondNav = $('.js-context-account-menu  > ul');
        const hasContextSecondNav = !!contextSecondNav.length;
        // clear oMMainNavDesktop content
        oMMainNavDesktop.html('');
        contextSecondNav.html('');

        // offcanvas menu for tablet/mobile
        // eslint-disable-next-line no-unused-vars
        const oMainNav = $('.navigation--bottom > ul.nav__links.nav__links--products');

        if (oMyAccountData) {
            const aLinks = oMyAccountData.find('a');
            const tempData = [];
            for (i = 0; i < aLinks.length; i += 1) {
                tempData.push({
                    link: aLinks[i].href,
                    text: aLinks[i].title,
                });
            }

            // remove link duplication
            // eslint-disable-next-line no-inner-declarations
            function duplicationPredicate(element, index, array) {
                let isUnique = true;
                array.forEach((el, ind) => {
                    if (ind !== index && element.text === el.text && element.link === el.link && index < ind) {
                        isUnique = false;
                    }
                });
                return isUnique;
            }
            aAcctData = tempData.filter(duplicationPredicate);
        }

        let navClose = '';
        navClose += '<div class="close-nav">';
        // eslint-disable-next-line max-len
        navClose += '<button type="button" class="js-toggle-sm-navigation btn"><span class="glyphicon glyphicon-remove"></span></button>';
        navClose += '</div>';

        // create Welcome User + expand/collapse and close button
        // This is for mobile navigation. Adding html and classes.
        const oUserInfo = $('.nav__right ul li.logged_in');
        // Check to see if user is logged in
        if (oUserInfo && oUserInfo.length > 0) {
            let sUserBtn = '';
            sUserBtn += '<li class="auto ">';
            sUserBtn += '<div class="userGroup">';
            sUserBtn += '<span class="glyphicon glyphicon-user myAcctUserIcon"></span>';
            sUserBtn += `<div class="userName">${oUserInfo[0].innerHTML}</div>`;
            if (aAcctData.length > 0) {
                // eslint-disable-next-line max-len
                sUserBtn += '<a class="collapsed js-nav-collapse" id="signedInUserOptionsToggle" data-toggle="collapse"  data-target=".offcanvasGroup1">';
                sUserBtn += '<span class="glyphicon glyphicon-chevron-up myAcctExp"></span>';
                sUserBtn += '</a>';
            }
            sUserBtn += '</div>';
            sUserBtn += navClose;

            $('.js-sticky-user-group').html(sUserBtn);

            const title = isCompany ? companyName : oMyAccountData.data('title');

            const accountHookTag = hasContextSecondNav ? '<button>' : '<div>';
            const accountHookClass = hasContextSecondNav ? 'js-context-switcher'
                : 'myAccountLinksHeader js-myAccount-toggle';

            // FOR DESKTOP
            let myAccountHook = $(accountHookTag, {
                class: accountHookClass,
                'data-toggle': 'collapse',
                'data-parent': '.nav__right',
                text: title,
            });
            myAccountHook.insertBefore(oMyAccountData);

            //* For toggling collapse myAccount on Desktop instead of with Bootstrap.js

            $(accountHookClass).attr('tabindex', '0');

            // eslint-disable-next-line no-inner-declarations
            function closeMyAccountMenuOnBodyClick(e) {
                if (!($('.js-secondaryNavAccount > ul.nav__links').is($(e.target)))) {
                    // eslint-disable-next-line no-undef
                    accountLinkFunc();
                }
            }

            if (!hasContextSecondNav) {
                // eslint-disable-next-line no-inner-declarations
                function accountLinkFunc() {
                    $('.myAccountLinksHeader').toggleClass('show');
                    $('.js-secondaryNavAccount').toggle();

                    // Close context menu first
                    $('.dropdownContext--switch').removeClass('toggled').attr('aria-expanded', 'false');

                    $('.my-company-menu').removeClass('show');
                    if ($(this).hasClass('show')) {
                        $('.myCompanyLinksHeader').removeClass('show'); // hide the other one
                        $('.js-secondaryNavCompany').slideUp(300);
                    }

                    if ($('.js-secondaryNavAccount').css('display') === 'block') {
                        $(document).on('click', closeMyAccountMenuOnBodyClick);
                    } else {
                        $(document).off('click', closeMyAccountMenuOnBodyClick);
                    }

                    return false;
                }

                $('.myAccountLinksHeader').click(accountLinkFunc);
                $('.myAccountLinksHeader').on('keypress', (e) => {
                    if (e.which === 13) {
                        accountLinkFunc();
                    }
                });
            }

            // FOR MOBILE
            // create a My Account Top link for desktop - in case more components come then more parameters
            // need to be passed from the backend
            myAccountHook = [];
            myAccountHook.push('<div class="sub-nav">');
            // eslint-disable-next-line max-len
            myAccountHook.push('<a id="signedInUserAccountToggle" class="myAccountLinksHeader collapsed js-myAccount-toggle" data-toggle="collapse" data-target=".offcanvasGroup2">');
            myAccountHook.push(oMyAccountData.data('title'));
            myAccountHook.push('<span class="glyphicon glyphicon-chevron-down myAcctExp"></span>');
            myAccountHook.push('</a>');
            myAccountHook.push('</div>');

            $('.js-myAccountLinksContainer').append(myAccountHook.join(''));

            // add UL element for nested collapsing list
            // eslint-disable-next-line max-len
            $('.js-myAccountLinksContainer').append($('<ul data-trigger="#signedInUserAccountToggle" class="offcanvasGroup2 offcanvasNoBorder collapse js-nav-collapse-body subNavList js-myAccount-root sub-nav"></ul>'));

            //* For toggling collapse on Mobile instead of with Bootstrap.js
            $('#signedInUserAccountToggle').click(function () {
                $(this).toggleClass('show');
                $('.offcanvasGroup2').slideToggle(400);
                if ($(this).hasClass('show')) {
                    $(this).find('span').removeClass('glyphicon-chevron-down').addClass('glyphicon-chevron-up');
                    $('#signedInCompanyToggle').removeClass('show'); // hide the other one
                    $('#signedInCompanyToggle')
                        .find('span')
                        .removeClass('glyphicon-chevron-up')
                        .addClass('glyphicon-chevron-down');
                    $('.offcanvasGroup3').slideUp(400);
                } else {
                    $(this).find('span').removeClass('glyphicon-chevron-up').addClass('glyphicon-chevron-down');
                }
            });

            // offcanvas items
            // TODO Follow up here to see the output of the account data in the offcanvas menu
            for (i = aAcctData.length - 1; i >= 0; i -= 1) {
                oLink = oDoc.createElement('a');
                oLink.title = aAcctData[i].text;
                oLink.href = aAcctData[i].link;
                oLink.textContent = aAcctData[i].text;

                oListItem = oDoc.createElement('li');
                oListItem.appendChild(oLink);
                oListItem = $(oListItem);
                oListItem.addClass('auto ');
                $('.js-myAccount-root').append(oListItem);
            }
        } else {
            // eslint-disable-next-line no-unused-vars
            const navButtons = `${sSignBtn.substring(0, sSignBtn.length - 5) + navClose}</li>`;
        }

        // desktop
        for (i = 0; i < aAcctData.length; i += 1) {
            oLink = oDoc.createElement('a');
            oLink.title = aAcctData[i].text;
            oLink.href = aAcctData[i].link;
            oLink.textContent = aAcctData[i].text;

            oListItem = oDoc.createElement('li');
            oListItem.appendChild(oLink);
            oListItem = $(oListItem);
            if (hasContextSecondNav) {
                contextSecondNav.get(0).appendChild(oListItem.get(0));
            } else {
                oListItem.addClass('auto col-md-4');
                oMMainNavDesktop.get(0).appendChild(oListItem.get(0));
            }
        }

        // hide and show content areas for desktop
        $('.js-secondaryNavAccount').on('shown.bs.collapse', () => {
            if ($('.js-secondaryNavCompany').hasClass('in')) {
                $('.js-myCompany-toggle').click();
            }
        });

        $('.js-secondaryNavCompany').on('shown.bs.collapse', () => {
            if ($('.js-secondaryNavAccount').hasClass('in')) {
                $('.js-myAccount-toggle').click();
            }
        });

        // change icons for up and down
        $('.js-nav-collapse-body').on('hidden.bs.collapse', (e) => {
            const target = $(e.target);
            const targetSpan = `${target.attr('data-trigger')} > span`;
            if (target.hasClass('in')) {
                $(targetSpan).removeClass('glyphicon-chevron-down').addClass('glyphicon-chevron-up');
            } else {
                $(targetSpan).removeClass('glyphicon-chevron-up').addClass('glyphicon-chevron-down');
            }
        });

        $('.js-nav-collapse-body').on('show.bs.collapse', (e) => {
            const target = $(e.target);
            const targetSpan = `${target.attr('data-trigger')} > span`;
            if (target.hasClass('in')) {
                $(targetSpan).removeClass('glyphicon-chevron-up').addClass('glyphicon-chevron-down');
            } else {
                $(targetSpan).removeClass('glyphicon-chevron-down').addClass('glyphicon-chevron-up');
            }
        });
        $('.js-dropdownContext--switch').removeClass('hide');
        ACC.navigation.openContextDropdown();
        ACC.iamActions.bindLogoutAction();
    },

    orderToolsNavigation() {
        $('.js-nav-order-tools').on('click', function () {
            $(this).toggleClass('js-nav-order-tools--active');
        });
    },

    highlightCurrentUnit() {
        const currentUnitId = $('#defaultB2BUnitDataId').data('myUnitId');
        $('.context-menu-section form').each(function () {
            const activeInput = $(this).find('input');
            if (activeInput.length < 2) return;
            const activeUnitBtn = activeInput.get(1).id;
            if (activeUnitBtn === currentUnitId) {
                $(this).addClass('active-context-unit');
            }
        });
    },

    openContextDropdown() {
        const contextDropdown = $('#js-context-dropdown');
        const changeContext = contextDropdown.data('is-context-changed');
        if (changeContext === true) {
            ACC.navigation.switchContext();
            ACC.navigation.highlightCurrentUnit();
        }
    },

    switchContext() {
        const contextMenuControl = $('.js-context-switcher');
        const contextShadow = $('.contextShadow');
        const contextBtn = $('.header-acct--toggle-mobile');
        const menuBrowse = $('.menu-browse');
        const mobileCtxMenu = $('.context-menu-mobile');
        const overlayShadow = $('.overlayShadow');
        const utilityNav = $('.navigation--top');
        const utilityNavHeight = utilityNav.outerHeight(true);
        let systemMsg;
        let systemMsgHeight;
        let maskFromTop;
        const pageHeader = $('.js-mainHeader');
        const mainContent = $('.main__inner-wrapper');

        if ($('.system-msg__container:visible').length) {
            systemMsg = $('.system-msg__container');
            systemMsgHeight = systemMsg.outerHeight(true);
        } else {
            systemMsgHeight = 0;
        }

        let globaMsgHeight = 0;

        if ($('header > .global-message').length) {
            // Adding the height of global message to overlays top property
            $('header > .global-message').each((i, e) => {
                globaMsgHeight += $(e).outerHeight();
            });
        }

        // eslint-disable-next-line prefer-const
        maskFromTop = globaMsgHeight + systemMsgHeight + utilityNavHeight;

        contextShadow.css('top', (`${maskFromTop}px`));

        const pageHeaderHeight = pageHeader.outerHeight(true);

        contextMenuControl
            .toggleClass('toggled')
            .attr('aria-expanded', contextMenuControl.attr('aria-expanded') === 'true' ? 'false' : 'true');

        $('#js-context-dropdown').toggleClass('show');
        $('.context-menu-mobile').toggleClass('show context-menu-options-open');
        contextShadow.hide();

        if (mobileCtxMenu.hasClass('show')) {
            contextBtn.toggleClass('opened');
        }

        if ($('.js-context-switcher.toggled').length) {
            contextShadow.show();
            pageHeader.addClass('fixed-header');
            mainContent.css('padding-top', (`${pageHeaderHeight}px`));
            contextShadow.css('top', (`${maskFromTop}px`));
        } else {
            contextShadow.hide();
            overlayShadow.hide();
            pageHeader.removeClass('fixed-header');
            mainContent.removeAttr('style');
            menuBrowse.removeClass('context-active');
        }

        // Hide merged org message
        if (!$('.context-menu-options-open').length) {
            $('.mobile-merged-org').addClass('hidden');
        }
    },

    headerContextSwitch() {
        $(document).on('click', (e) => {
            if ($(e.target).is('.js-context-switcher') === true) {
                ACC.navigation.switchContext();
            } else if ($('.js-context-switcher.toggled').length) {
                ACC.navigation.switchContext();
            }
        });
    },

    initializeMyUnit() {
        const unitType = $('#defaultB2BUnitDataId').data('myUnitType');
        const unitName = $('#defaultB2BUnitDataId').data('myUnitName');
        const mobileMergedPartiesMessage = $('.context-menu-mobile #mergedPartiesMessageId');

        // Highlight current unit
        ACC.navigation.highlightCurrentUnit();

        // Add shadow overlay just for the context menu switcher
        $('.context-dropdown-container').prepend('<div class="overlayShadow contextShadow"></div>');
        if (unitType && unitName) {
            if (unitType === 'PERSON') {
                ACC.navigation.myNavigation(false, null);
            } else if (unitType === 'ORGANIZATION') {
                ACC.navigation.myNavigation(true, unitName);
            }
        }

        // This is necessary to show the merged org message after switching context
        if ($('.companyNavComponent__child-wrap').length
            && $('.context-menu-mobile #mergedPartiesMessageId').length
            && $('.context-menu-options-open').length === 1) {
            mobileMergedPartiesMessage.removeClass('hidden');
            $('.context-menu-children').removeClass('show');
        }
    },

    mobileCtxMenu() {
        // eslint-disable-next-line no-unused-vars
        const ctxNavMobile = $('.context-menu-mobile-header--btn');
        const subList = $('.context-menu-section');
        $('.context-menu-mobile-header--btn').on('click', function () {
            $(this).find('i').toggleClass('glyphicon-menu-down').toggleClass('glyphicon-menu-up');
            $(this).toggleClass('active');
            subList.show();
        });
    },

    jumpToAnchor() {
        $('.selected-product__text-block').on('click', '.anchor[href^="#"]', function (e) {
            e.preventDefault();

            $('html, body').animate({
                scrollTop: $($.attr(this, 'href')).offset().top,
            }, 500);
        });
    },

    bindMergedUnitsMessage(btn) {
        const defaultSelector = '.js-update-merged-units-message-button';
        ACC.navigation.handleSurvivorUnitMessage({
            actionButtonSelector: btn != null ? btn : defaultSelector,
            actionButtonUrlDataAttributeName: 'target-url',
            unitUid: 'unit-uid',
        });
    },

    handleSurvivorUnitMessage(options) {
        const targetUrl = $(options.actionButtonSelector).data(options.actionButtonUrlDataAttributeName);
        const unitUid = $(options.actionButtonSelector).data(options.unitUid);
        $(options.actionButtonSelector).click((e) => {
            e.preventDefault();
            e.stopImmediatePropagation();
            $.post({
                url: targetUrl,
                data: { unitUid },
                success() {
                    const mergedPartiesMessage = $('#mergedPartiesMessageId');
                    const contextSecondNav = $('.js-context-account-menu  > ul');
                    $('.context-menu-mobile #mergedPartiesMessageId').remove();
                    $('.context-menu-children').addClass('show');

                    mergedPartiesMessage.addClass('hidden');
                    contextSecondNav.removeClass('hidden');
                },
                error(jqXHR, textStatus) {
                    console.log(`The following error occurred: ${textStatus}`);
                },
            });
        });
    },

    bindMergedCustomerNotification() {
        ACC.navigation.removeMergedCustomerNotification({
            actionButtonSelector: '.js-remove-merged-customer-notification',
            actionButtonUrlDataAttributeName: 'target-url',
            customerUid: 'customer-uid',
        });
    },

    removeMergedCustomerNotification(options) {
        const targetUrl = $(options.actionButtonSelector).data(options.actionButtonUrlDataAttributeName);
        const customerUid = $(options.actionButtonSelector).data(options.customerUid);
        $(options.actionButtonSelector).click(() => {
            $.post({
                url: targetUrl,
                data: { customerUid },
                error(jqXHR, textStatus) {
                    console.log(`The following error occurred: ${textStatus}`);
                },
            });
        });
    },
};
