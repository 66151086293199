// eslint-disable-next-line no-undef,no-unused-vars
const paginationInstance = new PearsonPagination({
    sortByElementId: 'sortBy',
    itemWrapperId: 'products',
    itemsWrapperClass: 'program-page__products',
    itemsClass: 'program-page__item',
    paginationComponentClass: 'c-paging',
    pagesClass: 'c-paging__page',
    truncClass: 'dot',
    previousBtn: 'prev',
    nextBtn: 'next',
    activePageClass: 'isActive',
    dotsClass: 'c-paging__page dot',
    numberPerPage: 5,
});
