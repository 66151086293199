// Always call this plugin on 'window load'!
// That way we are sure that all required DOM element will be present
// ( if available inside DOM ) before we fire plugin.
window.addEventListener('load', () => {
    // Create new Plugin instance and init

    // eslint-disable-next-line no-new,no-undef
    new PearsonTabs({
    // Tabs component wrapper class
        tabsWrapperClass: '.c-tabs-wrapper',
        // List of tabs class
        tabButtonWrapperClass: '.c-tabs-list',
        // Wrapper for list class
        tabListWrapperClass: '.c-tabs-list__wrapper',
        // Arrow left class of carousel
        arrowLeftClass: '.scroller-left',
        // Arrow right class of carousel
        arrowRightClass: '.scroller-right',
        // Tab button class
        tabButtonClass: '.c-tab',
        // Tab content wrapper class
        tabContentClass: '.c-tabs-content__wrapper',
        // Class of active tab
        tabButtonActiveClass: '.current-tab',
        // Class of active tab's content
        tabContentActiveClass: '.current-tab-content',
        // Tab course expert button class
        tabCourseExpertButtonClass: '.c-expert-btn',
    });
});
