ACC.backToTop = {

    _autoload: ['init'],

    init() {
        // not inside an iframe
        if (window.top !== window.self) {
            return;
        }

        let back_to_top_text = 'TOP';

        // eslint-disable-next-line max-len
        if (document.documentElement.lang.toLowerCase() === 'fr_ca' || document.documentElement.lang.toLowerCase() === 'fr') {
            back_to_top_text = 'HAUT';
        }

        // eslint-disable-next-line max-len
        const back_to_top_element = $(`<div class="back-to-top__container is-hidden" role="button"><a href="#top" aria-label="Back to top"><img src="/store/_ui/responsive/theme-master/assets/icons/ic_chevron_white.svg" alt="" role="presentation" /><span>${back_to_top_text}</span></a></div>`);

        const main_element = $('body > main');

        $('body')
            .attr('id', 'top');

        if (!$('.back-to-top__container').length) {
            if (main_element.length) {
                main_element.append(back_to_top_element);
            } else {
                $('body').append(back_to_top_element);
            }
        }

        // eslint-disable-next-line no-shadow
        const back_to_top = $('.back-to-top__container');

        $(window)
            .scroll(function () {
                if ($(this).scrollTop() >= 100) {
                    back_to_top
                        .removeClass('is-hidden')
                        .addClass('is-visible');
                } else {
                    back_to_top
                        .removeClass('is-visible')
                        .one('transitionend', () => {
                            back_to_top
                                .addClass('is-hidden');
                        });
                }
            });
    },
};
