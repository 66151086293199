ACC.digitalAccountsViewHelper = {
    initializeRadios() {
        $.each($("input[type='radio']:checked"), (key, value) => {
            const elementId = $(value).attr('id');
            if (elementId) {
                $(`[data-radio-child='${elementId}']`).removeClass('collapse');
                ACC.digitalAccountsViewHelper.showQualifiedUserEmailDiv();
            }
        });

        $('input[type=radio], select').each(function () {
            if ($(this).prop('disabled')) {
                $(this).parent().addClass('disabled');
            }
        });
    },

    setPreselectedElements() {
        ACC.digitalAccountsViewHelper.toggleSelectedOverlayForElement($('#qglobalNew-verificationType'));
        ACC.digitalAccountsViewHelper.toggleSelectedOverlayForElement($('#aimswebNew-verificationType'));
    },

    addQualifiedUserErrorMessage(error, divToAppendErrorMessage) {
        const isAimswebNewForm = ACC.digitalAccountsAimsWeb.isAimswebNewFormUsed();
        const moveSourceNew = $('#newAimswebSelectedQualifiedUser-digitalAccountsQualifiedUserAddEmailSection');
        const qualifiedUserInput = isAimswebNewForm ? $('input', moveSourceNew) : $('input', divToAppendErrorMessage);
        ACC.digitalAccountsViewHelper.removeQualifiedUserErrorMessage();
        qualifiedUserInput.after(`<div class="help-block"> <span class="has-error">${error}<span> </div>`);
        qualifiedUserInput.closest('.form-group').addClass('has-error');
    },

    removeQualifiedUserErrorMessage() {
        $('.help-block', "div[id$='digitalAccountsQualifiedUserAddEmailSection']").remove();
        $('.form-group', "div[id$='digitalAccountsQualifiedUserAddEmailSection']").removeClass('has-error');
        $('#qualified-contact').remove();
        $('#aimsWebQualifiedContact').remove();
    },

    preselectQualifiedUserLabel(qualifiedUserLabelSelector) {
        const isAimswebNewFormUsed = ACC.digitalAccountsAimsWeb.isAimswebNewFormUsed();
        const labelAddNewUser = isAimswebNewFormUsed ? $('#label-qualifiedUserNew') : $('#label-qualifiedUser');
        const qualifiedUserLabel = isAimswebNewFormUsed ? 'qualifiedUserNew' : 'qualifiedUser';
        const labelQualifiedUser = $(`#${$.escapeSelector(qualifiedUserLabelSelector)}`);
        const inputID = labelQualifiedUser.prop('for');
        const isDisabled = labelQualifiedUser.hasClass('disabled');
        if (!isDisabled) {
            $(`#${inputID}`).prop('checked', true);
            $(`#${qualifiedUserLabel}-`).prop('checked', false);
            labelQualifiedUser.addClass('selected');
            labelAddNewUser.removeClass('selected');
            ACC.digitalAccountsViewHelper.hideElement("div[id$='digitalAccountsQualifiedUserAddEmailSection']");
            ACC.digitalAccounts.bindAddQualifiedUserEmail();
        }
        $(':input', "div[id$='digitalAccountsQualifiedUserAddEmailSection']").val('');
    },

    moveEmailField() {
        const moveDestination = $('#label-qualifiedUser');
        let moveSource;
        if (ACC.digitalAccountsQGlobal.hasQGlobalProducts()) {
            moveSource = $('#qglobalSelectedQualifiedUser-digitalAccountsQualifiedUserAddEmailSection');
        } else if (ACC.digitalAccountsAimsWeb.hasAimswebProducts()) {
            const moveSourceNew = $('#newAimswebSelectedQualifiedUser-digitalAccountsQualifiedUserAddEmailSection');
            const moveDestinationNew = $('#label-qualifiedUserNew');

            moveSource = $('#existingAimswebSelectedQualifiedUser-digitalAccountsQualifiedUserAddEmailSection');
            moveDestinationNew.append(moveSourceNew);
        } else {
            moveSource = $('#selectedQualifiedUser-digitalAccountsQualifiedUserAddEmailSection');
        }
        moveDestination.append(moveSource);
    },

    showQualifiedUserEmailDiv() {
        let divID = '#addAimswebAccountID';
        if ($('#qualifiedUserContextSelector').get(0)) {
            divID = '#addQGlobalAccountID';
        }
        const selectedRadioButtonId = $("input[type='radio']:checked", divID).attr('id');
        const qualifiedUserSelectedValue = selectedRadioButtonId
            ? $("select[id$='-qualifiedUserID']", `#${selectedRadioButtonId}-divID`).val()
            : $("select[id$='-qualifiedUserID']").val();
        if (qualifiedUserSelectedValue === '+') {
            ACC.digitalAccountsViewHelper.showElement("div[id$='digitalAccountsQualifiedUserAddEmailSection']");
        }
    },

    showRadioChildContent(radioChild) {
        $(`[data-radio-child-group='${radioChild.attr('name')}']`).hide();
        const childRadioElement = $(`[data-radio-child='${radioChild.attr('id')}']`);
        childRadioElement.removeClass('hidden');
        childRadioElement.show();
        if (ACC.digitalAccounts.getSelectedContextPartyType() !== 'PERSON') {
            $('.js-qglobalPersonalNewVerificationTypeMessage').addClass('hidden');
            $('.js-qglobalPersonalNewVerificationTypeMessage').hide();
        }
    },

    hideRadioChildContent(radioChild) {
        $(`[data-radio-child-group='${radioChild.attr('name')}']`).hide();
        const childRadioElement = $(`[data-radio-child='${radioChild.attr('id')}']`);
        childRadioElement.addClass('hidden');
        childRadioElement.hide();
    },

    resetDivInputs(divId) {
        ACC.digitalAccountsViewHelper.resetCommonDivInputs(divId);
        ACC.digitalAccountsViewHelper.enableDisableRadios(divId, true);
    },

    resetCommonDivInputs(divId) {
        const divInputs = $(':input', divId);
        divInputs.not(':button, :submit, :reset, :checkbox, :radio').val('');
        divInputs.not(':button, :submit, :reset')
            .prop('checked', false)
            .prop('selected', false);
        ACC.digitalAccountsViewHelper.hideElement("div[id$='digitalAccountsQualifiedUserAddEmailSection']");
    },

    showElement(elementId) {
        $(elementId).removeClass('hidden');
        $(`${elementId}> .form-group`).removeClass('hidden');
    },

    hideElement(elementId) {
        $(elementId).addClass('hidden');
    },

    toggleSelectedOverlayForElement(element) {
        const $element = $(element);
        const $parent = $element.parent();

        // eslint-disable-next-line no-unused-expressions
        $element.is(':checked') ? $parent.addClass('selected') : $parent.removeClass('selected');
    },

    enableDisableRadios(divId, disable) {
        $.each($("[id$='-verificationType']", divId), (key, value) => {
            const elementId = $(value).attr('id');
            if (elementId) {
                const radioDivId = `[data-radio-child='${elementId}']`;
                if (disable) {
                    ACC.digitalAccountsViewHelper.hideElement(radioDivId);
                } else {
                    ACC.digitalAccountsViewHelper.showElement(radioDivId);
                }
            }
        });
    },

    enableDisableDivInputs(divId, disable) {
        const divInputs = $(':input', divId);
        divInputs.prop('disabled', disable);
        divInputs.parent().removeClass('disabled');
        if (disable) {
            divInputs.prop('selectedIndex', 0);
        }
    },

    setButtonDisableState(formButtonId, disable) {
        $(formButtonId).prop('disabled', disable);
    },

    enableDisabledElementsInStep2() {
        $('#aimswebExisting-verificationType, #aimswebNew-verificationType').parent().removeClass('disabled');
        $('#aimswebExisting-verificationType, #aimswebNew-verificationType').prop('disabled', false);
        $(':input', '#label-qualifiedUser').prop('disabled', false);
        $(':input', '#label-qualifiedUserNew').prop('disabled', false);
        $('.addQualifiedUserFormSubmit').prop('disabled', false);
        $('.c-checkbox__input').prop('disabled', false);
        $('#existingAccountInfo').prop('disabled', false);
        $('#digitalAccountAimswebAccountNumber').prop('disabled', false);
    },
};
