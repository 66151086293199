ACC.footer = {
    _autoload: ['bindSocialLinks'],

    /* adding social links to the footer */
    bindSocialLinks() {
        const socialLinks = {
            usassessments: {
                fbLink: 'https://www.facebook.com/PearsonAssessments',
                twLink: 'https://twitter.com/PearsonAssessUS',
                ytLink: 'https://www.youtube.com/c/PearsonAssessmentsUS',
                lnLink: 'https://www.linkedin.com/showcase/pearsonassess',
            },
            talentlens: {
                fbLink: 'https://www.facebook.com/TalentLens',
                twLink: 'https://twitter.com/talentlens',
                ytLink: 'https://www.youtube.com/channel/UCoAYnLMc7oiOrk-hirLSIkA',
                lnLink: 'https://www.linkedin.com/company/pearson-talentlens',
            },

        };

        $(document).ready(() => {
            // eslint-disable-next-line max-len
            const activeSite = $('.footer-social-share-container').hasClass('site-talentlens') ? 'talentlens' : 'usassessments';

            /* fill in target class with social links content */
            if ($('.footer-social-share-container').length === 1) {
                // eslint-disable-next-line max-len
                $('.footer-social-share-container').html(`<div class="blog-flex-social-share"><ul><li class="blog-flex-share-fb"><a href="${
                    socialLinks[activeSite].fbLink
                    // eslint-disable-next-line max-len
                }" target="_blank" rel="noopener noreferrer"><span class="share-title">Facebook</span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 147 315" focusable="false"><path class="cls-1" d="M147 55.2H111.4c-7 0-14.1 7.3-14.1 12.7v36.3h49.7c-2 27.8-6.1 53.2-6.1 53.2H97V315H31.8V157.4H0v-53H31.8V61c0-7.9-1.6-61 66.9-61H147V55.2Z"></path></svg></a></li><li class="blog-flex-share-twitter"><a href="${
                    socialLinks[activeSite].twLink
                    // eslint-disable-next-line max-len
                }" target="_blank" rel="noopener noreferrer"><span class="share-title">Twitter</span><svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" focusable="false"><path d="M24.253 8.756C24.69 17.08 18.297 24.182 9.97 24.62c-3.122.162-6.22-.646-8.86-2.32 2.702.18 5.375-.648 7.507-2.32-2.072-.248-3.818-1.662-4.49-3.64.802.13 1.62.077 2.4-.154-2.482-.466-4.312-2.586-4.412-5.11.688.276 1.426.408 2.168.387-2.135-1.65-2.73-4.62-1.394-6.965C5.574 7.816 9.54 9.84 13.802 10.07c-.842-2.738.694-5.64 3.434-6.48 2.018-.624 4.212.043 5.546 1.682 1.186-.213 2.318-.662 3.33-1.317-.386 1.256-1.248 2.312-2.4 2.942 1.048-.106 2.07-.394 3.02-.85-.458 1.182-1.343 2.15-2.48 2.71z"></path></svg></a></li><li class="blog-flex-share-youtube"><a href="${
                    socialLinks[activeSite].ytLink
                    // eslint-disable-next-line max-len
                }" target="_blank" rel="noopener noreferrer"><span class="share-title">Youtube</span></a></li><li class="blog-flex-share-linkedin"><a href="${
                    socialLinks[activeSite].lnLink
                    // eslint-disable-next-line max-len
                }" target="_blank" rel="noopener noreferrer"><span class="share-title">LinkedIn</span><svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" focusable="false"><path d="M25.424 15.887v8.447h-4.896v-7.882c0-1.98-.71-3.33-2.48-3.33-1.354 0-2.158.91-2.514 1.802-.13.315-.162.753-.162 1.194v8.216h-4.9s.067-13.35 0-14.73h4.9v2.087c-.01.017-.023.033-.033.05h.032v-.05c.65-1.002 1.812-2.435 4.414-2.435 3.222 0 5.638 2.106 5.638 6.632zM5.348 2.5c-1.676 0-2.772 1.093-2.772 2.54 0 1.42 1.066 2.538 2.717 2.546h.032c1.71 0 2.77-1.132 2.77-2.546C8.056 3.593 7.02 2.5 5.344 2.5h.005zm-2.48 21.834h4.896V9.604H2.867v14.73z"></path></svg></a></li></ul></div>`);
            }
        });
    },
};
