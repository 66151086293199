ACC.phonemasking = {

    _autoload: ['bindAll'],

    DEFAULT_PHONE_NUMBER_MIN_LENGTH: 10,
    DEFAULT_PHONE_NUMBER_MAX_LENGTH: 15,
    COUNTRY_CODE_PREFIX: '+',
    SUFFIX_DIGIT: '9',
    KEY_CODE_NUMBER_ZERO: 48,
    KEY_CODE_NUMBER_NINE: 57,
    KEY_CODE_NUMBER_ZERO_NUMPAD: 96,
    KEY_CODE_NUMBER_NINE_NUMPAD: 105,
    KEY_CODE_BACKSPACE: 8,
    KEY_CODE_DELETE: 46,
    // eslint-disable-next-line no-undef
    DEFAULT_NUMBER_FORMAT: intlTelInputUtils.numberType.MOBILE,
    COUNTRY_SPECIFIC_DYNAMIC_MASK_DATA: {
        countryIso2List: ['ie', 'id', 'vn'],
        countryPhoneNumberFormatsMap: {
            // eslint-disable-next-line max-len,no-undef
            ie: [intlTelInputUtils.numberType.FIXED_LINE, intlTelInputUtils.numberType.MOBILE, intlTelInputUtils.numberType.TOLL_FREE],
            id: [intlTelInputUtils.numberType.MOBILE, intlTelInputUtils.numberType.FIXED_LINE],
            vn: [intlTelInputUtils.numberType.FIXED_LINE, intlTelInputUtils.numberType.MOBILE],
        },
        countryPhoneNumberPlaceholderMap: {
            us: '555-555-5555',
            id: '812-3456-78901',
        },
    },

    INITIAL_ELEMENTS: [
        { input: $('#contactDetails\\.phone') },
        { input: $('#address\\.phone') },
        { input: $('#phoneNumber'), countrySelector: $('#countryIso'), businessCountrySelector: $('#businessCountry') },
    ],

    bindPhoneInput(input, countrySelector, businessCountrySelector) {
        if (businessCountrySelector) {
            ACC.phonemasking.bind(input, businessCountrySelector.val());
            ACC.phonemasking.bindCountryChange(input, businessCountrySelector);
        } else if (countrySelector) {
            ACC.phonemasking.bind(input, countrySelector.val());
            ACC.phonemasking.bindCountryChange(input, countrySelector);
        } else {
            const addressCountry = $('#address\\.country');
            ACC.phonemasking.bind(input, addressCountry ? addressCountry.val() : null);
        }
    },

    bindAll() {
        $.each(ACC.phonemasking.INITIAL_ELEMENTS, (index, map) => {
            const { countrySelector } = map;
            const { input } = map;
            const { businessCountrySelector } = map;

            ACC.phonemasking.bindPhoneInput(input, countrySelector, businessCountrySelector);
        });
    },

    definePlaceholderBasedOnCountryCode(selectedCountryPlaceholder, selectedCountryData) {
        // eslint-disable-next-line max-len
        return Object.keys(ACC.phonemasking.COUNTRY_SPECIFIC_DYNAMIC_MASK_DATA.countryPhoneNumberPlaceholderMap).find((key) => key === selectedCountryData.iso2)
            // eslint-disable-next-line max-len
            ? ACC.phonemasking.COUNTRY_SPECIFIC_DYNAMIC_MASK_DATA.countryPhoneNumberPlaceholderMap[selectedCountryData.iso2]
            : selectedCountryPlaceholder;
    },

    bind(input, initialValue) {
        if (ACC.config.isIntlTelInputEnabled) {
            const onlyCountries = ACC.config.intlTelInputOnlyCountries;
            let options;
            if (onlyCountries) {
                const onlyCountriesList = onlyCountries.split(',');
                if (initialValue && onlyCountriesList.indexOf(initialValue) === -1) {
                    onlyCountriesList.push(initialValue);
                }
                options = {
                    initialCountry: initialValue,
                    separateDialCode: true,
                    onlyCountries: onlyCountriesList,
                    customPlaceholder(selectedCountryPlaceholder, selectedCountryData) {
                        // eslint-disable-next-line max-len
                        return ACC.phonemasking.definePlaceholderBasedOnCountryCode(selectedCountryPlaceholder, selectedCountryData);
                    },
                };
            } else {
                options = {
                    preferredCountries: ACC.phonemasking.getPreferredCountries(),
                    initialCountry: initialValue,
                    separateDialCode: true,
                    customPlaceholder(selectedCountryPlaceholder, selectedCountryData) {
                        // eslint-disable-next-line max-len
                        return ACC.phonemasking.definePlaceholderBasedOnCountryCode(selectedCountryPlaceholder, selectedCountryData);
                    },
                };
            }
            ACC.phonemasking.init(input, options);
        }
    },

    init(input, options) {
        if (input.length) {
            ACC.phonemasking.fillInput(input);

            const iti = window.intlTelInputGlobals.getInstance(input.get(0));

            if (!iti) {
                // eslint-disable-next-line no-unused-vars
                const instance = window.intlTelInput(input.get(0), options);

                ACC.phonemasking.bindValueChange(input);
                ACC.phonemasking.dynamicInputMaskChange(input);
            }
        }
    },

    bindCountryChange(input, countrySelector) {
        countrySelector.on('change', () => {
            const iti = window.intlTelInputGlobals.getInstance(input.get(0));

            if (iti) {
                iti.setCountry(countrySelector.val());
            }
        });
    },

    bindValueChange(input) {
        ACC.phonemasking.splitCountryCode(input);

        input.on('change', () => {
            ACC.phonemasking.splitCountryCode(input);
        });
    },

    isKeyPressedNumber(event) {
        return (event.which >= ACC.phonemasking.KEY_CODE_NUMBER_ZERO
                        && event.which <= ACC.phonemasking.KEY_CODE_NUMBER_NINE)
                        || (event.which >= ACC.phonemasking.KEY_CODE_NUMBER_ZERO_NUMPAD
                        && event.which <= ACC.phonemasking.KEY_CODE_NUMBER_NINE_NUMPAD);
    },

    getPressedKeyNumberValue(event) {
        if (ACC.phonemasking.isKeyPressedNumber(event)) {
            // eslint-disable-next-line max-len
            const keycode = event.which >= ACC.phonemasking.KEY_CODE_NUMBER_ZERO_NUMPAD ? event.which - ACC.phonemasking.KEY_CODE_NUMBER_ZERO : event.which;
            return String.fromCharCode(keycode);
        }
        return null;
    },

    initDynamicMaskBasedOnConfigs(input) {
        if (ACC.phonemasking.isCountrySpecificDynamicMaskingOn(input)) {
            ACC.phonemasking.initCountrySpecificDynamicMask(input);
        } else {
            ACC.phonemasking.initDynamicMask(input);
        }
    },

    dynamicInputMaskChange(input) {
        ACC.phonemasking.initDynamicMaskBasedOnConfigs(input);

        input.on('countrychange', () => {
            input.val('');
            ACC.phonemasking.splitCountryCode(input);
            ACC.phonemasking.initDynamicMaskBasedOnConfigs(input);
        });

        input.on('keydown', (event) => {
            if (ACC.phonemasking.isCountrySpecificDynamicMaskingOn(input)
                && ACC.phonemasking.isKeyPressedNumber(event)
                && ACC.phonemasking.getLengthDifferenceBetweenMaskAndInputValue(input) === 0) {
                // eslint-disable-next-line max-len
                ACC.phonemasking.initCountrySpecificDynamicMask(input, ACC.phonemasking.getPressedKeyNumberValue(event));
            }
        });

        input.on('keyup', (event) => {
            if (ACC.phonemasking.isCountrySpecificDynamicMaskingOn(input)
                && ((ACC.phonemasking.isKeyPressedNumber(event)
                        && ACC.phonemasking.getLengthDifferenceBetweenMaskAndInputValue(input) !== 0)
                    || ((event.which === ACC.phonemasking.KEY_CODE_BACKSPACE
                            || event.which === ACC.phonemasking.KEY_CODE_DELETE)
                        && ACC.phonemasking.getLengthDifferenceBetweenMaskAndInputValue(input) > 0))) {
                ACC.phonemasking.initCountrySpecificDynamicMask(input);
            }
        });
    },

    isCountrySpecificDynamicMaskingOn(input) {
        const selectedCountryData = ACC.phonemasking.getSelectedCountryData(input);
        const countryList = ACC.phonemasking.COUNTRY_SPECIFIC_DYNAMIC_MASK_DATA.countryIso2List;
        for (let i = 0; i < countryList.length; i += 1) {
            if (countryList[i] === selectedCountryData.iso2) {
                return true;
            }
        }
        return false;
    },

    getLengthDifferenceBetweenMaskAndInputValue(input) {
        const selectedCountryData = ACC.phonemasking.getSelectedCountryData(input);
        const mask = input.data('mask');
        // eslint-disable-next-line max-len
        const maskLength = mask != null ? (selectedCountryData.dialCode + ACC.phonemasking.cleanValue(mask, '')).length : 0;
        const cleanedNumber = selectedCountryData.dialCode + ACC.phonemasking.cleanValue(input.val(), '');
        return maskLength - cleanedNumber.length;
    },

    submitFormCallback(form) {
        if (ACC.config.isIntlTelInputEnabled) {
            const input = $(form).find('.iti input');
            const value = ACC.phonemasking.collectPhoneNumber(input);
            const hiddenInputName = input.attr('name');
            input.attr('name', '');

            $('<input>').attr('type', 'hidden').attr('name', hiddenInputName).val(value)
                .appendTo(form);
        }
    },

    initDynamicMask(input) {
        input.inputmask({
            mask: ACC.phonemasking.createMask(input),
            onBeforeMask(pastedValue) {
                return ACC.phonemasking.onBeforeMaskHandler(input, pastedValue);
            },
        });
    },

    initCountrySpecificDynamicMask(input, overflowDigit) {
        input.inputmask({
            mask: ACC.phonemasking.createCountrySpecificDynamicMask(input, overflowDigit),
            onBeforeMask(pastedValue) {
                return ACC.phonemasking.dynamicMaskOnBeforeHandler(input, pastedValue);
            },
        });
    },

    onBeforeMaskHandler(input, pastedValue) {
        const selectedCountryData = ACC.phonemasking.getSelectedCountryData(input);
        // eslint-disable-next-line max-len,no-undef
        const exampleNumber = ACC.phonemasking.cleanValue(intlTelInputUtils.getExampleNumber(selectedCountryData.iso2, 0, 0), selectedCountryData.dialCode);

        return ACC.phonemasking.shouldAddCountryCodeMark(pastedValue, exampleNumber)
            ? ACC.phonemasking.format(input, `+${pastedValue}`)
            : ACC.phonemasking.format(input, pastedValue);
    },

    dynamicMaskOnBeforeHandler(input, pastedValue) {
        return ACC.phonemasking.format(input, pastedValue);
    },

    getExampleBasedOnNumber(number, countryData, numberType) {
        const cleanedNumber = ACC.phonemasking.cleanValue(number, countryData.dialCode);
        // eslint-disable-next-line max-len
        const initialExampleNumber = ACC.phonemasking.cleanValue(intlTelInputUtils.getExampleNumber(countryData.iso2, 0, numberType), countryData.dialCode);

        if (cleanedNumber.length <= initialExampleNumber.length) {
            return initialExampleNumber;
        }

        let exampleNumber = initialExampleNumber;
        let closestExampleFound = initialExampleNumber;
        while (countryData.dialCode.length + exampleNumber.length < ACC.phonemasking.DEFAULT_PHONE_NUMBER_MAX_LENGTH) {
            exampleNumber += ACC.phonemasking.SUFFIX_DIGIT;
            if (ACC.phonemasking.isNumberValid(exampleNumber, countryData.dialCode)
                && numberType === intlTelInputUtils.getNumberType(exampleNumber, countryData.iso2)) {
                if (exampleNumber.length >= cleanedNumber.length) {
                    return exampleNumber;
                }
                closestExampleFound = exampleNumber;
            }
        }
        return closestExampleFound;
    },

    getNumberTypeIntegerValue(number, countryData) {
        if (ACC.phonemasking.isNumberValid(number, countryData.dialCode)) {
            // eslint-disable-next-line no-undef
            return intlTelInputUtils.getNumberType(number, countryData.iso2);
        }
        return ACC.phonemasking.DEFAULT_NUMBER_FORMAT;
    },

    isNumberValid(number, countryDialCode) {
        const numberWithCountryPrefix = `+${countryDialCode}${ACC.phonemasking.cleanValue(number, countryDialCode)}`;
        return intlTelInputUtils.isValidNumber(numberWithCountryPrefix);
    },

    getNumberTypeStringValue(number, countryData) {
        const numberTypeIntegerValue = ACC.phonemasking.getNumberTypeIntegerValue(number, countryData);
        // eslint-disable-next-line max-len,no-undef
        return Object.keys(intlTelInputUtils.numberType).find((key) => intlTelInputUtils.numberType[key] === numberTypeIntegerValue);
    },

    shouldAddCountryCodeMark(value, example) {
        return !ACC.phonemasking.hasCountryCodeMark(value) && ACC.phonemasking.containsCountryCode(value, example);
    },

    hasCountryCodeMark(value) {
        return value.match('^\\+');
    },

    containsCountryCode(value, example) {
        return ACC.phonemasking.cleanValue(value).length > example.length;
    },

    getPreferredCountries() {
        const preferredCountries = ACC.config.intlTelInputTopCountries;

        if (preferredCountries) {
            return preferredCountries.split(',');
        }

        return ['us', 'gb', 'ca', 'au', 'nz'];
    },

    createMask(input) {
        const placeholder = input.attr('placeholder');
        return placeholder ? placeholder.replace(/[0-9]/g, 9) : '';
    },

    createCountrySpecificDynamicMask(input, overflowDigit) {
        const iti = window.intlTelInputGlobals.getInstance(input.get(0));
        const selectedCountryData = iti.getSelectedCountryData();

        const formattedNumber = ACC.phonemasking.getInputClosestNumberFormatExample(input, overflowDigit);
        iti.setPlaceholderNumberType(ACC.phonemasking.getNumberTypeStringValue(formattedNumber, selectedCountryData));
        const newMask = formattedNumber.replace(new RegExp(`^\\+${selectedCountryData.dialCode}`), '')
            .replace(new RegExp(`^00${selectedCountryData.dialCode}`), '')
            .replace(/[0-9]/g, 9);
        input.data('mask', newMask);
        return newMask;
    },

    getInputClosestNumberFormatExample(input, overflowDigit) {
        const selectedCountryData = ACC.phonemasking.getSelectedCountryData(input);

        let cleanedInputValue = ACC.phonemasking.cleanValue(input.val(), selectedCountryData.dialCode);
        if (overflowDigit != null) {
            cleanedInputValue += overflowDigit;
        }

        const numberWithCountryPrefix = `+${selectedCountryData.dialCode}${cleanedInputValue}`;
        // eslint-disable-next-line max-len
        const acceptedFormats = ACC.phonemasking.COUNTRY_SPECIFIC_DYNAMIC_MASK_DATA.countryPhoneNumberFormatsMap[selectedCountryData.iso2];
        // eslint-disable-next-line no-undef
        if (!intlTelInputUtils.isValidNumber(numberWithCountryPrefix)
            // eslint-disable-next-line max-len
            || !acceptedFormats.includes(intlTelInputUtils.getNumberType(numberWithCountryPrefix, selectedCountryData.iso2))) {
            // eslint-disable-next-line max-len
            const numberExample = ACC.phonemasking.getClosestNumberFormatExample(numberWithCountryPrefix, selectedCountryData);
            cleanedInputValue = ACC.phonemasking.cleanValue(numberExample, selectedCountryData.dialCode);
        }

        // eslint-disable-next-line max-len,no-undef
        return intlTelInputUtils.formatNumber(cleanedInputValue, selectedCountryData.iso2, intlTelInputUtils.numberFormat.INTERNATIONAL);
    },

    getClosestNumberFormatExample(currentNumber, countryData) {
        const closestNumberFormat = ACC.phonemasking.getClosestNumberFormat(currentNumber, countryData);
        // eslint-disable-next-line no-undef
        return ACC.phonemasking.getExampleBasedOnNumber(currentNumber, countryData, closestNumberFormat);
    },

    getClosestNumberFormat(currentNumber, countryData) {
        // eslint-disable-next-line max-len
        const numberFormats = ACC.phonemasking.COUNTRY_SPECIFIC_DYNAMIC_MASK_DATA.countryPhoneNumberFormatsMap[countryData.iso2];
        numberFormats.sort((a, b) => (ACC.phonemasking.compareNumberFormatsLength(a, b, countryData, currentNumber)));

        currentNumber = ACC.phonemasking.cleanValue(currentNumber, '');
        // eslint-disable-next-line max-len,no-undef
        const maxFormatLength = ACC.phonemasking.cleanValue(ACC.phonemasking.getExampleBasedOnNumber(currentNumber, countryData, numberFormats[numberFormats.length - 1]), '').length;
        for (let i = 0; i < numberFormats.length; i += 1) {
            // eslint-disable-next-line max-len,no-undef
            const numberExample = ACC.phonemasking.cleanValue(ACC.phonemasking.getExampleBasedOnNumber(currentNumber, countryData, numberFormats[i]), '');
            if (numberExample.length >= currentNumber.length || numberExample.length === maxFormatLength) {
                return numberFormats[i];
            }
        }
        return numberFormats[numberFormats.length - 1];
    },

    compareNumberFormatsLength(numberType1, numberType2, countryData, currentNumber) {
        // eslint-disable-next-line no-undef
        const numberType1Example = ACC.phonemasking.getExampleBasedOnNumber(currentNumber, countryData, numberType1);
        // eslint-disable-next-line no-undef
        const numberType2Example = ACC.phonemasking.getExampleBasedOnNumber(currentNumber, countryData, numberType2);
        return numberType1Example.length - numberType2Example.length;
    },

    format(input, pastedValue) {
        const iti = window.intlTelInputGlobals.getInstance(input.get(0));
        const selectedCountryData = iti.getSelectedCountryData();

        if (selectedCountryData) {
            const { dialCode } = selectedCountryData;
            const value = pastedValue || iti.getNumber();

            const cleanedValue = ACC.phonemasking.cleanValue(value, dialCode);

            // eslint-disable-next-line max-len
            return ACC.phonemasking.isUk(selectedCountryData) ? ACC.phonemasking.dropLeadingZero(cleanedValue) : cleanedValue;
        }

        return pastedValue;
    },

    getSelectedCountryData(input) {
        return window.intlTelInputGlobals.getInstance(input.get(0)).getSelectedCountryData();
    },

    isUk(selectedCountryData) {
        return selectedCountryData.dialCode === '44';
    },

    isUs(selectedCountryData) {
        return selectedCountryData.iso2 === 'us';
    },

    dropLeadingZero(value) {
        return value.replace(/^0/, '');
    },

    validate(input) {
        const $input = input.get(0);

        if (input.val()) {
            return ACC.phonemasking.validateNumber($input) && ACC.phonemasking.validateLength($input);
        }

        return true;
    },

    validateNumber(input) {
        if (ACC.config.isIntlTelInputEnabled) {
            const iti = window.intlTelInputGlobals.getInstance(input);
            const selectedCountryData = iti.getSelectedCountryData();
            // eslint-disable-next-line max-len
            const acceptedFormats = ACC.phonemasking.COUNTRY_SPECIFIC_DYNAMIC_MASK_DATA.countryPhoneNumberFormatsMap[selectedCountryData.iso2];
            // eslint-disable-next-line max-len
            const isValidFormat = acceptedFormats !== undefined ? acceptedFormats.includes(intlTelInputUtils.getNumberType(iti.getNumber(), selectedCountryData.iso2)) : true;
            return (iti.isValidNumber() && isValidFormat);
        }

        return true;
    },

    validateLength(input) {
        if (ACC.config.isIntlTelInputEnabled) {
            const iti = window.intlTelInputGlobals.getInstance(input);
            const selectedCountryData = iti.getSelectedCountryData();
            const ukInvalidLength = ACC.phonemasking.isUk(selectedCountryData) && iti.getNumber().length < 10;
            const lengthErrorCode = 2;

            return !(iti.getValidationError() === lengthErrorCode || ukInvalidLength);
        }

        return true;
    },

    cleanValue(value, dialCode) {
        if (value) {
            return value.replace(new RegExp(`^\\+${dialCode}`), '')
                .replace(new RegExp(`^00${dialCode}`), '')
                .replace(/[^0-9]/g, '');
        }
        return '';
    },

    collectPhoneNumber(input) {
        if (ACC.config.isIntlTelInputEnabled && input.length !== 0) {
            const iti = window.intlTelInputGlobals.getInstance($(input).get(0));

            return iti ? iti.getNumber() : input.val();
        }

        return input.val();
    },

    splitCountryCode(input) {
        const hiddenPhoneCountryCode = $('#phoneCountryCode');

        if (hiddenPhoneCountryCode.length) {
            const countryData = ACC.phonemasking.getSelectedCountryData($(input));

            if (countryData && countryData.dialCode) {
                hiddenPhoneCountryCode.val(countryData.dialCode);
            }
        }
    },

    fillInput(input) {
        const countryCode = $('#phoneCountryCode').val();
        const phoneNumber = input.val();

        if (countryCode) {
            input.val(ACC.phonemasking.COUNTRY_CODE_PREFIX + countryCode + phoneNumber);
        }
    },

    scrollTo(input) {
        $('html, body').animate({ scrollTop: input.offset().top }, 500);
    },
};
