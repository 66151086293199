/* ###################################################################################################
   ###################################################################################################

   Swiftype Search Functionality
   @link: https://bitbucket.pearson.com/projects/ODC/repos/swiftype/browse
*/

ACC.swiftype = {

    _autoload: [
    // "replaceDots",
        'hashChange',
    // "waitingForHash"
    ],

    replaceDots: function replaceDots() {
        const elements = $('.st-ui-type-detail');
        if (elements) {
            if (elements.length > 0) {
                elements.each(function () {
                    const el = $(this);
                    el.html(el.html().replace(/•/g, '').replace(/&nbsp;/gi, '').trim());
                });
            }
        }
    },

    hashChange() {
        window.addEventListener('hashchange', () => {
            // eslint-disable-next-line max-len
            if (window.location.hash.lastIndexOf('=Group') !== -1 || window.location.hash.lastIndexOf('=group') !== -1) {
                ACC.swiftype.waitingForHash();
                ACC.swiftype.replaceDots();
            }
        }, false);
    },

    waitingForHash() {
        let dotsFixDone = false;

        const swiftType_search = $('#st-overlay-search-input');
        const swiftType_sort = $('#st-sort-selector');
        const swiftType_sor_label = $("<label for='st-sort-selector' id='st-sort-selector-label'>Sort by</label>");
        // eslint-disable-next-line no-sequences,no-unused-expressions
        swiftType_content = $('span.st-ui-type-detail'),
        swiftype_searchEl = $('.st-search-sort-container'),
        swiftype_searchElParent = $('.st-search-sort-container').closest('.st-query-present');

        // first dots replace
        if (swiftType_content && swiftType_content.length > 0) {
            if (dotsFixDone === false) {
                dotsFixDone = true;
                ACC.swiftype.replaceDots();
            }
        }

        // Relocate Sort dropdown
        if (swiftype_searchEl) {
            // Remove dropdown from dom but hold on it's data
            $('.st-search-sort-container').detach();
            // Append it to desired location
            $('.st-ui-injected-search-summary').append(swiftype_searchEl);
            // Add coresponding HTML elements
            swiftType_sort
                .closest('.st-query-present')
                .addClass('flexAlign--horCenter control')
                .prepend(swiftType_sor_label)
                .closest('.st-query-present')
                .append('<i/>');
        }

        // search input
        if (swiftType_search.length > 0) {
            swiftType_search.attr('aria-label', 'Search');
            // eslint-disable-next-line no-undef
            updateContentSearchResultcount();
        }

        // sort dropdown
        if (swiftType_sort) {
            swiftType_sort.attr('aria-label', 'Sort');
        }
    },

};
