ACC.close = {
    _autoload: [
        ['bindCloseAccountModalButtons', $('.js-close-account-popup-button').length !== 0],
        ['bindCloseAccountButton', $('.js-close-account-popup-button').length !== 0],
    ],

    bindCloseAccountModalButtons() {
        $('.js-close-account-popup-button').click((event) => {
            event.preventDefault();
            const popupTitle = $('.js-close-account-popup-button').data('popupTitle');
            ACC.colorbox.open(popupTitle, {
                inline: true,
                href: '#popup_confirm_account_removal',
                className: 'js-close-account-popup',
                onComplete() {
                    $(this).colorbox.resize();
                },
            });
        });
    },

    bindCloseAccountButton() {
        $(document).on('click', '.js-close-account-action', (event) => {
            event.preventDefault();
            const url = `${ACC.config.encodedContextPath}/my-account/close-account`;
            $.ajax({
                url,
                type: 'POST',
                success() {
                    ACC.colorbox.close();
                    // eslint-disable-next-line no-shadow
                    const url = `${ACC.config.encodedContextPath}/logout?closeAcc=true`;
                    window.location.replace(url);
                },
                error(jqXHR, textStatus, errorThrown) {
                    alert(`Failed to close account. Error: [${errorThrown}]`);
                    window.location.reload();
                },
            });
        });
    },
};
