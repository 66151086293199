ACC.existingOrg = {

    _autoload: [
        'bindContinueAssociation',
    ],

    bindContinueAssociation() {
        $('#colorbox').on('click', '#associateExistingSurvivingOrgButton', () => {
            $modal.close();
            $('#number').val('');
        });
    },

};
