ACC.orderstatus = {
    _autoload: [
        ['bindOrderStatusSubmitOnClick', $('[data-recaptcha-control="getOrderStatusRecaptcha"]').length !== 0],
        'bindSelectedOrderSearch',
        'showFindAnotherForm',
        'enableForm',
    ],

    bindSelectedOrderSearch() {
        const orderStatusContainer = $('.js-order-status-form-container');
        const orderNumberSearchOptionBtn = $('.orderNumberRadioBtn');
        const poSearchOptionBtn = $('.poRadioBtn');
        const orderStatusBtn = $('#orderStatusBtn');
        const orderNumberInput = $('.orderNumberInput');
        const orderNumber = $('#orderNumber');
        const radioCard = $('.c-radioCard');
        const recaptcha = $('.captcha');
        const orderNumberForm = $('.poForm');
        const orderNumberValue = $('#orderNumber').val();
        const poNumberValue = $('#purchaseOrderNumber').val();
        const accountNumberValue = $('#accountNumber').val();
        const findAnotherButton = $('.order-status-find-another-button');
        const isFirstStep = orderStatusContainer.data('is-first-step');
        if (!orderNumberSearchOptionBtn.length) return;
        if (!poSearchOptionBtn.length) return;

        if ($('#orderNumber\\.errors').length || orderNumberValue.length) {
            orderNumberSearchOptionBtn.prop('checked', true);
            orderNumberSearchOptionBtn.closest('.c-radioCard').addClass('active');
            orderNumberInput.addClass('isVisible');
            recaptcha.addClass('isVisible');
            $('#orderNumber').val(orderNumberValue);

            if (!isFirstStep) {
                ACC.orderstatus.toggleFindAnotherForm(findAnotherButton);
            }
        }

        if ($('#purchaseOrderNumber\\.errors').length || poNumberValue.length) {
            poSearchOptionBtn.prop('checked', true);
            poSearchOptionBtn.parent().next('.help-block').removeClass('isVisible');
            orderNumberForm.find('.help-block').css('margin-left', '0px');
            poSearchOptionBtn.closest('.c-radioCard').addClass('active');
            orderNumberForm.addClass('isVisible');
            recaptcha.addClass('isVisible');
            $('#purchaseOrderNumber').val(poNumberValue);
            $('#accountNumber').val(accountNumberValue);

            if (!isFirstStep) {
                ACC.orderstatus.toggleFindAnotherForm(findAnotherButton);
            }
        }

        orderNumberSearchOptionBtn.on('change', () => {
            radioCard.removeClass('active');
            orderNumberSearchOptionBtn.closest('.c-radioCard').addClass('active');
            ACC.orderstatus.resetDivInputs('#orderNumberInput');
            ACC.orderstatus.removeErrorMessage('#poForm');
            // eslint-disable-next-line max-len
            ACC.orderstatus.showHideElements(orderNumberInput, recaptcha, orderNumberForm, poSearchOptionBtn, orderNumberSearchOptionBtn, orderStatusBtn);
        });

        poSearchOptionBtn.on('change', () => {
            radioCard.removeClass('active');
            poSearchOptionBtn.closest('.c-radioCard').addClass('active');
            ACC.orderstatus.resetDivInputs('#poForm');
            ACC.orderstatus.removeErrorMessage('#orderNumberInput');
            orderNumber.val('');
            // eslint-disable-next-line max-len
            ACC.orderstatus.showHideElements(orderNumberForm, recaptcha, orderNumberInput, orderNumberSearchOptionBtn, poSearchOptionBtn, orderStatusBtn);
        });
    },

    showFindAnotherForm() {
        const findAnotherButton = $('.order-status-find-another-button');

        findAnotherButton.on('click', () => {
            ACC.orderstatus.toggleFindAnotherForm(findAnotherButton);
        });
    },

    toggleFindAnotherForm(findAnotherButton) {
        const orderStatusForm = $('.order-status-form');
        const chevron = $(findAnotherButton.find('.glyphicon'));

        ACC.orderstatus.toggleClass(orderStatusForm, 'hidden-xs');
        ACC.orderstatus.toggleClass(orderStatusForm, 'hidden-sm');
        ACC.orderstatus.toggleClass(findAnotherButton, 'order-status-find-another-button--active');
        ACC.orderstatus.toggleClass(chevron, 'glyphicon-chevron-down');
        ACC.orderstatus.toggleClass(chevron, 'glyphicon-chevron-up');
    },

    enableForm() {
        $('.order-status').find('input:radio').removeAttr('disabled');
    },

    toggleClass(element, className) {
        // eslint-disable-next-line no-unused-expressions
        element.hasClass(className) ? element.removeClass(className) : element.addClass(className);
    },

    // eslint-disable-next-line max-len
    showHideElements(elementsShownOnRdBtnClick, recaptcha, elementsHiddenOnRdBtnClick, radioButtonUnchecked, radioButtonChecked, orderStatusBtn) {
        elementsShownOnRdBtnClick.addClass('isVisible');
        radioButtonChecked.parent().next('.help-block').removeClass('isVisible');
        recaptcha.addClass('isVisible');
        elementsHiddenOnRdBtnClick.removeClass('isVisible');
        radioButtonUnchecked.parent().next('.help-block').addClass('isVisible');
        radioButtonUnchecked.prop('checked', false);
        orderStatusBtn.prop('disabled', true);

        // resetting captcha when choosing how to find order
        if ($('[data-recaptcha-control="getOrderStatusRecaptcha"]').length) {
            // eslint-disable-next-line no-undef
            grecaptcha.reset();
        }
    },

    resetDivInputs(divId) {
        ACC.orderstatus.resetCommonDivInputs(divId);
    },

    resetCommonDivInputs(divId) {
        const divInputs = $(':input', divId);
        divInputs.not(':button, :submit, :reset, :checkbox, :radio').val('');
        divInputs.not(':button, :submit, :reset')
            .prop('checked', false)
            .prop('selected', false);
    },

    removeErrorMessage(divId) {
        const formGroup = $('.form-group', divId);
        const helpBlock = $('.help-block', formGroup);
        formGroup.removeClass('has-error');
        helpBlock.remove();
    },

    loadAllResults() {
        $('.order-history-list').children().removeClass('hidden');
        $('.js-order-status-load-btn').addClass('hidden');
    },

    bindOrderStatusSubmitOnClick() {
        $('#orderStatusBtn').click((e) => {
            e.preventDefault();
            const form = $('#orderStatusPurchaseOrderForm');

            if (form.valid()) {
                if ($('[data-recaptcha-control="getOrderStatusRecaptcha"]').length) {
                    // eslint-disable-next-line no-undef
                    grecaptcha.execute();
                } else {
                    ACC.orderstatus.orderStatusSubmitForm();
                }
            }

            return false;
        });
    },

    orderStatusSubmitForm() {
        const form = $('#orderStatusPurchaseOrderForm');
        form.submit();
    },
};

// eslint-disable-next-line no-unused-vars
const recaptchaOrderStatusSubmitCallback = function () {
    ACC.orderstatus.orderStatusSubmitForm();
};
