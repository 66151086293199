/** ###########################################################################################################
 ###########################################################################################################

 Name: Pearson tabs Component
 Description: Plugin made for handling tabs functionality
 Dependency: none

 Needs options object passed as argument with following items:

 @param {Object} options - options Object
 @param {String} options.tabsWrapperClass - Tabs wrapper DOM element CSS class
 @param {String} options.tabButtonClass - CSS Class attached on tab button DOM element
 @param {String} options.tabContentClass - CSS Class attached to tab content DOM element
 @param {String} [options.query = location.search] - Document query parameters, used for activating tab thru URL
 @param {String} [options.tabButtonActiveClass = '.current-tab'] - CSS class attached to button when in active state
 @param {String} [options.tabContentActiveClass = '.current-tab-content'] - CSS class attached to tab
 content when in active state

 // Example of options object ( minimum required settings )
 new PearsonTabs({
    tabsWrapperClass: '.tabs-wrapper',
    tabButtonWrapperClass: '.tabs-list',
    tabListWrapperClass: '.c-tabs-list__wrapper',
    arrowLeftClass: '.scroller-left',
    arrowRightClass: '.scroller-right',
    tabButtonClass: '.tab',
    tabContentClass: '.tabs-content__wrapper'
});
 */

(function (root, factory) {
    const pluginName = 'PearsonTabs';

    // eslint-disable-next-line no-undef
    if (typeof define === 'function' && define.amd) {
        // eslint-disable-next-line no-undef
        define([], factory(pluginName));
    } else if (typeof exports === 'object') {
        module.exports = factory(pluginName);
    } else {
        root[pluginName] = factory(pluginName);
    }
// eslint-disable-next-line no-unused-vars
}(this, (pluginName) => {
    const defaults = {
        tabsWrapperClass: '',
        tabButtonClass: '',
        tabCourseExpertButtonClass: '',
        tabContentClass: '',
        tabButtonWrapperClass: '',
        tabListWrapperClass: '',
        arrowLeftClass: '',
        arrowRightClass: '',
        tabButtonActiveClass: '',
        tabContentActiveClass: '',
        docTitle: document.title,
        rightSpace: 20,
    };

    /**
     * Merge defaults with user options
     * @param {Object} defaults Default settings
     * @param {Object} options User options
     */
    // eslint-disable-next-line no-shadow
    const extend = function (defaults, options) {
        let prop;
        const extended = {};
        // eslint-disable-next-line no-restricted-syntax
        for (prop in defaults) {
            if (Object.prototype.hasOwnProperty.call(defaults, prop)) {
                extended[prop] = defaults[prop];
            }
        }
        // eslint-disable-next-line no-restricted-syntax
        for (prop in options) {
            if (Object.prototype.hasOwnProperty.call(options, prop)) {
                extended[prop] = options[prop];
            }
        }
        return extended;
    };

    /**
     * DOM Elements validation
     @private
     @param {Object} options User options
     @returns {Boolean} true if all required DOM elements are present
     */
    const checkForRequiredDOMElements = function (options) {
        // If Wrapper and tab buttons are present
        // Check if tab wrapper DOM element is present
        if (!options.tabsWrapperDOMElement) {
            return false;
        }
        // Check if tab buttons DOM elements are present
        if (options.tabButtonDOMElements.length === 0) {
            return false;
        }
        return true;
    };

    /**
     * Checks for DOM selectors and collects DOM Elements
     @private
     @param {Object} options User options
     */
    const collectDOMElements = function (options) {
        // Throw Error if 'tabsWrapperClass' is not present
        if (!options.tabsWrapperClass) {
            throw new Error('Please provide: tabsWrapperClass param - class of DOM element that '
                + 'wraps whole tab component');
        }
        // Throw Error if 'tabButtonWrapperClass' is not present
        if (!options.tabButtonWrapperClass) {
            throw new Error('Please provide: tabButtonWrapperClass param - class of DOM element that '
                + 'wraps tab buttons');
        }
        // Throw Error if 'tabButtonClass' is not present
        if (!options.tabButtonClass) {
            throw new Error('Please provide: tabButtonClass param - class used on tab button DOM elements');
        }
        // Throw Error if 'tabContentClass' is not present
        if (!options.tabContentClass) {
            throw new Error('Please provide: tabContentClass param - class of DOM element that '
                + 'wraps tab content elements');
        }
        // Get DOM Elements
        options.tabsWrapperDOMElement = document.querySelector(options.tabsWrapperClass);
        options.tabButtonDOMElements = document.querySelectorAll(options.tabButtonClass);
        options.arrowLeftDOMElement = document.querySelector(options.arrowLeftClass);
        options.arrowRightDOMElement = document.querySelector(options.arrowRightClass);
    };

    /**
     * Scroll to tabs component
     @private
     @param {Number} number User options
     */
    const smoothScroll = function (number) {
        const isSmoothScrollSupported = 'scrollBehavior' in document.documentElement.style;
        const scrollToOptions = {
            top: number,
            left: 0,
            behavior: 'smooth',
        };

        // Scroll to desired top offset
        if (isSmoothScrollSupported) {
            // Native smooth scrolling
            window.scroll(scrollToOptions);
        } else {
            // Support scrolling for IE and EDGE
            window.scroll(scrollToOptions.left, scrollToOptions.top);
        }
    };

    /**
     * Attaches 'click' event to tab buttons
     @private
     @param {Object} options User options
     @param {Function} clickCallback Function to be executed on 'click'
     @param {Function} scrollCallback Function to scroll to top
     @param {Function} historyCallback Function add step to browser history
     @param {Function} arrowCallback Function slide left/right carousel
     */
    const attachClickHandler = function (options, clickCallback, scrollCallback, historyCallback, arrowCallback) {
        const tabButtons = options.tabButtonDOMElements;
        const leftArrow = options.arrowLeftDOMElement;
        const rightArrow = options.arrowRightDOMElement;

        // Attach 'click' event to tab buttons
        [].forEach.call(tabButtons, (element) => {
            element.addEventListener('click', (event) => {
                clickCallback(event, options);
                scrollCallback(options);
                historyCallback(event, options);
            }, false);
        });

        if (leftArrow && rightArrow) {
            // Attach 'click' event on left arrow
            leftArrow.addEventListener('click', (event) => {
                arrowCallback(options, event.target);
            });

            // Attach 'click' event on right arrow
            rightArrow.addEventListener('click', (event) => {
                arrowCallback(options, event.target);
            });
        }
    };

    /**
     * Attaches 'click' event to course expert buttons
     */
    const expertNameClick = function (options, getElementOffsetTop) {
        const expertNames = document.querySelectorAll(options.tabCourseExpertButtonClass);
        const expertsContent = document.getElementById('course-experts-block');
        if (expertsContent) {
            [].forEach.call(expertNames, (element) => {
                element.classList.add('active');
                element.addEventListener('click', () => {
                    document.getElementById('overview').click();
                    const expertsContentTopPosition = getElementOffsetTop(expertsContent);
                    smoothScroll(expertsContentTopPosition);
                }, false);
            });
        }
    };

    /**
     * Attaches 'resize' event to tab buttons
     @private
     @param {Object} options User options
     @param {Function} resizeCallback Function to be executed on 'resize'
     */
    const attachResizeHandler = function (options, resizeCallback) {
        const config = {
            tabListWrapperClass: options.tabListWrapperClass,
            tabButtonWrapperClass: options.tabButtonWrapperClass,
            arrowLeft: options.arrowLeftDOMElement,
            arrowRight: options.arrowRightDOMElement,
        };

        // Get from https://developer.mozilla.org/ru/docs/Web/API/Window/resize_event
        let resizeTimeout;

        function resizeThrottler() {
            // ignore resize events as long as an actualResizeHandler execution is in the queue
            if (!resizeTimeout) {
                resizeTimeout = setTimeout(() => {
                    resizeTimeout = null;
                    resizeCallback(config);
                    // The actualResizeHandler will execute at a rate of 15fps
                }, 66);
            }
        }

        window.addEventListener('resize', resizeThrottler, false);
    };

    /**
     *
     * @param {String} query Query string
     * @param {Number} pos position from where substr starts
     * @returns {string[]}
     */
    const getListOfParams = function (query, pos) {
        return query.substr(pos).split('&');
    };

    /**
     *
     * @param {String} element Array element
     * @param {String} param Url param
     * @returns {boolean}
     */
    const getElementForParam = function (element, param) {
        return element.split('=')[0] === param;
    };

    /**
     * Gets tab param
     @private
     @param {String} query Query string
     @param {String} param Url params
     @return {string[]} list of params
     */
    const getTabParams = function (query, param) {
        // Parse query string
        const paramsArray = getListOfParams(query, 1);
        // get element with anchored id
        return paramsArray.filter((element) => getElementForParam(element, param));
    };

    /**
     * Gets the value of the specified param
     * @param {string[]} param List of params
     * @returns {*|string}
     */
    const getParamValue = function (param) {
        return param[0].split('=')[1];
    };

    /**
     * Scroll to the element with anchored id
     @private
     @param {Object} options User options
     @param {String} query Query string
     */
    const goToAnchoredElement = function (options, query) {
        const anchoredElement = getTabParams(query, 'anchorId');
        // get element id
        const elementId = getParamValue(anchoredElement);
        // scroll to found element
        document.getElementById(elementId).scrollIntoView({
            behavior: 'smooth',
        });
    };

    /**
     * Get active tab element
     @private
     @param {Object} options User options
     @param {String} query Query string
     @return {HTMLElement}
     */
    const getActiveTab = function (options, query) {
        const tabParam = getTabParams(query, 'tab');
        // Extract tab query params value
        const tabParamValue = getParamValue(tabParam);
        // Find '.tab' element from query value
        return document.querySelector(`${options.tabButtonClass}[id='${tabParamValue}']`);
    };

    /**
     * Checks if tab has speicfied params
     @private
     @param {String} query Query string
     @param {String} param Url params
     @return {Boolean} True if param exists in query
     */
    const hasTabParams = function (query, param) {
        // Parse query string
        const paramsArray = getListOfParams(query, 1);
        // get element with anchored id
        return paramsArray.some((element) => getElementForParam(element, param));
    };

    /**
     * Checks for query params and activates tab
     @private
     @param {Object} options User options
     */
    const checkQueryParams = function (options) {
        // Default value set as 'window.location.search' ( optional )
        const query = options.tabsWrapperDOMElement.getAttribute('data-query') || window.location.search;
        if (!query) {
            return;
        }

        // Get array of urls query
        const paramsArray = query.substr(1).split('&');
        // Check if query 'tab' is present
        const hasTabParam = paramsArray.some((element) => element.split('=')[0] === 'tab');
        // If query is present
        if (hasTabParam) {
            // Get active tab
            const activeTab = getActiveTab(options, query);
            // Activate that '.tab' element
            if (activeTab) {
                activeTab.click();
                // Check if anchored content is present
                const hasAnchorIdParam = hasTabParams(query, 'anchorId');
                // if anchored content is present
                if (hasAnchorIdParam) {
                    goToAnchoredElement(options, query);
                }
            }
        }
    };

    /**
     *
     * @param {String} element Array element
     * @returns {boolean}
     */
    const getElementForUtmParam = function (element) {
        return element.split('=')[0].startsWith('utm_');
    };

    /**
     * Checks if tab has utm params
     @private
     @param {String} query Query string
     @return {Boolean} True if utm params exists in query
     */
    const hasUtmParams = function (query) {
        // Parse query string
        const paramsArray = getListOfParams(query, 1);

        return paramsArray.some((element) => {
            return getElementForUtmParam(element);
        });
    };

    /**
     * Gets utm param
     @private
     @param {String} query Query string
     @param {String} param Url params
     @return {string[]} list of params
     */
    const getUtmParams = function (query) {
        const paramsArray = getListOfParams(query, 1);
        return paramsArray.filter((element) => {
            return getElementForUtmParam(element);
        });
    };

    /**
     * Get value from top to desire element
     @private
     @param {HTMLElement} el Html element
     @return {Number}
     */
    const getElementOffsetTop = function (el) {
        let top = 0;
        let element = el;

        // Loop through the DOM tree
        // and add it's parent's offset to get page offset
        do {
            top += element.offsetTop || 0;
            element = element.offsetParent;
        } while (element);

        return top;
    };

    const getStickyHeaderHeight = function () {
        const header = document.querySelector('.mojo-nav__wrapper');

        if (header) {
            return header.offsetHeight;
        }
        return 0;
    };

    const scrollToTabs = function (options) {
        const $tabsComponent = document.querySelector(options.tabsWrapperClass);
        const tabsOffsetTop = getElementOffsetTop($tabsComponent);
        smoothScroll(tabsOffsetTop - getStickyHeaderHeight());
    };

    /**
     * Get width of all tabs
     @private
     @param {Element} tabList Html element
     @return {Number}
     */
    const getTabsWidth = function (tabList) {
        let itemsWidth = 0;

        [].forEach.call(tabList.children, (element) => {
            const itemWidth = element.offsetWidth;
            itemsWidth += itemWidth;
        });

        return itemsWidth;
    };

    /**
     * Get Focusable Elements
     @private
     @param {Object} options User options
     */
    function getFocusableElements(options) {
        const tabbedList = document.querySelectorAll(options.tabButtonClass);
        const { filter } = Array.prototype;
        return filter.call(tabbedList, (listItem) => listItem);
    }

    /**
    * Get Non-Focusable Tabs
    @private
    @param {Object} options User options
    @param {Array} tabs Tabs
    */
    function getNonFocusableTabs(options, tabs) {
        return tabs.filter((tab) => !tab.classList.contains(options.tabButtonActiveClass.replace('.', '')));
    }

    /**
     * Change focus with arrows (left and right)
     @private
     @param {Object} options User options
     */
    function arrowNavigation(options) {
        const tabs = document.querySelectorAll(options.tabButtonClass);

        const setTabindexNegative = () => {
            const otherTabs = getNonFocusableTabs(options, [...tabs]);

            otherTabs.forEach((el) => el.setAttribute('tabindex', '-1'));
        };

        [].forEach.call(tabs, (tab) => {
            tab.addEventListener('keydown', (event) => {
                if (event.key === 'Tab') {
                    setTabindexNegative();
                }
            });

            tab.addEventListener('keyup', (event) => {
                const firstTab = getFocusableElements(options)[0];
                const lastTab = getFocusableElements(options)[getFocusableElements(options).length - 1];

                setTabindexNegative();

                if (event) {
                    const { parentNode } = event.target;

                    if (event.key === 'ArrowRight') {
                        if (event.target === lastTab) {
                            firstTab.focus();
                            firstTab.setAttribute('tabindex', '0');
                        } else {
                            const element = parentNode.nextElementSibling.firstElementChild;
                            element.focus();
                            element.setAttribute('tabindex', '0');
                        }
                    }

                    if (event.key === 'ArrowLeft') {
                        if (event.target === firstTab) {
                            lastTab.focus();
                            lastTab.setAttribute('tabindex', '0');
                        } else {
                            const element = parentNode.previousElementSibling.firstElementChild;
                            element.focus();
                            element.setAttribute('tabindex', '0');
                        }
                    }

                    if (event.key === 'Home') {
                        firstTab.focus();
                        firstTab.setAttribute('tabindex', '0');
                    }

                    if (event.key === 'End') {
                        lastTab.focus();
                        lastTab.setAttribute('tabindex', '0');
                    }
                }
            });
        });
    }

    /**
     * Helper function for showing active tab button
     @private
     @param {Object} options User options
     @param {DOMNode} targetButton Button to be set to active
     @param {DOMNode} activeButton Current active button, used for active class removal
     */
    const showActiveTab = function (options, targetButton, activeButton) {
        // If element doesn't have 'active' class
        if (targetButton.getAttribute('class').indexOf(options.tabButtonActiveClass.slice(1)) === -1) {
            // Find active button DOM element, and remove active class from it
            activeButton.classList.remove(options.tabButtonActiveClass.slice(1));
            // Add 'active' class to current clicked button
            targetButton.classList.add(options.tabButtonActiveClass.slice(1));
            // Set 'aria-selected' to false for previous tab
            activeButton.setAttribute('aria-selected', false);
            // Set 'aria-selected' to true for previous tab
            targetButton.setAttribute('aria-selected', true);
            // Set 'tabindex' to -1 for previous tab
            activeButton.setAttribute('tabindex', '-1');
            // Set 'tabindex' to 0 for current tab
            targetButton.removeAttribute('tabindex');
        }
    };

    /**
     * Helper function for showing active tab content
     @private
     @param {Object} options User options
     @param {HTMLElement} targetContent Content to be shown
     @param {DOMNode} tabsWrapper Tabs component wrapper
     */
    const showContent = function (options, targetContent, tabsWrapper) {
        if (targetContent.getAttribute('class').indexOf(options.tabContentActiveClass.slice(1)) === -1) {
            // Find active content DOM element, and remove active class from it
            tabsWrapper.querySelector(options.tabContentActiveClass)
                .setAttribute('class', targetContent.getAttribute('class')
                    .replace(options.tabContentActiveClass.slice(1), ''));
            // Add 'active' class to current content section
            targetContent.setAttribute('class', `${targetContent.getAttribute('class')
            } ${options.tabContentActiveClass.slice(1)}`);
        }
    };

    /**
     * 'Click' callback function
     @private
     @param {Object} eventObject Event object
     @param {Object} options User options
     */
    const onButtonClick = function (eventObject, options) {
        const $this = eventObject.target;
        const tabID = $this.dataset.tabId;
        const $targetContent = document.getElementById(tabID);
        const $tabsWrapper = document.querySelector(options.tabsWrapperClass);
        const $activeButton = $tabsWrapper.querySelector(options.tabButtonActiveClass);
        // eslint-disable-next-line no-unused-vars
        const parentElement = $this.parentNode.parentNode;

        // Return if click on active/selected tab
        if ($this === $activeButton) {
            return;
        }

        // Add active class to the clicked element
        showActiveTab(options, $this, $activeButton);

        // If the element is not visible, set it as 'active'
        showContent(options, $targetContent, $tabsWrapper);

        if (typeof ShowMoreComponent !== 'undefined') {
            // eslint-disable-next-line no-undef
            ShowMoreComponent.trigger();
        }
    };

    /**
     * 'Click' callback function
     @private
     @param {Object} options Event object
     @param {Object} arrow Html element of clicked arrow
     */
    const onArrowClick = function (options, arrow) {
        const tabButtonWrapper = document.querySelector(options.tabButtonWrapperClass);
        const { direction } = arrow.dataset;
        let leftPosition;

        switch (direction) {
        case 'right': {
            const tabListWrapper = document.querySelector(options.tabListWrapperClass);

            options.arrowLeftDOMElement.classList.remove('fade');
            options.arrowRightDOMElement.classList.add('fade');

            leftPosition = tabListWrapper.offsetWidth - getTabsWidth(tabButtonWrapper) - options.rightSpace;
            break;
        }
        case 'left':
            options.arrowLeftDOMElement.classList.add('fade');
            options.arrowRightDOMElement.classList.remove('fade');

            leftPosition = 0;
            break;
        default:
            break;
        }

        tabButtonWrapper.style.left = `${leftPosition}px`;
    };

    /**
     * 'Resize' callback function
     @private
     @param {Object} config Object config with DOM elements
     */
    const onResize = function (config) {
        const tabListWrapper = document.querySelector(config.tabListWrapperClass);
        const tabButtonWrapper = document.querySelector(config.tabButtonWrapperClass);

        if (!config.arrowLeft && !config.arrowRight) {
            return;
        }

        if (tabListWrapper.offsetWidth < getTabsWidth(tabButtonWrapper) && window.innerWidth >= 768) {
            config.arrowRight.classList.remove('fade');
        } else {
            config.arrowRight.classList.add('fade');
        }

        if (tabButtonWrapper.offsetLeft < 0) {
            config.arrowLeft.classList.remove('fade');
        } else {
            config.arrowLeft.classList.add('fade');
        }
    };

    /**
     * Push state to browser history
     @private
     @param {Object} event Event object
     @param {Object} options User options
     */
    const pushHistory = function (event, options) {
        const $this = event.target;
        const btnId = $this.id;
        const tabDocTitle = `${options.docTitle} | ${$this.textContent}`;
        const query = options.tabsWrapperDOMElement.getAttribute('data-query') || window.location.search;

        const host = window.location.href.split('?')[0];
        let url = `${host}?tab=${btnId}`;

        const utmElementExists = hasUtmParams(query);
        if (utmElementExists) {
            const utmParams = getUtmParams(query);
            utmParams.forEach((element) => {
                url += `&${element}`;
            });
        }

        window.history.pushState({ query: `?tab=${btnId}` }, tabDocTitle, url);
        document.title = tabDocTitle;
    };

    /**
     * Listen browser history changes
     @private
     @param {Object} options User options
     */
    const attachHistoryHandler = function (options) {
        window.addEventListener('popstate', (event) => {
            if (event.state) {
                // Get active tab
                const activeTab = getActiveTab(options, event.state.query);

                onButtonClick({ target: activeTab }, options);
                scrollToTabs(options);
            } else {
                // Get first tab
                const firstTab = document.querySelector(options.tabButtonClass);

                onButtonClick({ target: firstTab }, options);
            }
        }, false);
    };

    /**
     * Show component after JS completed
     @private
     @param {Object} options User options
     */
    // eslint-disable-next-line no-unused-vars
    const showComponent = function (options) {
        // Add style to show element
        options.tabsWrapperDOMElement.style.display = 'block';
    };

    /**
     * Init carousel if it requires
     @private
     @param {Object} options User options
     */
    const initCarousel = function (options) {
        const config = {
            tabListWrapperClass: options.tabListWrapperClass,
            tabButtonWrapperClass: options.tabButtonWrapperClass,
            arrowLeft: options.arrowLeftDOMElement,
            arrowRight: options.arrowRightDOMElement,
        };
        // Trigger resize event
        onResize(config);
    };

    /**
     * Plugin Object
     * @param {Object} options User options
     * @constructor
     */
    function Plugin(options) {
        // Merge default and passed arguments
        this.options = extend(defaults, options);
        // Initialize tabs component
        this.init();
    }

    /**
     * Plugin prototype
     * @public
     * @constructor
     */
    Plugin.prototype = {
        init() {
            // Collect DOM elements
            collectDOMElements(this.options);
            // Check if DOM elements are present
            if (checkForRequiredDOMElements(this.options)) {
                // Attach 'click' event to tab buttons
                attachClickHandler(this.options, onButtonClick, scrollToTabs, pushHistory, onArrowClick);
                // Attach 'click' event to course expert buttons
                expertNameClick(this.options, getElementOffsetTop);
                // Attach scroll and click events on arrows
                attachResizeHandler(this.options, onResize);
                // Attach history handler
                attachHistoryHandler(this.options);
                // Check for query params
                checkQueryParams(this.options);
                // Init carousel
                initCarousel(this.options);
                // Arrow navigation
                arrowNavigation(this.options);
            }
        },
    };

    return Plugin;
}));
