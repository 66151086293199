ACC.paymentcards = {

    _autoload: [
        ['bindDeleteConfirmLink', $('.js-paymentcard_delete_confirm').length !== 0],
        ['bindDeletePaymentCard', $('.js-delete-payment-card').length !== 0],
    ],

    bindDeleteConfirmLink() {
        $('.js-paymentcard_delete_confirm_cancel').on('click', $modal.close);
    },

    bindDeletePaymentCard() {
        $('.js-delete-payment-card').on('click', function (e) {
            e.preventDefault();
            $modal.open(this);
        });
    },
};
