ACC.quickview = {

    _autoload: [
        'bindToUiCarouselLink',
    ],

    initQuickviewLightbox() {
        ACC.product.enableAddToCartButton();
        ACC.product.bindToAddToCartForm();
        ACC.product.enableStorePickupButton();
    },

    refreshScreenReaderBuffer() {
        // changes a value in a hidden form field in order
        // to trigger a buffer update in a screen reader
        $('#accesibility_refreshScreenReaderBufferField').attr('value', new Date().getTime());
    },

    bindToUiCarouselLink() {
        const titleHeader = $('#quickViewTitle').html();
        $('.js-reference-item').colorbox({
            close: '<span class="glyphicon glyphicon-remove"></span>',
            title: titleHeader,
            onComplete() {
                ACC.quickview.refreshScreenReaderBuffer();
                ACC.quickview.initQuickviewLightbox();
                ACC.ratingstars.bindRatingStars($('.quick-view-stars'));
            },
            onClosed() {
                ACC.quickview.refreshScreenReaderBuffer();
            },
        });
    },

};
