ACC.order = {

    _autoload: [
        ['clearSelectedCheckbox', $('.return_checkbox').length !== 0],
        'backToOrderHistory',
        'bindMultidProduct',
        'returnsCall',
        'returnsCheckboxClick',
    ],

    backToOrderHistory() {
        $('.orderBackBtn > button').on('click', function () {
            const sUrl = $(this).data('backToOrders');
            window.location = sUrl;
        });
    },

    bindMultidProduct() {
        // link to display the multi-d grid in read-only mode
        $(document).on('click', '.js-show-multiD-grid-in-order', function (event) {
            ACC.multidgrid.populateAndShowGrid(this, event, true);
            return false;
        });

        // link to display the multi-d grid in read-only mode
        $(document).on('click', '.showMultiDGridInOrderOverlay', function (event) {
            ACC.multidgrid.populateAndShowGridOverlay(this, event);
        });
    },

    returnsCall() {
        $('.return_order_button').on('click', function () {
            const delimiter = ',';
            let returnsUrl = 'returns';
            const orderId = this.id;
            let orderEntries = '';
            $('.return_checkbox').each(function () {
                if (this.checked) {
                    orderEntries = orderEntries.concat(this.id + delimiter);
                }
            });
            const index = orderEntries.lastIndexOf(delimiter);
            orderEntries = orderEntries.substring(0, index);

            const data = {};
            data.orderId = orderId;
            data.entryNumbers = orderEntries;

            returnsUrl = `${returnsUrl}?orderId=${orderId}&entryNumbers=${orderEntries}`;

            $.ajax({
                type: 'get',
                url: returnsUrl,
                success() {
                    window.location = returnsUrl;
                },
                // eslint-disable-next-line no-shadow,no-unused-vars
                error(data) {
                },
            });
        });
    },

    returnsCheckboxClick() {
        $('.return_checkbox').on('click', () => {
            let checked = 0;

            $('.return_checkbox').each(function () {
                if (this.checked) {
                    checked += 1;
                }
            });

            $('.return_order_button').prop('disabled', checked === 0);
        });
    },

    clearSelectedCheckbox() {
        $('.return_checkbox').each(function () {
            if (this.checked) {
                this.checked = false;
            }
        });
    },
};
