ACC.onlineTrainings = {

    _autoload: [
        ['init', $('.online-training-add-participants-form') !== 0],
        ['bindAddNewParticipant', $('.js-online-training-with-seats-form') !== 0],
        ['bindSaveParticipants', $('.js-online-training-with-seats-form') !== 0],
        ['bindRemoveParticipant', $('.js-online-training-with-seats-form') !== 0],
        ['bindTrainingDatePicker', $('.js-online-training-by-request-form') !== 0],
        ['bindSaveOTByRequestTrainingParticipantData', $('.js-online-training-by-request-form') !== 0],
    ],

    init() {
        ACC.onlineTrainings.bindDisplayAddParticipantsModal();
        ACC.onlineTrainings.loadOnlineTrainingsParticipantsDataInForm();
        ACC.onlineTrainings.validateOnlineTrainings();
        ACC.onlineTrainings.bindParticipantsCloseConfirmationModal();
    },

    checkIfAllInputsAreEmpty(productCode, isByRequestTraining) {
        const allInputFields = isByRequestTraining
            ? $(`#online-training-by-request-participant-modal-${productCode} :input[type=text]`)
            : $(`#online-training-add-participants-modal-${productCode} :input[type=text]`);

        let allEmpty = true;
        if (allInputFields.length > 0) {
            let index = 0;
            let inputField = allInputFields[index];

            while (inputField.value === '' && index < allInputFields.length) {
                index += 1;
                if (index < allInputFields.length) {
                    inputField = allInputFields[index];
                }
            }
            allEmpty = (inputField.value === '');
        }
        return allEmpty;
    },

    loadOnlineTrainingsParticipantsDataInForm() {
        $('.online-training-add-participants-form').each((index, participantsForm) => {
            const productCode = $(participantsForm).data('product-code');
            const orderId = $(participantsForm).data('order-id');
            const isByRequestTraining = $(participantsForm).data('by-request-training');
            if (isByRequestTraining) {
                ACC.onlineTrainings.loadOTByRequestParticipantInForm(productCode, orderId);
            } else {
                const productQuantity = $(participantsForm).data('quantity');
                ACC.onlineTrainings.loadParticipantsInForm(productCode, productQuantity, orderId);
            }
        });
    },

    bindDisplayAddParticipantsModal() {
        $('.js-add-online-training-participants-button').on('click', function () {
            const isByRequestTraining = $(this).data('by-request-training');
            const productCode = $(this).data('product-code');
            const orderId = $(this).data('order-id');
            ACC.onlineTrainings.showParticipantsModal($(this));
            if (isByRequestTraining) {
                $('.addParticipantsModal').addClass('byRequest');
                ACC.onlineTrainings.loadOTByRequestParticipantInForm(productCode, orderId);
                const form = $(`#online-training-by-request-participant-form-${productCode}`);
                const trainingDate1 = form.find(`#trainingDate1-${productCode}`);
                const trainingDate2 = form.find(`#trainingDate2-${productCode}`);
                ACC.onlineTrainings.setMinDateForTrainingDate2(trainingDate1, trainingDate2);
                ACC.onlineTrainings.bindSetMinDateForTrainingDate2(trainingDate1, trainingDate2);
                if (ACC.onlineTrainings.mobileAndTabletDevice()) {
                    const firstInputDate = document.querySelector('.trainingDate1');
                    firstInputDate.focus();
                }
            } else {
                const productQuantity = $(this).data('quantity');
                ACC.onlineTrainings.clearParticipantsForm(productCode);
                ACC.onlineTrainings.loadParticipantsInForm(productCode, productQuantity, orderId);
                if (ACC.onlineTrainings.mobileAndTabletDevice()) {
                    const firstInputParticipant = document.querySelector('#colorbox [name="firstName-0"]');
                    firstInputParticipant.focus();
                }
            }
        });
    },

    bindSetMinDateForTrainingDate2(trainingDate1, trainingDate2) {
        trainingDate1.change(() => {
            ACC.onlineTrainings.setMinDateForTrainingDate2(trainingDate1, trainingDate2);
        });
    },

    showParticipantsModal(triggerButton) {
        $modal.open(null, {
            title: triggerButton.data('modal-title'),
            href: triggerButton.data('modal-content'),
            inline: true,
            overlayClose: false,
            className: 'addParticipantsModal',
            onLoad() {
                document.body.classList.add('preventScroll');
                const closeButton = $('#cboxClose');
                closeButton.off('click');
                closeButton.on('click', (e) => {
                    e.preventDefault();
                    $('#js-online-training-participants-close-confirmation-modal').show();
                });
                if (ACC.onlineTrainings.mobileAndTabletDevice()) {
                    ACC.onlineTrainings.updatePreferedDatesLabel();
                }
            },
            onClosed() {
                document.body.classList.remove('preventScroll');
                const closeButton = $('#cboxClose');
                closeButton.off('click');
                closeButton.on('click', () => {
                    $modal.close();
                });
            },
        });
    },

    bindAddNewParticipant() {
        $('.js-online-training-add-new-participant').on('click', function () {
            const participantsForm = $(this).closest('form');
            ACC.onlineTrainings.appendParticipantToForm(participantsForm, 1);
            ACC.onlineTrainings.showHideAddParticipantButton(participantsForm);
        });
    },

    bindSaveParticipants() {
        $('.js-online-training-participants-save').on('click', function (e) {
            e.preventDefault();
            const participantsForm = ACC.onlineTrainings.getParticipantsForm($(this).data('product-code'));
            const participantsData = ACC.onlineTrainings.collectParticipantsData(participantsForm);
            ACC.onlineTrainings.saveParticipants(participantsData);
            $modal.close();
        });
    },

    showHideAddParticipantButton(participantsForm) {
        const addButton = participantsForm.find('.js-online-training-add-new-participant');
        const maxQuantity = participantsForm.data('max-quantity');
        const quoteEntry = participantsForm.data('is-quote-entry');
        if (quoteEntry || !maxQuantity) {
            return;
        }
        const numberOfParticipants = ACC.onlineTrainings.getCurrentNumberOfParticipants(participantsForm);
        if (numberOfParticipants === maxQuantity) {
            addButton.hide();
        } else {
            addButton.show();
        }
    },

    bindSaveOTByRequestTrainingParticipantData() {
        // eslint-disable-next-line prefer-arrow-callback
        $('.js-online-training-by-request-save').on('click', function (e) {
            e.preventDefault();
            const byRequestForm = ACC.onlineTrainings.getOTByRequestParticipantForm($(this).data('product-code'));
            const participantsData = ACC.onlineTrainings.collectByRequestParticipantsData(byRequestForm);
            // eslint-disable-next-line max-len
            ACC.onlineTrainings.saveOTByRequestTrainingParticipant(participantsData);
            $modal.close();
        });
    },

    bindRemoveParticipant() {
        $(document).on('click', '.js-online-training-remove-participant-button', function () {
            const participantsForm = $(this).closest('form');
            $(this).closest('.js-online-training-participant').remove();
            if (ACC.onlineTrainings.getCurrentNumberOfParticipants(participantsForm) === 1) {
                ACC.onlineTrainings.hideParticipantsRemoveButton(participantsForm);
                $('.js-online-training-participant').removeClass('multiParticipants');
            }
            ACC.onlineTrainings.updateParticipantsNumber(participantsForm);
            ACC.onlineTrainings.showHideAddParticipantButton(participantsForm);
            if (ACC.onlineTrainings.mobileAndTabletDevice()) {
                const firstButtonParticipant = document.querySelector('#colorbox .js-participant-number--mobile');
                firstButtonParticipant.click();
                const firstInputParticipant = document.querySelector('#colorbox [name="firstName-0"]');
                firstInputParticipant.focus();
            }
        });
    },

    validateOnlineTrainings(isCheckout) {
        let isValid = true;
        $('.online-training-add-participants-form').each((index, participantsForm) => {
            const productCode = $(participantsForm).data('product-code');
            const orderId = $(participantsForm).data('order-id');
            const isByRequestTraining = $(participantsForm).data('by-request-training');
            // eslint-disable-next-line max-len
            isValid = ACC.onlineTrainings.handleValidateOnlineTraining(productCode, orderId, isByRequestTraining, isCheckout) && isValid;
        });
        ACC.onlineTrainings.removeOnlineTrainingErrorUrlParameter();
        return isValid;
    },

    getOnlineTrainingErrorUrlParameter() {
        const searchParams = new URLSearchParams(window.location.search);
        return searchParams.get('onlineTrainingsError');
    },

    removeOnlineTrainingErrorUrlParameter() {
        const url = new URL(window.location.href);
        if (url.searchParams.has('onlineTrainingsError')) {
            url.searchParams.delete('onlineTrainingsError');
            window.history.replaceState({}, '', url);
        }
    },

    bindParticipantsCloseConfirmationModal() {
        $('.js-participants-modal-close-btn').on('click', () => {
            $('#js-online-training-participants-close-confirmation-modal').show();
        });
        $('.js-participants-close-confirmation-confirm').on('click', () => {
            $('#js-online-training-participants-close-confirmation-modal').hide();
        });
        $('.close-confirmation-modal--closeBtn').on('click', () => {
            $('#js-online-training-participants-close-confirmation-modal').hide();
        });
        $('.js-participants-close-confirmation-cancel').on('click', () => {
            $('#js-online-training-participants-close-confirmation-modal').hide();
            $('.js-add-participants').find('#cboxClose').off('click');
            $modal.close();
        });
    },

    handleValidateOnlineTraining(productCode, orderId, isByRequestTraining, showErrorMessage) {
        const form = isByRequestTraining
            ? ACC.onlineTrainings.getByRequestForm(productCode)
            : ACC.onlineTrainings.getParticipantsForm(productCode);
        const quoteEntry = form.data('is-quote-entry') === undefined
            ? false
            : form.data('is-quote-entry');

        const validationResponse = isByRequestTraining
            ? ACC.onlineTrainings.validateOTByRequestParticipant(productCode, orderId, quoteEntry)
            : ACC.onlineTrainings.validateParticipants(productCode, orderId, quoteEntry);
        const emptyForm = ACC.onlineTrainings.checkIfAllInputsAreEmpty(productCode, isByRequestTraining);
        if (quoteEntry) {
            // eslint-disable-next-line max-len
            showErrorMessage = showErrorMessage || (emptyForm && ACC.onlineTrainings.getOnlineTrainingErrorUrlParameter());
        }
        if (validationResponse === true) {
            ACC.onlineTrainings.setParticipantsCompleteStatus(productCode);
            emptyForm ? ACC.onlineTrainings.setAddButtonText(productCode)
                : ACC.onlineTrainings.setEditButtonText(productCode);
        } else if (validationResponse === false) {
            // eslint-disable-next-line max-len
            showErrorMessage ? ACC.onlineTrainings.setParticipantsCheckoutErrorStatus(productCode) : ACC.onlineTrainings.setParticipantsNotCompleteStatus(productCode);
            emptyForm ? ACC.onlineTrainings.setAddButtonText(productCode)
                : ACC.onlineTrainings.setEditButtonText(productCode);
        } else {
            // eslint-disable-next-line max-len
            showErrorMessage ? ACC.onlineTrainings.setParticipantsCheckoutErrorStatus(productCode) : ACC.onlineTrainings.setParticipantsNotCompleteStatus(productCode);
        }
        return validationResponse;
    },

    appendParticipantToForm(participantsForm, count, firstName, lastName, email) {
        let currentNumberOfParticipants = ACC.onlineTrainings.getCurrentNumberOfParticipants(participantsForm);
        for (let i = 0; i < count; i += 1) {
            const participantsContainer = participantsForm.find('.js-online-training-participants');
            const firstNameLabel = participantsForm.data('first-name-label');
            const lastNameLabel = participantsForm.data('last-name-label');
            const emailLabel = participantsForm.data('email-label');
            // eslint-disable-next-line max-len
            const participant = ACC.onlineTrainings.createNewParticipant(currentNumberOfParticipants, firstNameLabel, lastNameLabel, emailLabel, firstName, lastName, email);
            participantsContainer.append(participant);
            ACC.onlineTrainings.bindParticipantValidation(participant, currentNumberOfParticipants);
            currentNumberOfParticipants += 1;
        }
        if (currentNumberOfParticipants === 1) {
            ACC.onlineTrainings.hideParticipantsRemoveButton(participantsForm);
        } else {
            ACC.onlineTrainings.showParticipantsRemoveButton(participantsForm);
            $('.js-online-training-participant').addClass('multiParticipants');
        }
    },

    loadParticipantsInForm(productCode, productQuantity, orderId) {
        const participantsForm = ACC.onlineTrainings.getParticipantsForm(productCode);
        const quoteEntry = participantsForm.data('is-quote-entry');
        const participantsData = ACC.onlineTrainings.getParticipantsData(productCode, orderId, quoteEntry);

        if (!participantsData) {
            ACC.onlineTrainings.appendParticipantToForm(participantsForm, productQuantity);
        } else {
            const participantsList = participantsData.participants;
            if (Array.isArray(participantsList) && participantsList.length) {
                // eslint-disable-next-line max-len
                ACC.onlineTrainings.populateParticipantsList(participantsData.productCode, participantsData.participants);
                if (productQuantity > participantsList.length) {
                    // eslint-disable-next-line max-len
                    ACC.onlineTrainings.appendParticipantToForm(participantsForm, productQuantity - participantsList.length);
                }
            } else {
                ACC.onlineTrainings.appendParticipantToForm(participantsForm, productQuantity);
            }
        }
        ACC.onlineTrainings.showHideAddParticipantButton(participantsForm);
        if (quoteEntry) {
            ACC.onlineTrainings.hideParticipantsRemoveButton(participantsForm);
        }
    },

    saveParticipants(participantsData) {
        $.ajax({
            async: false,
            type: 'POST',
            url: `${ACC.config.encodedContextPath}/online-trainings/save-participants`,
            contentType: 'application/json',
            data: JSON.stringify(participantsData),
            success: (quantityUpdated) => {
                // eslint-disable-next-line max-len
                ACC.onlineTrainings.handleValidateOnlineTraining(participantsData.productCode, participantsData.orderId, false);
                if (quantityUpdated) {
                    $modal.close();
                    window.location.reload();
                }
            },
        });
    },

    saveOTByRequestTrainingParticipant(participantData) {
        $.ajax({
            async: false,
            type: 'POST',
            url: `${ACC.config.encodedContextPath}/online-trainings/by-request/save-participant-details`,
            contentType: 'application/json',
            data: JSON.stringify(participantData),
            success: () => {
                // eslint-disable-next-line max-len
                ACC.onlineTrainings.handleValidateOnlineTraining(participantData.productCode, participantData.orderId, true);
            },
        });
    },

    getParticipantsData(productCode, orderId, isQuoteEntry) {
        const quoteEntry = isQuoteEntry === true;
        let participantsData = null;
        $.ajax({
            async: false,
            type: 'GET',
            url: `${ACC.config.encodedContextPath}/online-trainings/get-participants`,
            data: $.param({ productCode, orderId, quoteEntry }),
            success: (response) => {
                participantsData = response;
            },
            error: () => {
                participantsData = null;
            },
        });
        return participantsData;
    },

    getOTByRequestParticipantData(productCode, orderId, is) {
        const quoteEntry = is === true;
        let participantsData = null;
        $.ajax({
            async: false,
            type: 'GET',
            url: `${ACC.config.encodedContextPath}/online-trainings/by-request/get-participant-details`,
            data: $.param({ productCode, orderId, quoteEntry }),
            success: (response) => {
                participantsData = response;
            },
            error: () => {
                participantsData = null;
            },
        });
        return participantsData;
    },

    validateParticipants(productCode, orderId, quoteEntry) {
        let responseData = null;
        $.ajax({
            async: false,
            type: 'POST',
            url: `${ACC.config.encodedContextPath}/online-trainings/validate-participants`,
            data: $.param({ productCode, orderId, quoteEntry }),
            success: (response) => {
                responseData = response;
            },
        });
        return responseData;
    },

    validateOTByRequestParticipant(productCode, orderId, quoteEntry) {
        let responseData = null;
        $.ajax({
            async: false,
            type: 'POST',
            url: `${ACC.config.encodedContextPath}/online-trainings/by-request/validate-participant-details`,
            data: $.param({ productCode, orderId, quoteEntry }),
            success: (response) => {
                responseData = response;
            },
        });
        return responseData;
    },

    populateParticipantsList(productCode, participants) {
        const participantsForm = ACC.onlineTrainings.getParticipantsForm(productCode);
        participants.forEach((participant) => {
            // eslint-disable-next-line max-len
            ACC.onlineTrainings.appendParticipantToForm(participantsForm, 1, participant.firstName, participant.lastName, participant.email);
        });
    },

    showParticipantsRemoveButton(participantsForm) {
        participantsForm.find('.js-online-training-participant')
            .find('.removeParticipant')
            .show();
    },

    hideParticipantsRemoveButton(participantsForm) {
        participantsForm.find('.js-online-training-participant')
            .find('.removeParticipant')
            .hide();
    },

    createNewParticipant(index, firstNameLabel, lastNameLabel, emailLabel, firstName = '', lastName = '', email = '') {
        const participantContainer = $('<div class="js-online-training-participant"></div>');
        const addParticipantForm = $('<div class="js-online-training-participant--form"></div>');

        addParticipantForm.append(
            ACC.onlineTrainings.createParticipantInputElement(firstName, 'firstName', index, firstNameLabel),
            ACC.onlineTrainings.createParticipantInputElement(lastName, 'lastName', index, lastNameLabel),
            ACC.onlineTrainings.createParticipantInputElement(email, 'email', index, emailLabel),
        );

        participantContainer.append(
            ACC.onlineTrainings.createParticipantNumberElement(index + 1),
            addParticipantForm,
            ACC.onlineTrainings.createParticipantRemoveButtonElement(),
        );
        return participantContainer;
    },

    createParticipantInputElement(value, name, index, labelName) {
        const formGroup = $('<div class="form-group"></div>');
        const label = $(`<label class="control-label input-label">${labelName}</label>`);
        const input = $(`<input name="${name}-${index}"
                                class="input form-control" type="text" value="${value}"
                                onkeydown="ACC.onlineTrainings.handleInputKeyDown(event)"
                                onkeyup="ACC.onlineTrainings.handleInputKeyUp(event)"
                                onblur="ACC.onlineTrainings.handleBlur(event)" />`);
        return formGroup.append(label, input);
    },

    createParticipantRemoveButtonElement() {
        // eslint-disable-next-line max-len
        return $('<div class="removeParticipant"><span class="js-online-training-remove-participant-button"></span></div>');
    },

    createParticipantNumberElement(number) {
        if (number === 1) {
            return $(`<div class="participantNumber selected">
                        <span class="js-participant-number">${number}</span>
                        <button class="js-participant-number--mobile"
                                onclick="ACC.onlineTrainings.selectParticipantMobile(event)"
                                type="button">
                            Participant #${number}
                        </button>
                      </div>`);
        }
        if (number > 9) {
            return $(`<div class="participantNumber doubleDigits">
                        <span class="js-participant-number">${number}</span>
                        <button class="js-participant-number--mobile"
                                onclick="ACC.onlineTrainings.selectParticipantMobile(event)"
                                type="button">
                            Participant #${number}
                        </button>
                      </div>`);
        // eslint-disable-next-line no-else-return
        } else {
            return $(`<div class="participantNumber">
                        <span class="js-participant-number">${number}</span>
                        <button class="js-participant-number--mobile"
                                onclick="ACC.onlineTrainings.selectParticipantMobile(event)"
                                type="button">
                            Participant #${number}
                        </button>
                      </div>`);
        }
    },

    bindParticipantValidation(participantContainer, index) {
        const participantsForm = participantContainer.closest('form');
        ACC.onlineTrainings.bindParticipantInputValidation(participantsForm, participantContainer, 'firstName', index);
        ACC.onlineTrainings.bindParticipantInputValidation(participantsForm, participantContainer, 'lastName', index);
        ACC.onlineTrainings.bindParticipantInputValidation(participantsForm, participantContainer, 'email', index);
    },

    bindParticipantInputValidation(participantsForm, participantContainer, inputName, index) {
        const inputRules = participantsForm.data('validation-rules')[inputName];
        inputRules.messages = participantsForm.data('validation-messages')[inputName];
        participantsForm.find(`input[name=${inputName}-${index}]`)
            .rules('add', inputRules);
    },

    getParticipantsForm(productCode) {
        return $(`#online-training-participants-form-${productCode}`);
    },

    getByRequestForm(productCode) {
        return $(`#online-training-by-request-participant-form-${productCode}`);
    },

    clearParticipantsForm(productCode) {
        ACC.onlineTrainings.getParticipantsForm(productCode).find('.js-online-training-participants').empty();
    },

    collectParticipantsData(participantsForm) {
        const productCode = participantsForm.data('product-code');
        const orderId = participantsForm.data('order-id');
        const quoteEntry = participantsForm.data('is-quote-entry');
        const participantsData = {
            productCode, orderId, quoteEntry, participants: [],
        };
        participantsForm.find('.js-online-training-participant').each((index, participantContainer) => {
            const participant = {
                firstName: $(participantContainer).find('input[name^=firstName]').val(),
                lastName: $(participantContainer).find('input[name^=lastName]').val(),
                email: $(participantContainer).find('input[name^=email]').val(),
            };
            participantsData.participants.push(participant);
        });
        return participantsData;
    },

    collectByRequestParticipantsData(byRequestForm) {
        const productCode = byRequestForm.data('product-code');
        const orderId = byRequestForm.data('order-id');
        const quoteEntry = byRequestForm.data('is-quote-entry');
        const participantContainer = byRequestForm.find('#online-training-by-request-participant');
        const preferredDate1 = byRequestForm.find(`#trainingDate1-${productCode}`).val();
        const preferredDate2 = byRequestForm.find(`#trainingDate2-${productCode}`).val();
        const contactPoint = {
            firstName: $(participantContainer).find('input[name^=firstName]').val(),
            lastName: $(participantContainer).find('input[name^=lastName]').val(),
            email: $(participantContainer).find('input[name^=email]').val(),
        };

        return {
            productCode, orderId, quoteEntry, preferredDate1, preferredDate2, contactPoint,
        };
    },

    updateParticipantsNumber(participantsForm) {
        participantsForm.find('.js-online-training-participant').each((index, participantContainer) => {
            $(participantContainer).find('.js-participant-number--mobile')[0].innerText = `Participant #${index + 1}`;
            $(participantContainer).find('.js-participant-number').text(index + 1);
            $(participantContainer).find('input').each((j, inputEl) => {
                const baseName = $(inputEl).attr('name').split('-')[0];
                $(inputEl).attr('name', `${baseName}-${index}`);
            });
        });
    },

    getCurrentNumberOfParticipants(participantsForm) {
        return participantsForm.find('.js-online-training-participant').length;
    },

    setOnlineTrainingStatus(productCode, messageDataAttr, alertType) {
        const participantsStatusContainer = $(`#js-online-training-participants-status-${productCode}`);
        const message = participantsStatusContainer.data(`${messageDataAttr}`);
        participantsStatusContainer
            .removeClass('c-alert--info c-alert--success c-alert--error')
            .addClass(`${alertType}`);
        participantsStatusContainer.find('.c-alert__text').text(message);
    },

    setParticipantsNotCompleteStatus(productCode) {
        // eslint-disable-next-line max-len
        ACC.onlineTrainings.setOnlineTrainingStatus(productCode, 'participants-not-complete-status', 'c-alert--info');
    },

    setParticipantsCompleteStatus(productCode) {
        // eslint-disable-next-line max-len
        ACC.onlineTrainings.setOnlineTrainingStatus(productCode, 'participants-complete-status', 'c-alert--success');
    },

    setParticipantsCheckoutErrorStatus(productCode) {
        // eslint-disable-next-line max-len
        ACC.onlineTrainings.setOnlineTrainingStatus(productCode, 'participants-checkout-error-status', 'c-alert--error');
    },

    setEditButtonText(productCode) {
        // eslint-disable-next-line max-len
        const addParticipantsButton = $(`#js-online-trainings-add-participants-section-${productCode}`).find('.js-add-online-training-participants-button');
        const addParticipantsButtonLabel = addParticipantsButton.find('span');
        const editParticipantsLabel = addParticipantsButton.data('edit-btn-text');
        addParticipantsButton.addClass('editButton');
        addParticipantsButtonLabel.text(editParticipantsLabel);
    },

    setAddButtonText(productCode) {
        // eslint-disable-next-line max-len
        const addParticipantsButton = $(`#js-online-trainings-add-participants-section-${productCode}`).find('.js-add-online-training-participants-button');
        const addParticipantsButtonLabel = addParticipantsButton.find('span');
        const addParticipantsLabel = addParticipantsButton.data('add-btn-text');
        addParticipantsButton.removeClass('editButton');
        addParticipantsButtonLabel.text(addParticipantsLabel);
    },

    getOTByRequestParticipantForm(productCode) {
        return $(`#online-training-by-request-participant-form-${productCode}`);
    },

    bindTrainingDatePicker() {
        const dateFormat = 'mm/dd/yy';
        $('.trainingDate1, .trainingDate2').datepicker({
            dateFormat,
            constrainInput: true,
            maxDate: '+1y',
            showOtherMonths: true,
        });

        ACC.onlineTrainings.setInitialMinDateValues();
    },

    setInitialMinDateValues() {
        $('.trainingDate1').each((index, trainingDate1) => {
            let minDateOffsetDays = $(trainingDate1).closest('.js-training-dates-wrapper').data('min-date-offset');
            if (!minDateOffsetDays || Number.isNaN(minDateOffsetDays) || minDateOffsetDays < 0) {
                minDateOffsetDays = 0;
            }
            $(trainingDate1).datepicker('option', 'minDate', minDateOffsetDays);
            const trainingDate2 = $(trainingDate1).closest('.js-training-dates-wrapper').find('.trainingDate2');
            trainingDate2.datepicker('option', 'minDate', minDateOffsetDays + 1);
        });
    },

    setMinDateForTrainingDate2(trainingDate1, trainingDate2) {
        const dateFormat = 'mm/dd/yy';
        const date1Value = trainingDate1.val();
        if (date1Value) {
            const date1 = $.datepicker.parseDate(dateFormat, date1Value);
            date1.setDate(date1.getDate() + 1);
            trainingDate2.datepicker('option', 'minDate', date1);
        }
    },

    loadOTByRequestParticipantInForm(productCode, orderId) {
        const participantForm = ACC.onlineTrainings.getOTByRequestParticipantForm(productCode);
        const quoteEntry = participantForm.data('is-quote-entry');
        const participantData = ACC.onlineTrainings.getOTByRequestParticipantData(productCode, orderId, quoteEntry);
        participantForm[0].reset();
        if (participantData) {
            // eslint-disable-next-line quotes
            participantForm.find(`input[name='productCode']`).val(
                ACC.onlineTrainings.getValueOrDefault(participantData.productCode),
            );
            // eslint-disable-next-line quotes
            participantForm.find(`input[name='trainingDate1']`).val(
                ACC.onlineTrainings.getValueOrDefault(participantData.preferredDate1),
            );
            // eslint-disable-next-line quotes
            participantForm.find(`input[name='trainingDate2']`).val(
                ACC.onlineTrainings.getValueOrDefault(participantData.preferredDate2),
            );
            if (participantData.contactPoint === null) {
                // eslint-disable-next-line quotes
                participantForm.find(`input[name='firstName']`).val('');
                // eslint-disable-next-line quotes
                participantForm.find(`input[name='lastName']`).val('');
                // eslint-disable-next-line quotes
                participantForm.find(`input[name='email']`).val('');
            } else {
                // eslint-disable-next-line quotes
                participantForm.find(`input[name='firstName']`).val(
                    ACC.onlineTrainings.getValueOrDefault(participantData.contactPoint.firstName),
                );
                // eslint-disable-next-line quotes
                participantForm.find(`input[name='lastName']`).val(
                    ACC.onlineTrainings.getValueOrDefault(participantData.contactPoint.lastName),
                );
                // eslint-disable-next-line quotes
                participantForm.find(`input[name='email']`).val(
                    ACC.onlineTrainings.getValueOrDefault(participantData.contactPoint.email),
                );
            }
        }
    },

    getValueOrDefault(value) {
        const defaultValue = '';
        return value === null ? defaultValue : value;
    },

    validateQuoteOnlineTrainingsData(quoteId) {
        let responseData = null;
        $.ajax({
            async: false,
            type: 'GET',
            url: `${ACC.config.encodedContextPath}/online-trainings/quote/validate-online-trainings`,
            data: $.param({ quoteId }),
            success: (response) => {
                responseData = response;
            },
        });
        return responseData;
    },

    selectParticipantMobile(event) {
        if ($('.form-group.has-error').length > 0) {
            event.preventDefault();
        } else {
            $('.participantNumber.selected').removeClass('selected');
            const parentContainer = event.target.closest('.participantNumber');
            parentContainer.classList.add('selected');
        }
    },

    handleInputKeyDown(event) {
        if (event.key === 'Enter') {
            if (event.target.closest('.form-group.has-error')) {
                event.preventDefault();
            } else {
                ACC.onlineTrainings.focusNextElement(event);
            }
        }
    },

    handleInputKeyUp(event) {
        setTimeout(() => {
            const hasError = $('.form-group.has-error');
            const participant = event.target.closest('.js-online-training-participant');

            if (hasError.length > 0) {
                participant.classList.add('disabledForm');
            } else {
                participant.classList.remove('disabledForm');
            }
        }, 0);
    },

    mobileAndTabletDevice() {
        if (window.innerWidth < 992) {
            return true;
        }

        return false;
    },

    focusNextElement(event) {
        event.preventDefault();
        const modal = $('#colorbox')[0];

        if (modal.classList.contains('byRequest')) {
            ACC.onlineTrainings.focusNextInputByRequest(event);
        } else {
            ACC.onlineTrainings.focusNextInputParticipants(event);
        }
    },

    focusNextInputParticipants(event) {
        const nextElementParent = event.target.parentElement.nextSibling;

        if (nextElementParent !== null && nextElementParent !== undefined) {
            nextElementParent.children[1].focus();
        } else {
            const nextParticipant = event.target.closest('.js-online-training-participant').nextSibling;

            if (nextParticipant !== null && nextParticipant !== undefined) {
                const nextParticipantForm = nextParticipant.children[1];
                const nextParticipantFirstName = nextParticipantForm.children[0].children[1];

                if (nextParticipantFirstName !== null && nextParticipantFirstName !== undefined) {
                    $('.participantNumber.selected').removeClass('selected');
                    nextParticipant.children[0].classList.add('selected');
                    nextParticipantFirstName.focus();
                }
            } else {
                $('.js-online-training-participants-save').focus();
            }
        }
    },

    focusNextInputByRequest(event) {
        const colParent = event.target.parentElement.parentElement;
        const nextInputColParent = colParent.nextElementSibling;

        if (nextInputColParent !== null && nextInputColParent !== undefined) {
            const nextInput = nextInputColParent.children[0].children[1];

            if (nextInput !== null && nextInput !== undefined) {
                nextInput.focus();
            }
        } else {
            $('.js-online-training-by-request-save').focus();
        }
    },

    updatePreferedDatesLabel() {
        const byRequestDescriptionLabel = $('.online-training-by-request-dates--description')[0];
        if (byRequestDescriptionLabel) {
            byRequestDescriptionLabel.innerText = 'Choose your preferred dates';
        }
    },

    focusOutSelect(event) {
        const selectedDatepicker = event.target.closest('.datepickerWrapper.selected');
        if (selectedDatepicker) {
            selectedDatepicker.classList.remove('selected');
            $('#colorbox')[0].classList.remove('preventScroll');
        }
    },

    focusSelect(event) {
        const selectedDatepicker = event.target.closest('.datepickerWrapper');
        if (selectedDatepicker) {
            selectedDatepicker.classList.add('selected');
            $('#colorbox')[0].classList.add('preventScroll');
        }
    },

    handleBlur(event) {
        if (event.target.closest('.form-group.has-error')) {
            event.target.focus();
        }
    },
};
