ACC.paginationsort = {

    downUpKeysPressed: false,

    bindAll() {
        this.bindPaginationSort();
    },
    bindPaginationSort() {
        const { bindSortForm, updatePaginationSort } = ACC.paginationsort;

        if (bindSortForm) {
            bindSortForm($('#sortForm1'));
            bindSortForm($('#sortForm2'));
        }

        if (updatePaginationSort) {
            updatePaginationSort();
        }

        // [Sep 08, 2021] Removed as failing babel pipeline
        // with (ACC.paginationsort)
        // {
        // bindSortForm($('#sortForm1'));
        // bindSortForm($('#sortForm2'));
        // updatePaginationSort();
        // }
    },
    bindSortForm(sortForm) {
        // browser.msie has been removed from jQuery as of version 1.9. Modernizr is recommended as a replacement
        // issue created
        // if ($.browser.msie)
        // {
        // this.sortFormIEFix($(sortForm).children('select'), $(sortForm).children('select').val());
        // }

        sortForm.change(function () {
            // if (!$.browser.msie)
            // {
            // this.submit();
            // }
            // else
            // {
            if (!ACC.paginationsort.downUpPressed) {
                this.submit();
            }
            ACC.paginationsort.downUpPressed = false;
            // }
        });
    },
    sortFormIEFix(sortOptions, selectedOption) {
        sortOptions.keydown(function (e) {
            // Pressed up or down keys
            if (e.keyCode === 38 || e.keyCode === 40) {
                ACC.paginationsort.downUpPressed = true;
            } else if (e.keyCode === 13 && selectedOption !== $(this).val()) { // Pressed enter
                $(this).parent().submit();
            } else { // Any other key
                ACC.paginationsort.downUpPressed = false;
            }
        });
    },
    updatePaginationSort() {
        const isNewPDPtemplate = document.getElementsByClassName('page-programDetailsPageV2');
        const sortOptionCount = document.getElementById('sortOptionsCount');

        /**
         * Check if "Show" select with quantity changes and adjust pagination
         */
        if (isNewPDPtemplate.length > 0 && sortOptionCount != null) {
            sortOptionCount.addEventListener('change', function () {
                // eslint-disable-next-line no-undef
                paginationInstance.updateNumPerPage(this.value);
            });
        }

        // ###############################################################################################
    },
};

$(document).ready(() => {
    ACC.paginationsort.bindAll();
});
