ACC.postCheckoutRegister = {
    _autoload: ['initComponent'],

    initComponent() {
        const name = 'postCheckout';
        const component = $(`[data-component=${name}]`);
        if (!component.length) {
            return;
        }

        const uid = $('#uid');
        const initialUID = uid.val();
        $('#same_as_email_address').on('change', function () {
            if ($(this).is(':checked')) {
                uid.val(initialUID);
                uid.attr('readonly', true);
                $('#pearsonGuestRegisterForm').validate().element('#uid');
            } else {
                uid.attr('readonly', false);
            }
        });
    },
};
