ACC.orderinvoice = {
    _autoload: ['bindViewInvoice'],

    bindViewInvoice() {
        $('.js-order-history__view-invoice').on('click', (event) => {
            event.preventDefault();

            ACC.orderinvoice.viewInvoice(event.target);
        });
    },

    viewInvoice(element) {
        const timeout = $(element).data('spinner-timeout');
        const spinnerMessage = $(element).data('spinner-message');
        const orderCode = $(element).data('order-code');

        $.ajax({
            type: 'GET',
            contentType: 'application/json',
            url: `${ACC.config.encodedContextPath}/invoices/view-invoice`,
            data: {
                orderCode,
            },
            beforeSend() {
                ACC.spinner.start(spinnerMessage);
            },
            error(error) {
                ACC.orderinvoice.viewInvoiceErrorHandler(timeout, error);
            },
            success(response) {
                ACC.orderinvoice.viewInvoiceSuccessHandler(response, orderCode, timeout);
            },
        });
    },

    viewInvoiceErrorHandler(timeout, error) {
        ACC.spinner.stop(timeout, ACC.orderinvoice.displayGlobalMessage(error));
    },

    viewInvoiceSuccessHandler(response, orderCode, timeout) {
        const data = {
            response,
            orderCode,
        };

        // eslint-disable-next-line no-unused-expressions
        response.documentFound
            ? ACC.spinner.stop(timeout, ACC.orderinvoice.downloadInvoice, data)
            : ACC.orderinvoice.viewInvoiceErrorHandler(timeout);
    },

    downloadInvoice(data) {
        const invoice = ACC.orderinvoice.convertToArrayBuffer(data.response.file);
        const file = new Blob([invoice], { type: 'application/pdf' });

        const fileURL = URL.createObjectURL(file);
        window.open(fileURL, `invoice-${data.orderCode}.pdf`);
    },

    displayGlobalMessage(error) {
        let messageKey;
        if (error === null || error.status === 404) {
            messageKey = 'text.account.order.details.view.invoice.error';
        } else {
            messageKey = 'text.account.order.details.view.invoice.api.error';
        }
        ACC.globalmessages.showErrorMessage(messageKey);
    },

    convertToArrayBuffer(base64) {
        const binaryString = window.atob(base64);
        const binaryLen = binaryString.length;
        const bytes = new Uint8Array(binaryLen);

        for (let i = 0; i < binaryLen; i += 1) {
            const ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }

        return bytes;
    },
};
