ACC.address = {

    _autoload: [
        'trackFormChanges',
        'bindToChangeAddressButton',
        'bindCreateUpdateAddressForm',
        'bindCountrySpecificAddressForms',
        'showAddressFormButtonPanel',
        'bindViewAddressBook',
        'bindToColorboxClose',
        'showRemoveAddressFromBookConfirmation',
        'showCancelAddressChangesConfirmation',
        'bindCheckCountryDropdownChanges',
        'searchAddress',
        'updateFavoriteAddress',
        'bindFocusOnSearchInput',
        ['toggleUpdateAddressButton', $('.js-update-address-button').length !== 0],
        'bindSpecialCharactersCheck',
        'hidePostalZipCodeOnLoad',
        'hidePostalZipCodeOnLoadForReReg',
    ],

    spinner: $(`<img src='${ACC.config.commonResourcePath}/images/spinner.gif' />`),
    addressID: '',
    handleChangeAddressButtonClick() {
        ACC.address.addressID = ($(this).data('address')) ? $(this).data('address') : '';
        $('#summaryDeliveryAddressFormContainer').show();
        $('#summaryOverlayViewAddressBook').show();
        $('#summaryDeliveryAddressBook').hide();

        // eslint-disable-next-line no-undef
        $.getJSON(getDeliveryAddressesUrl, ACC.address.handleAddressDataLoad);
        return false;
    },

    handleAddressDataLoad(data) {
        ACC.address.setupDeliveryAddressPopupForm(data);

        // Show the delivery address popup
        ACC.colorbox.open('', {
            inline: true,
            href: '#summaryDeliveryAddressOverlay',
            overlayClose: false,
            onOpen() {
                // empty address form fields
                ACC.address.emptyAddressForm();
                $(document).on('change', '#saveAddress', function () {
                    const saveAddressChecked = $(this).prop('checked');
                    $('#defaultAddress').prop('disabled', !saveAddressChecked);
                    if (!saveAddressChecked) {
                        $('#defaultAddress').prop('checked', false);
                    }
                });
            },
        });
    },

    setupDeliveryAddressPopupForm(data) {
        // Fill the available delivery addresses
        $('#summaryDeliveryAddressBook').html($('#deliveryAddressesTemplate').tmpl({ addresses: data }));
        // Handle selection of address
        $('#summaryDeliveryAddressBook button.use_address').click(ACC.address.handleSelectExistingAddressClick);
        // Handle edit address
        $('#summaryDeliveryAddressBook button.edit').click(ACC.address.handleEditAddressClick);
        // Handle set default address
        $('#summaryDeliveryAddressBook button.default').click(ACC.address.handleDefaultAddressClick);
    },

    emptyAddressForm() {
        const options = {
            // eslint-disable-next-line no-undef
            url: getDeliveryAddressFormUrl,
            data: { addressId: ACC.address.addressID, createUpdateStatus: '' },
            type: 'GET',
            success(data) {
                $('#summaryDeliveryAddressFormContainer').html(data);
                ACC.address.bindCreateUpdateAddressForm();
            },
        };

        $.ajax(options);
    },

    handleSelectExistingAddressClick() {
        const addressId = $(this).attr('data-address');
        // eslint-disable-next-line no-undef
        $.postJSON(setDeliveryAddressUrl, { addressId }, ACC.address.handleSelectExitingAddressSuccess);
        return false;
    },

    handleEditAddressClick() {
        $('#summaryDeliveryAddressFormContainer').show();
        $('#summaryOverlayViewAddressBook').show();
        $('#summaryDeliveryAddressBook').hide();

        const addressId = $(this).attr('data-address');
        const options = {
            // eslint-disable-next-line no-undef
            url: getDeliveryAddressFormUrl,
            data: { addressId, createUpdateStatus: '' },
            target: '#summaryDeliveryAddressFormContainer',
            type: 'GET',
            success() {
                ACC.address.bindCreateUpdateAddressForm();
                ACC.colorbox.resize();
            },
            error(xht, textStatus, ex) {
                alert(`Failed to update cart. Error details [${xht}, ${textStatus}, ${ex}]`);
            },
        };

        $(this).ajaxSubmit(options);
        return false;
    },

    handleDefaultAddressClick() {
        const addressId = $(this).attr('data-address');
        const options = {
            // eslint-disable-next-line no-undef
            url: setDefaultAddressUrl,
            data: { addressId },
            type: 'GET',
            success(data) {
                ACC.address.setupDeliveryAddressPopupForm(data);
            },
            error(xht, textStatus, ex) {
                alert(`Failed to update address book. Error details [${xht}, ${textStatus}, ${ex}]`);
            },
        };

        $(this).ajaxSubmit(options);
        return false;
    },

    handleSelectExitingAddressSuccess(data) {
        if (data != null) {
            ACC.refresh.refreshPage(data);
            ACC.colorbox.close();
        } else {
            alert('Failed to set delivery address');
        }
    },

    bindCreateUpdateAddressForm() {
        $('.create_update_address_form').each(function () {
            const options = {
                type: 'POST',
                beforeSubmit() {
                    $('#checkout_delivery_address').block({ message: ACC.address.spinner });
                },
                success(data) {
                    $('#summaryDeliveryAddressFormContainer').html(data);
                    const status = $('.create_update_address_id').attr('status');
                    if (status != null && status.toLowerCase() === 'success') {
                        ACC.refresh.getCheckoutCartDataAndRefreshPage();
                        ACC.colorbox.close();
                    } else {
                        ACC.address.bindCreateUpdateAddressForm();
                        ACC.colorbox.resize();
                    }
                },
                error(xht, textStatus, ex) {
                    alert(`Failed to update cart. Error details [${xht}, ${textStatus}, ${ex}]`);
                },
                complete() {
                    $('#checkout_delivery_address').unblock();
                },
            };

            $(this).ajaxForm(options);
        });
    },

    refreshDeliveryAddressSection(data) {
        $('.summaryDeliveryAddress').replaceWith($('#deliveryAddressSummaryTemplate').tmpl(data));
    },

    bindCountrySpecificAddressForms() {
        $(document).on('change', '#countrySelector select', function () {
            const contextId = $('.organisation_name').attr('data-contextUid');
            const options = {
                addressCode: '',
                // eslint-disable-next-line max-len
                contextUid: (contextId === undefined || contextId == null || contextId.length <= 0) ? null : contextId,
                countryIsoCode: $(this).val(),
            };

            ACC.address.displayCountrySpecificAddressForm(options, ACC.address.showAddressFormButtonPanel);
        });
    },
    showAddressFormButtonPanel() {
        if ($('#countrySelector :input').val() !== '') {
            $('#addressform_button_panel').show();
        }
    },

    bindToColorboxClose() {
        $('.closeColorBox').on('click', () => {
            $modal.close();
        });
    },

    displayCountrySpecificAddressForm(options, callback) {
        // eslint-disable-next-line max-len
        const target = ACC.address.isCheckoutFlow() ? ACC.address.getCheckoutDeliveryAddressFormUrl() : '/my-account/addressform';
        $.ajax({
            url: `${ACC.config.encodedContextPath}${target}`,
            async: true,
            data: options,
            dataType: 'html',
            beforeSend() {
                $('#i18nAddressForm').html(ACC.address.spinner);
            },
        }).done((data) => {
            $('#i18nAddressForm').html($(data).html());
            // eslint-disable-next-line max-len
            ACC.phonemasking.bind($('#address\\.phone'), options.countryIsoCode === 'UM' ? 'US' : options.countryIsoCode);
            if (typeof callback === 'function') {
                callback.call();
            }
            if (ACC.config.countriesWithoutPostalCodeValidation.includes(options.countryIsoCode)) {
                $('#addressForm').validate().settings.rules.postcode = {
                    required: false,
                };
            } else if (ACC.config.countriesWithPostCodeValidation.includes(options.countryIsoCode)) {
                $('#addressForm').validate().settings.rules.postcode = {
                    required: true,
                    minlength: 1,
                    maxlength: 30,
                    postCodeInvalid: true,
                };
            }
            ACC.validation.bindFormValidation();
            ACC.address.hidePostalZipCode(options.countryIsoCode);
        });
    },

    isCheckoutFlow() {
        return window.location.href.indexOf('/checkout/') >= 0;
    },

    getCheckoutDeliveryAddressFormUrl() {
        // eslint-disable-next-line max-len
        return ACC.address.isCreateQuoteFlow() ? '/checkout/quote/create/multi/delivery-address/addressform' : '/checkout/multi/delivery-address/addressform';
    },

    isCreateQuoteFlow() {
        return window.location.href.indexOf('/checkout/quote/create') >= 0;
    },

    bindToChangeAddressButton() {
        $(document).on('click', '.summaryDeliveryAddress .editButton', ACC.address.handleChangeAddressButtonClick);
    },

    bindViewAddressBook() {
        $('.js-address-book').on('click', function () {
            $modal.open(this);
        });

        $(document).on('click', '#summaryOverlayViewAddressBook', () => {
            $('#summaryDeliveryAddressFormContainer').hide();
            $('#summaryOverlayViewAddressBook').hide();
            $('#summaryDeliveryAddressBook').show();
            ACC.colorbox.resize();
        });
    },

    showRemoveAddressFromBookConfirmation() {
        $('.removeAddressFromBookButton').on('click', function () {
            $modal.open(this);
        });
    },

    trackFormChanges() {
        $('#addressForm').on('change', () => {
            $('.i18nAddressForm').data('changed', true);
        });
    },

    toggleUpdateAddressButton() {
        $('#addressForm').on('change', () => {
            ACC.buttonhelper.enable($('.js-update-address-button'));
        });
    },

    showCancelAddressChangesConfirmation() {
        $('.js-cancel-address-changes-popup-button').on('click', function () {
            if ($('.i18nAddressForm').data('changed')) {
                $modal.open(this);
            } else {
                window.location = $(this).data('cancelUrl');
            }
        });
    },

    bindCheckCountryDropdownChanges() {
        const dropdownElement = $('#address\\.country');
        const saveAddressComponent = $('#saveAddressInMyAddressBookComponent');
        const saveAddressCheckbox = $('#saveAddressInMyAddressBook');
        if (!dropdownElement.length) {
            return;
        }

        saveAddressComponent.show();
        if (!ACC.address.checkIfCountryIsInPearsonList()) {
            saveAddressCheckbox.prop('checked', false);
            saveAddressComponent.hide();
        }
        dropdownElement.change(() => {
            if (!ACC.address.checkIfCountryIsInPearsonList()) {
                saveAddressCheckbox.prop('checked', false);
                saveAddressComponent.hide();
            } else {
                saveAddressComponent.show();
            }
        });
    },

    checkIfCountryIsInPearsonList() {
        const addressCountry = $('#address\\.country');
        if (!addressCountry.length) {
            return;
        }
        const selectedCountryCode = addressCountry.find(':selected').val();
        const divWithIsoCodes = $('#countrySelector');
        if (!divWithIsoCodes.length) {
            return;
        }
        const isoCodesAsString = divWithIsoCodes[0].dataset.pearsonsupportedisocodes;

        if (isoCodesAsString === '' || isoCodesAsString.includes(selectedCountryCode)) {
            // eslint-disable-next-line consistent-return
            return true;
        }
        // eslint-disable-next-line consistent-return
        return false;
    },

    searchAddress() {
        const searchInput = $('#js-address-search-input');

        if (searchInput.length > 0) {
            const autocompleteOptions = {
                appendTo: searchInput,
                inputField: searchInput,
                delay: 500,
                minLength: 0,
                defaultMinLength: 0,
                autocompleteUrl: searchInput.data('search-url'),
                encodedContextPath: ACC.config.encodedContextPath,
                noResultsFallback: 'No items found in your address book',
            };
            // eslint-disable-next-line no-undef
            addAutocomplete(autocompleteOptions);
        }
    },

    bindFocusOnSearchInput() {
        $('#js-address-search-input').focus(() => {
            const inputField = $('#js-address-search-input');
            if (inputField.val().length < 3) {
                const keyEvent = $.Event('keydown');
                keyEvent.keyCode = $.ui.keyCode.SPACE;
                inputField.trigger(keyEvent);
            }
        });
    },
    updateFavoriteAddress() {
        $("button[id^='favorite']").on('click', function () {
            const addressCode = $(this).data('addressId');
            $.get({
                url: `${ACC.config.encodedContextPath}/addresses/update-favorite`,
                data: { addressCode },
                dataType: 'html',
                async: false,
                success() {
                    window.onunload = document.querySelector('.address-card').scrollIntoView();
                    window.location.reload();
                },
            });
        });
    },

    bindSpecialCharactersCheck() {
        const addressForm = document.querySelector('.account-section-form #addressForm');
        ACC.replaceSpecialCharacters.bindSpecialCharactersConversion(addressForm);
    },

    hidePostalZipCode(countryIsoCode) {
    // eslint-disable-next-line max-len
        if (ACC.config.isHidePostalCodeFieldByCountryListEnabled && ACC.config.countriesMissingPostalCode.indexOf(countryIsoCode) >= 0) {
            const inputElement = document.getElementById('address.postcode');
            if (inputElement) {
                inputElement.style.display = 'none';
                $('[for = "address.postcode"]').css('display', 'none');
            } else if (document.getElementById('registerNewCompanyMiniForm.postcode')) {
                document.getElementById('registerNewCompanyMiniForm.postcode').style.display = 'none';
                $('[for = "registerNewCompanyMiniForm.postcode"]').css('display', 'none');
            }
        }
    },

    hidePostalZipCodeOnLoad() {
        const countryIsoCode = $('#countrySelector :selected').val();
        ACC.address.hidePostalZipCode(countryIsoCode);
    },

    hidePostalZipCodeOnLoadForReReg() {
        const countryIsoCode = $('#defaultAddressCountrySelector :selected').val();
        ACC.address.hidePostalZipCode(countryIsoCode);
    },

};
