ACC.checkoutaddress = {

    spinner: $(`<img src='${ACC.config.commonResourcePath}/images/spinner.gif' />`),
    addressID: '',

    showAddressBook() {
        $(document).on('click', '#viewAddressBook', () => {
            const data = $('#savedAddressListHolder').html();
            $.colorbox({

                height: false,
                html: data,
                onComplete() {
                    $(this).colorbox.resize();
                },
            });
        });
    },

    showRemoveAddressConfirmation() {
        $(document).on('click', '.removeAddressButton', function () {
            const addressId = $(this).data('addressId');
            $.colorbox({
                inline: true,
                height: false,
                href: `#popup_confirm_address_removal_${addressId}`,
                onComplete() {
                    $(this).colorbox.resize();
                },
            });
        });
    },
};

// Address Verification
$(document).ready(() => {
    const { showAddressBook, showRemoveAddressConfirmation } = ACC.checkoutaddress;

    if (showAddressBook) {
        showAddressBook();
    }

    if (showRemoveAddressConfirmation) {
        showRemoveAddressConfirmation();
    }

    // [Sep 08, 2021] Removed as failing babel pipeline
    // with (ACC.checkoutaddress)
    // {

    // showAddressBook();
    // showRemoveAddressConfirmation();
    // }
});
