ACC.storefinder = {

    _autoload: [
        ['init', $('.js-store-finder').length !== 0],
        ['bindStoreChange', $('.js-store-finder').length !== 0],
        ['bindSearch', $('.js-store-finder').length !== 0],
        'bindPagination',
    ],

    storeData: '',
    storeId: '',
    coords: {},
    storeSearchData: {},

    createListItemHtml(data, id) {
        let item = '';
        item += '<li class="list__entry">';
        // eslint-disable-next-line max-len
        item += `<input type="radio" name="storeNamePost" value="${data.displayName}" id="store-filder-entry-${id}" class="js-store-finder-input" data-id="${id}">`;
        item += `<label for="store-filder-entry-${id}" class="js-select-store-label">`;
        item += '<span class="entry__info">';
        item += `<span class="entry__name">${data.displayName}</span>`;
        item += `<span class="entry__address">${data.line1} ${data.line2}</span>`;
        item += `<span class="entry__city">${data.town}</span>`;
        item += '</span>';
        item += '<span class="entry__distance">';
        item += `<span>${data.formattedDistance}</span>`;
        item += '</span>';
        item += '</label>';
        item += '</li>';
        return item;
    },

    refreshNavigation() {
        let listitems = '';
        data = ACC.storefinder.storeData;

        if (data) {
            for (let i = 0; i < data.data.length; i += 1) {
                listitems += ACC.storefinder.createListItemHtml(data.data[i], i);
            }

            $('.js-store-finder-navigation-list').html(listitems);

            // select the first store
            const firstInput = $('.js-store-finder-input')[0];
            $(firstInput).click();
        }

        const { page } = ACC.storefinder.storeSearchData;
        $('.js-store-finder-pager-item-from').html(page * 10 + 1);

        // eslint-disable-next-line max-len
        const to = ((page * 10 + 10) > ACC.storefinder.storeData.total) ? ACC.storefinder.storeData.total : page * 10 + 10;
        $('.js-store-finder-pager-item-to').html(to);
        $('.js-store-finder-pager-item-all').html(ACC.storefinder.storeData.total);
        $('.js-store-finder').removeClass('show-store');
    },

    bindPagination() {
        $(document).on('click', '.js-store-finder-details-back', (e) => {
            e.preventDefault();

            $('.js-store-finder').removeClass('show-store');
        });

        $(document).on('click', '.js-store-finder-pager-prev', (e) => {
            e.preventDefault();
            const { page } = ACC.storefinder.storeSearchData;
            ACC.storefinder.getStoreData(page - 1);
            // eslint-disable-next-line no-use-before-define
            checkStatus(page - 1);
        });

        $(document).on('click', '.js-store-finder-pager-next', (e) => {
            e.preventDefault();
            const { page } = ACC.storefinder.storeSearchData;
            ACC.storefinder.getStoreData(page + 1);
            // eslint-disable-next-line no-use-before-define
            checkStatus(page + 1);
        });

        function checkStatus(page) {
            if (page === 0) {
                $('.js-store-finder-pager-prev').attr('disabled', 'disabled');
            } else {
                $('.js-store-finder-pager-prev').removeAttr('disabled');
            }

            if (page === Math.floor(ACC.storefinder.storeData.total / 10)) {
                $('.js-store-finder-pager-next').attr('disabled', 'disabled');
            } else {
                $('.js-store-finder-pager-next').removeAttr('disabled');
            }
        }
    },

    bindStoreChange() {
        $(document).on('change', '.js-store-finder-input', function (e) {
            e.preventDefault();

            storeData = ACC.storefinder.storeData.data;

            const storeId = $(this).data('id');

            const $ele = $('.js-store-finder-details');

            $.each(storeData[storeId], (key, value) => {
                if (key === 'image') {
                    if (value !== '') {
                        $ele.find('.js-store-image').html(`<img src="${value}" alt="" />`);
                    } else {
                        $ele.find('.js-store-image').html('');
                    }
                } else if (key === 'productcode') {
                    $ele.find('.js-store-productcode').val(value);
                } else if (key === 'openings') {
                    if (value !== '') {
                        const $oele = $ele.find(`.js-store-${key}`);
                        let openings = '';
                        $.each(value, (key2, value2) => {
                            openings += `<dt>${key2}</dt>`;
                            openings += `<dd>${value2}</dd>`;
                        });

                        $oele.html(openings);
                    } else {
                        $ele.find(`.js-store-${key}`).html('');
                    }
                    // eslint-disable-next-line no-empty
                } else if (key === 'specialOpenings') {} else if (key === 'features') {
                    let features = '';
                    $.each(value, (key2, value2) => {
                        features += `<li>${value2}</li>`;
                    });

                    $ele.find(`.js-store-${key}`).html(features);
                } else if (value !== '') {
                    $ele.find(`.js-store-${key}`).html(value);
                } else {
                    $ele.find(`.js-store-${key}`).html('');
                }
            });

            ACC.storefinder.storeId = storeData[storeId];
            ACC.storefinder.initGoogleMap();
        });

        $(document).on('click', '.js-select-store-label', () => {
            $('.js-store-finder').addClass('show-store');
        });

        $(document).on('click', '.js-back-to-storelist', () => {
            $('.js-store-finder').removeClass('show-store');
        });
    },

    initGoogleMap() {
        if ($('.js-store-finder-map').length > 0) {
            ACC.global.addGoogleMapsApi('ACC.storefinder.loadGoogleMap');
        }
    },

    loadGoogleMap() {
        storeInformation = ACC.storefinder.storeId;

        if ($('.js-store-finder-map').length > 0) {
            $('.js-store-finder-map').attr('id', 'store-finder-map');
            // eslint-disable-next-line no-undef
            const centerPoint = new google.maps.LatLng(storeInformation.latitude, storeInformation.longitude);

            const mapOptions = {
                zoom: 13,
                zoomControl: true,
                panControl: true,
                streetViewControl: false,
                // eslint-disable-next-line no-undef
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                center: centerPoint,
            };

            // eslint-disable-next-line no-undef
            const map = new google.maps.Map(document.getElementById('store-finder-map'), mapOptions);

            // eslint-disable-next-line no-undef
            const marker = new google.maps.Marker({
                // eslint-disable-next-line no-undef
                position: new google.maps.LatLng(storeInformation.latitude, storeInformation.longitude),
                map,
                title: storeInformation.name,
                icon: 'https://maps.google.com/mapfiles/markerA.png',
            });
            // eslint-disable-next-line no-undef
            const infowindow = new google.maps.InfoWindow({
                content: storeInformation.name,
                disableAutoPan: true,
            });
            // eslint-disable-next-line no-undef
            google.maps.event.addListener(marker, 'click', () => {
                infowindow.open(map, marker);
            });
        }
    },

    bindSearch() {
        $(document).on('submit', '#storeFinderForm', (e) => {
            e.preventDefault();
            const q = $('.js-store-finder-search-input').val();

            if (q.length > 0) {
                ACC.storefinder.getInitStoreData(q);
            } else if ($('.js-storefinder-alert').length < 1) {
                const emptySearchMessage = $('.btn-primary').data('searchEmpty');
                $('.js-store-finder').hide();
                // eslint-disable-next-line max-len
                $('#storeFinder').before(`<div class="js-storefinder-alert alert alert-danger alert-dismissable getAccAlert" ><button class="close closeAccAlert" type="button" data-dismiss="alert" aria-hidden="true">×</button>${emptySearchMessage}</div>`);
                $('.closeAccAlert').on('click', function () {
                    $(this).parent('.getAccAlert').remove();
                });
            }
        });

        $('.js-store-finder').hide();
        $(document).on('click', '#findStoresNearMe', (e) => {
            e.preventDefault();
            ACC.storefinder.getInitStoreData(null, ACC.storefinder.coords.latitude, ACC.storefinder.coords.longitude);
        });
    },

    getStoreData(page) {
        ACC.storefinder.storeSearchData.page = page;
        url = $('.js-store-finder').data('url');
        $.ajax({
            url,
            data: ACC.storefinder.storeSearchData,
            type: 'get',
            success(response) {
                ACC.storefinder.storeData = $.parseJSON(response);
                ACC.storefinder.refreshNavigation();
                if (ACC.storefinder.storeData.total < 10) {
                    $('.js-store-finder-pager-next').attr('disabled', 'disabled');
                }
            },
        });
    },

    getInitStoreData(q, latitude, longitude) {
        $('.alert').remove();
        data = {
            q: '',
            page: 0,
        };

        if (q != null) {
            data.q = q;
        }

        if (latitude != null) {
            data.latitude = latitude;
        }

        if (longitude != null) {
            data.longitude = longitude;
        }

        ACC.storefinder.storeSearchData = data;
        ACC.storefinder.getStoreData(data.page);
        $('.js-store-finder').show();
        $('.js-store-finder-pager-prev').attr('disabled', 'disabled');
        $('.js-store-finder-pager-next').removeAttr('disabled');
    },

    init() {
        $('#findStoresNearMe').attr('disabled', 'disabled');
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    ACC.storefinder.coords = position.coords;
                    $('#findStoresNearMe').removeAttr('disabled');
                },
                (error) => {
                    // eslint-disable-next-line no-console
                    console.log(`An error occurred... The error code and message are: ${error.code}/${error.message}`);
                },
            );
        }
    },
};
