// eslint-disable-next-line no-unused-vars,no-undef
const pluginInstance = new FiltersToggle({
    hiddenClass: 'isHidden',
    toggleClass: 'isToggled',
    categoryClass: 'js-filters',
    showFiltersClass: 'js-filter-list',
    isVisibleClass: 'isVisible',
    showMoreOrLessClass: '.js-showHideFilters',
    toggleListClass: 'js-toggleFilters',
    // eslint-disable-next-line no-undef
    paginationUpdate: paginationInstance.update,
    // eslint-disable-next-line no-undef
    updateSort: paginationInstance.updateSort,
});
