ACC.contentsearch = {
    _autoload: [
        'searchTabSwitchAction',
        'bindContentSearchButton',
        'bindAssessmentsSearchButton',
        'initContentSearch',
    ],

    initContentSearch() {
        (function (w, d, t, u, n, s, e) {
            w.SwiftypeObject = n;
            w[n] = w[n] || function () {
                // eslint-disable-next-line prefer-rest-params
                (w[n].q = w[n].q || []).push(arguments);
            };
            // eslint-disable-next-line no-param-reassign
            s = d.createElement(t);
            // eslint-disable-next-line no-param-reassign,prefer-destructuring
            e = d.getElementsByTagName(t)[0];
            s.async = 1;
            s.src = u;
            e.parentNode.insertBefore(s, e);
        }(window, document, 'script', '//s.swiftypecdn.com/install/v2/st.js', '_st'));

        // eslint-disable-next-line no-undef
        _st('install', ACC.config.contentSearchSiteId, '2.0.0');

        $('.search__tabs').ready(() => {
            const inputSearch = $('#js-site-search-input');
            if (inputSearch.length) {
                // eslint-disable-next-line no-use-before-define
                searchText = decodeURI(extractSearchText(inputSearch.val()));
                $('.st-default-search-input').val(searchText);
                $('#js-site-search-input').val(searchText);

                ACC.contentsearch.triggerContentSearch(searchText);
            }
        });
    },

    searchTabSwitchAction() {
        $('.nav-tabs > li > a').click(function () {
            const active_tab_selector = $('.nav-tabs > li.active > a').attr('href');

            const actived_nav = $('.nav-tabs > li.active');
            actived_nav.removeClass('active');

            $(this).parents('li').addClass('active');

            $(active_tab_selector).removeClass('active');
            $(active_tab_selector).addClass('hide');

            $(`${active_tab_selector}SearchBox`).addClass('hide');
            $(`${active_tab_selector}SearchBox`).removeClass('active');

            const target_tab_selector = $(this).attr('href');
            const tab_selected = $(this).attr('id');

            $(target_tab_selector).removeClass('hide');
            $(target_tab_selector).addClass('active');

            $(`${target_tab_selector}SearchBox`).removeClass('hide');
            $(`${target_tab_selector}SearchBox`).addClass('active');

            let searchText;

            if (target_tab_selector === '#contentTab') {
                searchText = decodeURI(window.location.search.slice(window.location.search.lastIndexOf('=') + 1));
            } else if (target_tab_selector === '#assessmentTab') {
                searchText = $('.st-default-search-input').val();
            }

            if (tab_selected === 'content') {
                // eslint-disable-next-line no-use-before-define
                searchText = decodeURI(extractSearchText(searchText));
                $('.st-default-search-input').val(searchText);
                ACC.contentsearch.triggerContentSearch(searchText);
            }

            if (tab_selected === 'assessment') {
                const pageurl = decodeURI(window.location.href);

                $('.js-site-search-input-assessments').val(searchText);

                if (searchText && searchText.trim() !== '') {
                    if (pageurl.indexOf('#stq') !== -1) {
                        let strUrl = pageurl.substr(0, pageurl.indexOf('#stq'));
                        if (strUrl.indexOf('?text') !== -1) {
                            strUrl = strUrl.substr(0, pageurl.indexOf('?text=') + 6) + searchText;
                        } else if (strUrl.indexOf('&q') !== -1) {
                            strUrl = strUrl.substr(0, pageurl.indexOf('&q=') + 3) + searchText;
                        }
                        window.location.href = encodeURI(strUrl);
                    } else if (pageurl.indexOf('?text') !== -1) {
                        window.location.href = encodeURI(pageurl.substr(0, pageurl.indexOf('?text=') + 6) + searchText);
                    }
                } else {
                    window.location.href = encodeURI(window.location.href.split('#')[0]);
                }
            }
        });
    },

    bindContentSearchButton() {
        $(document).on('click', '#contentSearchBtn', () => {
            const searchText = $('.st-default-search-input').val();

            if (searchText && searchText.trim()) {
                ACC.contentsearch.triggerContentSearch(searchText.trim());
            }
        });
    },

    bindAssessmentsSearchButton() {
        $('.search-box-icon').on('click', function (event) {
            const searchInput = $(this).prev('.search-box-input');
            const searchText = searchInput.val();

            // eslint-disable-next-line no-unused-expressions
            searchText.trim() ? searchInput.val(searchText.trim()) : event.preventDefault();
        });
    },

    triggerContentSearch(searchText) {
        // eslint-disable-next-line no-use-before-define
        const contentSearchUrl = generatecontentSearchUrl(window.location.href, searchText);
        if (navigator.userAgent.lastIndexOf('Firefox') === -1) {
            $(document).ready(() => {
                window.location.href = contentSearchUrl;
            });
        } else {
            window.location.href = encodeURI(contentSearchUrl);
        }
        // eslint-disable-next-line no-use-before-define
        window.onpopstate = contentSearchFunction;
    },
};

function extractSearchText(str) {
    const _AMP_AMP = '%26%26';

    if (str !== null) {
        if (str.indexOf('<strong>') !== -1) {
            searchword = str.match('<strong>(.*)</strong>');
            return searchword ? searchword[1] : str;
        }
        if (str.indexOf(_AMP_AMP) !== -1) {
            searchword = str.split(_AMP_AMP);
            return searchword ? searchword[0] : str;
        }

        return str;
    }
    const searchWordURL = window.sanitizeHTML(window.location.href);
    searchword = searchWordURL.href.split('=');
    if (searchword[1].indexOf('#') !== -1) {
        updatedsearchword = searchword[1].split('#');
        return updatedsearchword[0];
    }
    return searchword[1];
}

function generatecontentSearchUrl(input, searchText) {
    // eslint-disable-next-line max-len,no-return-assign,no-undef
    return pageurl = `${input.substr(0, input.indexOf('#stq')).replace('#contentTab', '').replace('#assessmentTab', '').trim()}#stq=${searchText}`;
}

function contentSearchFunction() {
    // eslint-disable-next-line no-use-before-define
    updateContentSearchResultcount();
    // eslint-disable-next-line no-use-before-define
    hidespellingsuggestion();
}

function updateContentSearchResultcount() {
    const contentsearchResultCount = $('.st-ui-injected-search-summary').html();
    const contentTabName = $('#content_tab_id').data('tab-name');

    if (contentsearchResultCount) {
        if (contentsearchResultCount.indexOf('No results found') !== -1) {
            $('#content_tab_id').html(`${contentTabName} (0)`);
            $('.st-empty-search').removeClass('hide');
            $('.st-empty-search').addClass('active');
        } else if (contentsearchResultCount.indexOf('<strong>') !== -1) {
            resultcount = contentsearchResultCount.split('<strong>');
            if (resultcount.length > 2) {
                // eslint-disable-next-line no-use-before-define
                const countval = getContentSearchResultCount(resultcount);
                $('#content_tab_id').html(`${contentTabName} (${countval})`);
            } else {
                $('#content_tab_id').html(`${contentTabName} (1)`);
            }
        }
    }
}

function hidespellingsuggestion() {
    $('.st-spelling-suggestion-link').addClass('hide');
}

// eslint-disable-next-line no-unused-vars
function showEmptyPageIfEmptyResult() {
    $('.st-ui-container-primary_content').addClass('hide');
    $('.st-empty-search').addClass('active');
    $('st-query-not-present').css('display', 'block');
}

// eslint-disable-next-line no-unused-vars
function showResultPage() {
    $('.st-ui-container-primary_content').addClass('active');
    $('.st-ui-container-primary_content').removeClass('hide');
    $('st-query-not-present').css('display', 'none');
}

function getContentSearchResultCount() {
    return resultcount[3].substr(0, resultcount[3].indexOf('</strong>'));
}
