ACC.productDetail = {

    _autoload: [
        'initPageEvents',
        'bindVariantOptions',
    ],

    checkQtySelector(self, mode) {
        const input = $(self).parents('.js-qty-selector').find('.js-qty-selector-input');
        const inputVal = parseInt(input.val(), 10);
        const max = input.data('max');
        const minusBtn = $(self).parents('.js-qty-selector').find('.js-qty-selector-minus');
        const plusBtn = $(self).parents('.js-qty-selector').find('.js-qty-selector-plus');

        $(self).parents('.js-qty-selector').find('.btn').removeAttr('disabled');

        if (mode === 'minus') {
            if (inputVal !== 1) {
                ACC.productDetail.updateQtyValue(self, inputVal - 1);
                if (inputVal - 1 === 1) {
                    minusBtn.attr('disabled', 'disabled');
                }
            } else {
                minusBtn.attr('disabled', 'disabled');
            }
        } else if (mode === 'reset') {
            ACC.productDetail.updateQtyValue(self, 1);
        } else if (mode === 'plus') {
            if (max === 'FORCE_IN_STOCK') {
                ACC.productDetail.updateQtyValue(self, inputVal + 1);
            } else if (inputVal <= max) {
                ACC.productDetail.updateQtyValue(self, inputVal + 1);
                if (inputVal + 1 === max) {
                    plusBtn.attr('disabled', 'disabled');
                }
            } else {
                plusBtn.attr('disabled', 'disabled');
            }
        } else if (mode === 'input') {
            if (inputVal === 1) {
                minusBtn.attr('disabled', 'disabled');
            } else if (max === 'FORCE_IN_STOCK' && inputVal > 0) {
                ACC.productDetail.updateQtyValue(self, inputVal);
            } else if (inputVal === max) {
                plusBtn.attr('disabled', 'disabled');
            } else if (inputVal < 1) {
                ACC.productDetail.updateQtyValue(self, 1);
                minusBtn.attr('disabled', 'disabled');
            } else if (inputVal > max) {
                ACC.productDetail.updateQtyValue(self, max);
                plusBtn.attr('disabled', 'disabled');
            }
        } else if (mode === 'focusout') {
            // eslint-disable-next-line no-restricted-globals
            if (isNaN(inputVal)) {
                ACC.productDetail.updateQtyValue(self, 1);
                minusBtn.attr('disabled', 'disabled');
            } else if (inputVal >= max) {
                plusBtn.attr('disabled', 'disabled');
            }
        }
    },

    updateQtyValue(self, value) {
        const input = $(self).parents('.js-qty-selector').find('.js-qty-selector-input');
        // eslint-disable-next-line max-len
        const addtocartQty = $(self).parents('.addtocart-component').find('#addToCartForm').find('.js-qty-selector-input');
        // eslint-disable-next-line max-len
        const configureQty = $(self).parents('.addtocart-component').find('#configureForm').find('.js-qty-selector-input');
        input.val(value);
        addtocartQty.val(value);
        configureQty.val(value);
    },

    initPageEvents() {
        $(document).on('click', '.js-qty-selector .js-qty-selector-minus', function () {
            ACC.productDetail.checkQtySelector(this, 'minus');
        });

        $(document).on('click', '.js-qty-selector .js-qty-selector-plus', function () {
            ACC.productDetail.checkQtySelector(this, 'plus');
        });

        $(document).on('keydown', '.js-qty-selector .js-qty-selector-input', function (e) {
            // eslint-disable-next-line max-len,no-empty
            if (($(this).val() !== ' ' && ((e.which >= 48 && e.which <= 57) || (e.which >= 96 && e.which <= 105))) || e.which === 8 || e.which === 46 || e.which === 37 || e.which === 39 || e.which === 9) {
            } else if (e.which === 38) {
                ACC.productDetail.checkQtySelector(this, 'plus');
            } else if (e.which === 40) {
                ACC.productDetail.checkQtySelector(this, 'minus');
            } else {
                e.preventDefault();
            }
        });

        $(document).on('keyup', '.js-qty-selector .js-qty-selector-input', function () {
            ACC.productDetail.checkQtySelector(this, 'input');
            ACC.productDetail.updateQtyValue(this, $(this).val());
        });

        $(document).on('focusout', '.js-qty-selector .js-qty-selector-input', function () {
            ACC.productDetail.checkQtySelector(this, 'focusout');
            ACC.productDetail.updateQtyValue(this, $(this).val());
        });

        $('#Size').change(() => {
            // eslint-disable-next-line no-use-before-define
            changeOnVariantOptionSelection($('#Size option:selected'));
        });

        $('#variant').change(() => {
            // eslint-disable-next-line no-use-before-define
            changeOnVariantOptionSelection($('#variant option:selected'));
        });

        $('.selectPriority').change(function () {
            window.location.href = $(this[this.selectedIndex]).val();
        });

        function changeOnVariantOptionSelection(optionSelected) {
            window.location.href = optionSelected.attr('value');
        }
    },

    bindVariantOptions() {
        ACC.productDetail.bindCurrentStyle();
        ACC.productDetail.bindCurrentSize();
        ACC.productDetail.bindCurrentType();
    },

    bindCurrentStyle() {
        const currentStyle = $('#currentStyleValue').data('styleValue');
        const styleSpan = $('.styleName');
        if (currentStyle != null) {
            styleSpan.text(`: ${currentStyle}`);
        }
    },

    bindCurrentSize() {
        const currentSize = $('#currentSizeValue').data('sizeValue');
        const sizeSpan = $('.sizeName');
        if (currentSize != null) {
            sizeSpan.text(`: ${currentSize}`);
        }
    },

    bindCurrentType() {
        const currentSize = $('#currentTypeValue').data('typeValue');
        const sizeSpan = $('.typeName');
        if (currentSize != null) {
            sizeSpan.text(`: ${currentSize}`);
        }
    },
};
