(function (root, factory) {
    const pluginName = 'PearsonBreadcrumbs';

    // eslint-disable-next-line no-undef
    if (typeof define === 'function' && define.amd) {
        // eslint-disable-next-line no-undef
        define([], factory(pluginName));
    } else if (typeof exports === 'object') {
        module.exports = factory(pluginName);
    } else {
        root[pluginName] = factory(pluginName);
    }
// eslint-disable-next-line no-unused-vars
}(this, (pluginName) => {
    /**
     * Default options
     */
    const defaults = {
        element: '',
        container: '',
        truncationHtml: '',
        showAll: '',
        showFirstLast: '',
        truncClass: '',
        breadcrumbList: '',
        padding: 0,
        breadcrumbItemTag: '',
    };

    /**
     * Merge defaults with user options
     @param {Object} defaults Default settings
     @param {Object} options User options
     */
    // eslint-disable-next-line no-shadow
    const extend = function (defaults, options) {
        let prop;
        const extended = {};
        // eslint-disable-next-line no-restricted-syntax
        for (prop in defaults) {
            if (Object.prototype.hasOwnProperty.call(defaults, prop)) {
                extended[prop] = defaults[prop];
            }
        }
        // eslint-disable-next-line no-restricted-syntax
        for (prop in options) {
            if (Object.prototype.hasOwnProperty.call(options, prop)) {
                extended[prop] = options[prop];
            }
        }
        return extended;
    };

    /**
     * Add truncation for breadcrumbs
     @param {Object} options PearsonBreadcrumbs options
     @function
     */
    const addTruncation = function (options) {
        const list = options.breadcrumbList[0];
        const listElementCount = options.breadcrumbList[0].childElementCount;

        if (listElementCount > 2) {
            options.breadcrumbItem.insertAdjacentHTML('afterend', options.truncationHtml);
        }

        list.classList.remove(options.showAll);
        list.classList.add(options.showFirstLast);
    };

    /**
     * Remove truncation for breadcrumbs
     @param {Object} options PearsonBreadcrumbs options
     @function
     */
    const removeTruncation = function (options) {
        const truncation = document.querySelector(options.truncClass);
        if (!truncation) { return; }

        truncation.parentNode.removeChild(truncation);

        const list = options.breadcrumbList[0];
        list.classList.remove(options.showFirstLast);
        list.classList.add(options.showAll);
    };

    /**
     * Add handler on truncation
     @param {Object} options PearsonBreadcrumbs options
     @function
     */
    const addHandler = function (options) {
        const truncation = document.querySelector(options.truncClass);
        if (!truncation) { return; }
        const list = options.breadcrumbList[0];

        truncation.addEventListener('click', () => {
            truncation.parentNode.removeChild(truncation);

            list.classList.remove(options.showFirstLast);
            list.classList.add(options.showAll);
        });
    };

    /**
     * Check whether container width less than breadcrumb component
     @param {Object} options PearsonBreadcrumbs options
     @returns {boolean} true means we need to add truncation
     */
    const checkTruncation = function (options) {
        const breadcrumbContainerWidth = options.container[0].offsetWidth;
        const breadcrumbWidth = options.breadcrumbList[0].offsetWidth;

        return breadcrumbContainerWidth <= breadcrumbWidth + options.padding;
    };

    /**
     * DOM Elements validation
     @private
     @param {Object} options PearsonBreadcrumbs options
     @returns {Boolean} true if all required DOM elements are present
     */
    const checkForRequiredDOMElements = function (options) {
        // Return false if component is not presented
        if (options.element.length === 0) { return false; }

        // throw Error if container is not presented
        if (options.container.length === 0) {
            throw new Error('Please provide: container param - class of DOM element that wraps whole component');
        }

        // throw Error if breadcrumbList is not presented
        if (options.breadcrumbList.length === 0) {
            throw new Error('Please provide: breadcrumbList param - class of DOM element that wraps crumbs');
        }

        // throw Error if breadcrumbItem is not presented
        try {
            options.breadcrumbItem = document.querySelector(options.breadcrumbItemTag);
        } catch (err) {
            // eslint-disable-next-line max-len
            throw new Error("Please provide: breadcrumbItemTag param - tag 'li' of DOM element that shows single crumb");
        }

        return true;
    };

    /**
     * Plugin Object
     @param {Object} options User options
     @constructor
     */
    function Plugin(options) {
        this.options = extend(defaults, options);

        this.init(this.options);
    }

    /**
     * Plugin prototype
     @public
     @constructor
     */
    Plugin.prototype.init = function (options) {
        if (!checkForRequiredDOMElements(options)) { return; }

        const hasTruncation = checkTruncation(options);

        if (hasTruncation) {
            addTruncation(options);
            addHandler(options);
        } else {
            removeTruncation(options);
        }
    };

    return Plugin;
}));
