/* pmc-alert.js

  Pearson PMC Alert Component (AEM) behavior
  Requirements:
  1. Retrieve cookie value if cookie exists
  2. Set (session) cookie
  3. Manage state based on 1 & 2
  ================================================== */
// Polyfill: .after()
(function (arr) {
    arr.forEach((item) => {
        // eslint-disable-next-line no-prototype-builtins
        if (item.hasOwnProperty('after')) {
            return;
        }
        Object.defineProperty(item, 'after', {
            configurable: true,
            enumerable: true,
            writable: true,
            value: function after() {
                // eslint-disable-next-line prefer-rest-params
                const argArr = Array.prototype.slice.call(arguments);
                const docFrag = document.createDocumentFragment();

                argArr.forEach((argItem) => {
                    const isNode = argItem instanceof Node;
                    docFrag.appendChild(isNode ? argItem : document.createTextNode(String(argItem)));
                });

                this.parentNode.insertBefore(docFrag, this.nextSibling);
            },
        });
    });
}([Element.prototype, CharacterData.prototype, DocumentType.prototype]));

// Polyfill: .closest()
if (!Element.prototype.closest) {
    if (!Element.prototype.matches) {
        Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
    }
    Element.prototype.closest = function (s) {
        const el = this;
        let ancestor = this;
        if (!document.documentElement.contains(el)) return null;
        do {
            if (ancestor.matches(s)) return ancestor;
            ancestor = ancestor.parentElement;
        } while (ancestor !== null);
        return null;
    };
}

// Polyfill: .forEach()
if (typeof NodeList !== 'undefined' && NodeList.prototype && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = Array.prototype.forEach;
}

// Polyfill: .prepend()
(function (arr) {
    arr.forEach((item) => {
        // eslint-disable-next-line no-prototype-builtins
        if (item.hasOwnProperty('prepend')) {
            return;
        }
        Object.defineProperty(item, 'prepend', {
            configurable: true,
            enumerable: true,
            writable: true,
            value: function prepend() {
                // eslint-disable-next-line prefer-rest-params
                const argArr = Array.prototype.slice.call(arguments);
                const docFrag = document.createDocumentFragment();

                argArr.forEach((argItem) => {
                    const isNode = argItem instanceof Node;
                    docFrag.appendChild(isNode ? argItem : document.createTextNode(String(argItem)));
                });

                this.insertBefore(docFrag, this.firstChild);
            },
        });
    });
}([Element.prototype, Document.prototype, DocumentFragment.prototype]));

// Helper: set cookie
function setCookie(cookieName, cookieValue, cookieExpires) {
    const d = new Date();
    d.setTime(d.getTime() + (cookieExpires * 24 * 60 * 60 * 1000));
    const expires = `expires=${d.toUTCString()}`;
    document.cookie = `${cookieName}=${cookieValue};${expires};path=/`;
}

// Helper: get cookie
function getCookie(cookieName) {
    const name = `${cookieName}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    let i;
    let c;
    for (i = 0; i < ca.length; i += 1) {
        c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
}

// Global message fn
const globalMessage = function () {
    // No cookie behavior in author mode (AEM)
    if (!document.querySelector('body').classList.contains('cq-wcm-edit')) {
        // Location in Hybris is different then in AEM
        // eslint-disable-next-line no-unused-vars
        const header = document.querySelector('main > header');
        let msgContainer;
        let msgDismiss;
        let msgCookie;
        let msgID;
        // eslint-disable-next-line no-use-before-define
        const globalProperties = globalProperties || {};

        // Element check (container)
        if (document.querySelectorAll('.pmc-alert')) {
            msgContainer = document.querySelectorAll('.pmc-alert');
        }

        // Element check (button)
        if (document.querySelectorAll('.pmc-alert__dismiss')) {
            msgDismiss = document.querySelectorAll('.pmc-alert__dismiss');
        }

        // Container
        msgContainer.forEach((container) => {
            msgID = container.getAttribute('id');

            if (globalProperties.siteId) {
                msgCookie = getCookie(`global-msg-${document.location.hostname}-${globalProperties.siteId}-${msgID}`);
            } else {
                msgCookie = getCookie(`global-msg-${document.location.hostname}-${msgID}`);
            }

            // Check: does cookie exist?
            // eslint-disable-next-line no-mixed-operators
            if (!(msgCookie) || msgCookie !== '' && msgCookie !== null && msgCookie !== 'DISMISSED') {
                container.classList.add('is-visible');
            } else {
                // eslint-disable-next-line max-len
                container.parentNode.removeChild(container); // Remove global message from DOM b/c visitor has already dismissed during this session
            }
        });

        // Button
        msgDismiss.forEach((button) => {
            button.addEventListener('click', (event) => {
                event.preventDefault();
                msgContainer = button.closest('.pmc-alert');
                msgID = msgContainer.getAttribute('id');
                // Helper: set cookie
                if (globalProperties.siteId) {
                    // eslint-disable-next-line max-len
                    setCookie(`global-msg-${document.location.hostname}-${globalProperties.siteId}-${msgID}`, 'DISMISSED');
                } else {
                    setCookie(`global-msg-${document.location.hostname}-${msgID}`, 'DISMISSED');
                }
                msgContainer.classList.remove('is-visible');
                msgContainer.parentNode.removeChild(msgContainer);
            });
        });
    }
};

// Call globalMessage() fn
window.addEventListener('load', () => {
    globalMessage();
});
