ACC.forms = {

    createAndSubmitForm(path, params, method) {
        method = method || 'post';

        const fragment = document.createDocumentFragment();
        const form = document.createElement('form');
        form.setAttribute('method', method);
        form.setAttribute('action', path);

        const hiddenFields = ACC.forms.createHiddenInputs(params);
        hiddenFields.forEach((hiddenField) => { form.appendChild(hiddenField); });

        fragment.appendChild(form);
        document.body.appendChild(fragment);
        form.submit();
    },

    createHiddenInput(name, value) {
        const hiddenField = document.createElement('input');
        hiddenField.setAttribute('type', 'hidden');
        hiddenField.setAttribute('name', name);
        hiddenField.setAttribute('value', value);
        return hiddenField;
    },

    createHiddenInputs(params) {
        const hiddenFields = [];
        // eslint-disable-next-line no-restricted-syntax
        for (const key in params) {
            // eslint-disable-next-line no-prototype-builtins
            if (params.hasOwnProperty(key)) {
                hiddenFields.push(ACC.forms.createHiddenInput(key, params[key]));
            }
        }
        return hiddenFields;
    },
};
