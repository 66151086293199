ACC.onboarding = {

    _autoload: [
        'bindOnboardingModal',
        'bindClickNext',
        'bindClickPrevious',
    ],

    bindOnboardingModal() {
        const component = $('.onboarding-component');
        if (component.length > 0) {
            const componentUid = component.data('componentUid');
            const cookieExDays = component.data('cookieExdays');
            ACC.cookie.setCookie(componentUid, componentUid, cookieExDays);
            const firstSlide = $('.onboarding-slide-0');
            $modal.open(null, {
                html: firstSlide.html(),
                className: 'colorbox--onboarding-comp',
                onOpen: {},
                // eslint-disable-next-line no-use-before-define
                onComplete: onModalOpen,
            });
            // eslint-disable-next-line no-inner-declarations
            function onModalOpen() {
                const dialogContainer = $('#colorbox:visible');
                const dialogContainerWrap = dialogContainer.find('#cboxWrapper');

                // container: tag class + set aria
                dialogContainer.attr('aria-modal', 'true');

                // inner wrap: tag class
                dialogContainerWrap.addClass('colorbox--onboarding-comp__wrapper');
            }
        }
    },
    bindClickNext() {
        $('#colorbox').on('click', '.js-next-slide', () => {
            const nextSlideIndex = $('#colorbox .js-next-slide').data('nextContent');
            if (nextSlideIndex === '#') {
                $modal.close();
            } else {
                const nextSlideContent = $(`.onboarding-slide-${nextSlideIndex}`).html();
                $modal.open(null, {
                    html: nextSlideContent,
                    className: 'colorbox--onboarding-comp',
                });
            }
        });
    },
    bindClickPrevious() {
        $('#colorbox').on('click', '.js-previous-slide', () => {
            const previousSlideIndex = $('#colorbox .js-previous-slide').data('previousContent');
            const previousSlideContent = $(`.onboarding-slide-${previousSlideIndex}`).html();
            $modal.open(null, {
                html: previousSlideContent,
                className: 'colorbox--onboarding-comp',
            });
        });
    },
};
