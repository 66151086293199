ACC.headerbanner = {
    _autoload: ['headerBanner'],

    headerBanner() {
        const pearsonbanner = $('header .pearson-banner');
        // eslint-disable-next-line no-unused-vars
        const searchBox = $('header .search-box');
        const pBannerCont = pearsonbanner.find('>div');
        const logoLink = encodeURI(pBannerCont.find('>a').attr('href'));
        const parSys = $('header > div > .parsys');
        const header = $('body > header');
        const skipNav = $('.skip-nav');

        pBannerCont.find('>a').remove();
        pBannerCont.find('.pearson-banner-always-learning').remove();

        /* Set [role="banner"] on actual <header> element */
        pearsonbanner
            .find('>div[role="banner"]')
            .removeAttr('role');
        header
            .attr('role', 'banner');

        $('body > main').attr('id', 'main-content');

        pBannerCont.removeClass('container');
        // Prepend #site-logo rather than append to restore natural order and tabindex flow
        // pBannerCont.append('<div id="site-logo">
        // <a href="' + logoLink + '" aria-label="Pearson logo" title="Pearson logo">
        // <em class="sr-only">Pearson<em></a></div>');
        pBannerCont
            // eslint-disable-next-line max-len
            .prepend(`<div id="site-logo" title="Pearson logo"><a href="${logoLink}" aria-label="Pearson logo" title="Pearson logo"><em class="sr-only">Pearson</em></a></div>`);

        $('body > header').prepend('<div class="header"></div>');
        parSys.appendTo('header .header');

        // Move the "AEM skip to main" to the header
        header
            .prepend(skipNav);
    },
};
