ACC.profile = {
    _autoload: [
        'initialize',
        'trackFormChanges',
        'showCancelProfileChangesConfirmation',
        'bindCountryStates',
    ],

    spinner: $(`<img src='${ACC.config.commonResourcePath}/images/spinner.gif' />`),

    initialize() {
        if ($('#updateProfileToken').length > 0 && piSession) {
            piSession.getToken((status, token) => {
                $('#updateProfileToken').val(token);
                $('#updateProfileUserId').val(piSession.userId());
            });
        }
        const firstName = document.querySelector('[name="firstName"]');
        const middleName = document.querySelector('[name="middleName"]');
        const lastName = document.querySelector('[name="lastName"]');
        ACC.replaceSpecialCharacters.bindSpecialCharactersConversion(firstName);
        ACC.replaceSpecialCharacters.bindSpecialCharactersConversion(middleName);
        ACC.replaceSpecialCharacters.bindSpecialCharactersConversion(lastName);
    },

    trackFormChanges() {
        $('#updatePearsonProfileForm').on('change', () => {
            $('#updatePearsonProfileForm').data('changed', true);
        });
    },

    showCancelProfileChangesConfirmation() {
        $('.js-cancel-profile-changes-popup-button').on('click', function () {
            if ($('#updatePearsonProfileForm').data('changed')) {
                $modal.open(this);
            } else {
                window.location = $(this).data('cancelUrl');
            }
        });
    },

    bindCountryStates() {
        $('#defaultAddressCountrySelector').on('change', function () {
            ACC.registerPersona.displayStatesForChosenCountry(
                { countryIsoCode: $(this).val() },
                $('#countryAddressData'),
                ACC.registerPersona.spinner,
            );
        });
    },

    displayStatesForChosenCountry(options, countryAddressData, spinner) {
        $.ajax({
            url: `${ACC.config.encodedContextPath}/my-account/displayStates`,
            async: true,
            data: options,
            dataType: 'html',
            beforeSend() {
                countryAddressData.html(spinner);
            },
        }).done((data) => {
            countryAddressData.empty();
            countryAddressData.append($(data).html());
        });
    },
};
