ACC.expresscheckout = {

    _autoload: [
        'displayExpressCheckoutErrorMessage',
    ],

    displayExpressCheckoutErrorMessage() {
        const errorCodeForEC = window.localStorage.getItem('ECRedirectMessageCode');
        // eslint-disable-next-line prefer-template, max-len
        if (errorCodeForEC && (window.location.href.includes('interstitial') || window.location.href.includes('cart'))) {
            const globalMessageRequest = {
                globalMessages: [{
                    messageHolder: window.localStorage.getItem('ECRedirectGlobalMessageType'),
                    messages: [errorCodeForEC],
                    attributes: [window.localStorage.getItem('ECredirectGlobalMessageAttributes')],
                }],
            };
            ACC.globalmessages.createGlobalMessage(globalMessageRequest);
            ACC.expresscheckout.removeLocalStorageWithGlobalMessageData();
        }
    },

    doExpressCheckoutFromPopUp(element) {
        $modal.close();
        const isAuthGenerated = ACC.expresscheckout.isUserLoggedIn();
        if (!isAuthGenerated) {
            window.location = `${ACC.config.encodedContextPath}/login`;
        } else {
            // eslint-disable-next-line prefer-template
            const productSelectedData = $('#' + element).attr('data-modal');
            // eslint-disable-next-line prefer-template, max-len
            const mergeUrl = ACC.config.encodedContextPath + '/checkout/express-checkout/buy-now/merge?performMerge=' + $('#' + element).attr('data-isMerge');
            $.ajax({
                url: mergeUrl,
                type: 'POST',
                dataType: 'json',
                contentType: 'application/json',
                // eslint-disable-next-line max-len
                data: ACC.expresscheckout.getJSONDataForExpressCheckout(productSelectedData),
                // eslint-disable-next-line object-shorthand
                success: function (response) {
                    ACC.expresscheckout.handleExpressCheckoutResult(response);
                },
                error(jqXHR, textStatus, errorThrown) {
                    // log the error to the console
                    // eslint-disable-next-line no-console
                    console.log(`The following error occurred: ${textStatus}`, errorThrown);
                },
            });
        }
    },

    handleExpressCheckoutResult(response) {
        const urlForRedirect = response.redirectUrl;
        if (urlForRedirect.includes('https')) {
            window.location = urlForRedirect;
        } else {
            window.location = ACC.config.encodedContextPath + urlForRedirect;
        }

        if (response.errorCode !== '') {
            ACC.expresscheckout.updateLocalStorageToDisplayGlobalMessage(response);
        }
    },

    handleExpressCheckoutRedirectionWithHeaders(jqXHR) {
        const errorCode = jqXHR.getResponseHeader('express-checkout-error-code');
        const redirectUrl = jqXHR.getResponseHeader('Location');

        if (redirectUrl !== null) {
            if (redirectUrl.includes('https')) {
                window.location = redirectUrl;
            } else {
                window.location = ACC.config.encodedContextPath + redirectUrl;
            }
        }
        if (errorCode !== null) {
            window.localStorage.setItem('ECRedirectMessageCode', errorCode);
        }
    },

    getJSONDataForExpressCheckout(data) {
        const skusAsJSON = [];
        data = data.toString();
        const productDataList = data.split(',');
        // eslint-disable-next-line guard-for-in,no-restricted-syntax, no-plusplus
        for (let i = 0; i < productDataList.length; i++) {
            const productData = productDataList[i].split('-');
            skusAsJSON.push({ product: { code: productData[0] }, quantity: productData[1] });
        }

        return JSON.stringify({ cartEntries: skusAsJSON });
    },

    openMergeCartModalForExpressCheckout(result) {
        $modal.open(null, {
            html: result.expressCheckoutMergeModalLayer,
        });
    },

    isUserLoggedIn() {
        return ACC.forgerock.isAuthGenerated();
    },

    updateLocalStorageToDisplayGlobalMessage(response) {
        window.localStorage.setItem('ECRedirectMessageCode', response.errorCode);
        window.localStorage.setItem('ECRedirectGlobalMessageType', response.globalMessageType);
        window.localStorage.setItem('ECredirectGlobalMessageAttributes', response.attributes);
    },

    removeLocalStorageWithGlobalMessageData() {
        window.localStorage.removeItem('ECRedirectMessageCode');
        window.localStorage.removeItem('ECRedirectGlobalMessageType');
        window.localStorage.removeItem('ECredirectGlobalMessageAttributes');
    },
};
