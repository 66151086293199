ACC.paypal = {

    _autoload: [
        'bindPlaceDigitalOrder',
        'bindBillingAddressListeners',
        ['mojoPaypal', $('.js-paypal-review-order').length !== 0],
    ],

    taxesCalculationFailedId: '#taxesCalculationFailed',

    handleOrderPlaceAdditionalData() {
        // Nothing here, can be overridden to add custom logic
    },

    bindPlaceDigitalOrder() {
        const checkbox = $('#termsConditions');
        const placeOrderBtn = $('#paypalDigitalPlaceOrder');
        const isTaxesCalculationFailed = $(ACC.paypal.taxesCalculationFailedId).val() === 'true';
        const isBillingFormPresent = $('#billingAddressValidationForm').length;

        checkbox.on('click', () => {
            if (!checkbox.is(':checked') || (isBillingFormPresent && isTaxesCalculationFailed)) {
                placeOrderBtn.prop('disabled', true);
            } else {
                placeOrderBtn.prop('disabled', false);
            }
        });

        placeOrderBtn.click(() => {
            ACC.buttonhelper.disable(placeOrderBtn).withSpinner();
            ACC.paypal.handleOrderPlaceAdditionalData();
            let authorizeOrderUrl;
            if (ACC.paypalIntegrationType === 'cybersourcePaypal') {
                authorizeOrderUrl = `${ACC.config.encodedContextPath}/checkout/payment/cybs-paypal/authorizeOrder`;
            } else {
                authorizeOrderUrl = `${ACC.config.encodedContextPath}/checkout/payment/paypal/authorizeOrder`;
            }
            ACC.forms.createAndSubmitForm(
                authorizeOrderUrl,
                {
                    CSRFToken: ACC.config.CSRFToken,
                },
            );
        });
    },

    mojoPaypal() {
        const placeOrderBtn = $('.js-paypal-review-order');
        if ($(ACC.paypal.taxesCalculationFailedId).val() === 'true') {
            placeOrderBtn.prop('disabled', true);
        } else {
            placeOrderBtn.prop('disabled', false);
        }

        $(placeOrderBtn).on('click', function () {
            const processingOrderMsg = $(this).data('processing-order-msg');
            processingOrderMsg ? ACC.buttonhelper.disable($(this), processingOrderMsg).withSpinner()
                : ACC.buttonhelper.disable($(this)).withSpinner();
            ACC.paypal.handleOrderPlaceAdditionalData();

            let authorizeOrderUrl;
            if (ACC.paypalIntegrationType === 'cybersourcePaypal') {
                authorizeOrderUrl = `${ACC.config.encodedContextPath
                }/checkout/billingAgreement/cybs-paypal/createBillingAgreement`;
            } else {
                authorizeOrderUrl = `${ACC.config.encodedContextPath}/checkout/payment/paypal/authorizeOrder`;
            }

            ACC.forms.createAndSubmitForm(
                authorizeOrderUrl,
                {
                    CSRFToken: ACC.config.CSRFToken,
                },
            );
        });
    },

    bindBillingAddressListeners() {
        $(document).on('focusout', '#billingAddressValidationForm input', (e) => {
            e.preventDefault();
            const billingAddressBtn = $('#billingAddressValidationBtn');
            const billingSubscriptionAddressBtn = $('#subscriptionBillingAddressValidationBtn');
            if ($('div').hasClass('has-error')) {
                billingAddressBtn.prop('disabled', true);
                billingSubscriptionAddressBtn.prop('disabled', true);
            } else {
                billingAddressBtn.prop('disabled', false);
                billingSubscriptionAddressBtn.prop('disabled', false);
            }
        });
    },
};
