ACC.removeOrganization = {

    _autoload: [],

    doAction() {
        const button = $('.js-remove-organization-button');
        const actionUrl = button.attr('data-action-url');
        const unitUid = button.attr('data-unit-uid');

        $.post({
            url: actionUrl,
            data: { unitUid },
            complete(data) {
                ACC.colorbox.close();
                if (data.status === 205) {
                    window.location.replace('/store');
                }
            },
        });
    },
};
