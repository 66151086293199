ACC.accounts = {

    _autoload: [
        'moveAddOrgBtn',
        'displayPersonalAccount',
        'bindAccountsButtons',
        'addAddressOnClick',
        ['disableContext', $('#disabledContext').length !== 0],
        'selectContext',
        ['disableJobTitleOption', $('#profileJobTitle').length !== 0],
        'removeBillingAddress',
        'bindRemoveOrgSubmitModal',
    ],

    context: {
        contextUid: null,
    },

    spinner: $(`<img src='${ACC.config.commonResourcePath}/images/spinner.gif' />`),

    moveAddOrgBtn() {
        const acctSectionHeader = '.page-accounts .c-sectionHeader';
        const addressSectionHeader = '.page-addresses .c-sectionHeader';
        const addOrgBtnClass = '.add-org.text-right a';
        const addAddressBtn = '#buttonAddAddress';
        $(acctSectionHeader).removeClass('hasBorderBottom');
        $(addressSectionHeader).removeClass('hasBorderBottom');
        $(addOrgBtnClass).addClass('c-sectionHeader__btn').appendTo(acctSectionHeader);
        $(addAddressBtn).addClass('c-sectionHeader__btn').appendTo(addressSectionHeader);
    },

    displayPersonalAccount() {
        $('.js-accounts-selected .row-accounts').hide();
        $('.loadingIndicator').hide();
        // Needed to show the cards under Addresses page
        $('.js-accounts-selected .row-accounts[data-account-party-type="PERSON"]').show();
        // Needed to show the cards under Accounts page
        $('.js-accounts-selected .row-accounts[data-account-name="Personal Account"]').show();
        ACC.accounts.context.contextUid = $('.js-accounts-selected .row-accounts').attr('data-account-uid');
    },

    selectContext() {
        $('.js-accounts-selection-button[data-select-context="true"]').click();
    },

    bindAccountsButtons() {
        const activeClass = 'active';
        const disabledClass = 'disabled';
        const activeElement = '.accountPageSideContent .active';

        $('.js-accounts-selection-button').on('click', function () {
            if (!$(this).parent().hasClass(activeClass)) {
                $(activeElement).children().removeAttr(disabledClass);
                $(activeElement).removeClass(activeClass);
                $(this).parent().addClass(activeClass);
                $(this).attr(disabledClass, disabledClass);
            } else {
                $(this).parent().addClass(activeClass);
            }
            $('.js-accounts-selected .row-accounts').hide();
            $(`.js-accounts-selected .row-accounts[data-account-uid="${$(this).attr('data-account-uid')}"]`).show();
            ACC.accounts.context.contextUid = $(this).attr('data-account-uid');
            ACC.accounts.decideIfOrgCertificateIsNeeded(
                { orgUid: $(this).attr('data-account-uid') },
                $('#certificateCard'),
                $('.loadingIndicator'),
                ACC.accounts.spinner,
            );
        });

        $('#ctx-selector').change(function () {
            $('.js-accounts-selected .row-accounts').hide();
            // eslint-disable-next-line max-len
            $(`.js-accounts-selected .row-accounts[data-account-uid="${$('option:selected', this).attr('data-account-uid')}"]`).show();
            ACC.accounts.context.contextUid = $('option:selected', this).attr('data-account-uid');
            ACC.accounts.decideIfOrgCertificateIsNeeded(
                { orgUid: $('#ctx-selector option:selected').attr('data-account-uid') },
                $('#certificateCard'),
                $('.loadingIndicator'),
                ACC.accounts.spinner,
            );
        });
    },

    findOrgCertificate(options, certificateCard, loadingIndicator, spinner) {
        $.ajax({
            url: `${ACC.config.encodedContextPath}/accounts/findCertificate`,
            async: true,
            type: 'GET',
            data: options,
            dataType: 'html',
            beforeSend() {
                $('.loadingIndicator').show();
                loadingIndicator.html(spinner);
                $('.js-accounts-selection-button').prop('disabled', true);
            },
        }).done((data) => {
            certificateCard.empty();
            $('.js-accounts-selection-button').prop('disabled', false);
            if (data !== '') {
                $('.loadingIndicator').hide();
                certificateCard.show();
            }
            certificateCard.append($(data).html());
        });
    },

    decideIfOrgCertificateIsNeeded(options, certificateCard, loadingIndicator, spinner) {
        $.ajax({
            url: `${ACC.config.encodedContextPath}/accounts/isOrgCertificateNeeded`,
            type: 'GET',
            dataType: 'text',
            data: options,
        }).done((data) => {
            if (data === 'true') {
                ACC.accounts.findOrgCertificate(options, certificateCard, loadingIndicator, spinner);
            }
        });
    },

    addAddressOnClick() {
        $('.add-address-js').click((e) => {
            e.preventDefault();
            window.location = `${$('.add-address-js').attr('data-url')}?context=${ACC.accounts.context.contextUid}`;
            return false;
        });
    },

    disableContext() {
        if (document.getElementById('disabledContext').value !== 'true') {
            document.getElementById('address.context').removeAttribute('readonly');
        } else {
            document.getElementById('address.context').setAttribute('readonly', 'readonly');
            document.getElementById('address.context').style = 'pointer-events: none;';
        }
    },

    disableJobTitleOption() {
        $('#profileJobTitle option').each((index, element) => {
            if (element.text === document.getElementById('jobTitleNotPresentOnCurrentStore').value) {
                element.style = 'display: none;';
            }
        });
    },

    removeBillingAddress() {
        $('.js-remove-billing-address').on('click', function (e) {
            e.preventDefault();
            $modal.open(this);
        });
    },

    bindRemoveOrgSubmitModal() {
        const removeOrgModalSubmitButton = $('.js-remove-org-modal-submit-button');
        removeOrgModalSubmitButton.click((e) => {
            e.preventDefault();
            ACC.buttonhelper.disable(e.target).withSpinner();
            e.target.closest('form').submit();
        });
    },
};
