ACC.qualification = {

    _autoload: [
        ['bindExpirationTimeForQualification', $('#qual-expirationDate').length !== 0],
        'bindCountrySelection',
        'showForAccreditationCheck',
        'checkIfRegionDataIsPresent',
    ],

    bindExpirationTimeForQualification() {
        const minDate = new Date();

        $('#qual-expirationDate').datepicker({
            dateFormat: 'yy-mm-dd',
            constrainInput: true,
            minDate,
            showOtherMonths: true,
            selectOtherMonths: true,
        });
    },

    bindCountrySelection() {
        const countrySelector = $('#countryIso');
        // eslint-disable-next-line no-unused-vars
        const phoneNumberInput = $('#phoneNumber');

        countrySelector.on('change', function () {
            const countryIsoCode = $(this).val();
            if (countryIsoCode !== null) {
                const url = window.location.href.toString().endsWith('add') ? '' : '../';
                $.get(`${url}regions`, { countryIsoCode }, (data) => {
                    ACC.qualification.addOptions(data, $('#state'));
                    if (data.length > 0 && !ACC.qualification.isCountryIsoCodeGB(countryIsoCode)) {
                        $('#accreditedInstitutionQualificationForm-state-input-js').show();
                    } else {
                        $('#accreditedInstitutionQualificationForm-state-input-js').hide();
                    }
                });

                $.get(`${url}phoneCodes`, { countryIsoCode }, (data) => {
                    ACC.qualification.addOptions(data, $('#phoneCountryCode'), true);
                });
            }
        });
    },

    showForAccreditationCheck() {
        $('#accredited').change(() => {
            $('#forAccreditation').closest('.checkbox').toggleClass('collapse');
            document.getElementById('forAccreditation').checked = false;
        });
    },

    checkIfRegionDataIsPresent() {
        if ($('#state option').length > 1 && !ACC.qualification.isCountryIsoCodeGB($('#countryIso').val())) {
            $('#accreditedInstitutionQualificationForm-state-input-js').show();
        }
    },

    isCountryIsoCodeGB(countryIsoCode) {
        return countryIsoCode === 'GB';
    },

    addOptions(data, combo, plainValue) {
        combo.children('option').not(':first').remove();
        $.each(data, (index) => {
            combo.append($(
                '<option/>',
                {
                    value: plainValue ? data[index] : data[index].isocode,
                    text: plainValue ? data[index] : data[index].name,
                },
            ));
        });
    },
};
