ACC.spinner = {

    _autoload: [],

    start(message) {
        ACC.spinner.attach(message);

        $('#cboxOverlay').css({ 'background-color': 'rgba(255, 255, 255, 0.5)' });
        const customModalSettings = {
            escKey: false,
            overlayClose: false,
            closeButton: false,
            trapFocus: true,
            onComplete() {
                $('#cboxLoadedContent').removeAttr('style');
                $('#cboxContent').css({ background: 'transparent', float: '', border: 'none' });
            },
        };

        $modal.open(null, customModalSettings);
    },

    stop(timeout, callback, data) {
        // eslint-disable-next-line no-unused-expressions
        timeout ? setTimeout(() => {
            ACC.spinner.detach(callback, data);
        }, timeout) : ACC.spinner.detach(callback, data);
    },

    attach(message) {
        const messageValue = message || '';
        let content = '';
        let spinnerCircle = {};

        if (ACC.spinner.isAlreadyAttached()) {
            spinnerCircle = $('#spinnerCircle');
            spinnerCircle.find('.spinner-circle-description').text(messageValue);
            spinnerCircle.removeClass('hidden');
            return;
        }

        content = '<div id="spinnerCircle">'
                      + '<div class="sk-fading-circle">'
                          + '<div class="sk-circle1 sk-circle"></div>'
                          + '<div class="sk-circle2 sk-circle"></div>'
                          + '<div class="sk-circle3 sk-circle"></div>'
                          + '<div class="sk-circle4 sk-circle"></div>'
                          + '<div class="sk-circle5 sk-circle"></div>'
                          + '<div class="sk-circle6 sk-circle"></div>'
                          + '<div class="sk-circle7 sk-circle"></div>'
                          + '<div class="sk-circle8 sk-circle"></div>'
                          + '<div class="sk-circle9 sk-circle"></div>'
                          + '<div class="sk-circle10 sk-circle"></div>'
                          + '<div class="sk-circle11 sk-circle"></div>'
                          + '<div class="sk-circle12 sk-circle"></div>'
                     + '</div>'
                     + `<p class="spinner-circle-description">${messageValue}</p>`
                 + '</div>';

        $('body').append(content);
    },

    detach(callback, data) {
        ACC.spinner.hide();
        $modal.close();

        if (callback) {
            callback(data);
        }
    },

    isAlreadyAttached() {
        return $('#spinnerCircle').length;
    },

    hide() {
        $('#spinnerCircle').addClass('hidden');
    },
};
