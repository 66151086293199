ACC.multidgrid = {

    populateAndShowGridOverlay(element, event) {
        event.preventDefault();

        const itemIndex = $(element).data('index');
        const gridEntries = $(`#grid${itemIndex}`);

        const strSubEntries = gridEntries.data('sub-entries');
        const productName = gridEntries.data('product-name');
        const arrSubEntries = strSubEntries.split(',');
        const firstVariantCode = arrSubEntries[0].split(':')[0];

        const targetUrl = `${gridEntries.data('target-url')}?productCode=${firstVariantCode}`;

        ACC.colorbox.open(productName, {
            href: targetUrl,
            className: 'read-only-grid',
            close: '<span class="glyphicon glyphicon-remove"></span>',
            // eslint-disable-next-line no-undef
            width: window.innerWidth > parseInt(cboxOptions.maxWidth, 10) ? cboxOptions.maxWidth : cboxOptions.width,
            // eslint-disable-next-line no-undef,max-len
            height: window.innerHeight > parseInt(cboxOptions.maxHeight, 10) ? cboxOptions.maxHeight : cboxOptions.height,
            onComplete() {
                $('body').addClass('offcanvas');
                const oH = $('#cboxLoadedContent').height();
                $('#cboxLoadedContent').height(`${oH - $('#cboxTitle').height()}px`);
            },

            onClosed() {
                $('body').removeClass('offcanvas');
            },

        });
    },

    populateAndShowGrid(element) {
        const itemIndex = $(element).data('index');
        grid = $(`#ajaxGrid${itemIndex}`);
        const gridEntries = $(`#grid${itemIndex}`);

        $(element).toggleClass('open');

        if (!grid.is(':hidden')) {
            grid.slideUp();
            return;
        }

        if (grid.html() !== '') {
            grid.slideToggle('slow');
            return;
        }

        const strSubEntries = gridEntries.data('sub-entries');
        const arrSubEntries = strSubEntries.split(',');
        const firstVariantCode = arrSubEntries[0].split(':')[0];

        const targetUrl = gridEntries.data('target-url');

        const method = 'GET';
        $.ajax({
            url: targetUrl,
            data: { productCode: firstVariantCode },
            type: method,
            success(data) {
                // eslint-disable-next-line no-undef
                grid.html(DOMPurify.sanitize(data));
                grid.slideDown('slow');
            },
            error(xht, textStatus, ex) {
                alert(`Failed to get variant matrix. Error details [${xht}, ${textStatus}, ${ex}]`);
            },

        });
    },
};
