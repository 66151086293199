/** ###########################################################################################################
 ###########################################################################################################

    Modals Functionality

    ----------------------------------------------------------------------------------------------------------
    Dependency:
    - Colorbox modal plugin ( v1.6.4 )

    This functionality is used as abstraction layer between any third party library for modals
    and usage of modals in our store/templates.

    That way we can easy replace any library without readjustement in all .js/.jsp files.

    ----------------------------------------------------------------------------------------------------------
    Usage:
    - Attach "data-modal-title" and "data-modal-content" attributes to button that triggers modal opening
    - Open: Inside .js trigger modal open function with clicked element passed as parameter => "$modal.open(clickedEl)"
    - Close: Inside .js trigger modal close function without any parameters => "$modal.close()""

*/

// ###########################################################################################################
// Colorbox library global settings

const COLORBOX = {
    className: 'isHidden',
    opacity: 0.85,
    close: '<span class="screenreader">Close</span>',
    fixed: true,
    reposition: false,
    transition: 'none',
    top: '0',
    left: '0',
    data: true,
    title() {
        // Return title element
        return '<h4 class="headline"><span class="headline-text"></span></h4>';
    },
    html() {
        // Get modal content data from element
        const htmlContent = $.colorbox.element()[0].dataset.modalContent;
        // If value has is ID of the element, return this as $ object
        if (htmlContent && htmlContent[0] === '#') {
            // Copy content with all events ( true flag in clone method )
            const contentCopy = $(htmlContent).clone(true);
            return $(contentCopy);
        }
        // Else return it as string wrapped in DIV container
        return htmlContent ? `<div><p>${htmlContent}</p></div>` : '';
    },

    onOpen() {
        $('#colorbox').css('opacity', 0);
    },

    onLoad() {},

    onComplete() {
        const colorBoxModal = document.querySelectorAll('#colorbox')[0];
        const cboxContent = colorBoxModal.querySelector('#cboxContent');
        const cboxTitle = cboxContent.querySelector('#cboxTitle');
        const { modalTitle } = $.colorbox.element()[0].dataset;

        // Remove some of #colorbox default styles
        colorBoxModal.style.width = '';
        colorBoxModal.style.height = '';

        // Remove title inline styles
        cboxTitle.removeAttribute('style');

        // Remove title if content is not present
        if (modalTitle) {
            // Set title if available ( attached as data attribute to clicked element )
            cboxTitle.querySelector('.headline-text').textContent = modalTitle;
        } else if ($.colorbox.arguments && typeof $.colorbox.arguments[0].title === 'string') {
            // Set title if passed as argument while calling modals library without clicked element
            // eslint-disable-next-line max-len
            cboxTitle.innerHTML = `<h4 class="headline"><span class="headline-text">${$.colorbox.arguments[0].title}</span></h4>`;
        } else {
            // Remove title DOM NODE if title is undefined
            cboxTitle.parentNode.removeChild(cboxTitle);
        }

        // Remove styles from one of inner divs
        colorBoxModal.querySelector('#cboxMiddleRight').removeAttribute('style');
        colorBoxModal.querySelector('#cboxMiddleLeft').removeAttribute('style');

        // Remove style attribute on #cboxWrapper & #cboxContent
        colorBoxModal.querySelector('#cboxWrapper').removeAttribute('style');
        cboxContent.removeAttribute('style');
        cboxContent.querySelector('#cboxLoadedContent').removeAttribute('style');

        $('#colorbox').css('opacity', 1);

        // Remove hidden class ( change modal state from hidden to visible )
        setTimeout(() => {
            colorBoxModal.classList.remove('isHidden');
        }, 200);
    },
};

// ###########################################################################################################
// Our functionality written as $modal class with predefined methods
// for opening and closing modal windows

// eslint-disable-next-line no-unused-vars,func-names
const $modal = (function () {
    // Open modal
    // eslint-disable-next-line no-underscore-dangle, func-names
    const _open = function (clickedEl, content) {
        // In case of additional content that should be passed
        // exclude DOM element which brings properties such as
        // modal title and content
        if (clickedEl === null && content) {
            // Concatenate global settings and override with our custom ( jQuery dependency )
            const options = $.extend({}, COLORBOX, content);
            // and open modal with defined settings
            return $.colorbox(options);
        }

        if (clickedEl && content) {
            // Concatenate global settings and override with our custom ( jQuery dependency )
            const options = $.extend({}, COLORBOX, content);
            // and open modal with defined settings
            return $(clickedEl).colorbox(options);
        }

        // In case of classic usage, just trigger modal library, attach it to DOM element
        // and pass global settings to it.
        // ( IMPORTANT: This element should have title and modal content attached as HTML 5 data attributes
        // data-modal-title and data-modal-content )
        return $(clickedEl).colorbox(COLORBOX);
    };

    // Close modal
    // eslint-disable-next-line no-underscore-dangle,func-names
    const _close = function () {
        // Trigger modal library's internat closing method
        return $.colorbox.close();
    };

    // Return whole $modal functionality as JS Object with public methods
    // open and close mapped to private methods equivalent
    return {
        open: _open,
        close: _close,
    };
}());
