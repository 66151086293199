ACC.mobilecheck = {

    _autoload: ['mobileCheck'],

    mobileCheck() {
        const mainNavDisplay = $('.menu-browse .child-page-list-level-1').css('display');

        if ($('header').css('z-index') === '253') {
            ACC.screenState.isMobile = true;
        }

        if ($('header').css('z-index') === '254') {
            ACC.screenState.isTablet = true;
        }

        if ($('header').css('z-index') === '252') {
            ACC.screenState.isDesktop = true;
        }

        // check for browser variants of display: flex
        if (mainNavDisplay === '-webkit-box' || mainNavDisplay === 'flex' || mainNavDisplay === '-ms-flexbox') {
            ACC.screenState.mainNavNotCollapsed = true;
        }
    },

};
