ACC.minicart = {

    _autoload: [],

    updateMiniCartDisplay(totalItems) {
        const cartQuantityAnchor = $('.userNav__cart');
        const cartQuantitySpan = cartQuantityAnchor.find('span');
        if (totalItems >= 100) {
            totalItems = '99+';
        }
        cartQuantityAnchor.attr('data-item-number', totalItems);
        cartQuantityAnchor.attr('aria-label', `Shipping cart, ${totalItems}`);
        cartQuantitySpan.html(totalItems);
    },
};
