ACC.carthelp = {

    _autoload: [
        'bindCartHelp',
    ],

    bindCartHelp() {
        $('#help-message-link').on('click', function (e) {
            e.preventDefault();
            $modal.open(this);
        });
    },

};
